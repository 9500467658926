<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>Stock Request</h6>
      <button type="button" class="btn btn-primary" @click="showAddModal = true"
        style="background-color: #141727;color:white;border:2px solid #141727">Add Resource</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Users</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Material</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Resource Name</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quantity</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Action</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resource, index) in resources" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ resource.user }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ resource.material }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ resource.resource_name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ resource.quantity }}</p>
              </td>
              <td class="align-middle">
                <select v-model="resource.action" class="form-control">
                  <option value="approve">Approve</option>
                  <option value="reject">Reject</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resources: [
        {
          user: "Karan Salve",
          material: "Caps",
          resource_name: "Campaign Cap A",
          quantity: 100,
          action: "approve"
        },
        {
          user: "Chirag ambani",
          material: "Posters",
          resource_name: "Campaign Poster B",
          quantity: 50,
          action: "reject"
        }
      ]
    };
  },
  methods: {
  }
};
</script>

<style scoped>
.table {
  width: 100%;
  margin-top: 20px;
}

.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}
</style>
