<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h5>Edit Task</h5>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addTask" class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="selectevent">{{ $t("events") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newTask.event_id"
                  class="form-control select-with-arrow"
                  id="selectevent"
                  required
                >
                  <option value="" selected>{{ $t("select_event") }}</option>
                  <option
                    v-for="event in events"
                    :key="event.id"
                    :value="event.id"
                  >
                    {{ event.event_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="taskName">Task Name</label>
              <input
                v-model="newTask.task_name"
                type="text"
                class="form-control"
                id="taskName"
                required
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="taskStatus">{{ $t("status") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newTask.status"
                  class="form-control select-with-arrow"
                  id="taskStatus"
                  required
                >
                  <option value="0">{{ $t("pending") }}</option>
                  <option value="1">{{ $t("accept") }}</option>
                  <option value="2">{{ $t("work_in_progress") }}</option>
                  <option value="3">{{ $t("completed") }}</option>
                  <option value="4">{{ $t("deferred") }}</option>
                  <option value="5">{{ $t("cancelled") }}</option>
                  <option value="6">{{ $t("cancelled_by_admin") }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="assignUser">{{ $t("assigned_user") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newTask.assign"
                  class="form-control select-with-arrow"
                  id="assignUser"
                  required
                >
                  <option value="" disabled selected>
                    {{ $t("select_user") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="assignGroup">{{ $t("assign_team") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newTask.assignGroup"
                  class="form-control select-with-arrow"
                  id="assignGroup"
                  required
                >
                  <option value="" disabled selected>
                    {{ $t("select_team") }}
                  </option>
                  <option
                    v-for="group in voterGroups"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="startDateTime">Start Date Time</label>
              <input
                v-model="newTask.startDateTime"
                type="datetime-local"
                class="form-control"
                id="startDateTime"
                required
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="endDateTime">End Date Time</label>
              <input
                v-model="newTask.endDateTime"
                type="datetime-local"
                class="form-control"
                id="endDateTime"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="vehicles">Vehicles</label>
              <multiselect
                v-model="newTask.vehicles"
                :options="availableVehicles"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="vehicle_name"
                track-by="vehicle_name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="resources">Resources</label>
              <multiselect
                v-model="newTask.resources"
                :options="availableResources"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="resource_name"
                track-by="resource_name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="specialNotes">Special Notes</label>
              <input
                v-model="newTask.specialNotes"
                type="text"
                class="form-control"
                id="specialNotes"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="taskDescription">Task Description</label>
              <textarea
                v-model="newTask.description"
                class="form-control"
                id="taskDescription"
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button
              type="submit"
              class="btn btn-primary mt-3"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
              "
              :disabled="isLoading"
              :loading="isLoading"
            >
              Update
            </button>
          </div>
        </div>
      </form>
      <div
        class="card-body px-0 pt-0 pb-2 subtaskcard"
        v-if="newTask.subtasks && newTask.subtasks.length > 0"
      >
        <div class="table-responsive">
          <table class="table align-items-center mb-0" style="padding: 20px">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Task Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Task Description
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Special Notes
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Start Date Time
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  End Date Time
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Status
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="subtask in newTask.subtasks" :key="subtask.id">
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ subtask.task_name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ subtask.task_description }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ subtask.special_notes }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ formatDateTime(subtask.start_date_time) }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ formatDateTime(subtask.end_date_time) }}
                  </p>
                </td>
                <td>
                  <select
                    v-model="subtask.status"
                    class="form-select text-xs font-weight-bold"
                    @change="updateSubTaskStatus(subtask)"
                  >
                    <option value="0">Pending</option>
                    <option value="1">Work-in-Progress</option>
                    <option value="2">Completed</option>
                    <option value="3">Deferred</option>
                    <option value="4">Cancelled</option>
                    <option value="5">Cancelled by Admin</option>
                  </select>
                </td>
                <td class="align-middle">
                  <!-- <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit task">
                  <i class="fas fa-edit"></i>
                </a> -->
                  <a
                    href="javascript:;"
                    class="text-danger font-weight-bold text-xs ms-3"
                    @click="confirmDelete(subtask, subtask.id)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import TaskDataService from "../../services/TaskDataService.js";
import SubTaskDataService from "../../services/SubTaskDataService.js";
//import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import TeamDataService from "../../services/TeamDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import VehicleDataService from "../../services/VehicleDataService.js";
import EventDataService from "../../services/EventDataService.js";
import { formatDateTime } from "../../utils.js";
import Swal from "sweetalert2";
import ResourcesDataService from "../../services/ResourcesDataService.js";
import moment from "@/moment";

export default {
  name: "EditTask",
  components: {
    Multiselect,
  },
  data() {
    return {
      tasks: [],
      taskId: null,
      isLoading: false,
      newTask: {
        task_name: "",
        description: "",
        status: "0",
        assign: null,
        event_id: "",
        assignGroup: null,
        specialNotes: "",
        startDateTime: "",
        endDateTime: "",
        vehicles: [],
        resources: [],
        subtasks: [],
      },
      voterGroups: [],
      users: [],
      events: [],
      availableVehicles: [],
      availableResources: [],
    };
  },
  created() {
    this.taskId = this.$route.params.id;
    this.getTask(this.taskId);
    this.getAllGroups();
    this.getAllUsers();
    this.getAllVehicles();
    this.getAllEvents();
    this.getAllResources();
  },
  methods: {
    formatDateTime(date) {
      return new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(new Date(date));
    },
    getTask(taskId) {
      TaskDataService.get(taskId)
        .then((response) => {
          console.log("TAKE DATA :" + response.data.task);
          const taskData = response.data.task;

          this.newTask = {
            id: taskData.id,
            task_name: taskData.task_name || "",
            description: taskData.task_description || "",
            status: taskData.status || "0",
            assign: taskData.assigned_user || "",
            event: taskData.event_id || "",
            event_id: taskData.event_id || "",
            assignGroup: taskData.assigned_group || "",
            specialNotes: taskData.special_notes || "",
            startDateTime: formatDateTime(taskData.start_date_time) || "",
            endDateTime: formatDateTime(taskData.end_date_time) || "",
            vehicles: taskData.vehicles,
            resources: taskData.resources,
            subtasks: taskData.subtasks || [],
          };
          console.log(this.newTask);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllGroups() {
      // VoterGroupsDataService.getAll()
      //   .then(response => {
      //     this.voterGroups = response.data.voter_group;
      //     console.log(this.voterGroups);
      //   })
      //   .catch(e => {
      //     console.log(e);
      //   });
      TeamDataService.getAll()
        .then((response) => {
          this.voterGroups = response.data.teams;
          console.log(this.voterGroups);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllUsers() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
          console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllVehicles() {
      VehicleDataService.getAll()
        .then((response) => {
          this.availableVehicles = response.data.vehicles;
          console.log(this.availableVehicles);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllResources() {
      // Assuming you have a data service for resources
      ResourcesDataService.getAll()
        .then((response) => {
          this.availableResources = response.data.resources;
          console.log(this.availableResources);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllEvents() {
      EventDataService.getAll()
        .then((response) => {
          this.events = response.data.events;
          console.log(this.events);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateSubTaskStatus(subtask) {
      const payload = {
        id: subtask.id,
        status: subtask.status,
      };
      console.log(payload);
      SubTaskDataService.updateStatus(payload)
        .then((response) => {
          console.log(response);
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "SubTask status updated successfully!",
              confirmButtonText: "OK",
            }).then(() => {});
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addTask() {
      this.isLoading = true;
      const stdate = moment(this.newTask.startDateTime)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD HH:mm:ss");
      const eddate = moment(this.newTask.endDateTime)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD HH:mm:ss");

      const newTask = {
        id: this.newTask.id,
        task_name: this.newTask.task_name,
        task_description: this.newTask.description,
        status: this.newTask.status,
        event_id: this.newTask.event_id,
        assigned_group: this.newTask.assignGroup,
        assigned_user: this.newTask.assign,
        special_notes: this.newTask.specialNotes,
        start_date_time: stdate,
        end_date_time: eddate,
        // vehicles: Array.isArray(this.newTask.vehicles) ? this.newTask.vehicles : [this.newTask.vehicles],
        // resources: Array.isArray(this.newTask.resources) ? this.newTask.resources : [this.newTask.resources]
        vehicles: this.newTask.vehicles.map((vehicle) => vehicle.id),
        resources: this.newTask.resources.map((resource) => resource.id),
      };

      TaskDataService.update(newTask).then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Task updaed successfully!",
          confirmButtonText: "OK",
        }).then(() => {
          this.tasks.push(newTask);

          // Reset the form fields
          this.newTask.name = "";
          this.newTask.description = "";
          this.newTask.status = "Pending";
          this.newTask.assign = "Chirag";
          this.newTask.event = "Event1";
          this.newTask.assignGroup = "Group 1";
          this.newTask.specialNotes = "";
          this.newTask.startDateTime = "";
          this.newTask.endDateTime = "";
          this.newTask.vehicles = [];
          this.newTask.resources = [];
          this.$router.push("/task");
        });

        console.log(response);
        this.showAddModal = false;
        //this.getAllArea();
      });
    },
    confirmDelete(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTask(index, id);
          Swal.fire(
            "Deleted!",
            "The SubTask has been deleted.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    },
    deleteTask(index, id) {
      SubTaskDataService.delete(id)
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.card {
  margin-bottom: 20px;
}

.multiselect {
  display: block;
}

.text-success {
  margin-left: auto;
  font-weight: bold;
}
.subtaskcard {
  margin: 30px;
  border-radius: 10px;
  background-color: #f2f2f2;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
</style>
