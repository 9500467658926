<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <EditVotersGroup />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <projects-table />
      </div>
    </div> -->
  </div>
</template>

<script>
import EditVotersGroup from "./components/EditVotersGroup";
// import ProjectsTable from "./components/ProjectsTable";

export default {
  name: "Edit Voters Group",
  components: {
    EditVotersGroup,
    // ProjectsTable,
  },
};
</script>
