import http from '../http-common';

class OfficesDataService {
  getAll() {
    return http.get("/office");
  }

  get(id) {
    return http.get(`/office/edit/${id}`);
  }

  create(data) {
    return http.post("/office/add", data);
  }

  update(data) {
    return http.put(`/office/update`, data);
  }

  delete(id) {
    return http.put(`/office/delete/${id}`);
  }
}

export default new OfficesDataService();
