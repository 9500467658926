import http from '../http-common';

class TeamDataService {
  getAll() {
    return http.get("/teams");
  }

  get(id) {
    return http.get(`/teams/edit/${id}`);
  }

  create(data) {
    return http.post("/teams/add", data);
  }

  update(data) {
    return http.put(`/teams/update`, data);
  }

  delete(id) {
    return http.put(`/teams/delete/${id}`);
  }
}

export default new TeamDataService();
