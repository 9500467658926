import http from '../http-common';

class TaskDataService {
  getAll() {
    return http.get("/tasks");
  }
  getAllForReports() {
    return http.get("/reports-tasks");
  }

  get(id) {
    return http.get(`/tasks/edit/${id}`);
  }

  create(data) {
    return http.post("/tasks/add", data);
  }

  update(data) {
    return http.put(`/tasks/update`, data);
  }

  updateStatus(data) {
    return http.put(`/tasks/status-update`, data);
  }

  delete(id) {
    return http.put(`/tasks/delete/${id}`);
  }
}

export default new TaskDataService();
