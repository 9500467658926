import http from '../http-common';

class CandidateDataService {
  getAll() {
    return http.get("/candidate-list");
  }
  getAllUser() {
    return http.get("/super-admin/admin-list");
  }

  get(id) {
    return http.get(`/candidate/edit/${id}`);
  }

  getAdmin(id) {
    return http.get(`/super-admin/admin/edit/${id}`);
  }

  create(data) {
    return http.post("/candidate-register", data);
  }

  createAdmin(data) {
    return http.post("/super-admin/new-admin-register", data);
  }
  update(data) {
    return http.put(`/candidate/update`, data);
  }
  updateAdmin(data) {
    return http.put(`/super-admin/admin/update`, data);
  }

  delete(id) {
    return http.put(`/candidate/delete/${id}`);
  }
}

export default new CandidateDataService();
