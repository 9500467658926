<template>
  <div class="py-4 container-fluid">
    <v-stepper v-model="activeStep" class="stepper-container">
      <v-stepper-header class="stepper-header">
        <v-stepper-step
          :class="{'step-circle': true, 'step-circle--active': activeStep === 1, 'step-circle--complete': activeStep > 1}"
          step="1"
          :editable="true"
        >
          1
        </v-stepper-step>
        <v-divider class="step-divider"></v-divider>

        <v-stepper-step
          :class="{'step-circle': true, 'step-circle--active': activeStep === 2, 'step-circle--complete': activeStep > 2}"
          step="2"
          :editable="true"
        >
          2
        </v-stepper-step>
        <v-divider class="step-divider"></v-divider>

        <v-stepper-step
          :class="{'step-circle': true, 'step-circle--active': activeStep === 3, 'step-circle--complete': activeStep > 3}"
          step="3"
          :editable="true"
        >
          3
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" v-if="activeStep === 1">
          <step-one />
        </v-stepper-content>
        <v-stepper-content step="2" v-if="activeStep === 2">
          <step-two />
        </v-stepper-content>
        <v-stepper-content step="3" v-if="activeStep === 3">
          <step-three />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import StepOne from './Area.vue';
import StepTwo from './AddOffice.vue';
import StepThree from './SignUp.vue';

export default {
  name: 'DashboardStepper',
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
  },
  data() {
    return {
      activeStep: this.step,
    };
  },
  watch: {
    step(newStep) {
      this.activeStep = newStep;
    },
  },
  methods: {
    goToStep(step) {
      this.activeStep = step;
    },
    nextStep() {
      if (this.activeStep < 3) {
        this.activeStep += 1;
      }
    },
    prevStep() {
      if (this.activeStep > 1) {
        this.activeStep -= 1;
      }
    },
  },
};
</script>



<style scoped>
.container-fluid {
  padding: 0 2rem;
}

.stepper-container {
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px;
}

.stepper-header {
  display: flex; 
  align-items: center;
  justify-content: space-between;
}

.step-circle {
  background-color: #bdbdbd;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.step-circle--active {
  background-color: #82d616;
}

.step-circle--complete {
  background-color: #66bb6a;
}

.step-divider {
  flex-grow: 1;
  margin: 0 8px;
  border-top: 2px solid #bdbdbd;
}

.stepper-actions {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.stepper-action-button {
  margin: 0 8px;
}

.stepper-prev {
  background-color: #f44336; /* Red for Previous button */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 999;
}

.stepper-next {
  background-color: #4caf50; /* Green for Next button */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.stepper-action-button:disabled {
  background-color: #bdbdbd; /* Disabled button color */
  cursor: not-allowed;
}
</style>



