<template>
  <div ref="map" style="width: 100%; height: 400px;"></div>
</template>

<script>
export default {
  props: {
    center: {
      type: Object,
      required: true,
    },
    markerPosition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      marker: null,
    };
  },
  watch: {
    center(newCenter) {
      if (this.map) {
        this.map.setCenter(newCenter);
      }
    },
    markerPosition(newPosition) {
      if (this.marker) {
        this.marker.setPosition(newPosition);
      }
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      if (!window.google || !window.google.maps) {
        console.error('Google Maps JavaScript API not loaded');
        return;
      }

      this.map = new google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: 10,
      });

      this.marker = new google.maps.Marker({
        position: this.markerPosition,
        map: this.map,
      });
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 400px;
}
</style>
