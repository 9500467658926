<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>user</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-102.000000, -291.000000)" fill="#000000" fill-rule="nonzero">
        <g transform="translate(102.000000, 291.000000)">
          <path
            class="color-background"
            d="M12,12 C15.3137,12 18,9.3137 18,6 C18,2.6863 15.3137,0 12,0 C8.6863,0 6,2.6863 6,6 C6,9.3137 8.6863,12 12,12 Z M12,14 C8.13,14 0,16.17 0,20 C0,23.87 8.13,26 12,26 C15.87,26 24,23.87 24,20 C24,16.17 15.87,14 12,14 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script></script>
