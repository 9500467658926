<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    ></div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addSurvey" class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyName">Survey Name</label>
              <input
                v-model="newSurvey.survey_name"
                type="text"
                class="form-control"
                id="surveyName"
                required
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyArea">Area</label>
              <div class="select-wrapper">
                <select
                  v-model="newSurvey.area_id"
                  class="form-control select-with-arrow"
                  id="surveyArea"
                  required
                >
                  <option value="" selected>Select area</option>
                  <option
                    v-for="group in area"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyGroup">Group Name</label>
              <div class="select-wrapper">
                <select
                  v-model="newSurvey.group_id"
                  class="form-control select-with-arrow"
                  id="surveyGroup"
                  required
                >
                  <option value="" selected>Select Group Name</option>
                  <option
                    v-for="group in groups"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.voter_group_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyEvent">Event Name</label>
              <div class="select-wrapper">
                <select
                  v-model="newSurvey.event_id"
                  class="form-control select-with-arrow"
                  id="surveyEvent"
                  required
                >
                  <option value="" selected>Select Event Name</option>
                  <option
                    v-for="event in events"
                    :key="event.id"
                    :value="event.id"
                  >
                    {{ event.event_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="form-group">
              <label for="StartDate">Start Date</label>
              <input
                v-model="newSurvey.valid_from"
                type="datetime-local"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="EndDate">End Date</label>
              <input
                v-model="newSurvey.valid_to"
                type="datetime-local"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="surveyLocation">Location</label>
              <!-- <google-map v-model="formattedLocation" :lat="formattedLocation.coordinates[1]" :lng="formattedLocation.coordinates[0]" /> -->
              <input
                id="autocomplete"
                v-model="locationSearch"
                placeholder="Search for a location"
                class="form-control"
              />
              <GoogleMapEdit
                :center="mapCenter"
                :markerPosition="markerPosition"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="surveyDescription">Survey Description</label>
              <textarea
                v-model="newSurvey.survey_description"
                class="form-control"
                id="surveyDescription"
                cols="30"
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="category_id">{{ $t("select_category") }}</label>
                <div class="select-wrapper">
                  <select
                    v-model="newSurvey.category_id"
                    id="category_id"
                    class="form-control select-with-arrow"
                    @change="onCategoryChange"
                    aria-label="category"
                  >
                    <option value="" selected>
                      {{ $t("select_category") }}
                    </option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.category_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Select Questions</label><br />
          <div class="row">
            <div
              class="col-md-4"
              v-for="(question, index) in displayedQuestions"
              :key="index"
            >
              <div class="form-check">
                <input
                  type="checkbox"
                  v-model="newSurvey.questions"
                  :value="question.id"
                  class="form-check-input"
                  :id="'question' + index"
                />
                <label class="form-check-label" :for="'question' + index">{{
                  question.text
                }}</label>
              </div>
            </div>
          </div>
          <button
            v-if="displayedQuestions.length < availableQuestions.length"
            @click="loadMore"
            type="button"
            class="btn btn-secondary mt-3"
          >
            Load More
          </button>
        </div>

        <center>
          <button
            type="submit"
            class="btn btn-primary mt-3"
            style="
              background-color: #141727;
              color: white;
              border: 2px solid #141727;
            "
            :disabled="isLoading"
            :loading="isLoading"
          >
            Update Survey
          </button>
        </center>
      </form>
    </div>
  </div>
</template>

<script>
import GoogleMapEdit from "./GoogleMapEdit.vue";
import AreaDataService from "../../services/AreaDataService.js";
import QuestionsDataService from "../../services/QuestionsDataService.js";
import SurveryMasterDataService from "../../services/SurveryMasterDataService.js";
import EventDataService from "../../services/EventDataService.js";
import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import { formatDateTime } from "../../utils.js";
import Swal from "sweetalert2";
import CategoryMasterDataService from "../../services/CategoryMasterDataService.js";

export default {
  name: "EditSurvey",

  data() {
    return {
      locationSearch: "",
      mapCenter: { lat: 0, lng: 0 },
      markerPosition: { lat: 0, lng: 0 },
      geocoder: null,
      autocomplete: null,
      surveys: [],
      isLoading: false,
      newSurvey: {
        survey_name: "",
        location: { type: "Point", coordinates: [] },
        area: "",
        survey_description: "",
        valid_from: "",
        valid_to: "",
        event_id: "",
        group_id: "",
        category_id: "",
        bck_category_id: "",
        questions: [],
      },
      area: [],
      categories: [],
      showModal: false,
      showQuestionsModalFlag: false,
      selectedSurveyQuestions: [],
      availableQuestions: [],
      displayedQuestions: [],
      questionsToShow: 10,
    };
  },
  components: {
    GoogleMapEdit,
  },
  created() {
    this.survey_id = this.$route.params.id;
    this.getSurveyDetails(this.survey_id);
    this.getAllEvents();
    this.getAllGroups();
    this.getAllAreas();
    this.getAllCategory();
    this.loadMore();
  },

  computed: {
    formattedLocation() {
      if (this.newSurvey.location.coordinates.length === 2) {
        return {
          type: "Point",
          coordinates: [
            this.newSurvey.location.coordinates[0],
            this.newSurvey.location.coordinates[1],
          ],
        };
      } else {
        return { type: "Point", coordinates: [0, 0] };
      }
    },
  },
  methods: {
    fetchLocationData() {
      if (this.newSurvey.location && this.newSurvey.location.coordinates) {
        const [lng, lat] = this.newSurvey.location.coordinates;
        const locationData = {
          lat: lat,
          lng: lng,
          address: this.newSurvey.location_name || "",
        };
        this.locationSearch = locationData.address;
        this.mapCenter = { lat: locationData.lat, lng: locationData.lng };
        this.markerPosition = { lat: locationData.lat, lng: locationData.lng };
      } else {
        console.warn("Location data is not available or invalid.");
      }
    },
    updateMarker() {
      if (!this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }

      this.geocoder.geocode(
        { address: this.locationSearch },
        (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            this.mapCenter = { lat: location.lat(), lng: location.lng() };
            this.markerPosition = { lat: location.lat(), lng: location.lng() };
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    },
    initAutocomplete() {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { types: ["geocode"] }
      );

      this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        this.mapCenter = { lat: location.lat(), lng: location.lng() };
        this.markerPosition = { lat: location.lat(), lng: location.lng() };
        this.locationSearch = place.formatted_address;
      }
    },
    onCategoryChange(event) {
      const categoryId = event.target.value;
      this.displayedQuestions = [];
      this.newSurvey.questions = [];
      this.generateQuestions(categoryId);
    },
    getSurveyDetails() {
      SurveryMasterDataService.get(this.survey_id)
        .then((response) => {
          const taskData = response.data.survey;
          this.newSurvey = {
            id: taskData.id,
            survey_name: taskData.survey_name || "",
            location: taskData.location,
            location_name: taskData.location_name,
            area: taskData.area || "",
            survey_description: taskData.survey_description || "",
            questions: taskData.questions.map((question) => question.id),
            group_id: taskData.group_id || "",
            area_id: taskData.area_id || "",
            event_id: taskData.event_id || "",
            category_id: taskData.category_id || "",
            bck_category_id: taskData.category_id || "",
            valid_from: formatDateTime(taskData.valid_from) || "",
            valid_to: formatDateTime(taskData.valid_to) || "",
          };
          this.fetchLocationData();
          this.generateQuestions(this.newSurvey.category_id);
          console.log(this.newSurvey);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllEvents() {
      EventDataService.getAll()
        .then((response) => {
          this.events = response.data.events;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllGroups() {
      VoterGroupsDataService.getAll()
        .then((response) => {
          this.groups = response.data.voter_group;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllAreas() {
      AreaDataService.getAll()
        .then((response) => {
          this.area = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllCategory() {
      CategoryMasterDataService.getAll()
        .then((response) => {
          this.categories = response.data.categoryMasterData || [];
        })
        .catch((e) => {
          console.log(e);
          this.categories = [];
        });
    },
    generateQuestions(category_id) {
      console.log(category_id);
      if (category_id == "" || category_id == null) {
        QuestionsDataService.getAll()
          .then((response) => {
            this.availableQuestions = response.data.surveyQuestions.map(
              (question) => ({
                id: question.id,
                text: question.question,
                category_id: question.category_id,
              })
            );
            this.loadMore();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        QuestionsDataService.getAllQuestionCategoryWise(category_id)
          .then((response) => {
            this.availableQuestions = response.data.questions.map(
              (question) => ({
                id: question.id,
                text: question.question,
                category_id: question.category_id,
              })
            );
            this.loadMore();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    loadMore() {
      const currentLength = this.displayedQuestions.length;
      const moreQuestions = this.availableQuestions.slice(
        currentLength,
        currentLength + this.questionsToShow
      );
      this.displayedQuestions = this.displayedQuestions.concat(moreQuestions);
    },
    addSurvey() {
      this.isLoading = true;
      const selectedArea = this.area.find(
        (area) => area.id === this.newSurvey.area_id
      );

      const newSurvey = {
        id: this.newSurvey.id,
        survey_id: this.newSurvey.id,
        survey_name: this.newSurvey.survey_name,
        address: this.newSurvey.address ? "mubmai" : "",
        location: {
          type: "Point",
          coordinates: [this.markerPosition.lng, this.markerPosition.lat],
        },
        location_name: this.locationSearch,
        area: selectedArea ? selectedArea.area : "",
        area_id: selectedArea ? selectedArea.id : "",
        event_id: this.newSurvey.event_id,
        group_id: this.newSurvey.group_id,
        category_id: this.newSurvey.category_id,
        valid_from: this.newSurvey.valid_from,
        valid_to: this.newSurvey.valid_to,
        survey_description: this.newSurvey.survey_description,
        questions: this.newSurvey.questions,
      };

      console.log(newSurvey);

      SurveryMasterDataService.update(newSurvey)
        .then((response) => {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Survey updated successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/survey");
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const errorMessages = error.response.data.errors
              .map((err) => `${err.message}`)
              .join("\n");

            Swal.fire({
              icon: "error",
              title: "Error",
              text: errorMessages,
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "An unexpected error occurred. Please try again.",
              confirmButtonText: "OK",
            });
          }
        });
    },
    // updateLocation(location) {
    //   this.newSurvey.location = { lat: location.lat(), lng: location.lng() };
    // },
    showQuestionsModal(questions) {
      this.selectedSurveyQuestions = questions;
      this.showQuestionsModalFlag = true;
    },
    closeQuestionsModal() {
      this.showQuestionsModalFlag = false;
    },
    deleteSurvey(index) {
      this.surveys.splice(index, 1);
    },
  },
  mounted() {
    this.initAutocomplete();
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Space for the custom arrow */
  border: 1px solid #ced4da; /* Match the border style */
  border-radius: 0.25rem; /* Optional: to match the form styling */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode character for down arrow */
  font-size: 0.8rem; /* Adjust size if needed */
  color: #495057; /* Adjust arrow color if needed */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't interfere with interactions */
}

.table {
  width: 100%;
  margin-top: 20px;
}
.modal.fade.show {
  display: block;
}
</style>
