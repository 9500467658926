<template>
  <div class="card mb-4">
    <div class="container">
      <div class="card-header pb-0">
        <h6>{{ $t("Voting Center Location") }}</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <table class="table mt-4">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("Acno") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("Part No.") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("location_name") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
               <center>
                {{ $t("Voting Center Location") }}
               </center>
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("action") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(location, index) in paginatedLocations" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ location.ac_no }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ location.partnos }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ location.location_name }}
                </p>
              </td>
              <td>
                <center>
                  <p class="text-xs font-weight-bold mb-0">
                  <i
                    v-if="location.voting_center_location"
                    class="fas fa-map-marker-alt"
                    style="color: red; cursor: pointer"
                    @click="openMap(location.voting_center_location)"
                  ></i>
                </p>
                </center>
              </td>
              <td>
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  @click="navigateToEditArea(location.center_id)"
                >
                  <i class="fas fa-edit"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination">
          <button
            @click="prevPage"
            :disabled="currentPage === 1"
            style="background-color: #141727"
          >
            {{ $t("previous") }}
          </button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button
            @click="nextPage"
            :disabled="currentPage === totalPages"
            style="background-color: #141727"
          >
            {{ $t("next") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import AreaDataService from "../../services/AreaDataService.js";
import VotersSearchDataService from "../../services/VotersSearchDataService.js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      newLocation: {
        name: "",
        area: [],
      },
      locations: [], // The raw locations from the API
      groupedLocations: [], // The processed locations grouped by center_id
      currentPage: 1,
      itemsPerPage: 10,
      searchPolygon: "", // For polygon area search
      searchVotingCenter: "", // For voting center search
      votingCenterMap: null, // Separate map for voting center
      marker: null,
      votingCenterMarker: null, // Marker for voting center
      polygonAutocomplete: null,
      votingCenterAutocomplete: null, // Autocomplete for voting center
      selectedLanguage: this.$i18n.locale,
      markerLocation: null, // To store marker location for voting center
    };
  },
  mounted() {
    this.getAllVotingCenterLocation();
  },
  created() {
    this.getAllVotingCenterLocation();
  },
  computed: {
    paginatedLocations() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.groupedLocations.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.groupedLocations.length / this.itemsPerPage);
    },
  },
  methods: {
    getAllVotingCenterLocation() {
  const acno = localStorage.getItem('defaultacno'); // Retrieve acno from localStorage
  VotersSearchDataService.getAllVotingCenterLocation(acno)
    .then((response) => {
      this.locations = response.data.votingLocationlist;
      this.groupLocationsByCenterId(); // Group locations after fetching
    })
    .catch((e) => {
      console.log(e);
    });
}
,
    groupLocationsByCenterId() {
      const grouped = this.locations.reduce((acc, location) => {
        const { center_id, location_name, partno, voting_center_location } = location;

        if (!acc[center_id]) {
          acc[center_id] = {
            ac_no: location.ac_no,
            center_id,
            location_name,
            partnos: [],
            voting_center_location, // Add voting center location to the grouped object
          };
        }

        acc[center_id].partnos.push(partno);
        return acc;
      }, {});

      // Convert the grouped object into an array
      this.groupedLocations = Object.values(grouped).map((group) => ({
        ...group,
        partnos: group.partnos.join(", "), // Join part numbers with commas
      }));
    },
    // Function to check if voting center location is available
    hasVotingCenterLocation(voting_center_location) {
      return voting_center_location !== null;
    },
    // Method for switching language
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    navigateToEditArea(center_id) {
      console.log("Center ID:", center_id);
      if (!center_id) {
        console.error("No center_id passed!");
        return;
      }

      VotersSearchDataService.getVotingLocation(center_id)
        .then((response) => {
          console.log(response);
          this.$router.push({
            name: "Edit Voting Center Location",
            params: { center_id },
          });
        })
        .catch((error) => {
          console.error("Error fetching voting location:", error);
        });
    },
    toggleStatus(location) {
      location.status = !location.status;
      console.log(
        `Location ${location.name} status: ${location.status ? "Active" : "Inactive"}`
      );
    },
    confirmDelete(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteArea(index, id);
          Swal.fire("Deleted!", "The Area has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        }
      });
    },
    deleteArea(index, id) {
      AreaDataService.delete(id)
        .then((response) => {
          console.log(response);
          this.groupedLocations.splice(index, 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>



<style scoped>
.langdrop {
  width: 130px;
  margin-left: 87%;
}
@media (min-width: 300px) and (max-width: 550px) {
  .langdrop {
    width: 130px;
    margin-left: 57%;
  }
}
/* Switch component styles */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #82d616;
}

input:focus + .slider {
  box-shadow: 0 0 1px #82d616;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f8f9fa;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}
</style>
