import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";

// Import vue-i18n and language files
import { createI18n } from "vue-i18n";
import en from "./views/locales/en.json";
import hi from "./views/locales/hi.json";
import mr from "./views/locales/mr.json";

// Create an i18n instance with the languages
const i18n = createI18n({
  locale: 'en', // Set default locale
  fallbackLocale: 'en', // Fallback language if the selected one is missing a translation
  messages: {
    en,
    hi,
    mr,
  },
});

// Create the Vue app instance and apply the configurations
const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.use(i18n); // Use i18n instance in the app

// Mount the app to the DOM
appInstance.mount("#app");
