<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>least</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-102.000000, -291.000000)" fill="#4d4d4d" fill-rule="nonzero">
        <g transform="translate(102.000000, 291.000000)">
          <rect x="4" y="14" width="2" height="6"></rect>
          <rect x="8" y="10" width="2" height="10"></rect>
          <rect x="12" y="6" width="2" height="14"></rect>
          <rect x="16" y="2" width="2" height="18"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>
