<template>
  <div>
    <input
      type="text"
      v-model="address"
      id="pac-input"
      class="form-control"
      placeholder="Enter a location"
      style="margin-bottom: 5px; width: 50%"
    />
    <div id="map"></div>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: ["value"],
  data() {
    return {
      map: null,
      marker: null,
      address: "",
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // Center the map on India
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 20.5937, lng: 78.9629 }, // Coordinates for India
        zoom: 5,
      });

      this.marker = new google.maps.Marker({
        map: this.map,
        draggable: true,
      });

      const input = document.getElementById("pac-input");
      const autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: "in" }, // Restrict to India
      });
      autocomplete.bindTo("bounds", this.map);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17);
        }

        this.marker.setPosition(place.geometry.location);
        this.marker.setVisible(true);

        this.updateLocation(place.geometry.location, place.formatted_address);
      });

      this.marker.addListener("dragend", () => {
        this.updateLocation(this.marker.getPosition());
      });
    },
    updateLocation(location, address = null) {
      const lat = location.lat();
      const lng = location.lng();
      const pointFormat = `POINT(${lng} ${lat})`;
      this.$emit("input", { lat, lng });
      this.$emit("point-format", pointFormat);
      if (address) {
        this.address = address;
      }
      this.$emit("address-changed", this.address);
    },
  },
};
</script>

<style scoped>
#map {
  height: 200px;
  width: 100%;
}
</style>
