<template>
  <div
    class="container"
    style="background-color: white; padding: 10px; border-radius: 10px"
  >
  <!-- <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <div>
        <button @click="exportToPDF" class="btn btn-success">
          Export to PDF
        </button>
      </div>
      </div>
      <div class="col-sm-6"></div>
    </div>
  </div> -->
    <div class="row mb-3">
      <div class="col-sm-3">
        <!-- Min Age Filter Input -->
        <label>Age From</label>
        <input
          type="number"
          class="form-control"
          v-model.number="minAge"
          @input="filterVoters"
          min="18"
          max="110"
          placeholder="Enter Min Age (18-110)"
        />
      </div>
      <div class="col-sm-3">
        <!-- Max Age Filter Input -->
        <label>Age To</label>
        <input
          type="number"
          class="form-control"
          v-model.number="maxAge"
          @input="filterVoters"
          min="18"
          max="110"
          placeholder="Enter Max Age (18-110)"
        />
      </div>
      <div class="col-sm-3">
        <label>Gender</label>
        <select
          class="form-control"
          v-model="selectedGender"
          @change="filterVoters"
        >
          <option value="">Select Gender</option>
          <option value="M">Male</option>
          <option value="F">Female</option>
        </select>
      </div>
      <div class="col-sm-3">
        <label>यादी भाग क्र.</label>
        <select
          class="form-control"
          v-model="selectedPartno"
          @change="filterVoters"
        >
          <option value="">Select यादी भाग क्र</option>
          <option value="ALL">ALL</option>
          <option
            v-for="part in partnoList"
            :key="part.partno"
            :value="part.partno"
          >
            {{ part.partno }} - {{ part.area_name }}
          </option>
        </select>
      </div>
      <!-- Add Export PDF Button -->
      
    </div>

    <!-- Warning Message -->
    <div v-if="ageWarning" class="alert alert-warning">
      {{ ageWarning }}
    </div>

    <!-- Loader -->
    <div v-if="loading" class="text-center mt-3">
      <span>Loading...</span>
    </div>

    <!-- Responsive Data Table -->
    <div v-else class="table-responsive">
      <table class="table mt-3" ref="votersTable">
        <thead>
          <tr style="font-size: 13px">
            <th style="font-size: 13px">Age</th>
            <th>AC No/Part No/SL No</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Voting Center</th>
            <th>Align with Party</th>
            <th>Align with Candidate</th>
            <th>Phone No</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(voter, index) in paginatedData"
            :key="index"
            style="font-size: 13px"
          >
            <td>{{ voter.age }}</td>
            <td>{{ voter.acno }}/{{ voter.partno }}/{{ voter.slnoinpart }}</td>
            <td>{{ voter.name }}</td>
            <td>{{ voter.gender }}</td>
            <td>{{ voter.section }}</td>
            <td>{{ voter.vcenter }}</td>
            <td>
              {{ voter.voterInfo ? voter.voterInfo.align_with_party : "" }}
            </td>
            <td>
              {{ voter.voterInfo ? voter.voterInfo.align_with_candidate : "" }}
            </td>
            <td>{{ voter.voterInfo ? voter.voterInfo.phone_no : "" }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div class="pagination d-flex justify-content-center">
        <button
          @click="prevPage"
          :disabled="currentPage === 1"
          class="btn btn-primary"
          style="background-color: #141727"
        >
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="btn btn-primary"
          style="background-color: #141727"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VotersSearchDataService from "../../services/VotersSearchDataService.js";
import { jsPDF } from "jspdf"; // Import jsPDF
import html2canvas from "html2canvas"; // Import html2canvas

export default {
  name: "ReportByAge",
  data() {
    return {
      voterslist: [],
      filteredVotersList: [],
      partnoList: [],
      selectedPartno: "",
      selectedGender: "M", // Set default gender to Male
      minAge: 25, // Set default min age to 25
      maxAge: 30, // Set default max age to 35
      loading: true,
      currentPage: 1,
      itemsPerPage: 10,
      ageWarning: "", // Warning message for invalid age input
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.filteredVotersList.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredVotersList.slice(start, start + this.itemsPerPage);
    },
  },
  created() {
    this.getAllPartno();
    this.filterVoters(); // Initial filter to set data based on default values
  },
  watch: {
    minAge(newMinAge) {
      if (newMinAge > this.maxAge) {
        this.ageWarning = "Minimum age cannot be greater than maximum age.";
      } else {
        this.ageWarning = "";
      }

      // Automatically set maxAge to minAge + 5 if it was last set automatically
      if (this.maxAgeAutoSet) {
        this.maxAge = newMinAge + 5;
      }

      this.filterVoters();
    },
    maxAge(newMaxAge) {
      if (this.minAge > newMaxAge) {
        this.ageWarning = "Minimum age cannot be greater than maximum age.";
      } else {
        this.ageWarning = "";
      }

      // Mark maxAge as manually set if the user changes it
      this.maxAgeAutoSet = false;
      this.filterVoters();
    },
  },
  methods: {
    getAllPartno() {
      const defaultacno = localStorage.getItem("defaultacno");
      if (!defaultacno) {
        console.error("No acno found in localStorage");
        return;
      }
      VotersSearchDataService.getAllPartno(defaultacno)
        .then((response) => {
          this.partnoList = response.data.partnolist || [];
          this.partnoList.sort((a, b) => a.partno - b.partno);
          this.selectedPartno = this.partnoList[0]?.partno || "";
          this.filterVoters();
        })
        .catch((error) => console.error("Error fetching partno list:", error));
    },
    filterVoters() {
      this.loading = true;
      this.maxAgeAutoSet = true;
      const acno = localStorage.getItem("defaultacno") || "";
      const partnoParam =
        this.selectedPartno === "ALL" ? null : this.selectedPartno; // Set to null if ALL is selected

      VotersSearchDataService.getAllAlphabetically(
        acno,
        partnoParam,
        this.minAge,
        this.maxAge,
        this.selectedGender
      )
        .then((response) => {
          this.voterslist = response.data.voterslist.flatMap(
            (group) => group.voters
          );

          this.voterslist.sort((a, b) => {
            if (a.age !== b.age) {
              return a.age - b.age;
            } else if (a.partno !== b.partno) {
              return a.partno - b.partno;
            } else {
              return a.slnoinpart - b.slnoinpart;
            }
          });

          this.filteredVotersList = this.voterslist;
        })
        .catch((error) => console.error("Error during API call:", error))
        .finally(() => {
          this.loading = false;
          this.currentPage = 1;
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    exportToPDF() {
      const pdf = new jsPDF("p", "pt", "a4");
      pdf.setFontSize(15);
      pdf.text("Voters Report By Age", 14, 22); // Title

      const table = this.$refs.votersTable;

      html2canvas(table).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 550; // Adjust based on your need
        const pageHeight = pdf.internal.pageSize.height;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 30; // Start after title

        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("voters_report_by_age.pdf");
      });
    },
  },
};
</script>
<style>
.table-responsive {
  overflow-x: auto;
}
.pagination {
  margin-top: 1rem;
}
</style>
