import http from '../http-common';

const defaultacno = localStorage.getItem('defaultacno');
class EventDataService {
  getAll() {
    return http.get("/events");
  }

  getAllForReports(payload) {
    return http.get("/reports-events", { params: payload });
  }
  

  get(id) {
    return http.get(`/events/edit/${id}`);
  }
  whatsAppMessageSendCount() {
    return http.post("/update-count");
  }
  votingCenterLocation(acno,partno) {
    return http.post("/voting-center-location", {
      
        acno: acno || defaultacno, 
        partno: partno || '',
      
    });
  }
  create(data) {
    return http.post("/events/add", data);
  }

  update(data) {
    return http.put(`/events/update`, data);
  }

  delete(id) {
    return http.put(`/events/delete/${id}`);
  }
}

export default new EventDataService();
