<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>{{ $t('task') }}</h6>
         </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('task_name') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('task_description') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('status') }} %
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('assigned_user') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('assigned_team') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('special_note_placeholder') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('start_date_time') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('end_date_time') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('status') }}<span style="visibility: hidden;">ForSpacecolume</span>
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('action') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(task, index) in paginatedtasks" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ task.task_name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ task.task_description }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ task.status_percentage }}%</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ task.assignedUser ? task.assignedUser.f_name + ' ' + task.assignedUser.l_name : ""}}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ task.assignedTeam ? task.assignedTeam.name: ""}}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ task.special_notes }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ formatDate(task.start_date_time) }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ formatDate(task.end_date_time) }}</p>
              </td>
              <td>
                <select v-model="task.status" class="form-select text-xs font-weight-bold"  @change="updateTaskStatus(task, index)">
                  <option value="0">Pending</option>
                  <option value="1">Accept</option>
                  <option value="2">Work-in-Progress</option>
                  <option value="3">Completed</option>
                  <option value="4">Deferred</option>
                  <option value="5">Cancelled</option>
                  <option value="6">Cancelled by Admin</option>
                </select>
              </td>
              <td class="align-middle">
                <a href="javascript:;" @click="navigateToEditTask(task.id)" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit task">
                  <i class="fas fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  class="text-danger font-weight-bold text-xs ms-3" @click="confirmDelete(index, task.id)"
                >
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css';
import TaskDataService from "../../services/TaskDataService.js";
import Swal from 'sweetalert2';

export default {
  name: "task-table",
  data() {
    return {
      tasks: [],
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  created() {
    this.getAllTask();
  },
  computed: {
    paginatedtasks() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.tasks.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.tasks.length / this.itemsPerPage);
    }
  },
  methods: {
    getAllTask() {
      TaskDataService.getAllForReports()
        .then(response => {
          this.tasks = response.data.tasks;
          console.log(this.tasks);
        })
        .catch(e => {
          console.log(e);
        });
    },
    formatDateTime(date) {
      return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(new Date(date));
    },
    
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    updateTaskStatus(task, index) {
      const payload = {
        id: task.id,
        status: task.status,
      };

      TaskDataService.updateStatus(payload)
        .then(response => {
          console.log(response);
          if (response) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Task status updated successfully!',
              confirmButtonText: 'OK'
            }).then(() => {
              //this.getAllTask();
              this.tasks[index] = { ...task };
            });
          }
        })
        .catch(e => {
          console.log(e);
        });

     // this.$set(this.tasks, index, { ...task });
    },
    navigateToAddTask() {
      this.$router.push('/add-task');
    },
    navigateToEditTask(id) {
      this.$router.push(`/edit-task/${id}`);
    },
    confirmDelete(index, id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteTask(index, id);
        Swal.fire(
          'Deleted!',
          'The Task has been deleted.',
          'success'
        ).then(() => {
          window.location.reload();
        });
      }
    });
  },
    deleteTask(index,id) {
      TaskDataService.delete(id)
        .then(response => {
          console.log(response);
          this.tasks.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
        });
    },
   
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;color:white;border:2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.form-select {
  font-size: 0.75rem;
  padding: 0.25rem;
}

.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}
</style>
