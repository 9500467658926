<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>questions</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-102.000000, -291.000000)" fill="#000000" fill-rule="nonzero">
        <g transform="translate(102.000000, 291.000000)">
          <path
            class="color-background"
            d="M12,2 C6.486,2 2,6.486 2,12 C2,17.514 6.486,22 12,22 C17.514,22 22,17.514 22,12 C22,6.486 17.514,2 12,2 Z M12,20 C7.589,20 4,16.411 4,12 C4,7.589 7.589,4 12,4 C16.411,4 20,7.589 20,12 C20,16.411 16.411,20 12,20 Z M12.5,7 C11.121,7 10,8.121 10,9.5 C10,9.776 10.224,10 10.5,10 C10.776,10 11,9.776 11,9.5 C11,8.673 11.673,8 12.5,8 C13.327,8 14,8.673 14,9.5 C14,10.327 13.327,11 12.5,11 C12.224,11 12,11.224 12,11.5 L12,13 C12,13.276 12.224,13.5 12.5,13.5 C12.776,13.5 13,13.276 13,13 L13,11.89 C13.998,11.722 14.794,10.951 14.949,9.951 C15.083,9.067 14.387,8.35 13.5,8.206 C13.056,8.133 12.528,8 12.5,7 Z M12.5,15.5 C11.948,15.5 11.5,15.948 11.5,16.5 C11.5,17.052 11.948,17.5 12.5,17.5 C13.052,17.5 13.5,17.052 13.5,16.5 C13.5,15.948 13.052,15.5 12.5,15.5 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script></script>
