import http from '../http-common';

class ResourcesTypeDataService {
  getAll() {
    return http.get("/resources-type");
  }

  get(id) {
    return http.get(`/resources-type/${id}`);
  }

  create(data) {
    return http.post("/resources-type/add", data);
  }

  update(data) {
    return http.put(`/resources-type/update`, data);
  }

  delete(id) {
    return http.put(`/resources-type/delete/${id}`);
  }
}

export default new ResourcesTypeDataService();
