<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between">
      <ul class="nav nav-pills flex-column flex-md-row">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#">{{ $t('survey') }}</a>
        </li>
        <li class="nav-item ml-md-3">
          <a class="nav-link" data-toggle="tab" @click="navigateToGetCategory">{{ $t('categories') }}</a>
        </li>
      </ul>
      <button type="button" class="btn btn-primary" @click="navigateToAddSurvey" :style="{ backgroundColor: '#141727', color: 'white', border: '2px solid #141727' }">{{ $t('add_survey') }}</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('survey_name') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('location') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('area') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('questions') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(survey, index) in paginatedSurveys" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ survey.survey_name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ survey.location_name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ survey.areas ? survey.areas.name : '' }}</p>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-secondary"
                  @click="navigateToSurveyQuiz(survey.id)"
                >
                  {{ $t('show_questions') }}
                </button>
              </td>
              <td class="align-middle">
                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="{{ $t('edit_task') }}" @click="navigateToEditSurvey(survey.id)">
                  <i class="fas fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  class="text-danger font-weight-bold text-xs ms-3"
                  @click="confirmDelete(index, survey.id)">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        
        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css';
import Swal from 'sweetalert2';
import SurveryMasterDataService from "../../services/SurveryMasterDataService.js";

export default {
  name: "Survey-table",
  data() {
    return {
      surveys: [],
      newSurvey: {
        name: "",
        location: "",
        area: "",
        questions: []
      },
      showModal: false,
      showQuestionsModalFlag: false,
      selectedSurveyQuestions: [],
      availableQuestions: [
        { id: 1, text: 'तुमचे आवडते खाद्यपदार्थ कोणते आहेत?' },
        { id: 2, text: 'तुम्हाला कोणता खेळ आवडतो?' },
        { id: 3, text: 'तुमचे वय किती आहे?' },
        { id: 4, text: 'तुम्हाला कोणता रंग आवडतो?' },
        { id: 5, text: 'तुमचे नाव काय आहे?' }
      ],
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  created() {
    this.getAllSurveys();
  },
  computed: {
    paginatedSurveys() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.surveys.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.surveys.length / this.itemsPerPage);
    }
  },
  methods: {
    getAllSurveys() {
      SurveryMasterDataService.getAll()
        .then(response => {
          this.surveys = response.data.surveys;
          console.log(this.surveys);
        })
        .catch(e => {
          console.log(e);
        });
    },
    navigateToAddSurvey() {
      this.$router.push('/add-Survey');
    },
    navigateToGetCategory() {
      this.$router.push('/category');
    },
    navigateToEditSurvey(id) {
      this.$router.push(`/edit-Survey/${id}`);
    },
    navigateToSurveyQuiz(id) {
      this.$router.push(`/survey-questions/${id}`);
    },
    addSurvey() {
      // Simulate adding the survey to your list (you should implement actual logic)
      const newSurvey = {
        id: this.surveys.length + 1,
        name: this.newSurvey.name,
        location: this.newSurvey.location,
        area: this.newSurvey.area,
        questions: this.newSurvey.questions.map(id => {
          return this.availableQuestions.find(q => q.id === id);
        })
      };
      this.surveys.push(newSurvey);

      // Reset form and close modal
      this.newSurvey.name = "";
      this.newSurvey.location = "";
      this.newSurvey.area = "";
      this.newSurvey.questions = [];
      this.showModal = false;
    },
    showQuestionsModal(questions) {
      this.selectedSurveyQuestions = questions;
      this.showQuestionsModalFlag = true;
    },
    closeQuestionsModal() {
      this.showQuestionsModalFlag = false;
    },
    confirmDelete(index, id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteSurvey(index, id);
        Swal.fire(
          'Deleted!',
          'The Survey has been deleted.',
          'success'
        ).then(() => {
          window.location.reload();
        });
      }
    });
  },
    deleteSurvey(index,id) {
      SurveryMasterDataService.delete(id)
        .then(response => {
          console.log(response);
          this.surveys.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  }
};
</script>

<style scoped>
.table {
  width: 100%;
  margin-top: 20px;
}
.modal.fade.show {
  display: block;
}

.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;color:white;border:2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.nav-pills {
  display: flex;
  flex-direction: column;
}

.nav-pills .nav-item {
  margin-bottom: 10px;
}

.nav-pills .nav-item .nav-link {
  text-align: center;
  padding: 10px;
}

.nav-pills .nav-link.active {
  background-color: #141727;
  color: white;
}

.table-responsive {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .nav-pills {
    flex-direction: row;
  }

  .nav-pills .nav-item {
    margin-bottom: 0;
  }

  .nav-item.ml-md-3 {
    margin-left: 15px;
  }

  .col-md-4.mb-3.mb-md-0 {
    margin-bottom: 0 !important;
  }
}
</style>
