<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h5>{{ $t("add_voters_group") }}</h5>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addVoterGroup" class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="groupName">{{ $t("voter_group_name") }}</label>
              <input v-model="votersGroup.voter_group_name" type="text" class="form-control" id="groupName" required />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="groupDesc">{{ $t("voter_group_description") }}</label>
              <input v-model="votersGroup.voter_group_description" type="text" class="form-control" id="groupDesc" required />
            </div>
          </div>
          <div class="col-md-6">
  <div class="form-group">
    <label for="surveyArea">{{ $t("area") }}</label>
    <div class="select-wrapper">
      <select v-model="votersGroup.area" class="form-control select-with-arrow" id="surveyArea" required>
        <option value="" selected>{{ $t("select_area") }}</option>
        <option v-for="area in areas" :key="area.id" :value="area.id">{{ area.name }}</option>
      </select>
    </div>
  </div>
</div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="specialInstructions">{{ $t("special_instructions") }}</label>
              <input v-model="votersGroup.special_instructions" type="text" class="form-control" id="specialInstructions" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="votersGroupLocation">{{ $t("location_point") }}</label>
              <google-map v-model="votersGroup.location" @point-format="updatePointFormat" @address-changed="updateAddress" />
            </div>
          </div>
          <div class="col-md-12 text-center">
            <button type="submit" class="btn btn-primary mt-3" style="background-color: #141727;color:white;border:2px solid #141727"  :disabled="isLoading"
            :loading="isLoading">{{ $t("add_voter_group") }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import GoogleMap from "./GoogleMap.vue";
import AreaDataService from "../../services/AreaDataService.js";
import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import Swal from 'sweetalert2';

export default {
  name: "AddVotersGroup",
  
  data() {
    return {
      isLoading: false,
      votersGroup: {
        voter_group_name: "",
        voter_group_description: "",
        area: "",
        address: "",
        location_name: "", 
        location: "",
        special_instructions: ""
      },
      areas: [],
    };
  },
  components: {
    GoogleMap
  },
  created() {
    this.getAllArea();
  },
  methods: {
    getAllArea() {
    AreaDataService.getAll()
      .then(response => {
        this.areas = response.data.area;
      })
      .catch(e => {
        console.log(e);
      });
  },
  updateAddress(address) {
    this.votersGroup.address = address;
  },
  updatePointFormat(pointFormat) {
    this.votersGroup.location_name = pointFormat;
  },
  addVoterGroup() {
    this.isLoading = true;
    // Check if the location address is provided
    if (!this.votersGroup.address || this.votersGroup.address.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please enter Location before adding the voter group.',
        confirmButtonText: 'OK'
      });
      return;
    }

    const selectedArea = this.areas.find(area => area.id === this.votersGroup.area);
    const locationString = this.votersGroup.location_name;
    const coordinatesMatch = locationString.match(/POINT\(([^)]+)\)/);
    let coordinates = [];
    
    if (coordinatesMatch) {
      coordinates = coordinatesMatch[1].split(' ').map(Number);
    }

    const payload = {
      voter_group_name: this.votersGroup.voter_group_name,
      location: {
        type: "Point",
        coordinates: coordinates,
      },
      location_name: this.votersGroup.address,
      voter_group_description: this.votersGroup.voter_group_description,
      area: selectedArea ? selectedArea.area : '',
      area_id: selectedArea ? selectedArea.id : '',
      special_instructions: this.votersGroup.special_instructions,
    };

    VoterGroupsDataService.create(payload)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Voters Group added successfully!',
          confirmButtonText: 'OK'
        }).then(() => {
          this.$router.push('/voters-group'); 
        });
      })
      .catch(error => {
        console.error("Error adding Voters Group:", error);
      });
  }
  }
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.card {
  margin-bottom: 20px;
}
</style>
