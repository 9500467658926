<template>
  <div>
    <div id="map"></div>
  </div>
</template>

<script>
import { MarkerClusterer } from "@googlemaps/markerclusterer"; // Import MarkerClusterer

export default {
  name: "GoogleMapForDashboard",
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Object,
      default: () => ({ lat: 20.5937, lng: 78.9629 }), // Default center (India)
    },
    zoom: {
      type: Number,
      default: 5, // Default zoom level focused on India
    },
  },
  data() {
    return {
      map: null,
      mapMarkers: [], // To store Google Maps Marker instances
      markerCluster: null, // To store MarkerClusterer instance
    };
  },
  watch: {
    markers: {
      handler(newMarkers) {
        if (this.map) {
          this.clearMarkers();
          this.addMarkers(newMarkers);
          this.fitMapToMarkers();
        }
      },
      immediate: true, // Run on component mount
      deep: true,
    },
  },
  mounted() {
    this.loadGoogleMapsApi()
      .then(() => {
        this.initMap();
        if (this.markers.length) {
          this.addMarkers(this.markers);
          this.fitMapToMarkers();
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });
  },
  methods: {
    loadGoogleMapsApi() {
      return new Promise((resolve, reject) => {
        if (typeof google !== "undefined" && google.maps) {
          resolve();
        } else {
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBXQTU7zqbbVqM91KHNo1SpURyqO9HQocA`; // Replace with your API key
          script.async = true;
          script.defer = true;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        }
      });
    },
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.center,
        zoom: this.zoom,
      });
    },
    addMarkers() {
      if (!this.map) return; // Ensure the map is initialized

      // Log the incoming markers for debugging purposes
      console.log("Markers Data:", this.markers);

      // Create an array of Google Maps Marker instances
      this.mapMarkers = this.markers.map((markerData) => {
        console.log("Marker Data:", markerData.lat, markerData.lng);

        const marker = new google.maps.Marker({
          position: { lat: markerData.lat, lng: markerData.lng },
          title: markerData.title || "",
        });

        // Add an info window if content is available
        if (markerData.infoWindowContent) {
          const infoWindow = new google.maps.InfoWindow({
            content: markerData.infoWindowContent,
          });

          marker.addListener("click", () => {
            infoWindow.open(this.map, marker);
          });
        }

        return marker;
      });

      // Log the map markers for debugging purposes
      console.log("Google Map Markers:", this.mapMarkers);

      // Initialize MarkerClusterer with markers and add it to the map
      this.markerCluster = new MarkerClusterer({
        map: this.map,
        markers: this.mapMarkers,
        options: {
          minimumClusterSize: 2, // Minimum number of markers for a cluster
          maxZoom: null, // Set to null or a higher value to allow visibility after zooming
          zoomOnClick: true, // Zoom into the cluster when clicked
        },
      });
    },
    clearMarkers() {
      // Remove markers from the map
      this.mapMarkers.forEach((marker) => {
        marker.setMap(null);
      });
      this.mapMarkers = [];

      // Clear clusters
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
        this.markerCluster = null;
      }
    },
    fitMapToMarkers() {
      if (this.mapMarkers.length === 0) return;

      const bounds = new google.maps.LatLngBounds();
      this.mapMarkers.forEach((marker) => {
        bounds.extend(marker.getPosition());
      });
      this.map.fitBounds(bounds);
    },
  },
};
</script>

<style scoped>
#map {
  height: 500px; /* Adjust height as needed */
  width: 100%;
}
</style>
