<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>roles</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-102.000000, -291.000000)" fill="#000000" fill-rule="nonzero">
        <g transform="translate(102.000000, 291.000000)">
          <path
            class="color-background"
            d="M12 2C13.38 2 14.5 3.12 14.5 4.5C14.5 5.88 13.38 7 12 7C10.62 7 9.5 5.88 9.5 4.5C9.5 3.12 10.62 2 12 2M17 14V12.5C17 10.57 15.43 9 13.5 9H10.5C8.57 9 7 10.57 7 12.5V14H6C5.45 14 5 14.45 5 15V19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19V15C19 14.45 18.55 14 18 14H17M12 9.5C13.1 9.5 14 10.4 14 11.5C14 12.6 13.1 13.5 12 13.5C10.9 13.5 10 12.6 10 11.5C10 10.4 10.9 9.5 12 9.5M18.75 13.5L17.5 14.5L17.5 16L18.75 17L20 16L20 14.5L18.75 13.5Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script></script>
