<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>projects report</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-102.000000, -291.000000)" fill="#4d4d4d" fill-rule="nonzero">
        <g transform="translate(102.000000, 291.000000)">
          <path
            class="color-background"
            d="M19,3 L5,3 C3.897,3 3,3.897 3,5 L3,19 C3,20.103 3.897,21 5,21 L19,21 C20.103,21 21,20.103 21,19 L21,5 C21,3.897 20.103,3 19,3 Z M9,9 L7,9 L7,11 L9,11 L9,9 Z M11,9 L13,9 L13,11 L11,11 L11,9 Z M15,9 L17,9 L17,11 L15,11 L15,9 Z M9,13 L7,13 L7,15 L9,15 L9,13 Z M11,13 L13,13 L13,15 L11,15 L11,13 Z M15,13 L17,13 L17,15 L15,15 L15,13 Z M9,17 L7,17 L7,19 L9,19 L9,17 Z M11,17 L13,17 L13,19 L11,19 L11,17 Z M15,17 L17,17 L17,19 L15,19 L15,17 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
