<template>
  <div class="container mb-4">
    <!-- Header Section -->
    <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="mx-auto text-center col-lg-8">
            <h1 class="mt-5 mb-2 text-white">{{ $t("welcome") }}</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Form Section -->
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10">
        <div class="mx-auto col-xl-8 col-lg-9 col-md-10">
          <div class="card z-index-0">
            <div class="pt-4 card-header">
              <h5>{{ $t("register_with") }}</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent="addCandidates">
                <div class="row mb-3">
                  <!-- Organization Field -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="installation_type" class="form-control-label">
                        {{ $t("select_type") }}<span style="color: red">*</span>
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.installation_type"
                          id="installation_type"
                          class="form-control select-with-arrow"
                          aria-label="Organization ID"
                          required
                        >
                          <option value="" disabled selected>
                            {{ $t("select_type") }}
                          </option>
                          <option value="1">{{ $t("loksevak") }}</option>
                          <option value="2">{{ $t("survey") }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="org_f_name" class="form-control-label">
                      {{ $t("org_full_name") }}
                      <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.organization_full_name"
                      id="organization_full_name"
                      type="text"
                      :placeholder="$t('org_full_name')"
                      aria-label="Organization Full Name"
                      icon="mdi-account"
                    />
                    <span v-if="errors.organization_full_name" class="error">{{
                      errors.organization_full_name
                    }}</span>
                  </div>

                  <!-- Reporting To Field -->
                  <div class="col-md-4">
                    <label for="org_s_name" class="form-control-label">
                      {{ $t("org_short_name") }}
                      <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.organization_short_name"
                      id="organization_short_name"
                      type="text"
                      :placeholder="$t('org_short_name')"
                      aria-label="Organization Short Name"
                      icon="mdi-account"
                    />
                    <span v-if="errors.organization_short_name" class="error">{{
                      errors.organization_short_name
                    }}</span>
                  </div>

                  <!-- First Name Field -->
                  <div class="col-md-6">
                    <label for="f_name" class="form-control-label">
                      {{ $t("first_name") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.f_name"
                      id="f_name"
                      type="text"
                      :placeholder="$t('first_name_placeholder')"
                      aria-label="First Name"
                      icon="mdi-account"
                    />
                    <span v-if="errors.f_name" class="error">{{
                      errors.f_name
                    }}</span>
                  </div>

                  <!-- Last Name Field -->
                  <div class="col-md-6">
                    <label for="l_name" class="form-control-label">
                      {{ $t("last_name_placeholder") }}
                    </label>
                    <soft-input
                      v-model="newCandidate.l_name"
                      id="l_name"
                      type="text"
                      :placeholder="$t('last_name_placeholder')"
                      aria-label="Last Name"
                      icon="mdi-account-outline"
                    />
                  </div>

                  <!-- Phone Number Field -->
                  <div class="col-md-6">
                    <label for="phone_no" class="form-control-label">
                      {{ $t("phone_number") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.phone_no"
                      id="phone_no"
                      type="text"
                      :placeholder="$t('phone_number_placeholder')"
                      aria-label="Phone Number"
                      icon="mdi-phone"
                      @input="prependCountryCode"
                    />
                    <span v-if="errors.phone_no" class="error">{{
                      errors.phone_no
                    }}</span>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="gender" class="label-text">
                        {{ $t("gender") }} <span class="required">*</span>
                      </label>
                      <div class="radio-group">
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="male"
                            name="gender"
                            value="1"
                          />
                          <label for="male" class="radio-label">
                            {{ $t("male") }}
                          </label>
                        </div>
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="female"
                            name="gender"
                            value="2"
                          />
                          <label for="female" class="radio-label">
                            {{ $t("female") }}
                          </label>
                        </div>
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="other"
                            name="gender"
                            value="3"
                          />
                          <label for="other" class="radio-label">
                            {{ $t("other") }}
                          </label>
                        </div>
                      </div>
                      <span v-if="errors.gender" class="error">{{
                        errors.gender
                      }}</span>
                    </div>
                  </div>

                  <!-- Email Field -->
                  <div class="col-md-6" v-if="showEmailAndPasswordFields">
                    <label for="email" class="form-control-label">
                      {{ $t("email") }}
                    </label>
                    <soft-input
                      v-model="newCandidate.email"
                      id="email"
                      type="email"
                      :placeholder="$t('email_placeholder')"
                      aria-label="Email"
                      icon="mdi-email"
                    />
                  </div>

                  <!-- Password Field -->
                  <div class="col-md-6" v-if="showEmailAndPasswordFields">
                    <label for="password" class="form-control-label">
                      {{ $t("password") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.password"
                      id="password"
                      type="password"
                      :placeholder="$t('password_placeholder')"
                      aria-label="Password"
                      icon="mdi-lock"
                    />
                  </div>

                  <!-- Aadhaar Upload Field -->
                  <div class="col-md-6">
                    <div class="form-group text-center">
                      <label for="org_symbol" class="form-control-label">
                        {{ $t("org_symbol") }}
                      </label>
                      <div>
                        <img
                          :src="orgSymbolUrl"
                          alt="org_symbol"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerOrgSymbolInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="aadhaar"
                        ref="orgSymbolInput"
                        class="d-none"
                        aria-label="Org Symbol"
                        @change="onAadhaarChange"
                      />
                    </div>
                  </div>

                  <!-- Profile Picture Upload Field -->
                  <div class="col-md-6">
                    <div class="form-group text-center">
                      <label for="profile_photo" class="form-control-label">
                        {{ $t("election_symbol") }}
                      </label>
                      <div>
                        <img
                          :src="electionSymbolUrl"
                          alt="election symbol"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerElectionSymbolInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="profile_photo"
                        ref="electionSymbolInput"
                        class="d-none"
                        aria-label="Profile Picture"
                        @change="onProfilePhotoChange"
                      />
                    </div>
                  </div>

                  <!-- Terms and Conditions -->
                  <div class="col-md-12 mb-3">
                    <soft-checkbox
                      id="flexCheckDefault"
                      name="flexCheckDefault"
                      class="font-weight-light"
                      checked
                    >
                      {{ $t("agree_terms") }}
                      <a
                        href="javascript:;"
                        class="text-dark font-weight-bolder"
                      >
                        {{ $t("terms_and_conditions") }}
                      </a>
                    </soft-checkbox>
                  </div>
                </div>

                <!-- Submit Button -->
                <div class="text-center">
                  <soft-button
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                  >
                    {{ $t("sign_up") }}
                  </soft-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SoftInput from "@/components/SoftInput.vue";
import profilePhotoUrl from "@/assets/img/profile.png";
import aadhaarPhotoUrl from "@/assets/img/adhaar.png";
import electionSymbolUrl from "@/assets/img/election_symbol.png";
import orgSymbolUrl from "@/assets/img/org_symbol.png";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import Swal from "sweetalert2";
import UsersDataService from "../services/UsersDataService.js";
import OfficesDataService from "../services/OfficesDataService.js";
import RolesDataService from "../services/RolesDataService.js";
import CandidateDataService from "../services/CandidateDataService.js";
import { handleApiErrors } from "@/utils/errorHandler";

export default {
  name: "SignupBasic",
  components: {
    SoftInput,
    SoftCheckbox,
    SoftButton,
  },
  data() {
    return {
      users: [],
      offices: [],
      roles: [],
      newCandidate: {
        installation_type: "",
        organization_full_name: "",
        organization_short_name: "",
        f_name: "",
        l_name: "",
        phone_no: "",
        email: "",
        password: "",
        gender: "",
        organization_symbol: "",
        organization_election_symbol: "",
      },
      selectedLanguage: this.$i18n.locale,
      errors: {},
      profilePhotoUrl,
      aadhaarPhotoUrl,
      orgSymbolUrl,
      electionSymbolUrl,
    };
  },
  computed: {
    showEmailAndPasswordFields() {
      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );
      return selectedRole ? selectedRole.type !== 2 : true;
    },
  },
  created() {
    this.getUsers();
    this.getOffices();
    this.getRoles();
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    getUsers() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getOffices() {
      OfficesDataService.getAll()
        .then((response) => {
          this.offices = response.data.offices;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getRoles() {
      RolesDataService.getAll()
        .then((response) => {
          this.roles = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    triggerOrgSymbolInput() {
      this.$refs.orgSymbolInput.click();
    },
    triggerElectionSymbolInput() {
      this.$refs.electionSymbolInput.click();
    },
    onProfilePhotoChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          this.electionSymbolUrl = base64Data;
          this.newCandidate.organization_election_symbol = base64Data;
        };
        reader.readAsDataURL(file);
      }
    },
    onAadhaarChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          this.orgSymbolUrl = base64Data;
          this.newCandidate.organization_symbol = base64Data;
        };
        reader.readAsDataURL(file);
      }
    },
    onRoleChange() {
      if (!this.showEmailAndPasswordFields) {
        this.newCandidate.email = "";
        this.newCandidate.password = "";
      }
    },
    prependCountryCode() {
      this.newCandidate.phone_no = this.newCandidate.phone_no.replace(
        /[^\d]/g,
        ""
      );

      if (
        this.newCandidate.phone_no &&
        !this.newCandidate.phone_no.startsWith("91")
      ) {
        this.newCandidate.phone_no = "91" + this.newCandidate.phone_no;
      }

    
      this.newCandidate.phone_no = this.newCandidate.phone_no.slice(0, 12);

      this.newCandidate.phone_no = "+" + this.newCandidate.phone_no;

      if (this.newCandidate.phone_no.length !== 13) {
        this.errors.phone_no = this.$t("phone_number_must_be_10_digits");
      } else {
        this.errors.phone_no = "";
      }
    },

    addCandidates() {
      console.log("YAHA");
      const payload = {
        installation_type: this.newCandidate.installation_type,
        organization_full_name: this.newCandidate.organization_full_name,
        organization_short_name: this.newCandidate.organization_short_name,
        f_name: this.newCandidate.f_name,
        l_name: this.newCandidate.l_name,
        phone_no: this.newCandidate.phone_no,
        email: this.newCandidate.email,
        password: this.newCandidate.password,
        gender: this.newCandidate.gender,
        organization_symbol: this.newCandidate.organization_symbol,
        organization_election_symbol: this.newCandidate
          .organization_election_symbol,
      };
      CandidateDataService.createAdmin(payload)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "You have registered successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/super-admin/admin-list");
          });
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  padding-right: 2rem;
  border: 1px solid #ced4da;
}

.select-wrapper::after {
  content: "\25BC";
  font-size: 0.8rem;
  color: #495057;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.error {
  color: red;
  font-size: 13px;
  font-family: "sans-serif";
  font-weight: bold;
}

.label-text {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.required {
  color: red;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.radio-option {
  display: flex;
  align-items: center;
}

.radio-label {
  font-size: 14px;
  margin-left: 0.5rem;
  cursor: pointer;
  margin-top: 7px;
}

input[type="radio"] {
  margin-right: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.img-fluid {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.text-center {
  text-align: center;
}

@media (max-width: 576px) {
  .select-wrapper,
  .form-group,
  .radio-group {
    width: 100%;
  }
}
</style>
