export function handleApiErrors(error) {
    if (error.response && error.response.status === 403) {
      return error.response.data.errors.reduce((acc, err) => {
        acc[err.code] = err.message;
        return acc;
      }, {});
    } else {
      console.error('An unexpected error occurred:', error);
      return null;
    }
  }
  