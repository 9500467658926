import http from '../http-common';

class StockInwardDataService {
  getAll() {
    return http.get("/inward-stocks");
  }

  get(id) {
    return http.get(`/inward-stocks/edit/${id}`);
  }

  create(data) {
    return http.post("/inward-stocks/add", data);
  }

  update(data) {
    return http.put(`/inward-stocks/update`, data);
  }

  delete(id) {
    return http.delete(`/inward-stocks/${id}`);
  }
}

export default new StockInwardDataService();
