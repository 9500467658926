<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>group</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-102.000000, -291.000000)" fill="#000000" fill-rule="nonzero">
        <g transform="translate(102.000000, 291.000000)">
          <path
            class="color-background"
            d="M9,10 C10.6568542,10 12,8.65685425 12,7 C12,5.34314575 10.6568542,4 9,4 C7.34314575,4 6,5.34314575 6,7 C6,8.65685425 7.34314575,10 9,10 Z M9,12 C6.33,12 0,13.34 0,16 L0,18 C0,18.55 0.45,19 1,19 L17,19 C17.55,19 18,18.55 18,18 L18,16 C18,13.34 11.67,12 9,12 Z M15.19,10 C16.74,10 18,8.74 18,7.19 C18,5.64 16.74,4.38 15.19,4.38 C13.64,4.38 12.38,5.64 12.38,7.19 C12.38,8.74 13.64,10 15.19,10 Z M15.19,12 C14.12,12 12.18,12.5 11,13.22 C11.31,13.83 11.42,14.48 11.42,15.13 L11.42,18 L23,18 C23.55,18 24,17.55 24,17 L24,16 C24,13.25 17.5,12 15.19,12 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script></script>
