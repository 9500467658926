<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h5>{{ $t("add_office") }}</h5>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addOffice" class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="officeName">{{ $t("office_name") }}</label>
              <input
                v-model="newOffice.office_name"
                type="text"
                class="form-control"
                id="officeName"
                required
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyArea">{{ $t("area") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newOffice.area"
                  class="form-control select-with-arrow"
                  id="surveyArea"
                  required
                >
                  <option value="" selected>{{ $t("select_area") }}</option>
                  <option v-for="area in areas" :key="area.id" :value="area.id">
                    {{ area.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-4">
            <div class="form-group">
              <label for="teamMembers">{{ $t('teams') }}</label>
              <multiselect v-model="newOffice.teams" :options="availableTeams" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name"></multiselect>
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="editPrimaryIncharge">{{
                $t("primary_incharge")
              }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newOffice.primary_incharge"
                  class="form-control select-with-arrow"
                  id="editPrimaryIncharge"
                  required
                >
                  <option value="" selected>
                    {{ $t("select_primary_incharge") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label for="editSecondaryIncharge">{{
                $t("secondary_incharge")
              }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newOffice.secondary_incharge"
                  class="form-control select-with-arrow"
                  id="editSecondaryIncharge"
                >
                  <option value="" selected>
                    {{ $t("select_secondary_incharge") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="surveyLocation">{{ $t("location") }}</label>
              <google-map
                v-model="newOffice.location.coordinates"
                @point-format="updatePointFormat"
                @address-changed="updateAddress"
                required
              />
            </div>
          </div>
        </div>

        <center>
          <button
            type="submit"
            class="btn btn-primary mt-3"
            style="
              background-color: #141727;
              color: white;
              border: 2px solid #141727;
            "
            :disabled="isLoading"
            :loading="isLoading"
          >
            {{ $t("add_office_button") }}
          </button>
        </center>
      </form>
    </div>
  </div>
</template>

<script>
import GoogleMap from "./GoogleMap.vue";
import AreaDataService from "../../services/AreaDataService.js";
// import TeamDataService from "../../services/TeamDataService.js";
import OfficesDataService from "../../services/OfficesDataService.js";
import Swal from "sweetalert2";
// import Multiselect from 'vue-multiselect';
import "vue-multiselect/dist/vue-multiselect.css";
import UsersDataService from "../../services/UsersDataService.js";

export default {
  name: "AddOffice",
  data() {
    return {
      // availableTeams: [],
      isLoading: false,
      newOffice: {
        office_name: "",
        primary_incharge: "",
        secondary_incharge: "",
        location: "",
        address: "",
        location_name: "",
        area: "",
        teams: [],
      },
      areas: [],
      users: [],
    };
  },
  components: {
    GoogleMap,
  },
  created() {
    this.getAllAreas();
    // this.getAllTeams();
    this.getAllUsers();
  },
  methods: {
    getAllAreas() {
      AreaDataService.getAll()
        .then((response) => {
          this.areas = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // getAllTeams() {
    //   TeamDataService.getAll()
    //     .then(response => {
    //       this.availableTeams = response.data.teams;
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    // },
    getAllUsers() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
          console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateAddress(address) {
      this.newOffice.address = address;
    },
    updatePointFormat(pointFormat) {
      this.newOffice.location_name = pointFormat;
    },
    addOffice() {
      this.isLoading = true;
      // Check if the location is filled
      if (
        !this.newOffice.location_name ||
        this.newOffice.location_name.trim() === ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please select a location before adding the office.",
          confirmButtonText: "OK",
        });
        return;
      }

      // Check if the address is filled
      if (!this.newOffice.address || this.newOffice.address.trim() === "") {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please enter an address before adding the office.",
          confirmButtonText: "OK",
        });
        return;
      }

      const selectedArea = this.areas.find(
        (area) => area.id === this.newOffice.area
      );
      const locationString = this.newOffice.location_name;
      const coordinatesMatch = locationString.match(/POINT\(([^)]+)\)/);
      let coordinates = [];

      if (coordinatesMatch) {
        coordinates = coordinatesMatch[1].split(" ").map(Number);
      }

      const payload = {
        office_name: this.newOffice.office_name,
        location: {
          type: "Point",
          coordinates: coordinates,
        },
        address: this.newOffice.address,
        location_name: this.newOffice.address,
        area: selectedArea ? selectedArea.area : "",
        area_id: selectedArea ? selectedArea.id : "",
        primary_incharge: this.newOffice.primary_incharge,
        secondary_incharge: this.newOffice.secondary_incharge,
      };

      OfficesDataService.create(payload)
        .then((response) => {
          console.log(response);
          if (localStorage.getItem("steps") < 4) {
            const steps = parseInt(localStorage.getItem("steps"), 10);
            const updatedStep = steps + 1;

            const payload = {
              admin_id: localStorage.getItem("admin_id"),
              step: updatedStep,
            };

            UsersDataService.updateStep(payload)
              .then((response) => {
                console.log(response);
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Office added successfully!",
                  confirmButtonText: "OK",
                }).then(() => {
                  localStorage.setItem("steps", updatedStep);
                  this.$router.push(`/installation/${updatedStep}`);
                });
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Office added successfully!",
              confirmButtonText: "OK",
            }).then(() => {
              this.$router.push(`/office`);
            });
          }
        })
        .catch((error) => {
          console.error("Error adding office:", error);
        });
    },
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.card {
  margin-bottom: 20px;
}

.btn {
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
}
</style>
