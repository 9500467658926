<template>
  <div class="card mb-4">
    
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>{{ $t('Religion') }}</h6>
      <button type="button" class="btn btn-primary" @click="showModal('add')" style="background-color: #141727; color: white; border: 2px solid #141727">{{ $t('add_category') }}</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">{{ $t('religion_name') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-left">{{ $t('action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(religion, index) in paginatedReligions" :key="index">
              <td class="text-left">
                <p class="text-xs font-weight-bold mb-0">{{ religion.name }}</p>
              </td>
             
              <td class="text-left">
                <a v-if="!isAdminOrSuperAdmin(religion)"  href="javascript:;" class="text-secondary font-weight-bold text-xs" @click="showModal('edit', index)">
                  <i class="fas fa-edit"></i>
                </a>
                <a v-if="!isAdminOrSuperAdmin(religion)"  href="javascript:;" class="text-danger font-weight-bold text-xs ms-3" @click="confirmDelete(index, religion.id)">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
        <button v-for="page in totalPages" :key="page" @click="goToPage(page)" :class="{ active: currentPage === page }">
          {{ page }}
        </button>
        <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
      </div>
    </div>

    <!-- Add Role Modal -->
    <div v-if="isAddModalVisible" class="modal fade show" style="display: block;" tabindex="-1" role="dialog" aria-labelledby="addRoleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addRoleModalLabel">{{ $t('add_category') }}</h5>
            <button type="button" class="close" @click="hideModal('add')" aria-label="Close" style="background-color: #141727;color:white;border:2px solid #141727;border-radius: 50%;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addRole">
              <div class="form-group">
                <label for="roleName">{{ $t('category_name') }}</label>
                <input type="text" class="form-control" id="roleName" v-model="newReligionCat.name" required>
              </div>
              
              <button type="submit" class="btn btn-primary" style="background-color: #141727; color: white; border: 2px solid #141727">{{ $t('add_category') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Role Modal -->
    <!-- Edit Role Modal -->
  <div v-if="isEditModalVisible" class="modal fade show" style="display: block;" tabindex="-1" role="dialog" aria-labelledby="editRoleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editRoleModalLabel">{{ $t('update_category') }}</h5>
          <button type="button" class="close" @click="hideModal('edit')" aria-label="Close" style="background-color: #141727;color:white;border:2px solid #141727;border-radius: 50%;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="updateRole">
            <div class="form-group">
              <label for="editRoleName">{{ $t('category_name') }}</label>
              <input type="text" class="form-control" id="editRoleName" v-model="religions[selectedIndex].name" required>
            </div>
            <button type="submit" class="btn btn-primary" style="background-color: #141727; color: white; border: 2px solid #141727">{{ $t('update_category') }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>


  </div>
</template>
<script>
import '@fortawesome/fontawesome-free/css/all.css';
import RolesDataService from "../../services/RolesDataService.js";
import ReligionCategoryDataService from "../../services/ReligionCategoryDataService.js";
import ReligionDataService from "../../services/ReligionDataService.js";
import Swal from 'sweetalert2';

export default {
  name: "ReligionTable",
  components: {
    // SoftBadge,
  },
  data() {
    return {
      roles: [],
      religions: [],
      modules: ['Dashboard', 'Candidates', 'Task', 'Survey', 'Questions', 'Office', 'Resources', 'Vehicles', 'Events', 'Group of Voters'],
      newReligionCat: {
        name: ""
      },
      selectedIndex: null,
      isAddModalVisible: false,
      isEditModalVisible: false,
      selectedRoles: [], // For selecting roles in add mode
      currentPage: 1,
      itemsPerPage: 10,
      selectedLanguage: this.$i18n.locale,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.roles.length / this.itemsPerPage);
    },
    paginatedReligions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.religions.slice(start, end);
    },
  },
  created() {
    this.getAllRoles();
    this.getAllReligionscat();
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    isAdminOrSuperAdmin(role) {
      const roleName = role.name.toLowerCase();
      return roleName === 'admin' || roleName === 'super admin';
    },
    getAllRoles() {
      RolesDataService.getAll()
        .then(response => {
          this.roles = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAllReligionscat() {
      ReligionCategoryDataService.getAll()
        .then(response => {
          this.religions = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    addRole() {
  console.log("Adding religion category", this.newReligionCat);

  // Add new role to the local roles array
  this.roles.push({
    name: this.newReligionCat.name,
  });

  const payload = {
    name: this.newReligionCat.name,
  };
  console.log(payload);

  ReligionCategoryDataService.create(payload)
    .then(response => {
      // Log the response
      console.log(response.data.role);

      // Reload the page after successfully adding the role
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
    });

  this.resetForm('add');
}
,
    showModal(mode, index = null) {
      if (mode === 'add') {
        this.isAddModalVisible = true;
        this.selectedRoles = [];
        this.newReligionCat = { name: ""};
      } else if (mode === 'edit' && index !== null) {
        this.selectedIndex = index;
        this.isEditModalVisible = true;
        this.selectedRoles = this.roles[index].modules ? this.roles[index].modules : []; // Populate selected modules for edit
        console.log("Selected roles:", this.selectedRoles);
      }
    },
    roleHasModule(module) {
      return this.selectedRoles.includes(module);
    },
    hideModal(mode) {
      if (mode === 'add') {
        this.isAddModalVisible = false;
      } else if (mode === 'edit') {
        this.isEditModalVisible = false;
      }
      this.selectedIndex = null;
    },
    updateRole() {
      const updatedRole = {
        ...this.religions[this.selectedIndex],
        modules: this.selectedRoles,
        //role_id: this.roles[this.selectedIndex].id 
      };

      ReligionCategoryDataService.update(this.religions[this.selectedIndex].id,updatedRole)
        .then(response => {
          //this.teams = response.data.role;
          console.log(response.data.role);
        })
        .catch(e => {
          console.log(e);
        });

      // Simulate API call to update role
      this.religions.splice(this.selectedIndex, 1, updatedRole);
      this.resetForm('edit');
    },
    confirmDelete(index, id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteReligion(index, id);
        Swal.fire(
          'Deleted!',
          'The Role has been deleted.',
          'success'
        );
      }
    });
  },
  deleteReligion(index, id) {
    ReligionDataService.delete(id)
      .then(response => {
        console.log(response);
        this.religions.splice(index, 1);
      })
      .catch(e => {
        console.log(e);
      });
  },
    resetForm(mode) {
      if (mode === 'add') {
        this.newReligionCat = { name: ""};
        this.selectedRoles = [];
        this.isAddModalVisible = false;
      } else if (mode === 'edit') {
        this.selectedIndex = null;
        this.isEditModalVisible = false;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
};
</script>

<style scoped>
.langdrop{
   width:130px;margin-left:87%;
}
@media (min-width:300px) and (max-width:550px){
  .langdrop{
   width:130px;margin-left:57%;
}
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #82d616; /* Green color */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.table th,
.table td {
  text-align: left;
  padding: 12px;
}

.modal.fade.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  margin-top: 10%;
}

.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin: 0 0.5rem;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ffffff;
  color: #000000;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 0.5rem;
}

.pagination button.active {
  background-color: rgb(20, 23, 39);
  border-color: rgb(20, 23, 39);
  border-radius: 50px;
}
</style>
