<template>
  <div>
    <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="mx-auto col-xl-8 col-lg-9 col-md-10">
          <div class="card z-index-0">
            <div class="card-body">
              <form role="form" @submit.prevent="updateCandidates">
                <div class="row mb-3">
                  <!-- <div class="col-md-6">
                    <div class="form-group">
                      <label for="org_id" class="form-control-label">
                        {{ $t("organization")
                        }}<span style="color: red">*</span>
                      </label>
                      <div class="">
                        <soft-input
                          v-model="newCandidate.org_id"
                          id="f_name"
                          type="text"
                          :placeholder="$t('first_name_placeholder')"
                          aria-label="First Name"
                          icon="mdi-account"
                          class="readonly-input"
                        />
                      </div>
                    </div>
                  </div> -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="candidate" class="form-control-label">
                        {{ $t("candidate") }}
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.candidate_id"
                          id="candidate"
                          class="form-control select-with-arrow"
                          aria-label="candidate id"
                        >
                          <option value="" selected>
                            {{ $t("candidate") }}
                          </option>
                          <option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                          >
                            {{ user.f_name }} {{ user.l_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="reporting_to" class="form-control-label">
                        {{ $t("reporting_to") }}
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.reporting_to"
                          id="reporting_to"
                          class="form-control select-with-arrow"
                          aria-label="Reporting To"
                        >
                          <option value="" selected>
                            {{ $t("reporting_to") }}
                          </option>
                          <option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                          >
                            {{ user.f_name }} {{ user.l_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <label for="f_name" class="form-control-label">
                      {{ $t("first_name") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.f_name"
                      id="f_name"
                      type="text"
                      placeholder="{{ $t('first_name') }}"
                      aria-label="First Name"
                      icon="mdi-account"
                    />
                    <span v-if="errors.f_name" class="error">{{
                      errors.f_name
                    }}</span>
                  </div>
                  <div class="col-md-6">
                    <label for="l_name" class="form-control-label">
                      {{ $t("last_name") }}
                    </label>
                    <soft-input
                      v-model="newCandidate.l_name"
                      id="l_name"
                      type="text"
                      placeholder="{{ $t('last_name') }}"
                      aria-label="Last Name"
                      icon="mdi-account-outline"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="phone_no" class="form-control-label">
                      {{ $t("phone_number") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.phone_no"
                      id="phone_no"
                      type="text"
                      placeholder="{{ $t('phone_number') }}"
                      aria-label="Phone Number"
                      icon="mdi-phone"
                      @input="prependCountryCode"
                    />
                    <span v-if="errors.phone_no" class="error">{{
                      errors.phone_no
                    }}</span>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="role_id" class="form-control-label">
                        {{ $t("role") }}<span style="color: red">*</span>
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.role_id"
                          id="role_id"
                          class="form-control select-with-arrow"
                          aria-label="Role"
                          required
                          @change="onRoleChange"
                        >
                          <option value="" disabled selected>
                            {{ $t("select_option") }} {{ $t("role") }}
                          </option>
                          <option
                            v-for="role in roles"
                            :key="role.id"
                            :value="role.id"
                          >
                            {{ role.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <label for="email" class="form-control-label">
                      {{ $t("email") }}
                    </label>
                    <soft-input
                      v-model="newCandidate.email"
                      id="email"
                      type="email"
                      placeholder=""
                      aria-label="Email"
                      icon="mdi-email"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="password" class="form-control-label">
                      {{ $t("password") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.password"
                      id="password"
                      type="password"
                      placeholder=""
                      aria-label="Password"
                      icon="mdi-lock"
                    />
                  </div> -->

                  <div class="col-md-4" v-if="showEmailAndPasswordFields">
                    <label for="email" class="form-control-label">
                      {{ $t("email") }}
                    </label>
                    <soft-input
                      v-model="newCandidate.email"
                      id="email"
                      type="email"
                      :placeholder="$t('email_placeholder')"
                      aria-label="Email"
                      icon="mdi-email"
                    />
                  </div>

                  <!-- Password Field -->
                  <div class="col-md-4" v-if="showEmailAndPasswordFields">
                    <label for="password" class="form-control-label">
                      {{ $t("password") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.password"
                      id="password"
                      type="password"
                      :placeholder="$t('password_placeholder')"
                      aria-label="Password"
                      icon="mdi-lock"
                    />
                  </div>
                  <div class="col-md-4" v-if="showPartFields">
                    <div class="form-group">
                      <label for="part_no" class="form-control-label">
                        {{ $t("Part no") }}
                      </label>
                      <multiselect
                        v-model="newCandidate.partnoList"
                        :options="availablePartno"
                        :multiple="true"
                        :track-by="'value'"
                        label="resource_name"
                        placeholder="Select partno"
                      >
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <div>
                        <label for="gender" class="label-text">
                          {{ $t("gender") }} <span class="required">*</span>
                        </label>
                      </div>
                      <div class="radio-group">
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="male"
                            name="gender"
                            value="1"
                          />
                          <label for="male" class="radio-label">{{
                            $t("male")
                          }}</label>
                        </div>
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="female"
                            name="gender"
                            value="2"
                          />
                          <label for="female" class="radio-label">{{
                            $t("female")
                          }}</label>
                        </div>
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="other"
                            name="gender"
                            value="3"
                          />
                          <label for="other" class="radio-label">{{
                            $t("other")
                          }}</label>
                        </div>
                      </div>
                      <span v-if="errors.gender" class="error">{{
                        errors.gender
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="office" class="form-control-label">
                        {{ $t("office") }} <span style="color: red">*</span>
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.office"
                          id="office"
                          class="form-control select-with-arrow"
                          aria-label="office"
                        >
                          <option value="" disabled selected>
                            {{ $t("select_office") }}
                          </option>
                          <option
                            v-for="office in offices"
                            :key="office.id"
                            :value="office.id"
                          >
                            {{ office.office_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="special_note" class="form-control-label">
                        {{ $t("special_note") }}
                      </label>
                      <textarea
                        v-model="newCandidate.special_note"
                        id="special_note"
                        class="form-control"
                        placeholder=""
                        aria-label="Special Note"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group text-center">
                      <label for="aadhaar" class="form-control-label">
                        {{ $t("aadhaar_upload") }}
                      </label>
                      <div>
                        <img
                          :src="aadhaarPhotoUrl"
                          alt="Aadhaar Card"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerAadhaarFileInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="aadhaar"
                        ref="aadhaarFileInput"
                        class="d-none"
                        aria-label="Aadhaar Upload"
                        @change="onAadhaarChange"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group text-center">
                      <label for="profile_photo" class="form-control-label">
                        {{ $t("profile_picture") }}
                      </label>
                      <div>
                        <img
                          :src="profilePhotoUrl"
                          alt="Profile Picture"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerProfileFileInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="profile_photo"
                        ref="profileFileInput"
                        class="d-none"
                        aria-label="Profile Photo"
                        @change="onProfileChange"
                      />
                    </div>
                  </div>
                  <div class="col-md-4" v-if="showEmailAndPasswordFields">
                    <div class="form-group text-center">
                      <label for="slip_image" class="form-control-label">
                        {{ $t("Slip Image") }}
                      </label>
                      <div>
                        <img
                          :src="SlipImagePhotoUrl"
                          alt="Slip Image"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerSlipImageFileInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="slip_image"
                        ref="SlipImageFileInput"
                        class="d-none"
                        aria-label="Slip Image"
                        @change="onSlipImageChange"
                      />
                    </div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <soft-checkbox
                      id="flexCheckDefault"
                      name="flexCheckDefault"
                      class="font-weight-light"
                      checked
                    >
                      {{ $t("i_agree") }}
                      <a
                        href="javascript:;"
                        class="text-dark font-weight-bolder"
                        >{{ $t("terms_and_conditions") }}</a
                      >
                    </soft-checkbox>
                  </div>
                </div>
                <div class="text-center">
                  <soft-button
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                     :disabled="isLoading"
        :loading="isLoading"
                  >
                    {{ $t("update") }}
                  </soft-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import profilePhotoUrl from "@/assets/img/profile.png";
import SlipImagePhotoUrl from "@/assets/img/slipimage.png";
import aadhaarPhotoUrl from "@/assets/img/adhaar.png";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
// import SoftRadio from "@/components/SoftRadio.vue";
import Swal from "sweetalert2";
import UsersDataService from "../../services/UsersDataService.js";
import OfficesDataService from "../../services/OfficesDataService.js";
import RolesDataService from "../../services/RolesDataService.js";
import CandidateDataService from "../../services/CandidateDataService.js";
import VotersSearchDataService from "../../services/VotersSearchDataService.js";
import { handleApiErrors } from "@/utils/errorHandler";
import Multiselect from "vue-multiselect";

export default {
  name: "EditCandidate",
  components: {
    SoftInput,
    SoftCheckbox,
    SoftButton,
    Multiselect,
    // SoftRadio,
  },
  data() {
    return {
      users: [],
      offices: [],
      roles: [],
      availablePartno: [], // Available options for partnos
      isLoading: false,
      newCandidate: {
        org_id: localStorage.getItem("orgId"),
        reporting_to: "",
        f_name: "",
        l_name: "",
        phone_no: "",
        email: "",
        password: "",
        gender: "",
        office: "",
        role_id: "",
        special_note: "",
        profile_picture: "",
        adharcard_picture: "",
        slip_image: "",
        partnoList: [], // This will hold the selected partnoList
      },
      errors: {},
      profilePhotoUrl,
      SlipImagePhotoUrl,
      aadhaarPhotoUrl,
    };
  },
  computed: {
    showEmailAndPasswordFields() {
      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );

      // Add a check to ensure `selectedRole` exists and has a `type` property
      return selectedRole && selectedRole.type !== undefined
        ? selectedRole.type !== 2
        : true; // Default to showing the fields if selectedRole is undefined
    },
    showPartFields() {
      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );

      // Same check here
      return selectedRole && selectedRole.type !== undefined
        ? selectedRole.type === 2
        : true; // Default to showing the fields if selectedRole is undefined
    },
    showfortUserFields() {
      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );

      // Same check here
      return selectedRole && selectedRole.type !== undefined
        ? selectedRole.type === 2
        : false; // Default to hiding the fields if selectedRole is undefined
    },
    filteredRoles() {
      const steps = parseInt(localStorage.getItem("steps"), 10);
      if (steps === 3) {
        return this.roles.filter((role) => role.name === "Candidate");
      }
      // Otherwise, show all roles
      return this.roles;
    },
  },
  created() {
    this.getUser();
    this.getOffice();
    this.getRoles();
    this.getAllPartno();
    this.candidate_id = this.$route.params.id;
    this.getCandidateDetails(this.candidate_id);
  },
  watch: {
    availablePartno(newVal) {
      if (
        newVal.length > 0 &&
        this.newCandidate &&
        this.newCandidate.partno_list
      ) {
        this.matchPartnoList();
      }
    },
  },
  methods: {
    getAllPartno() {
      const defaultacno = localStorage.getItem("defaultacno");

      if (!defaultacno) {
        console.error("No acno found in localStorage");
        return;
      }

      VotersSearchDataService.getAllPartno(defaultacno)
        .then((response) => {
          if (response.data && response.data.partnolist) {
            // Sort the partnolist in ascending order based on partno
            const sortedPartnoList = response.data.partnolist.sort(
              (a, b) => a.partno - b.partno
            );

            // Map the sorted list to include both partno and area_name for display
            this.availablePartno = sortedPartnoList.map((item) => ({
              resource_name: `${item.partno} - ${item.area_name}`, // Display partno and area_name
              value: item.partno, // Keep partno as value for payload
            }));
          } else {
            this.availablePartno = []; // Fallback to empty array if no data
            console.warn("No partnolist in response");
          }
        })
        .catch((error) => {
          this.availablePartno = []; // Ensure it's an array even in case of error
          console.error("Error fetching part numbers:", error);
        });
    },
    getCandidateDetails() {
      CandidateDataService.get(this.candidate_id)
        .then((response) => {
          const canData = response.data.candidate;

          // Parse partno_list string into an array if it exists
          let partnoListParsed = [];
          if (canData.partno_list) {
            try {
              partnoListParsed = JSON.parse(canData.partno_list);
            } catch (error) {
              console.error("Error parsing partno_list:", error);
            }
          }

          // Assign the data to newCandidate
          this.newCandidate = {
            id: canData.id,
            org_id: canData.org_id || "",
            candidate_id: canData.candidate_id || "",
            reporting_to: canData.reporting_to || "",
            f_name: canData.f_name || "",
            l_name: canData.l_name || "",
            phone_no: canData.phone_no || "",
            email: canData.email || "",
            password: canData.password || "",
            gender: canData.gender || "",
            office: canData.office || "",
            role_id: canData.role_id || "",
            special_note: canData.special_note || "",
            // adharcard_picture: canData.adharcard_picture || "",
            adharcard_picture: canData.adharcard_picture
              ? `https://storage.googleapis.com/lok-sevak/${canData.adharcard_picture}`
              : "",
            profile_picture: canData.profile_picture
              ? `https://storage.googleapis.com/lok-sevak/${canData.profile_picture}`
              : "",
            slip_image: canData.slip_image
              ? `https://storage.googleapis.com/lok-sevak/${canData.slip_image}`
              : "",
            partno_list: partnoListParsed || [], // Store parsed partno_list
          };
          this.profilePhotoUrl = this.newCandidate.profile_picture;
          this.aadhaarPhotoUrl = this.newCandidate.adharcard_picture;
          this.SlipImagePhotoUrl = this.newCandidate.slip_image;
          console.log("newCandidate partnoList", this.newCandidate.partno_list);
        })
        .catch((error) => {
          console.error("Error fetching candidate details:", error);
        });
    },

    matchPartnoList() {
      const matchedPartnos = this.availablePartno.filter((part) =>
        this.newCandidate.partno_list.includes(part.value)
      );

      // Assign the matched partnos to newCandidate
      this.newCandidate.partnoList = matchedPartnos || [];
      console.log("Matched partnos", this.newCandidate.partnoList);
    },
    getUser() {
      UsersDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getOffice() {
      OfficesDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.offices = response.data.offices;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getRoles() {
      RolesDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.roles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    triggerAadhaarFileInput() {
      this.$refs.aadhaarFileInput.click();
    },
    triggerProfileFileInput() {
      this.$refs.profileFileInput.click();
    },
    triggerSlipImageFileInput() {
      this.$refs.SlipImageFileInput.click();
    },
    onAadhaarChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newCandidate.adharcard_picture = e.target.result.split(",")[1];
          this.aadhaarPhotoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    onProfileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newCandidate.profile_picture = e.target.result.split(",")[1];
          this.profilePhotoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    onSlipImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newCandidate.slip_image = e.target.result.split(",")[1];
          this.SlipImagePhotoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    isValidBase64(str) {
      try {
        return btoa(atob(str)) === str;
      } catch (err) {
        return false;
      }
    },
    onRoleChange() {
      if (!this.showEmailAndPasswordFields) {
        this.newCandidate.email = "";
        this.newCandidate.password = "";
      }
    },
    prependCountryCode() {
      if (
        this.newCandidate.phone_no &&
        !this.newCandidate.phone_no.startsWith("+91")
      ) {
        this.newCandidate.phone_no = "+91" + this.newCandidate.phone_no;
      }
    },
    updateCandidates() {
      this.isLoading = true;
      const payload = {
        candidate_id: this.newCandidate.id,
        org_id: this.newCandidate.org_id,
        reporting_to: this.newCandidate.reporting_to,
        f_name: this.newCandidate.f_name,
        l_name: this.newCandidate.l_name,
        phone_no: this.newCandidate.phone_no.startsWith("+91")
          ? this.newCandidate.phone_no
          : "+91" + this.newCandidate.phone_no,
        email: this.newCandidate.email,
        password: this.newCandidate.password,
        gender: this.newCandidate.gender,
        office: this.newCandidate.office,
        role_id: this.newCandidate.role_id,
        special_note: this.newCandidate.special_note,
        profile_picture: this.newCandidate.profile_picture,
        adharcard_picture: this.newCandidate.adharcard_picture,
        slip_image: this.newCandidate.slip_image,
        partno_list: JSON.stringify(
          (this.newCandidate.partnoList || []).map((part) => part.value)
        ), // Extracting the values
      };

      if (
        payload.adharcard_picture &&
        !this.isValidBase64(payload.adharcard_picture)
      ) {
        delete payload.adharcard_picture;
      }
      if (
        payload.profile_picture &&
        !this.isValidBase64(payload.profile_picture)
      ) {
        delete payload.profile_picture;
      }

      console.log("Payload to update:", payload);

      CandidateDataService.update(payload)
        .then((response) => {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Details updated successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/candidates");
          });
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
/* Add your scoped styles here */
.error {
  color: red;
}
.form-group {
  margin-bottom: 1rem;
}

.label-text {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.required {
  color: red;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.radio-option {
  display: flex;
  align-items: center;
}

.radio-label {
  font-size: 14px;
  margin-left: 0.5rem;
  cursor: pointer;
  margin-left: -7px;
  margin-top: 7px;
}

input[type="radio"] {
  margin-right: 0.5rem;
}
.readonly-input {
  background-color: #f5f5f5;
  color: #666666;
  pointer-events: none;
  opacity: 1;
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 0.5rem;
  display: block;
}
</style>
