<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <AddOffice />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <projects-table />
      </div>
    </div> -->
  </div>
</template>

<script>
import AddOffice from "./components/AddOffice";
// import ProjectsTable from "./components/ProjectsTable";

export default {
  name: "Users",
  components: {
    AddOffice,
    // ProjectsTable,
  },
};
</script>
