<template>
  <!-- area or location -->
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>area or location</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1869.000000, -293.000000)" fill="#000000" fill-rule="nonzero">
        <g transform="translate(1716.000000, 291.000000)">
          <g id="area-or-location" transform="translate(153.000000, 2.000000)">
            <path
              class="color-background"
              d="M12,2 C8.13,2 5,5.13 5,9 C5,12.87 10,20 12,22 C14,20 19,12.87 19,9 C19,5.13 15.87,2 12,2 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script></script>
