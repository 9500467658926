<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-lg-6 col-7">
          <h6>{{ $t("user_all_task_list") }}</h6>
          <p class="text-sm mb-0">
            <i class="fa fa-check text-info" aria-hidden="true"></i>
            <span class="font-weight-bold ms-1">{{ tasks.length }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ $t("task_name") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("description") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("event") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("status_percentage") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("assigned_user") }}
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ $t("status") }}
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ $t("deadline") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="task in paginatedTasks" :key="task.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <h6 class="mb-0 text-sm">{{ task.task_name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm mb-0">{{ task.task_description }}</p>
              </td>
              <td>
                <p class="text-sm mb-0">
                  {{ task.assignedTeam?.name || "N/A" }}
                </p>
              </td>
              <td>
                <p class="text-sm mb-0">{{ task.status_percentage }}%</p>
              </td>
              <td>
                <p class="text-sm mb-0">
                  {{ task.assignedUser.f_name }} {{ task.assignedUser.l_name }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span
                  :class="{
                    'badge bg-danger':
                      task.status === 0 ||
                      task.status === 5 ||
                      task.status === 6,
                    'badge bg-warning': task.status === 2 || task.status === 4,
                    'badge bg-primary': task.status === 1,
                    'badge bg-success': task.status === 3,
                  }"
                >
                  {{ getStatusText(task.status) }}
                </span>
              </td>

              <td class="align-middle text-center">
                <span class="text-xs font-weight-bold">{{
                  formatDate(task.end_date_time)
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">
          {{ $t("previous") }}
        </button>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          {{ $t("next") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TaskDataService from "../../services/TaskDataService.js";

export default {
  name: "TasksCard",
  data() {
    return {
      tasks: [],
      currentPage: 1,
      tasksPerPage: 5,
    };
  },
  created() {
    this.getAllTasks();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.tasks.length / this.tasksPerPage);
    },
    paginatedTasks() {
      const start = (this.currentPage - 1) * this.tasksPerPage;
      const end = start + this.tasksPerPage;
      return this.tasks.slice(start, end);
    },
  },
  methods: {
    getAllTasks() {
      TaskDataService.getAll()
        .then((response) => {
          this.tasks = response.data.tasks || [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    getStatusText(status) {
      switch (status) {
        case 0:
          return this.$t("pending");
        case 1:
          return this.$t("accept");
        case 2:
          return this.$t("work-in-progress");
        case 3:
          return this.$t("completed");
        case 4:
          return this.$t("Deffered");
        case 5:
          return this.$t("cancelled");
        case 6:
          return this.$t("cancelled by admin");
        default:
          return "";
      }
    },
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 30px;
  border: none;
  background-color: #141727;
  color: white;
  cursor: pointer;
}
.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>
