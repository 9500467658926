<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>{{ $t("offices") }}</h6>
      <button type="button" class="btn btn-primary" @click="navigateToAddOffice" :style="{backgroundColor: '#141727', color: 'white', border: '2px solid #141727'}">{{ $t("add_office") }}</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("office_name") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("location_name") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("primary_incharge") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("secondary_incharge") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("action") }}</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(office, index) in paginatedOffices" :key="index">
              <td><p class="text-xs font-weight-bold mb-0">{{ office.office_name }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ office.location_name }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ office.primary_incharge_details ?  office.primary_incharge_details.f_name+' '+office.primary_incharge_details.l_name : ""}}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ office.secondary_incharge_details ?  office.secondary_incharge_details.f_name+' '+office.secondary_incharge_details.l_name : "" }}</p></td>
              <td class="align-middle">
                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" @click="navigateToEditOffice(office.id)"><i class="fas fa-edit"></i> {{ $t("edit") }}</a>
                <a href="javascript:;" class="text-danger font-weight-bold text-xs ms-3" @click="confirmDelete(index,office.id)"><i class="fas fa-trash-alt"></i> {{ $t("delete") }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Pagination -->
    <nav class="mt-4" aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <button class="page-link" :class="{ active: currentPage === page }" :style="pageLinkStyle(page)" @click="goToPage(currentPage - 1)" :disabled="currentPage === 1" style="border-radius: 10px !important;width: 70px;">{{ $t("previous") }}</button>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page">
          <button class="page-link" :class="{ active: currentPage === page }" :style="pageLinkStyle(page)" @click="goToPage(page)">{{ page }}</button>
        </li>
        <li class="page-item">
          <button class="page-link" :class="{ active: currentPage === page }" :style="pageLinkStyle(page)" @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages" style="border-radius: 10px !important;width: 70px;">{{ $t("next") }}</button>
        </li>
      </ul>
    </nav>

    <!-- Add Modal -->
    <div v-if="showAddModal" class="modal fade show d-block" tabindex="-1" role="dialog" :style="{background: 'rgba(0,0,0,0.5)'}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("add_office_modal_title") }}</h5>
            <button type="button" class="close" @click="showAddModal = false" aria-label="Close" :style="{backgroundColor: '#141727', color: 'white', border: '2px solid #141727', borderRadius: '50%'}"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addOffice">
              <div class="form-group">
                <label for="officeName">{{ $t("office_name_label") }}</label>
                <input v-model="newOffice.officeName" type="text" class="form-control" id="officeName" required />
              </div>
              <div class="form-group">
                <label for="organizationId">{{ $t("organization_label") }}</label>
                <input v-model="newOffice.organizationId" type="text" class="form-control" id="organizationId" required />
              </div>
              <div class="form-group">
                <label for="address">{{ $t("address_label") }}</label>
                <input v-model="newOffice.address" type="text" class="form-control" id="address" required />
              </div>
              <div class="form-group">
                <label for="primaryIncharge">{{ $t("primary_incharge_label") }}</label>
                <select v-model="incharges" class="form-control" id="primaryIncharge" required>
                  <option v-for="item in incharges" :key="item.id" :value="item.id">
                    {{ item.f_name }} {{ item.l_name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="secondaryIncharge">{{ $t("secondary_incharge_label") }}</label>
                <select v-model="secondincharges" class="form-control" id="secondaryIncharge" required>
                  <option v-for="item1 in secondincharges" :key="item1.id" :value="item1.id">
                    {{ item1.f_name }} {{ item1.l_name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="mapUrl">{{ $t("map_url_label") }}</label>
                <input v-model="newOffice.mapUrl" type="text" class="form-control" id="mapUrl" required />
              </div>
              <button type="submit" class="btn btn-primary mt-3" :style="{backgroundColor: '#141727', color: 'white', border: '2px solid #141727'}">{{ $t("add_office_button") }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div v-if="showEditModal" class="modal fade show d-block" tabindex="-1" role="dialog" :style="{background: 'rgba(0,0,0,0.5)'}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("edit_office_modal_title") }}</h5>
            <button type="button" class="close" @click="showEditModal = false" aria-label="Close" :style="{backgroundColor: '#141727', color: 'white', border: '2px solid #141727', borderRadius: '50%'}"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateOffice">
              <div class="form-group">
                <label for="editOfficeName">{{ $t("office_name_label") }}</label>
                <input v-model="currentOffice.officeName" type="text" class="form-control" id="editOfficeName" required />
              </div>
              <div class="form-group">
                <label for="editOrganizationId">{{ $t("organization_label") }}</label>
                <input v-model="currentOffice.organizationId" type="text" class="form-control" id="editOrganizationId" required />
              </div>
              <div class="form-group">
                <label for="editAddress">{{ $t("address_label") }}</label>
                <input v-model="currentOffice.address" type="text" class="form-control" id="editAddress" required />
              </div>
              <div class="form-group">
                <label for="editPrimaryIncharge">{{ $t("primary_incharge_label") }}</label>
                <input v-model="currentOffice.primaryIncharge" type="text" class="form-control" id="editPrimaryIncharge" required />
              </div>
              <div class="form-group">
                <label for="editSecondaryIncharge">{{ $t("secondary_incharge_label") }}</label>
                <input v-model="currentOffice.secondaryIncharge" type="text" class="form-control" id="editSecondaryIncharge" required />
              </div>
              <div class="form-group">
                <label for="editMapUrl">{{ $t("map_url_label") }}</label>
                <input v-model="currentOffice.mapUrl" type="text" class="form-control" id="editMapUrl" required />
              </div>
              <button type="submit" class="btn btn-primary mt-3" :style="{backgroundColor: '#141727', color: 'white', border: '2px solid #141727'}">{{ $t("save_changes_button") }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfficesDataService from "../../services/OfficesDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      offices: [],
      incharges: [],
      secondincharges: [],
      newOffice: {
        officeName: '',
        organizationId: '',
        address: '',
        primaryIncharge: '',
        secondaryIncharge: '',
        teams: []
      },
      showAddModal: false,
      showEditModal: false,
      currentOffice: null,
      currentPage: 1,
      perPage: 10
    };
  },
  created() {
    this.getAllOffices();
    this.getAllIncharges();
    this.getAllSecondIncharges();
  },
  computed: {
    paginatedOffices() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.offices.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.offices.length / this.perPage);
    }
  },
  methods: {
    
    getAllOffices() {
      OfficesDataService.getAll()
        .then(response => {
          this.offices = response.data.offices;
          console.log(this.offices);
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAllIncharges() {
      UsersDataService.getAll()
        .then(response => {
          this.incharges = response.data;
          console.log(this.incharges);
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAllSecondIncharges() {
      UsersDataService.getAll()
        .then(response => {
          this.secondincharges = response.data;
          console.log(this.secondincharges);
        })
        .catch(e => {
          console.log(e);
        });
    },
    addOffice() {
      this.offices.push({ ...this.newOffice });

      UsersDataService.create(this.newOffice)
        .then(response => {
          console.log(response.data.role);
        })
        .catch(e => {
          console.log(e);
        });

      this.showAddModal = false;
      this.newOffice = {
        officeName: '',
        organizationId: '',
        address: '',
        primaryIncharge: '',
        secondaryIncharge: '',
        mapUrl: ''
      };
    },
    navigateToAddOffice() {
      this.$router.push('/add-office');
    },
    navigateToEditOffice(id) {
      this.$router.push(`/edit-office/${id}`);
    },
    openEditModal(office, index) {
      this.currentOffice = { ...office, index };
      this.showEditModal = true;
    },
    updateOffice() {
      Object.assign(this.offices[this.currentOffice.index], this.currentOffice);
      this.showEditModal = false;
      this.currentOffice = null;
    },
    confirmDelete(index, id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteOffice(index, id);
        Swal.fire(
          'Deleted!',
          'The Office has been deleted.',
          'success'
        ).then(() => {
          // window.location.reload();
        });
      }
    });
  },
    deleteOffice(index,id) {
      OfficesDataService.delete(id)
        .then(response => {
          console.log(response);
          this.offices.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
        });
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    pageLinkStyle(page) {
      return {
        borderRadius: '10px',
        width: '35px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: this.currentPage === page ? '#ffffff' : '#141727',
        backgroundColor: this.currentPage === page ? '#141727' : '#ffffff',
        border: this.currentPage === page ? '2px solid #141727' : '2px solid #e1e4e8'
      };
    }
  }
};
</script>

<style scoped>
.modal-content {
  border-radius: 15px;
}
.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}
</style>
