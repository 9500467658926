<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>master</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-102.000000, -291.000000)" fill="#4d4d4d" fill-rule="nonzero">
        <g transform="translate(102.000000, 291.000000)">
          <path
            d="M12,2 C7.02943725,2 3,6.02943725 3,11 C3,15.9705627 7.02943725,20 12,20 C16.9705627,20 21,15.9705627 21,11 C21,6.02943725 16.9705627,2 12,2 Z M12,18 C8.13417162,18 5,14.8658284 5,11 C5,7.13417162 8.13417162,4 12,4 C15.8658284,4 19,7.13417162 19,11 C19,14.8658284 15.8658284,18 12,18 Z M12,6 C10.3431458,6 9,7.3431458 9,9 C9,10.6568542 10.3431458,12 12,12 C13.6568542,12 15,10.6568542 15,9 C15,7.3431458 13.6568542,6 12,6 Z M12,10 C11.4477153,10 11,9.55228475 11,9 C11,8.44771525 11.4477153,8 12,8 C12.5522847,8 13,8.44771525 13,9 C13,9.55228475 12.5522847,10 12,10 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
