<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h5>{{ $t("add_task") }}</h5>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addTask" class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="selectevent">{{ $t("events") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newTask.event"
                  class="form-control select-with-arrow"
                  id="selectevent"
                  required
                >
                  <option value="" selected>{{ $t("select_event") }}</option>
                  <option
                    v-for="event in events"
                    :key="event.id"
                    :value="event.id"
                  >
                    {{ event.event_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="taskName">{{ $t("task_name") }}</label>
              <input
                v-model="newTask.task_name"
                type="text"
                class="form-control"
                id="taskName"
                required
              />
              <span v-if="errors.task_name" class="error">{{
                errors.task_name
              }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="taskStatus">{{ $t("status") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newTask.status"
                  class="form-control select-with-arrow"
                  id="taskStatus"
                  required
                >
                  <option value="0">{{ $t("pending") }}</option>
                  <option value="1">{{ $t("accept") }}</option>
                  <option value="2">{{ $t("work_in_progress") }}</option>
                  <option value="3">{{ $t("completed") }}</option>
                  <option value="4">{{ $t("deferred") }}</option>
                  <option value="5">{{ $t("cancelled") }}</option>
                  <option value="6">{{ $t("cancelled_by_admin") }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="assignUser">{{ $t("assigned_user") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newTask.assign"
                  class="form-control select-with-arrow"
                  id="assignUser"
                  required
                >
                  <option value="" selected>{{ $t("select_user") }}</option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="assignGroup">{{ $t("assign_team") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newTask.assignGroup"
                  class="form-control select-with-arrow"
                  id="assignGroup"
                  required
                >
                  <option value="" selected>{{ $t("select_team") }}</option>
                  <option
                    v-for="group in voterGroups"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="start_date_time">{{ $t("start_date_time") }}</label>
              <input
                v-model="newTask.start_date_time"
                type="datetime-local"
                class="form-control"
                id="start_date_time"
                required
              />
              <span v-if="errors.start_date_time" class="error">{{
                errors.start_date_time
              }}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="end_date_time">{{ $t("end_date_time") }}</label>
              <input
                v-model="newTask.end_date_time"
                type="datetime-local"
                class="form-control"
                id="end_date_time"
                required
              />
              <span v-if="errors.end_date_time" class="error">{{
                errors.end_date_time
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="vehicles">{{ $t("vehicles") }}</label>
              <multiselect
                v-model="newTask.vehicles"
                :options="availableVehicles"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="vehicle_name"
                track-by="vehicle_name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="resources">{{ $t("resources") }}</label>
              <multiselect
                v-model="newTask.resources"
                :options="availableResources"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="resource_name"
                track-by="resource_name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="specialNotes">{{ $t("special_notes") }}</label>
              <input
                v-model="newTask.specialNotes"
                type="text"
                class="form-control"
                id="specialNotes"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="taskDescription">{{ $t("task_description") }}</label>
              <textarea
                v-model="newTask.description"
                class="form-control"
                id="taskDescription"
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button
              type="submit"
              class="btn btn-primary mt-3"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
              "
               :disabled="isLoading"
        :loading="isLoading"
            >
              {{ $t("add_task") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
//import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import TeamDataService from "../../services/TeamDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import EventDataService from "../../services/EventDataService.js";
import VehicleDataService from "../../services/VehicleDataService.js";
import ResourcesDataService from "../../services/ResourcesDataService.js";
import TaskDataService from "../../services/TaskDataService.js";
import Swal from "sweetalert2";
import { handleApiErrors } from "@/utils/errorHandler";

export default {
  name: "AddTask",
  components: {
    Multiselect,
  },
  data() {
    return {
      tasks: [],
      availableVehicles: [],
      availableResources: [],
      isLoading: false,
      newTask: {
        task_name: "",
        task_description: "",
        status: "0",
        assign: "",
        event: "",
        assignGroup: "",
        specialNotes: "",
        start_date_time: "",
        end_date_time: "",
        vehicles: [],
        resources: [],
      },
      errors: {},
      voterGroups: [],
      users: [],
      events: [],
    };
  },
  created() {
    this.getAllGroups();
    this.getAllUsers();
    this.getAllEvents();
    this.getAllVehicles();
    this.getAllResources();
  },
  methods: {
    getAllGroups() {
      TeamDataService.getAll()
        .then((response) => {
          this.voterGroups = response.data.teams;
          console.log(this.voterGroups);
        })
        .catch((e) => {
          console.log(e);
        });

      // VoterGroupsDataService.getAll()
      //   .then(response => {
      //     this.voterGroups = response.data.voter_group;
      //     console.log(this.voterGroups);
      //   })
      //   .catch(e => {
      //     console.log(e);
      //   });
    },
    getAllUsers() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
          console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllEvents() {
      EventDataService.getAll()
        .then((response) => {
          this.events = response.data.events;
          console.log(this.events);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllVehicles() {
      VehicleDataService.getAll()
        .then((response) => {
          this.availableVehicles = response.data.vehicles;
          console.log(this.availableVehicles);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllResources() {
      // Assuming you have a data service for resources
      ResourcesDataService.getAll()
        .then((response) => {
          this.availableResources = response.data.resources;
          console.log(this.availableResources);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addTask() {
      this.isLoading = true;
      const newTask = {
        id: this.tasks.length + 1,
        task_name: this.newTask.task_name,
        task_description: this.newTask.description,
        status: this.newTask.status,
        event: this.newTask.event,
        event_id: this.newTask.event,
        start_date_time: this.newTask.start_date_time,
        end_date_time: this.newTask.end_date_time,
        vehicles: this.newTask.vehicles.map((vehicle) => vehicle.id),
        resources: this.newTask.resources.map((resource) => resource.id),
        assigned_group: this.newTask.assignGroup,
        assigned_user: this.newTask.assign,
        special_notes: this.newTask.specialNotes,
        // vehicles: Array.isArray(this.newTask.vehicles) ? this.newTask.vehicles : [this.newTask.vehicles],
        // resources: Array.isArray(this.newTask.resources) ? this.newTask.resources : [this.newTask.resources]
      };
      console.log(newTask);

      TaskDataService.create(newTask)
        .then((response) => {
          // this.newQuestion = { question: "", option1: "", option2: "", option3: "", location: "", answer1: "" , answer2: "", answer3: "", start_date: "",end_date: ""};

          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Task added successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/task");

            // Reset the form fields
            this.tasks.push(newTask);
            this.newTask.task_name = "";
            this.newTask.task_description = "";
            this.newTask.status = "0";
            this.newTask.assign = "";
            this.newTask.event = "";
            this.newTask.assignGroup = "";
            this.newTask.specialNotes = "";
            this.newTask.start_date_time = "";
            this.newTask.end_date_time = "";
            this.newTask.vehicles = [];
            this.newTask.resources = [];
          });

          console.log(response);
          this.showAddModal = false;
          //this.getAllArea();
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.error("Error adding events:", error);
        });
    },
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.card {
  margin-bottom: 20px;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 5px;
}

.text-success {
  margin-left: auto;
  font-weight: bold;
}

.error {
  color: red;
  font-size: 13px;
  font-family: "sansserif";
  font-weight: bold;
}
.error::first-letter {
  text-transform: capitalize;
}
</style>
