<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h6>{{ $t("Top Voting Center") }}</h6>
      <!-- Export Dropdown -->
      <div>
        <select v-model="selectedExportOption" @change="exportData">
          <option disabled value="">{{ $t("Export") }}</option>
          <option value="excel">{{ $t("Export as Excel") }}</option>
          <option value="pdf">{{ $t("Export as PDF") }}</option>
        </select>
      </div>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                AC No
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Part No
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Voting Center
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Voter Count
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Voters Reached
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                style="color: red"
              >
                Percentage
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(center, index) in sortedVotingCentersDescending"
              :key="index"
            >
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ center.ac_no }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ center.partno }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ center.vcenter }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ center.voter_count }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ center.voters_reached }}
                </p>
              </td>
              <td>
                <p
                  class="text-xs font-weight-bold mb-0"
                  :class="{
                    'percentage-red': center.voter_count === 0,
                    'percentage-green': center.voter_count > 0,
                  }"
                >
                  {{
                    center.voter_count > 0
                      ? (
                          (center.voters_reached / center.voter_count) *
                          100
                        ).toFixed(2)
                      : 0
                  }}%
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            @click="goToPageDesc(currentPageDesc - 1)"
            :disabled="currentPageDesc === 1"
          >
            Previous
          </button>
          <span>Page {{ currentPageDesc }} of {{ totalPagesDesc }}</span>
          <button
            @click="goToPageDesc(currentPageDesc + 1)"
            :disabled="currentPageDesc === totalPagesDesc"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swal from "sweetalert2";
import axios from "axios";
import * as XLSX from "xlsx"; // Excel export library
import jsPDF from "jspdf"; // PDF export library
import "jspdf-autotable"; // Plugin to create tables in PDF
import "@fortawesome/fontawesome-free/css/all.css";
import img1 from "../../assets/img/team-2.jpg";
const NotoSerifDevanagariBase64 = "Tm90b1NlcmlmRGV2YW5hZ2FyaS1SZWd1bGFyLnR0Zg==";
export default {
  name: "CandidatesTable",
  data() {
    return {
      votingCenters: [],
      img1,
      itemsPerPage: 10, // Number of items per page
      currentPageDesc: 1,
      selectedExportOption: "", // For dropdown export options
    };
  },
  created() {
    this.fetchVotingCenters();
  },
  computed: {
    sortedVotingCentersDescending() {
      const sortedCenters = [...this.votingCenters].sort(
        (a, b) => (b.voters_reached || 0) - (a.voters_reached || 0)
      ); // Sort by 'voters_reached' descending
      const startIndex = (this.currentPageDesc - 1) * this.itemsPerPage;
      return sortedCenters.slice(startIndex, startIndex + this.itemsPerPage); // Paginate
    },
    totalPagesDesc() {
      return Math.ceil(this.votingCenters.length / this.itemsPerPage);
    },
  },
  methods: {
    fetchVotingCenters() {
      const token = localStorage.getItem("token");
      const tenantId = localStorage.getItem("tenantId");
      const defaultacno = localStorage.getItem("defaultacno");
      axios
        .get(
          `https://rushivarya-stg-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/matdan-kendra-list`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: token,
            },
            params: {
              acno: defaultacno, // Add acno as a query parameter
            },
          }
        )
        .then((response) => {
          this.votingCenters = response.data || [];
        })
        .catch((error) => {
          console.error("Error fetching voting center data:", error);
          this.votingCenters = []; // Handle error by setting default value
        });
    },

    // Export functionality
    exportData() {
      if (this.selectedExportOption === "excel") {
        this.exportAsExcel();
      } else if (this.selectedExportOption === "pdf") {
        this.exportAsPDF();
      }
    },
    exportAsExcel() {
      const sortedCenters = [...this.votingCenters].sort(
        (a, b) => (b.voters_reached || 0) - (a.voters_reached || 0)
      );
      const worksheet = XLSX.utils.json_to_sheet(sortedCenters);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Voting Centers");
      XLSX.writeFile(workbook, "voting_centers.xlsx");
    },
    exportAsPDF() {
      const doc = new jsPDF();

      // Step 1: Add the font to jsPDF
      doc.addFileToVFS(
        "NotoSerifDevanagari-Regular.ttf",
        
        NotoSerifDevanagariBase64
      );
      doc.addFont(
        "NotoSerifDevanagari-Regular.ttf",
        "NotoSerifDevanagari",
        "normal"
      );
      doc.setFont("NotoSerifDevanagari"); // Use the Devanagari font for Hindi text

      // Prepare the sorted voting center data
      const sortedCenters = [...this.votingCenters].sort(
        (a, b) => (b.voters_reached || 0) - (a.voters_reached || 0)
      );

      // Generate table data
      const tableData = sortedCenters.map((center) => {
        const percentage =
          center.voter_count > 0
            ? ((center.voters_reached / center.voter_count) * 100).toFixed(2) +
              "%"
            : "0%";

        return [
          center.ac_no,
          center.partno,
          center.vcenter,
          center.voter_count,
          center.voters_reached,
          percentage,
        ];
      });

      console.log("Table Data:", tableData); // Debugging line

      // Step 2: Use autoTable to export the table with Hindi text
      doc.autoTable({
        head: [
          [
            "AC No",
            "Part No",
            "Voting Center",
            "Voter Count",
            "Voters Reached",
            "Percentage",
          ],
        ],
        body: tableData,
        styles: {
          font: 'NotoSerifDevanagari',
          overflow: 'linebreak',
          cellWidth: 'wrap',
        },
      });

      // Step 3: Save the generated PDF
      doc.save("voting_centers.pdf");
    },

    // Pagination for descending sorted data
    goToPageDesc(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPagesDesc) {
        this.currentPageDesc = pageNumber;
      }
    },
  },
};
</script>

<style scoped>
.langdrop {
  width: 130px;
  margin-left: 87%;
}
@media (min-width: 300px) and (max-width: 550px) {
  .langdrop {
    width: 130px;
    margin-left: 57%;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.pagination button {
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.pagination button:disabled {
  background-color: #ffffff;
  color: #000000;
  cursor: not-allowed;
}
.pagination span {
  margin: 0 0.5rem;
}
.percentage-red {
  color: red;
}
.percentage-green {
  color: green;
}
</style>
