<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>{{ $t('events') }}</h6>
     
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('location') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('area') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('event_name') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('start_date_time') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('end_date_time') }}
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                {{ $t('action') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(event, index) in events" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ event.location_name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ event.areas.name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ event.event_name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ formatDate(event.event_start) }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ formatDate(event.event_end) }}</p>
              </td>
              <td class="align-middle">
                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" @click="navigateToEditEvent(event.id)">
                  <i class="fas fa-edit"></i>
                </a>
                <a href="javascript:;" class="text-danger font-weight-bold text-xs ms-3" @click="confirmDelete(index,event.id)">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css';
import EventDataService from "../../services/EventDataService.js";
import Swal from 'sweetalert2';

export default {
  name: "EventTable",
  data() {
    return {
      events: [],
      currentEvent: {
        location: "",
        area: "",
        event_name: "",
        start_datetime: "",
        end_datetime: "",
      },
      showEditModal: false,
      currentIndex: null,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  created() {
    this.getAllEvents();
  },
  computed: {
    paginatedevents() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.events.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.events.length / this.itemsPerPage);
    }
  },
  methods: {
    getAllEvents() {
    // Get the current date
    const currentDate = new Date();
    
    // Calculate start_date (current date - 1 month)
    const startDate = new Date(currentDate);
    startDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 month
    
    // Calculate end_date (current date + 1 month)
    const endDate = new Date(currentDate);
    endDate.setMonth(currentDate.getMonth() + 1); // Add 1 month

    // Format dates to YYYY-MM-DD
    const formatDateToYYYYMMDD = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const payload = {
      start_date: formatDateToYYYYMMDD(startDate),
      end_date: formatDateToYYYYMMDD(endDate)
    };

    // Make API call with payload
    EventDataService.getAllForReports(payload)
      .then(response => {
        this.events = response.data.events;
        console.log(this.events);
      })
      .catch(e => {
        console.log(e);
      });
  },
    navigateToAddEvent() {
      this.$router.push('/add-event');
    },
    navigateToEditEvent(id) {
      this.$router.push(`/edit-event/${id}`);
    },
    openEditModal(event, index) {
      this.currentEvent = { ...event };
      this.currentIndex = index;
      this.showEditModal = true;
    },
    updateEvent() {
      if (this.currentIndex !== null) {
        this.$set(this.events, this.currentIndex, { ...this.currentEvent });
      }
      this.showEditModal = false;
    },
    confirmDelete(index, id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteEvent(index, id);
        Swal.fire(
          'Deleted!',
          'The Event has been deleted.',
          'success'
        ).then(() => {
          // window.location.reload();
        });
      }
    });
  },
    deleteEvent(index,id) {
      EventDataService.delete(id)
        .then(response => {
          console.log(response);
          this.events.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
  }
};
</script>

<style scoped>
.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}
.modal.fade.show {
  display: block;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;color:white;border:2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
