import http from '../http-common';

class SubTaskDataService {
  getAll() {
    return http.get("/sub-tasks");
  }

  get(id) {
    return http.get(`/sub-tasks/edit/${id}`);
  }

  create(data) {
    return http.post("/sub-tasks/add", data);
  }

  update(data) {
    return http.put(`/sub-tasks/update`, data);
  }

  updateStatus(data) {
    return http.put(`/sub-tasks/status-update`, data);
  }

  delete(id) {
    return http.put(`/sub-tasks/delete/${id}`);
  }
}

export default new SubTaskDataService();
