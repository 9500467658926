<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>survey</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-102.000000, -291.000000)" fill="#4d4d4d" fill-rule="nonzero">
        <g transform="translate(102.000000, 291.000000)">
          <path
            class="color-background"
            d="M19,3 L5,3 C3.897,3 3,3.897 3,5 L3,19 C3,20.103 3.897,21 5,21 L19,21 C20.103,21 21,20.103 21,19 L21,5 C21,3.897 20.103,3 19,3 Z M10,17 L6,13 L7.414,11.586 L10,14.172 L16.586,7.586 L18,9 L10,17 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
