<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>{{ $t('vehicles') }}</h6>
      <button type="button" class="btn btn-primary" @click="navigateToAddVehicle" style="background-color: #141727; color: white; border: 2px solid #141727;">{{ $t('add_vehicle') }}</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('vehicle_name') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('vehicle_type') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('seating_capacity') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('weight_capacity') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('special_instructions') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('driver_name') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('driver_phone') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('assign') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vehicle, index) in vehicles" :key="index">
              <td><p class="text-xs font-weight-bold mb-0">{{ vehicle.vehicle_name }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ getVehicleType(vehicle.vehicle_type) }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ vehicle.seating_capacity }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ vehicle.weight_capacity }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ vehicle.special_instructions }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ vehicle.driver_name }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ vehicle.driver_phone_no }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">
                <button 
                  style="background-color:#141727; color: white; border: 2px solid #141727; border-radius: 5px;" 
                  @click="openAssignModal(vehicle, index)"
                >
                  {{ $t('assign') }}
                </button></p></td>
              <td class="align-middle">
                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" @click="openEditModal(vehicle, index)">
                  <i class="fas fa-edit"></i>
                </a>
                <a href="javascript:;" class="text-danger font-weight-bold text-xs ms-3" @click="confirmDelete(index, vehicle.id)">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
        </div>
      </div>
    </div>
    <div v-if="showAssignModal" class="modal fade show d-block" tabindex="-1" role="dialog" style="background: rgba(0,0,0,0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('assign_user') }}</h5>
            <button type="button" class="close" @click="showAssignModal = false" aria-label="Close" style="background-color: #141727; color: white; border: 2px solid #141727; border-radius: 50%;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="assignUser">
              <div class="form-group">
                <label for="selectUser">{{ $t('select_user') }}</label>
                <select
        v-model="assignVehicle.assign"
        class="form-control select-with-arrow"
        id="assignUser"
        required
      >
        <option value="" selected>{{ $t("select_user") }}</option>
        <option v-for="user in users" :key="user.id" :value="user.id">
          {{ user.f_name }} {{ user.l_name }}
        </option>
      </select>
              </div>
              <button type="submit" class="btn btn-primary mt-3" style="background-color: #141727; color: white; border: 2px solid #141727;">
                {{ $t('assign') }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit Modal -->
    <div v-if="showEditModal" class="modal fade show d-block" tabindex="-1" role="dialog" style="background: rgba(0,0,0,0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('edit_vehicle') }}</h5>
            <button type="button" class="close" @click="showEditModal = false" aria-label="Close" style="background-color: #141727; color: white; border: 2px solid #141727; border-radius: 50%;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateVehicle">

              <div class="form-group">
                <label for="candidate_id" class="form-control-label">
      {{ $t('Candidate') }}
    </label>
    <div class="select-wrapper">
      <select
        v-model="currentVehicle.candidate_id"
        id="candidate_id"
        class="form-control select-with-arrow"
        aria-label="Candidate"
      >
        <option value="" disabled selected>
          {{ $t('select_candidate') }}
        </option>
        <option
          v-for="candidate in candidates"
          :key="candidate.id"
          :value="candidate.id"
        >
          {{ candidate.f_name }} {{ candidate.l_name }}
        </option>
      </select>
    </div>
              </div>
              <div class="form-group">
                <label for="editVehicleName">{{ $t('vehicle_name') }}</label>
                <input v-model="currentVehicle.vehicle_name" type="text" class="form-control" id="editVehicleName" required />
              </div>
              <div class="form-group">
  <label for="editVehicleType">{{ $t('vehicle_type') }}</label>
  <div class="select-wrapper">
    <select
      v-model="currentVehicle.vehicle_type"
      class="form-control select-with-arrow"
      id="editVehicleType"
      required
    >
      <option value="">{{ $t('select_type') }}</option>
      <option value="0">{{ $t('passenger') }}</option>
      <option value="1">{{ $t('carrier') }}</option>
    </select>
  </div>
</div>

              <div class="form-group">
                <label for="editSeatingCapacity">{{ $t('seating_capacity') }}</label>
                <input v-model="currentVehicle.seating_capacity" type="number" class="form-control" id="editSeatingCapacity" required />
              </div>
              <div class="form-group">
                <label for="editWeightCapacity">{{ $t('weight_capacity') }}</label>
                <input v-model="currentVehicle.weight_capacity" type="text" class="form-control" id="editWeightCapacity" required />
              </div>
              <div class="form-group">
                <label for="editSpecialInstructions">{{ $t('special_instructions') }}</label>
                <input v-model="currentVehicle.special_instructions" type="text" class="form-control" id="editSpecialInstructions" required />
              </div>
              <div class="form-group">
                <label for="editDriverName">{{ $t('driver_name') }}</label>
                <input v-model="currentVehicle.driver_name" type="text" class="form-control" id="editDriverName" required />
              </div>
              <div class="form-group">
                <label for="editDriverPhone">{{ $t('driver_phone') }}</label>
                <input v-model="currentVehicle.driver_phone_no" type="text" class="form-control" id="editDriverPhone" @input="prependCountryCode" required />
              </div>
              <button type="submit" class="btn btn-primary mt-3" style="background-color: #141727; color: white; border: 2px solid #141727;">{{ $t('save_changes') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css';
import VehicleDataService from "../../services/VehicleDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import Swal from 'sweetalert2';

export default {
  name: "VehicleTable",
  data() {
  return {
    vehicles: [],
    users: [],
    currentVehicle: {
      id: "",
      vehicle_name: "",
      vehicle_type: "",
      seating_capacity: "",
      weight_capacity: "",
      special_instructions: "",
      driver_name: "",
      driver_phone_no: "",
      candidate_id: ""
    },
    showEditModal: false,
    showAssignModal: false,
    currentIndex: null,
    currentPage: 1,
    itemsPerPage: 10,
    assignVehicle: {
      assign: ""  
    }
  };
},

  created() {
    this.getAllVehicles();
    this.getAllUsers();
    this.getCandidates();
  },
  computed: {
    paginatedvehicles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.vehicles.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.vehicles.length / this.itemsPerPage);
    }
  },
  methods: {
    getCandidates() {
      UsersDataService.getAdminCandidate()
        .then(response => {
          this.candidates = response.data;
        })
        .catch(e => {
          console.error(e);
        });
    },
    getAllUsers() {
      UsersDataService.getAll()
        .then(response => {
          this.users = response.data;
          console.log(this.users);
        })
        .catch(e => {
          console.log(e);
        });
    },
     openAssignModal(vehicle, index) {
      this.currentVehicle = { ...vehicle };
      this.currentIndex = index;
      this.showAssignModal = true;
    },
    getAllVehicles() {
      console.log(VehicleDataService.getAll());
      VehicleDataService.getAll()
        .then(response => {
          this.vehicles = response.data.vehicles;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getVehicleType(type) {
      if (type === '0') {
        return 'Passenger';
      } else if (type === '1') {
        return 'Carrier';
      } else {
        return '';
      }
    },
    navigateToAddVehicle() {
      this.$router.push('/add-vehicle');
    },
    openEditModal(vehicle, index) {
      this.currentVehicle = { ...vehicle };
      this.currentIndex = index;
      this.showEditModal = true;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    assignUser() {
  // Here you would handle the assignment logic, e.g., updating the vehicle or sending a request.
  // Example:
  const assignmentData = {
    id: this.currentVehicle.id,
    userId: this.assignVehicle.assign
  };

  // Example service call
  VehicleDataService.assignUserToVehicle(assignmentData)
  .then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'User assigned successfully!',
        confirmButtonText: 'OK'
      }).then(() => {
        this.showAssignModal = false;
        this.assignVehicle.assign = ""; // Clear the selection after assignment
      });
    })
    .catch(e => {
      if (e.response && e.response.data && e.response.data.msg === "Vehicle is already occupied!") {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Vehicle is already assigned to another user!',
        confirmButtonText: 'OK'
      });
    } else {
      console.error(e);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to assign user to vehicle. Please try again.',
        confirmButtonText: 'OK'
      });
    }
    });
}
,
    updateVehicle() {
      const updateData = {
        id: this.currentVehicle.id,
        vehicle_name: this.currentVehicle.vehicle_name,
        vehicle_type: this.currentVehicle.vehicle_type,
        seating_capacity: this.currentVehicle.seating_capacity,
        weight_capacity: this.currentVehicle.weight_capacity,
        candidate_id: this.currentVehicle.candidate_id,
        special_instructions: this.currentVehicle.special_instructions,
        driver_name: this.currentVehicle.driver_name,
        driver_phone_no: this.currentVehicle.driver_phone_no.startsWith('+91')
          ? this.currentVehicle.driver_phone_no
          : '+91' + this.currentVehicle.driver_phone_no,
      };

      VehicleDataService.update(updateData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Vehicle updated successfully!',
            confirmButtonText: 'OK'
          }).then(() => {
          window.location.reload();
        }).then(() => {
            //this.$router.push('/vehicle'); 
            this.showEditModal = false;
            this.$set(this.vehicles, this.currentIndex, { ...this.currentVehicle });
          });

        })
        .catch(e => {
          console.log(e);
        });
    },
    confirmDelete(index, id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteVehicle(index, id);
        Swal.fire(
          'Deleted!',
          'The Vehivle has been deleted.',
          'success'
        ).then(() => {
          window.location.reload();
        });
      }
    });
  },
    deleteVehicle(index,id) {
      VehicleDataService.delete(id)
        .then(response => {
          console.log(response);
          this.vehicle.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
        });
    },
  }
};
</script>

<style scoped>
.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}
.modal.fade.show {
  display: block;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;color:white;border:2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
