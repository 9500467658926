<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <div class="select-wrapper">
          <select
            class="form-control select-with-arrow"
            v-model="selectedPartno"
            @change="filterVotersByPartno"
          >
            <option value="">Select यादी भाग क्र</option>
            <!-- <option value="all">All</option> -->
            <!-- Add the "All" option -->

            <option
              v-for="part in partnoList"
              :key="part.partno"
              :value="part.partno"
            >
              {{ part.partno }} - {{ part.area_name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Loader -->
    <div v-if="loading" class="text-center">
      <span>Loading...</span>
    </div>

    <!-- Content -->
    <div
      v-else
      class="card mb-4 custom-card"
      style="margin-top: 20px !important"
    >
      <div
        class="card-header pb-0 d-flex justify-content-between align-items-center"
      >
        <h6 class="custom-card-header">{{ $t("Voters Alphabetically") }}</h6>
      </div>

      <div class="card-body px-0 pt-0 pb-2 custom-card-body">
        <div class="accordion" id="alphabetAccordion">
          <div
            v-for="(group, index) in filteredVotersList"
            :key="index"
            class="accordion-item custom-accordion-item"
          >
            <h2 class="accordion-header">
              <button
                class="accordion-button custom-accordion-button"
                type="button"
                @click="toggleAccordion(index)"
              >
                {{ group.firstLetter }} ({{ group.voterCount }} voters)
              </button>
            </h2>
            <div
              v-if="activeAccordion === index"
              class="accordion-collapse show"
            >
              <div class="accordion-body custom-accordion-body">
                <ul class="list-group custom-list-group">
                  <li
                    v-for="(voter, voterIndex) in group.voters"
                    :key="voter.id"
                    class="list-group-item custom-list-item d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <p class="mb-0 voter-name">
                        <strong>{{ voter.name }}</strong
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>अनुक्रमांक : {{ voter.slnoinpart }}</strong>
                      </p>
                      <p class="mb-0 voter-details">
                        <small
                          >ID: {{ voter.idcardno }} | Part No.:
                          {{ voter.partno }} | Age: {{ voter.age }} | Gender:
                          {{ voter.gender }}</small
                        >
                      </p>
                      <p class="mb-0 voter-location" style="font-weight: bold">
                        <small
                          >{{ voter.section }} | Voting Center:
                          {{ voter.vcenter }}</small
                        >
                      </p>
                    </div>

                    <div class="action-buttons">
                      <a
                        href="javascript:;"
                        @click="navigateToEditVoter(voter.id)"
                        class="text-secondary font-weight-bold text-xs"
                      >
                        <!-- Edit icon -->
                      </a>
                      <a
                        href="javascript:;"
                        class="text-danger font-weight-bold text-xs ms-3"
                        @click="confirmDeleteVoter(voterIndex, voter.id)"
                      >
                        <!-- Delete icon -->
                      </a>

                      <button
                        v-if="voter.voterInfo"
                        @click="handleVoterInfo(voter)"
                        style="
                          background-color: #141727;
                          color: white;
                          border: 2px solid #141727;
                          border-radius: 5px;
                        "
                      >
                        View Info
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showFamilyDetailsPopup"
      class="modal fade show"
      style="display: block"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Voter Details</h5>
            <button type="button" class="close" @click="closeModal">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <p>
              <strong>Align with Party:</strong>
              {{ voterDetails.align_with_party }}
            </p>
            <p>
              <strong>Align with Candidate:</strong>
              {{ voterDetails.align_with_candidate }}
            </p>
            <p><strong>Phone No:</strong> {{ voterDetails.phone_no }}</p>

            <h6>Family Members:</h6>
            <ul>
              <li v-for="(member, index) in familyMembers" :key="member.id">
                {{ member.voterName.name }}
                <span v-if="index < familyMembers.length - 1"></span>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VotersSearchDataService from "../../services/VotersSearchDataService.js";

export default {
  name: "Alphabetically",
  data() {
    return {
      voterslist: [],
      filteredVotersList: [],
      partnoList: [],
      selectedPartno: "all", // Initialize with "all"
      activeAccordion: null,
      loading: true,
      showFamilyDetailsPopup: false,
      voterDetails: {},
      familyMembers: [], // Store family members' data
    };
  },
  created() {
    // this.getAllVoterAlphabetically();
    this.getAllPartno();
  },
  methods: {
    closeModal() {
      // Close the popup by setting showFamilyDetailsPopup to false
      this.showFamilyDetailsPopup = false;
    },
    handleVoterInfo(voter) {
      const { voter_list_id, family_id } = voter.voterInfo;

      // Call the API to fetch family details
      VotersSearchDataService.familyDetailsIdWise({
        voter_list_id: voter_list_id,
        family_id: family_id,
      })
        .then((response) => {
          console.log("API Response:", response.data);

          // Log the voter_list_id for the clicked voter
          console.log("Clicked voter's voter_list_id:", voter_list_id);

          // Filter the voterslist to match the specific voter_list_id
          const filteredVoters = response.data.voterslist.filter((v) => {
            console.log(`Comparing ${v.voter_list_id} with ${voter_list_id}`);
            return v.voter_list_id === voter_list_id; // Ensure this is an exact match
          });

          // If we have a match, populate voterDetails
          if (filteredVoters.length > 0) {
            console.log("Matched Voter Data:", filteredVoters);
            const selectedVoter = filteredVoters[0]; // Get the first matched voter
            this.voterDetails = {
              align_with_party:
                selectedVoter.align_with_party || "Not Available",
              align_with_candidate:
                selectedVoter.align_with_candidate || "Not Available",
              phone_no: selectedVoter.phone_no || "Not Available",
            };
            console.log("Matched voter details:", this.voterDetails);
          } else {
            // If no matching voter is found, provide default values
            this.voterDetails = {
              align_with_party: "Not Available",
              align_with_candidate: "Not Available",
              phone_no: "Not Available",
            };
            console.log(
              "No matching voter found for voter_list_id:",
              voter_list_id
            );
          }

          // Assign family members from the response
          this.familyMembers = response.data.voterslist || [];

          // Open the popup
          this.showFamilyDetailsPopup = true;
        })
        .catch((error) => {
          console.error("Error fetching family details:", error);
        });
    },
    getAllPartno() {
      const defaultacno = localStorage.getItem("defaultacno");
      if (!defaultacno) {
        console.error("No acno found in localStorage");
        return;
      }

      VotersSearchDataService.getAllPartno(defaultacno)
        .then((response) => {
          this.partnoList = response.data.partnolist;

          // Check if partnoList is defined and is an array
          if (Array.isArray(this.partnoList)) {
            console.log("Original partnoList:", this.partnoList); // Debugging line

            // Sort partnoList in ascending order numerically
            this.partnoList.sort((a, b) => {
              return a.partno - b.partno; // Sort numerically
            });

            console.log("Sorted partnoList:", this.partnoList); // Debugging line

            // Set selectedPartno to the first partno by default
            if (this.partnoList.length > 0) {
              this.selectedPartno = this.partnoList[0].partno;
              this.filterVotersByPartno(); // Filter based on the first partno
            }
          } else {
            console.error("partnoList is not an array:", this.partnoList);
          }
        })
        .catch((error) => {
          console.error("Error fetching partno list:", error);
        });
    },

    // getAllVoterAlphabetically() {
    //   const defaultacno = localStorage.getItem("defaultacno"); // Retrieve acno from localStorage
    //   VotersSearchDataService.getAllAlphabetically(defaultacno)
    //     .then((response) => {
    //       this.voterslist = response.data.voterslist;
    //       this.filteredVotersList = this.voterslist; // Initialize with full list
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     })
    //     .finally(() => {
    //       this.loading = false; // Hide loader after fetching data
    //     });
    // },
    filterVotersByPartno() {
      this.loading = true; // Show loader while fetching data

      const defaultacno = localStorage.getItem("defaultacno");
      const acno = typeof defaultacno === "string" ? defaultacno : "";

      console.log("acno from localStorage:", acno);

      // Call the API directly using getAllAlphabetically
      VotersSearchDataService.getAllAlphabetically(
        acno,
        this.selectedPartno === "all" ? null : this.selectedPartno
      )
        .then((response) => {
          this.voterslist = response.data.voterslist;
          this.filteredVotersList = this.voterslist;
          this.activeAccordion = null; // Reset active accordion when filtering
        })
        .catch((error) => {
          console.log("Error during API call:", error);
        })
        .finally(() => {
          this.loading = false; // Hide loader after data loads
        });
    },

    toggleAccordion(index) {
      // Toggle the active accordion item
      this.activeAccordion = this.activeAccordion === index ? null : index;
    },
  },
};
</script>

<style scoped>
/* Loader Styles */
.text-center {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
}

/* Card Styling */
.custom-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-card-header {
  font-weight: bold;
  font-size: 1.25rem;
  color: #2d2d2d;
}

/* Accordion Styling */
.custom-accordion-item {
  margin-bottom: 1rem;
}

.custom-accordion-button {
  background-color: #82d616;
  color: white;
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.custom-accordion-button:hover {
  background-color: #6fb800;
}

/* List Group Styling */
.custom-list-group {
  list-style: none;
  padding: 0;
}

.custom-list-item {
  border-radius: 8px;
  padding: 15px;
  background-color: #ffffff;
  margin: 5px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Popup Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  max-width: 600px;
  width: 100%;
}

.modal-header,
.modal-footer {
  background-color: #f8f9fa;
}

.modal-title {
  font-weight: bold;
}

.close {
  cursor: pointer;
}
</style>
