import http from '../http-common';

class SocialMediaPostDataService {
  getAll() {
    return http.get("/social-media");
  }
  getAllForReports() {
    return http.get("/reports-social");
  }
  get(id) {
    return http.get(`/social-media/edit/${id}`);
  }

  create(data) {
    return http.post("/social-media/add", data);
  }

  update(data) {
    return http.put(`/social-media/update`, data);
  }

  inactive(data) {
    //return http.put(`/social-media/inactive/${id}`);
    return http.put(`/social-media/inactive`, data);
  }
}

export default new SocialMediaPostDataService();
