import http from '../http-common-user';

const defaultacno = localStorage.getItem('defaultacno');
class VotersSearchDataService {
  getAllVoters() {
    return http.get("/voters-list");
  }
  getAllVotingCenterLocation(acno) {
    return http.get(`/voting-location-list?acno=${acno}`);
  }
  getVotingLocation(id) {
    // Retrieve 'acno' from localStorage
    const acno = localStorage.getItem('defaultacno');
    
    // Send both 'id' and 'acno' as query parameters in the request
    return http.get(`/voting-location-edit`, {
      params: { id, acno }
    });
  }
  getAllAlphabetically(acno, partno, fromAge, toAge, gender) {
    // Construct the base URL with acno and partno as query parameters
    let url = `/voters-list-atoz?acno=${acno}`;
    
    // Add partno to the URL if provided
    if (partno) {
        url += `&partno=${partno}`;
    }
    
    // Add fromAge, toAge, and gender to the URL
    if (fromAge !== undefined) {
        url += `&fromAge=${fromAge}`;
    }
    
    if (toAge !== undefined) {
        url += `&toAge=${toAge}`;
    }
    
    if (gender) {
        url += `&gender=${gender}`;
    }

    // Make the GET request with the constructed URL
    return http.get(url);
}


  VotingLocationUpdate(data) {
    return http.put(`/voting-location-update`, data);
  }
  getVotersFamilyInfo(partno, enfname, enlname, id) {
    return http.get("/voter-info-family-details", {
      params: {
        partno: partno,
        enfname: enfname,
        enlname: enlname,
        id: id
      }
    });
  }
  
  searchAcnoWithoutVotersFamily(acno,prabhag,partno, enfname) {
    return http.get("/search-acno-without-voters-family", {
      params: {
        acno: acno || defaultacno, 
        prabhag: prabhag || '',
        partno: partno || ''  ,
        enfname: enfname,
      }
    });
  }

  
  
  getAllPrabhag() {
    return http.get("/prabhag-list");
  }


  getAllPartno(acno) {
    // Construct the URL with the acno query parameter
    return http.get(`/partno-list?acno=${acno}`);
  }
  

  getAllvcenterList(acno) {
    return http.get(`/vcenter-list?acno=${acno}`);
  }

  searchVoters() {
    return http.get(`/search-voters`);
  }

  vcenterList() {
    return http.get(`/vcenter-list`);
  }

  sectionList(acno) {
    return http.get(`/section-list?acno=${acno}`);
  }

  // searchAcnoVoters(enfname, prabhag, partno) {
  //   return http.get(`/search-acno-voters?acno=${acno}&enfname=${enfname}&prabhag=${prabhag}&partno=${partno}`);
  // }

  areaWiseList(acno) {
    return http.get(`/area-wise-list?acno=${acno}`);
}

familyDetailsIdWise(payload) {
  return http.get(`/voters-family-details-familyidwise`, {
    params: {
      voter_list_id: payload.voter_list_id,
      family_id: payload.family_id,
    },
  });
}



  searchAcnoVoters(acno,enfname, prabhag, partno,section) {
    
    const params = new URLSearchParams({
      acno: acno || defaultacno,               // Assuming 'acno' is static and always included
      enfname: enfname || '',  // Default to an empty string if enfname is empty
      prabhag: prabhag || '',  // Default to an empty string if prabhag is empty
      partno: partno || ''  ,   // Default to an empty string if partno is empty
      section: section || ''     // Default to an empty string if partno is empty
    });
  
    // Send the GET request to the server with the query string
    return http.get(`/search-acno-voters?${params.toString()}`);
  }

  searchVotersListForSectionWise(acno, enfname, prabhag, center_id, location_name, partno) { 
    const params = new URLSearchParams({
      acno: acno || defaultacno,               // Default acno
      enfname: enfname || '',                  // Default to an empty string if enfname is empty
      prabhag: prabhag || '',                  // Default to an empty string if prabhag is empty
      vcenter_id: center_id || '',             // Ensure center_id is passed correctly
      section: location_name || '',            // Pass location_name as section
      partno: partno || ''                     // Pass partno
    });
  
    // Send the GET request to the server with the query string
    return http.get(`/search-section-voters?${params.toString()}`);
  }

  
 

  searchVcenterVoters(acno ,enfname, vcenter) {
    
    const params = new URLSearchParams({
      acno: acno || defaultacno,               // Assuming 'acno' is static and always included
      enfname: enfname || '',              // Assuming 'acno' is static and always included
      vcenter: vcenter || '', 
    });
  
    // Send the GET request to the server with the query string
    return http.get(`/search-vcenter-voters?${params.toString()}`);
  }
  
  
  update(data) {
    return http.put(`/voters-group/update`, data);
  }
  create(data) {
    return http.post("/voters-group/add", data);
  }

  delete(id) {
    return http.put(`/voters-group/delete/${id}`);
  }

  voterInfoAdd(data) {
    return http.post("/voter-info-add", data);
  }
}

export default new VotersSearchDataService();
