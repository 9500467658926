import http from '../http-common';

class RequestVehiclesDataService {
  getAll() {
    return http.get("/request-vehicle");
  }

  get(id) {
    return http.get(`/request-vehicle/${id}`);
  }

  create(data) {
    return http.post("/request-vehicle/add", data);
  }

  update(id, data) {
    return http.put(`/request-vehicle/${id}`, data);
  }

  delete(id) {
    return http.delete(`/request-vehicle/${id}`);
  }
}

export default new RequestVehiclesDataService();
