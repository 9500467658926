<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 sidenav"
    :class="{ 'disabled-sidebar': step == '1' || step == '2' || step == '3' }"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <!-- Common Items -->
      <li class="nav-item">
        <sidenav-collapse
          :navText="$t('Dashboard')"
          :to="{ name: 'Dashboard' }"
        >
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li>

      <!-- Items for userType 0 -->
      <li class="nav-item" v-if="userType == '0'">
        <sidenav-collapse
          navText="Admin Register"
          :to="{ name: 'User Register' }"
        >
          <template #icon>
            <CustomerSupport />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="userType == '0'">
        <sidenav-collapse navText="Users" :to="{ name: 'Users' }">
          <template #icon>
            <Users />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="userType == '0'">
        <sidenav-collapse navText="Roles" :to="{ name: 'Roles' }">
          <template #icon>
            <Role />
          </template>
        </sidenav-collapse>
      </li>

      <!-- Items for userType 1 -->
      <li class="nav-item">
        <div @click="toggleSubMenu" class="master-menu">
          <sidenav-collapse :navText="$t('master')" :to="{ name: 'Master' }">
            <template #icon>
              <Master />
            </template>
          </sidenav-collapse>
        </div>
        <ul v-if="isSubMenuVisible" class="submenu">
          <li class="nav-item">
            <sidenav-collapse :navText="$t('Offices')" :to="{ name: 'Office' }">
              <template #icon>
                <Office />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item">
            <sidenav-collapse :navText="$t('Roles')" :to="{ name: 'Roles' }">
              <template #icon>
                <Role />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item" v-if="userType == '1'">
            <sidenav-collapse
              :navText="$t('Religion')"
              :to="{ name: 'Religions' }"
            >
              <template #icon>
                <Religion />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item" v-if="userType == '1'">
            <sidenav-collapse
              :navText="$t('Religion Category')"
              :to="{ name: 'ReligionCategory' }"
            >
              <template #icon>
                <Category />
              </template>
            </sidenav-collapse>
          </li>
          <!-- <li class="nav-item">
            <sidenav-collapse :navText="$t('Area')" :to="{ name: 'Area' }">
              <template #icon>
                <Area />
              </template>
            </sidenav-collapse>
          </li> -->
          <li class="nav-item">
            <sidenav-collapse :navText="$t('Voting Center Location')" :to="{ name: 'Voting Center Location' }">
              <template #icon>
                <Area />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item">
            <sidenav-collapse
              :navText="$t('User management')"
              :to="{ name: 'Candidates' }"
            >
              <template #icon>
                <Users />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item">
            <sidenav-collapse
              :navText="$t('User Registration')"
              :to="{ name: 'Sign Up' }"
            >
              <template #icon>
                <CustomerSupport />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item" v-if="userType == '1'">
            <sidenav-collapse
              :navText="$t('Voter Group')"
              :to="{ name: 'Voters' }"
            >
              <template #icon>
                <Group />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item">
            <sidenav-collapse :navText="$t('Team')" :to="{ name: 'Team' }">
              <template #icon>
                <Team />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item" v-if="userType == '1'">
            <sidenav-collapse
              :navText="$t('Material Type')"
              :to="{ name: 'MaterialType' }"
            >
              <template #icon>
                <CreditCard />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item" v-if="userType == '1'">
            <sidenav-collapse
              :navText="$t('Material')"
              :to="{ name: 'Material' }"
            >
              <template #icon>
                <CreditCard />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item" v-if="userType == '1'">
            <sidenav-collapse
              :navText="$t('Vehicle')"
              :to="{ name: 'Vehicle' }"
            >
              <template #icon>
                <Spaceship />
              </template>
            </sidenav-collapse>
          </li>
        </ul>
      </li>

      <!-- Survey Menu -->
      <li class="nav-item">
        <sidenav-collapse
          :navText="$t('Voter Search')"
          :to="{ name: 'Voter Search' }"
        >
          <template #icon>
            <Group />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          :navText="$t('Alphabetically')"
          :to="{ name: 'Alphabetically' }"
        >
          <template #icon>
            <Group />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          :navText="$t('Report By Age')"
          :to="{ name: 'Report By Age' }"
        >
          <template #icon>
            <Group />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          :navText="$t('Report By Alignment')"
          :to="{ name: 'Report By Alignment' }"
        >
          <template #icon>
            <Group />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('Events')" :to="{ name: 'Events' }">
          <template #icon>
            <Shop />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          :navText="$t('Social Media Post')"
          :to="{ name: 'Social Media Post' }"
        >
          <template #icon>
            <Group />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('Task')" :to="{ name: 'Task' }">
          <template #icon>
            <Task />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="userType == '1'">
        <!-- Survey Menu with Down Arrow -->
        <div @click="toggleSurveySubMenu" class="survey-menu">
          <sidenav-collapse :navText="$t('Survey')" :to="{ name: 'Survey' }">
            <template #icon>
              <Survey />
            </template>
          </sidenav-collapse>
          <span
            :class="{
              'arrow-down': isSurveySubMenuVisible,
              'arrow-right': !isSurveySubMenuVisible,
            }"
            class="arrow-icon"
          ></span>
        </div>

        <!-- Submenus for Survey -->
        <ul v-if="isSurveySubMenuVisible" class="submenu">
          <!-- Survey List Submenu -->
          <li class="nav-item" v-if="userType == '1'">
            <sidenav-collapse
              :navText="$t('survey_list')"
              :to="{ name: 'Survey' }"
            >
              <template #icon>
                <Survey />
              </template>
            </sidenav-collapse>
          </li>

          <!-- Questions Submenu -->
          <li class="nav-item" v-if="userType == '1'">
            <sidenav-collapse
              :navText="$t('Questions')"
              :to="{ name: 'Questions' }"
            >
              <template #icon>
                <Question />
              </template>
            </sidenav-collapse>
          </li>
        </ul>
      </li>

      <li class="nav-item" v-if="userType == '1'">
        <sidenav-collapse :navText="$t('Stock Inward')" :to="{ name: 'Stock' }">
          <template #icon>
            <Stock />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="userType == '1'">
        <sidenav-collapse
          :navText="$t('Request Vehicle')"
          :to="{ name: 'Request-Vehicle' }"
        >
          <template #icon>
            <Spaceship />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <!-- Survey Menu with Down Arrow -->
        <div @click="toggleReportsSubMenu" class="survey-menu">
          <sidenav-collapse :navText="$t('Reports')" :to="{ name: 'Top Voting Center' }">
            <template #icon>
              <Reports />
            </template>
          </sidenav-collapse>
          <span
            :class="{
              'arrow-down': isReportsSubMenuVisible,
              'arrow-right': !isReportsSubMenuVisible,
            }"
            class="arrow-icon"
          ></span>
        </div>

        <!-- Submenus for Survey -->
        <ul v-if="isReportsSubMenuVisible" class="submenu">
          <!-- Survey List Submenu -->
          <li class="nav-item">
            <sidenav-collapse
              :navText="$t('Top Voting Center')"
              :to="{ name: 'Top Voting Center' }"
            >
              <template #icon>
                <Top />
              </template>
            </sidenav-collapse>
          </li>

          <!-- Questions Submenu -->
          <li class="nav-item">
            <sidenav-collapse
              :navText="$t('Least Voting Center')"
              :to="{ name: 'Least Voting Center' }"
            >
              <template #icon>
                <Least />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item">
            <sidenav-collapse
              :navText="$t('Events Report')"
              :to="{ name: 'Events Report' }"
            >
              <template #icon>
                <Shop />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item">
            <sidenav-collapse
              :navText="$t('Task Report')"
              :to="{ name: 'Task Report' }"
            >
              <template #icon>
                <Task />
              </template>
            </sidenav-collapse>
          </li>
          <li class="nav-item">
            <sidenav-collapse
              :navText="$t('Social media post')"
              :to="{ name: 'Social media post' }"
            >
              <template #icon>
                <Group />
              </template>
            </sidenav-collapse>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import Users from "../../components/Icon/Users.vue";
import Group from "../../components/Icon/Group.vue";
import Master from "../../components/Icon/Master.vue";
import Question from "../../components/Icon/Question.vue";
import Survey from "../../components/Icon/Survey.vue";
import Task from "../../components/Icon/Task.vue";
import Role from "../../components/Icon/Role.vue";
import Religion from "../../components/Icon/Religion.vue";
import Category from "../../components/Icon/Category.vue";
import Team from "../../components/Icon/Team.vue";
import Area from "../../components/Icon/Area.vue";
import Stock from "../../components/Icon/Stock.vue";
import Reports from "../../components/Icon/Reports.vue";
import Top from "../../components/Icon/Top.vue";
import Least from "../../components/Icon/Least.vue";

export default {
  name: "SidenavList",
  data() {
    return {
      isSubMenuVisible: false,
      isSurveySubMenuVisible: false,
      isReportsSubMenuVisible: false,
      // userType: localStorage.getItem('type'),
      userType: localStorage.getItem("installation_type"),
      step: localStorage.getItem("steps"),
    };
  },
  props: {
    cardBg: String,
  },
  components: {
    SidenavCollapse,
    Shop,
    Office,
    CustomerSupport,
    Users,
    Task,
    Team,
    Role,
    Stock,
    Area,
    Group,
    Master,
    Question,
    Survey,
    Spaceship,
    CreditCard,
    Category,
    Religion,
    Reports,
    Top,
    Least,
  },
  methods: {
    toggleSubMenu() {
      this.isSubMenuVisible = !this.isSubMenuVisible;
    },
    toggleSurveySubMenu() {
      this.isSurveySubMenuVisible = !this.isSurveySubMenuVisible;
    },
    toggleReportsSubMenu() {
      this.isReportsSubMenuVisible = !this.isReportsSubMenuVisible;
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>

<style scoped>
/* Add custom styles here to ensure proper scrolling and layout */
.sidenav {
  background-color: white;
  border-radius: 10px;
  overflow-y: auto;
  /* Hide scrollbar for Webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Style for Master Menu */
.master-menu {
  cursor: pointer;
  background-color: #ffffff;
  color: black;
  font-weight: bold;
}

/* Style for Submenu Items */
.submenu {
  list-style-type: none;
  padding-left: 20px;
  margin: 0;
}

/* Show submenu when visible */
.submenu {
  display: block;
}

/* Style for Survey Menu */
.survey-menu {
  cursor: pointer;
  background-color: #ffffff;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Style for the arrow icon */
.arrow-icon {
  font-size: 12px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

/* Rotate the arrow down when submenu is visible */
.arrow-down {
  transform: rotate(90deg);
}

/* Rotate the arrow to the right when submenu is hidden */
.arrow-right {
  transform: rotate(0deg);
}
.disabled-sidebar {
  pointer-events: none;
  opacity: 0.5; /* Adjust the opacity to visually indicate that the sidebar is disabled */
}
</style>
