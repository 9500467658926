<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>{{ $t("social_media_post") }}</h6>
      <button type="button" class="btn btn-primary" @click="navigateToAddPost" style="background-color: #141727; color:white; border:2px solid #141727">{{ $t("add_post") }}</button>
    </div>
    <div class="container">
      <div class="card-body px-0 pt-0 pb-2 table-responsive">
        <table class="table mt-4">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("area") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("team") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("voter_group") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("platform") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("start_date") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("end_date") }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t("status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(post, index) in paginatedPosts" :key="index">
              <td><p class="text-xs font-weight-bold mb-0">{{ post.areas.map(area => area.name).join(', ') ?? '' }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ post.teams.map(team => team.name).join(', ') ?? '' }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ post.voterGroups.map(group => group.voter_group_name).join(', ') ?? '' }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ post.platform }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ post.start_date }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ post.end_date }}</p></td>
              <td>
                <label class="switch">
                  <input type="checkbox" :checked="post.status === 1" @change="toggleStatus(post, index)">
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">{{ $t("previous") }}</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t("next") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialMediaPostDataService from "../../services/SocialMediaPostDataService.js";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      newPost: {
        id: '',
        area: '',
        team: '',
        votersGroup: '',
        platform: '',
        startDate: '',
        endDate: '',
        status: false,
      },
      posts: [],
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    paginatedPosts() {
      if (!this.posts || !this.posts.length) {
        return [];
      }
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.posts.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.posts.length / this.itemsPerPage);
    }
  },
  created() {
    this.getAllPosts();
  },
  methods: {
    getAllPosts() {
      SocialMediaPostDataService.getAll()
        .then(response => {
          console.log(response.data.socialMediaPosts);
          this.posts = response.data.socialMediaPosts || [];
        })
        .catch(e => {
          console.log(e);
        });
    },
    updatePostStatus(post, index) {
      const payload = {
        id: post.id,
        status: post.status
      };
     
      SocialMediaPostDataService.inactive(payload)
        .then(response => {
          console.log(response);
          if (response) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Post inactivated successfully!',
              confirmButtonText: 'OK'
            }).then(() => {
              window.location.reload();
              // Update the post in the local array
              this.$set(this.posts, index, { ...post, status: payload.status });
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    navigateToAddPost() {
      this.$router.push('/social-media-post-add');
    },
    toggleStatus(post, index) {
      //post.status = post.status === 1 ? 0 : 1;
      this.updatePostStatus(post, index);
      console.log(`Post ${post.areas.map(area => area.name).join(', ')} status: ${post.status === 1 ? 'Active' : 'Inactive'}`);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  }
};
</script>

<style scoped>
/* Switch component styles */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #82d616;
}

input:focus + .slider {
  box-shadow: 0 0 1px #82d616;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f8f9fa;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}
</style>
