<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h6>{{ $t("material") }}</h6>
      <button
        type="button"
        class="btn btn-primary"
        @click="showAddModal = true"
        style="
          background-color: #141727;
          color: white;
          border: 2px solid #141727;
        "
      >
        {{ $t("add_resource") }}
      </button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("campaigning_material") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("resource_name") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("action") }}
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resource, index) in resources" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ resource.resourcestype.name }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ resource.resource_name }}
                </p>
              </td>
              <td class="align-middle">
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  @click="openEditModal(resource, index)"
                  ><i class="fas fa-edit"></i
                ></a>
                <a
                  href="javascript:;"
                  class="text-danger font-weight-bold text-xs ms-3"
                  @click="confirmDelete(index, resource.id)"
                  ><i class="fas fa-trash-alt"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">
            {{ $t("previous") }}
          </button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">
            {{ $t("next") }}
          </button>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <div
      v-if="showAddModal"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Add Material") }}</h5>
            <button
              type="button"
              class="close"
              @click="showAddModal = false"
              aria-label="Close"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
                border-radius: 50%;
              "
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addResource">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group text-center">
                    <label for="profile_photo" class="form-control-label">{{
                      $t("upload_photo")
                    }}</label>
                    <div>
                      <img
                        :src="newResource.photoUrl || resourcePhotoUrl"
                        alt="Profile Picture"
                        class="img-fluid mb-2"
                        style="width: 100px; object-fit: cover; cursor: pointer"
                        @click="triggerProfileFileInput"
                      />
                    </div>
                    <input
                      type="file"
                      id="profile_photo"
                      ref="profileFileInput"
                      class="d-none"
                      aria-label="Profile Photo Upload"
                      @change="onProfilePhotoChange"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="resourcesTypeId">{{
                  $t("campaigning_material")
                }}</label>
                <div class="select-wrapper">
                  <select
                    v-model="newResource.resources_type_id"
                    class="form-control select-with-arrow"
                    id="resourcesTypeId"
                    required
                  >
                    <option value="" selected>
                      Select Campaigning Material
                    </option>
                    <option
                      v-for="resType in resourcestype"
                      :key="resType.id"
                      :value="resType.id"
                    >
                      {{ resType.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="resourceName">{{ $t("resource_name") }}</label>
                <input
                  v-model="newResource.resource_name"
                  type="text"
                  class="form-control"
                  id="resourceName"
                  required
                />
              </div>
              <button
                type="submit"
                class="btn btn-primary mt-3"
                style="
                  background-color: #141727;
                  color: white;
                  border: 2px solid #141727;
                "
              >
                {{ $t("add_resource") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div
      v-if="showEditModal"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("Edit Material") }}</h5>
            <button
              type="button"
              class="close"
              @click="showEditModal = false"
              aria-label="Close"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
                border-radius: 50%;
              "
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateResource">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group text-center">
                    <label
                      for="edit_profile_photo"
                      class="form-control-label"
                      >{{ $t("upload_photo") }}</label
                    >
                    <div>
                      <img
                        :src="currentResource.photoUrl || resourcePhotoUrl"
                        alt="Profile Picture"
                        class="img-fluid mb-2"
                        style="width: 100px; object-fit: cover; cursor: pointer"
                        @click="triggerEditProfileFileInput"
                      />
                    </div>
                    <input
                      type="file"
                      id="edit_profile_photo"
                      ref="editProfileFileInput"
                      class="d-none"
                      aria-label="Profile Photo Upload"
                      @change="onEditProfilePhotoChange"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="editResourcesTypeId">{{
                  $t("campaigning_material")
                }}</label>
                <div class="select-wrapper">
                  <select
                    v-model="currentResource.resources_type_id"
                    class="form-control select-with-arrow"
                    id="editResourcesTypeId"
                    required
                  >
                    <option value="" selected>
                      Select Campaigning Material
                    </option>
                    <option
                      v-for="resType1 in resourcestype"
                      :key="resType1.id"
                      :value="resType1.id"
                    >
                      {{ resType1.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="editResourceName">{{ $t("resource_name") }}</label>
                <input
                  v-model="currentResource.resource_name"
                  type="text"
                  class="form-control"
                  id="editResourceName"
                  required
                />
              </div>
              <button
                type="submit"
                class="btn btn-primary mt-3"
                style="
                  background-color: #141727;
                  color: white;
                  border: 2px solid #141727;
                "
              >
                {{ $t("save_changes") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resourcePhotoUrl from "@/assets/img/resource.png";
import "@fortawesome/fontawesome-free/css/all.css";
import ResourcesDataService from "../../services/ResourcesDataService.js";
import ResourcesTypeDataService from "../../services/ResourcesTypeDataService.js";
import Swal from "sweetalert2";

export default {
  name: "resource-table",
  data() {
    return {
      resources: [],
      resourcestype: [],
      newResource: {
        id: "",
        resources_type_id: "",
        resource_name: "",
        quantity: "",
        photoUrl: "",
      },
      currentResource: {
        id: "",
        resources_type_id: "",
        resource_name: "",
        quantity: "",
        photoUrl: "",
      },
      resourcePhotoUrl,
      showAddModal: false,
      showEditModal: false,
      currentIndex: null,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getAllResources();
    this.getAllResourcesType();
  },
  computed: {
    paginatedresources() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.resources.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.resources.length / this.itemsPerPage);
    },
  },
  methods: {
    getAllResources() {
      ResourcesDataService.getAll()
        .then((response) => {
          this.resources = response.data.resources;
          console.log(this.resources);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllResourcesType() {
      ResourcesTypeDataService.getAll()
        .then((response) => {
          this.resourcestype = response.data.resources;
          console.log(this.resourcestype);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    addResource() {
  // Ensure required fields are filled before proceeding
  if (!this.newResource.resource_name || !this.newResource.resources_type_id) {
    Swal.fire({
      icon: "warning",
      title: "Missing Data",
      text: "Please fill in all required fields.",
      confirmButtonText: "OK",
    });
    return;
  }

  const payload = {
    resource_name: this.newResource.resource_name,
    resources_type_id: this.newResource.resources_type_id,
    image: this.newResource.photoUrl ? this.newResource.photoUrl.split(",")[1] : "",
  };

  ResourcesDataService.create(payload)
    .then((response) => {
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Resource added successfully!",
          confirmButtonText: "OK",
        }).then(() => {
          this.getAllResources();
          this.resetNewResource(); // Clear form fields
          this.showAddModal = false; // Close modal
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });
},

updateResource() {
  if (this.currentIndex !== null) {
    const resourceData = { ...this.currentResource };

    if (resourceData.photoUrl) {
      // Process the photoUrl before updating
      fetch(resourceData.photoUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const payload = {
              id: resourceData.id,
              resource_name: resourceData.resource_name,
              resources_type_id: resourceData.resources_type_id,
              image: reader.result.split(",")[1], // Processed image as base64
            };

            ResourcesDataService.update(payload)
              .then((response) => {
                if (response) {
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Resource updated successfully!",
                    confirmButtonText: "OK",
                  }).then(() => {
                    this.getAllResources();
                    this.showEditModal = false; // Close the modal after success
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          };
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
        });
    } else {
      // Case where no image needs to be uploaded
      const payload = {
        id: resourceData.id,
        resource_name: resourceData.resource_name,
        resources_type_id: resourceData.resources_type_id,
        image: "",
      };

      ResourcesDataService.update(payload)
        .then((response) => {
          if (response) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Resource updated successfully!",
              confirmButtonText: "OK",
            }).then(() => {
              this.getAllResources();
              this.showEditModal = false; // Close the modal after success
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
}
,
    openEditModal(resource, index) {
      this.currentResource = { ...resource };
      this.currentResource.photoUrl = resource.image
        ? "https://storage.googleapis.com/lok-sevak/" + resource.image
        : null;
      this.currentIndex = index;
      this.showEditModal = true;
    },
    resetNewResource() {
      this.newResource = {
        id: "",
        resources_type_id: "",
        resource_name: "",
        quantity: "",
        photoUrl: "",
      };
      this.resourcePhotoUrl = "";
    },
    triggerProfileFileInput() {
      this.$refs.profileFileInput.click();
    },
    triggerEditProfileFileInput() {
      this.$refs.editProfileFileInput.click();
    },
    onProfilePhotoChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newResource.photoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    onEditProfilePhotoChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentResource.photoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    confirmDelete(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteResource(index, id);
          Swal.fire(
            "Deleted!",
            "The Resource has been deleted.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    },
    deleteResource(index, id) {
      ResourcesDataService.delete(id)
        .then((response) => {
          console.log(response);
          this.resource.splice(index, 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.table {
  width: 100%;
  margin-top: 20px;
}

.modal.fade.show {
  display: block;
}

.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
