<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h5>{{ $t("add_vehicle") }}</h5>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addVehicle" class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="candidate_id" class="form-control-label">
                {{ $t("Candidate") }}
              </label>
              <div class="select-wrapper">
                <select
                  v-model="newVehicle.candidate_id"
                  id="candidate_id"
                  class="form-control select-with-arrow"
                  aria-label="Candidate"
                >
                  <option value="" disabled selected>
                    {{ $t("select_candidate") }}
                  </option>
                  <option
                    v-for="candidate in candidates"
                    :key="candidate.id"
                    :value="candidate.id"
                  >
                    {{ candidate.f_name }} {{ candidate.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="vehicleName">{{ $t("vehicle_name") }}</label>
              <input
                v-model="newVehicle.vehicle_name"
                type="text"
                class="form-control"
                id="vehicleName"
                required
              />
              <span v-if="errors.vehicle_name" class="error">{{
                errors.vehicle_name
              }}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="vehicleType">{{ $t("vehicle_type") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newVehicle.vehicle_type"
                  class="form-control select-with-arrow"
                  id="vehicleType"
                  required
                >
                  <option value="" disabled>{{ $t("select_type") }}</option>
                  <option value="0">{{ $t("passenger") }}</option>
                  <option value="1">{{ $t("carrier") }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="seatingCapacity">{{ $t("seating_capacity") }}</label>
              <input
                v-model="newVehicle.seating_capacity"
                type="text"
                class="form-control"
                id="seatingCapacity"
                required
              />
              <span v-if="errors.seating_capacity" class="error">{{
                errors.seating_capacity
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="weightCapacity">{{ $t("weight_capacity") }}</label>
              <input
                v-model="newVehicle.weight_capacity"
                type="text"
                class="form-control"
                id="weightCapacity"
                required
              />
              <span v-if="errors.weight_capacity" class="error">{{
                errors.weight_capacity
              }}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="specialInstructions">{{
                $t("special_instructions")
              }}</label>
              <input
                v-model="newVehicle.special_instructions"
                type="text"
                class="form-control"
                id="specialInstructions"
              />
              <span v-if="errors.special_instructions" class="error">{{
                errors.special_instructions
              }}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="driverName">{{ $t("driver_name") }}</label>
              <input
                v-model="newVehicle.driver_name"
                type="text"
                class="form-control"
                id="driverName"
                required
              />
              <span v-if="errors.driver_name" class="error">{{
                errors.driver_name
              }}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="driverPhoneNo">{{ $t("driver_phone_no") }}</label>
              <input
                v-model="newVehicle.driver_phone_no"
                type="text"
                class="form-control"
                id="driverPhoneNo"
                @input="validatePhoneNumber"
                maxlength="13"
                required
              />
              <span v-if="errors.driver_phone_no" class="error">{{
                errors.driver_phone_no
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button
              type="submit"
              class="btn btn-primary mt-3"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
              " :disabled="isLoading"
        :loading="isLoading"
            >
              {{ $t("add_vehicle_button") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VehicleDataService from "../../services/VehicleDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import Swal from "sweetalert2";

export default {
  name: "AddVehicle",
  data() {
    return {
      candidates: [],
      isLoading: false,
      newVehicle: {
        candidate_id: "",
        vehicle_name: "",
        vehicle_type: "",
        seating_capacity: "",
        weight_capacity: "",
        special_instructions: "",
        driver_name: "",
        driver_phone_no: "",
      },
      errors: {},
    };
  },
  created() {
    this.getCandidates();
  },
  methods: {
    getCandidates() {
      UsersDataService.getAdminCandidate()
        .then((response) => {
          this.candidates = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    validatePhoneNumber() {
      let phone = this.newVehicle.driver_phone_no;

      if (!phone.startsWith("+91")) {
        phone = "+91" + phone.replace(/\D/g, ""); 
      } else {
        phone = "+91" + phone.slice(3).replace(/\D/g, "");
      }

      this.newVehicle.driver_phone_no = phone.slice(0, 13);
      if (this.newVehicle.driver_phone_no.length !== 13) {
        this.errors.driver_phone_no = "Phone number must be 10 Digits.";
      } else {
        this.errors.driver_phone_no = "";
      }
    },

    addVehicle() {
      this.isLoading = true;
      const newVehicle = {
        vehicle_name: this.newVehicle.vehicle_name,
        vehicle_type: this.newVehicle.vehicle_type,
        seating_capacity: this.newVehicle.seating_capacity,
        weight_capacity: this.newVehicle.weight_capacity,
        special_instructions: this.newVehicle.special_instructions,
        driver_name: this.newVehicle.driver_name,
        driver_phone_no: this.newVehicle.driver_phone_no,
        candidate_id: this.newVehicle.candidate_id,
      };

      VehicleDataService.create(newVehicle)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Vehicle added successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/vehicle"); 
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            // Handle validation errors
            this.errors = error.response.data.errors.reduce((acc, err) => {
              acc[err.code] = err.message;
              return acc;
            }, {});
          } else {
            // Handle other errors
            console.error("An unexpected error occurred:", error);
          }
          console.error("Error adding vehicle:", error);
        });
    },
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.card {
  margin-bottom: 20px;
}
.error {
  color: red;
}
</style>
