<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h6>{{ $t("Least Voting Center") }}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                AC No
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Part No
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Voting Center
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Voter Count
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Voters Reached
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                %
              </th>
              <!-- New column -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(center, index) in sortedVotingCenters" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ center.ac_no }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ center.partno }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ center.vcenter }}
                </p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ center.voter_count }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ center.voters_reached }}
                </p>
              </td>
              <td>
                <p
                  class="text-xs font-weight-bold mb-0"
                  :class="{
                    'percentage-red': center.voter_count === 0,
                    'percentage-green': center.voter_count > 0,
                  }"
                >
                  {{
                    center.voter_count > 0
                      ? (
                          (center.voters_reached / center.voter_count) *
                          100
                        ).toFixed(2)
                      : 0
                  }}%
                </p>
              </td>

              <!-- Calculate percentage here -->
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            @click="goToPageAsc(currentPageAsc - 1)"
            :disabled="currentPageAsc === 1"
          >
            Previous
          </button>
          <span>Page {{ currentPageAsc }} of {{ totalPagesAsc }}</span>
          <button
            @click="goToPageAsc(currentPageAsc + 1)"
            :disabled="currentPageAsc === totalPagesAsc"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
// import SoftBadge from "@/components/SoftBadge.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import img1 from "../../assets/img/team-2.jpg";

export default {
  name: "CandidatesTable",
  data() {
    return {
      votingCenters: [],
      candidates: [],
      img1,
      itemsPerPage: 10, // Number of items per page
      currentPageAsc: 1,
      currentPageDesc: 1,
      selectedLanguage: this.$i18n.locale,
    };
  },
  created() {
    this.fetchVotingCenters();
    this.startAutoRefresh();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.candidates.length / this.itemsPerPage);
    },
    paginatedCandidates() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.candidates.slice(start, end);
    },
    sortedVotingCenters() {
      const sortedCenters = [...this.votingCenters].sort(
        (a, b) => (a.voters_reached || 0) - (b.voters_reached || 0)
      ); // Sort by 'voters_reached' ascending
      const startIndex = (this.currentPageAsc - 1) * this.itemsPerPage;
      return sortedCenters.slice(startIndex, startIndex + this.itemsPerPage); // Paginate
    },

    // Descending order sorting with pagination for the second table
    sortedVotingCentersDescending() {
      const sortedCenters = [...this.votingCenters].sort(
        (a, b) => (b.voters_reached || 0) - (a.voters_reached || 0)
      ); // Sort by 'partno' descending
      const startIndex = (this.currentPageDesc - 1) * this.itemsPerPage;
      return sortedCenters.slice(startIndex, startIndex + this.itemsPerPage); // Paginate
    },

    // Calculate total pages for pagination of the ascending order table
    totalPagesAsc() {
      return Math.ceil(this.votingCenters.length / this.itemsPerPage);
    },

    // Calculate total pages for pagination of the descending order table
    totalPagesDesc() {
      return Math.ceil(this.votingCenters.length / this.itemsPerPage);
    },
  },
  components: {
    // SoftAvatar,
    // SoftBadge,
  },
  methods: {
    fetchVotingCenters() {
      const now = Date.now();
      const storedData = JSON.parse(localStorage.getItem("votingCenters"));
      const lastUpdated = localStorage.getItem("lastUpdated");

      // If stored data exists and was updated within the last 5 minutes, use it
      if (storedData && lastUpdated && now - lastUpdated < 5 * 60 * 1000) {
        this.votingCenters = storedData;
        console.log("Using cached data:", this.votingCenters);
      } else {
        // Otherwise, fetch fresh data from the API
        const token = localStorage.getItem("token");
        const tenantId = localStorage.getItem("tenantId");
        const defaultacno = localStorage.getItem("defaultacno");
        axios
          .get(
            `https://rushivarya-stg-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/matdan-kendra-list`,
            {
              headers: {
                "Content-type": "application/json",
                Authorization: token,
              },
              params: {
              acno: defaultacno, // Add acno as a query parameter
              },
            }
          )
          .then((response) => {
            console.log("Fetched fresh data:", response.data); // Debugging line
            this.votingCenters = response.data || [];

            // Store the data and update the timestamp in localStorage
            localStorage.setItem(
              "votingCenters",
              JSON.stringify(this.votingCenters)
            );
            localStorage.setItem("lastUpdated", now);
          })
          .catch((error) => {
            console.error("Error fetching voting center data:", error);
            this.votingCenters = []; // Handle error by setting default value
          });
      }
    },

    // Set an interval to refresh the data every 5 minutes
    startAutoRefresh() {
      setInterval(() => {
        this.fetchVotingCenters();
      }, 5 * 60 * 1000); // 5 minutes
    },
    goToPageAsc(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPagesAsc) {
        this.currentPageAsc = pageNumber;
      }
    },

    goToPageDesc(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPagesDesc) {
        this.currentPageDesc = pageNumber;
      }
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },

    navigateToAddCandidate() {
      this.$router.push("/sign-up");
    },
    navigateToEditCandidate(id) {
      this.$router.push(`/edit-user/${id}`);
    },
    confirmDelete(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCandidate(index, id);
          Swal.fire(
            "Deleted!",
            "The Candidate has been deleted.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    },

    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style scoped>
.langdrop {
  width: 130px;
  margin-left: 87%;
}
@media (min-width: 300px) and (max-width: 550px) {
  .langdrop {
    width: 130px;
    margin-left: 57%;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ffffff;
  color: #000000;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 0.5rem;
}
.percentage-red {
  color: red;
}
.percentage-green {
  color: green;
}
</style>
