<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>Survery Questions</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">प्रश्न</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">पर्याय अ</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">पर्याय ब</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">पर्याय क</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(question, index) in questions" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ question.question }}</h6>
                  </div>
                </div>
              </td>
              <td><p class="text-xs font-weight-bold mb-0">{{ question.option1 }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ question.option2 }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ question.option3 }}</p></td>
            </tr>
          </tbody>
        </table>
        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SurveryMasterDataService from "../../services/SurveryMasterDataService.js";

export default {
  name: "SurveyQuestions",
  data() {
    return {
      questions: [],
      newQuestion: {
        question: "",
        option1: "",
        option2: "",
        option3: "",
      },
      showModal: false,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  created() {
    this.survey_id = this.$route.params.id;
    this.getSurveyDetails(this.survey_id);
  },
  computed: {
    paginatedquestions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.questions.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.questions.length / this.itemsPerPage);
    }
  },
  methods: {
    getSurveyDetails() {
      SurveryMasterDataService.get(this.survey_id)
        .then(response => {
          this.questions = response.data.survey.questions;
          console.log(this.questions);
        })
        .catch(e => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    addQuestion() {
      this.questions.push({ ...this.newQuestion });
      this.newQuestion.question = "";
      this.newQuestion.option1 = "";
      this.newQuestion.option2 = "";
      this.newQuestion.option3 = "";
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.table {
  width: 100%;
  margin-top: 20px;
}
.modal.fade.show {
  display: block;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;color:white;border:2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
