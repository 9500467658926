import http from '../http-common';

class AreaDataService {
  getAll() {
    return http.get("/area");
  }

  get(id) {
    return http.get(`/area/edit/${id}`);
  }

  create(data) {
    return http.post("/area/add", data);
  }

  update(data) {
    return http.put(`/area/update`, data);
  }
  delete(id) {
    return http.put(`/area/delete/${id}`);
  }
}

export default new AreaDataService();
