<template>
  <div class="card mb-4">
    
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>{{ $t('users') }}</h6>
      <button type="button" class="btn btn-primary" @click="navigateToAddCandidate"
        style="background-color: #141727; color: white; border: 2px solid #141727">{{ $t('add_user') }}</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('candidates') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('organization') }}</th>
              <!-- <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Last Known Location</th> -->
              <!-- <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('office') }}</th> -->
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('phone_number') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('email') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('date') }}</th>
              <!-- <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('status') }}</th> -->
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('action') }}</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(candidate, index) in paginatedCandidates" :key="index">
              <td>
  <div class="d-flex px-2 py-1">
    <div>
      <soft-avatar 
        :img="candidate.profile_picture && candidate.profile_picture !== 'def.png' 
          ? `https://storage.googleapis.com/lok-sevak/` + candidate.profile_picture 
          : img1" 
        size="sm" 
        border-radius="lg" 
        class="me-3" 
        :alt="`user${index}`" 
      />
    </div>
    <div class="d-flex flex-column justify-content-center">
      <h6 class="mb-0 text-sm">{{ candidate.f_name }} {{ candidate.l_name }}</h6>
    </div>
  </div>
</td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ candidate.organization_full_name }}</p>
              </td>
              <!-- <td>
                <p class="text-xs font-weight-bold mb-0">{{ candidate.current_location_gps }}</p>
              </td> -->
              <!-- <td>
                <p class="text-xs font-weight-bold mb-0">{{ candidate.user_office ? candidate.user_office.office_name : "" }}</p>
              </td> -->
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ candidate.phone_no }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ candidate.email }}</p>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ formatDate(candidate.created_at) }}</span>
              </td>
              <!-- <td class="align-middle text-center text-sm">
                <soft-badge :color="candidate.status === 'Active' ? 'success' : 'secondary'" variant="gradient" size="sm">{{ candidate.status }}</soft-badge>
              </td> -->
              <td class="align-middle">
                <a href="javascript:;" @click="navigateToEditCandidate(candidate.id)" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit task">
                  <i class="fas fa-edit"></i>
                </a>
                <!-- <a href="javascript:;" class="text-danger font-weight-bold text-xs ms-3"  @click="confirmDelete(index, candidate.id)">
                  <i class="fas fa-trash-alt"></i>
                </a> -->
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
          <span>Page {{ currentPage }} of {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SoftAvatar from "@/components/SoftAvatar.vue";
// import SoftBadge from "@/components/SoftBadge.vue";
import '@fortawesome/fontawesome-free/css/all.css';
import img1 from "../../assets/img/team-2.jpg";
import CandidateDataService from "../../services/CandidateDataService.js";

export default {
  name: "CandidatesTable",
  data() {
    return {
      candidates: [],
      img1,
      currentPage: 1,
      itemsPerPage: 10,
      selectedLanguage: this.$i18n.locale,
    };
  },
  created() {
    this.getAllCandidate();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.candidates.length / this.itemsPerPage);
    },
    paginatedCandidates() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.candidates.slice(start, end);
    },
  },
  components: {
    SoftAvatar,
    // SoftBadge,
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    getAllCandidate() {
      console.log(CandidateDataService.getAll());
      CandidateDataService.getAll()
      
        .then(response => {
          this.candidates = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    navigateToAddCandidate() {
      this.$router.push('/sign-up');
    },
    navigateToEditCandidate(id) {
      this.$router.push(`/edit-candidate/${id}`);
    },
    confirmDelete(index, id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCandidate(index, id);
          Swal.fire(
            'Deleted!',
            'The Candidate has been deleted.',
            'success'
          ).then(() => {
            window.location.reload();
          });
        }
      });
    },
    deleteCandidate(index, id) {
      CandidateDataService.delete(id)
        .then(response => {
          console.log(response);
          this.candidates.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
        });
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style scoped>
.langdrop{
   width:130px;margin-left:87%;
}
@media (min-width:300px) and (max-width:550px){
  .langdrop{
   width:130px;margin-left:57%;
}
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ffffff;
  color: #000000;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 0.5rem;
}
</style>
