<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <Voterslist />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <projects-table />
      </div>
    </div> -->
  </div>
</template>

<script>
import Voterslist from "./components/Voterslist";
// import ProjectsTable from "./components/ProjectsTable";

export default {
  name: "Users",
  components: {
    Voterslist,
    // ProjectsTable,
  },
};
</script>
