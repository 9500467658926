<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h5>{{ $t("add_event") }}</h5>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addTask" class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="taskName">{{ $t("event_name") }}</label>
              <input
                v-model="newEvent.name"
                type="text"
                class="form-control"
                id="taskName"
                required
              />
              <span v-if="errors.event_name" class="error">{{
                errors.event_name
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="location">{{ $t("area") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.location"
                  id="location"
                  class="form-control select-with-arrow"
                  aria-label="location"
                  required
                >
                  <option value="" selected>{{ $t("select_area") }}</option>
                  <option
                    v-for="area in locations"
                    :key="area.id"
                    :value="area.id"
                  >
                    {{ area.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="startDateTime">{{ $t("start_date_time") }}</label>
              <input
                v-model="newEvent.startDateTime"
                type="datetime-local"
                class="form-control"
                id="startDateTime"
                required
              />
              <span v-if="errors.event_start" class="error">{{
                errors.event_start
              }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="endDateTime">{{ $t("end_date_time") }}</label>
              <input
                v-model="newEvent.endDateTime"
                type="datetime-local"
                class="form-control"
                id="endDateTime"
                required
              />
              <span v-if="errors.event_end" class="error">{{
                errors.event_end
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="vehicles">{{ $t("vehicles") }}</label>
              <multiselect
                v-model="newEvent.vehicles"
                :options="availableVehicles"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="vehicle_name"
                track-by="vehicle_name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="resources">{{ $t("resources") }}</label>
              <multiselect
                v-model="newEvent.resources"
                :options="availableResources"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="resource_name"
                track-by="resource_name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-6" v-if="userType == '1'">
            <div class="form-group">
              <label for="candidate_id" class="form-control-label">
                {{ $t("Candidate") }}
              </label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.candidate_id"
                  id="candidate_id"
                  class="form-control select-with-arrow"
                  aria-label="Candidate"
                >
                  <option value="" disabled selected>
                    {{ $t("select_candidate") }}
                  </option>
                  <option
                    v-for="candidate in candidates"
                    :key="candidate.id"
                    :value="candidate.id"
                  >
                    {{ candidate.f_name }} {{ candidate.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="team">{{ $t("team") }}</label>
              <multiselect
                v-model="newEvent.teams"
                :options="availableTeams"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="name"
                track-by="name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="votersGroup">{{ $t("voters_group") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.voter_group_id"
                  id="votersGroup"
                  class="form-control select-with-arrow"
                  aria-label="votersGroup"
                >
                  <option value="" selected>
                    {{ $t("select_voters_group") }}
                  </option>
                  <option
                    v-for="voterG in votersGroup"
                    :key="voterG.id"
                    :value="voterG.id"
                  >
                    {{ voterG.voter_group_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="first_contact">{{
                $t("select_first_contact")
              }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.first_contact"
                  id="first_contact"
                  class="form-control select-with-arrow"
                  aria-label="first_contact"
                  required
                >
                  <option value="" selected>
                    {{ $t("select_first_contact") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="second_contact">{{
                $t("select_second_contact")
              }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.second_contact"
                  id="second_contact"
                  class="form-control select-with-arrow"
                  aria-label="second_contact"
                >
                  <option value="" selected>
                    {{ $t("select_second_contact") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="third_contact">{{
                $t("select_third_contact")
              }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.third_contact"
                  id="third_contact"
                  class="form-control select-with-arrow"
                  aria-label="third_contact"
                >
                  <option value="" selected>
                    {{ $t("select_third_contact") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="votersGroupLocation">{{
                $t("location_point")
              }}</label>
              <google-map
                v-model="newEvent.location"
                @point-format="updatePointFormat"
                @address-changed="updateAddress"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button
              type="submit"
              class="btn btn-primary mt-3"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
              "
              :disabled="isLoading"
              :loading="isLoading"
            >
              {{ $t("add_event") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import GoogleMap from "./GoogleMap.vue";
import Multiselect from "vue-multiselect";
import VehicleDataService from "../../services/VehicleDataService.js";
import ResourcesDataService from "../../services/ResourcesDataService.js";
import AreaDataService from "../../services/AreaDataService.js";
import EventDataService from "../../services/EventDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import TeamDataService from "../../services/TeamDataService.js";
import Swal from "sweetalert2";
import { handleApiErrors } from "@/utils/errorHandler";

export default {
  name: "EditEvent",
  components: {
    Multiselect,
    GoogleMap,
  },
  data() {
    return {
      userType: localStorage.getItem("installation_type"),
      locations: [],
      users: [],
      votersGroup: [],
      availableVehicles: [],
      availableTeams: [],
      availableResources: [],
      events: [],
      candidates: [],
      isLoading: false,
      newEvent: {
        name: "",
        event: "",
        candidate_id: "",
        voter_group_id: "",
        first_contact: "",
        second_contact: "",
        third_contact: "",
        votersGroup: "",
        startDateTime: "",
        location: "",
        endDateTime: "",
        teams: [],
        vehicles: [],
        resources: [],
      },
      errors: {},
    };
  },
  created() {
    this.getAllVehicles();
    this.getAllResources();
    this.getAllArea();
    this.getAllUser();
    this.getAllVotersGroup();
    this.getAllTeams();
    this.getCandidates();
  },
  methods: {
    getCandidates() {
      UsersDataService.getAdminCandidate()
        .then((response) => {
          this.candidates = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getAllUser() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllVotersGroup() {
      VoterGroupsDataService.getAll()
        .then((response) => {
          this.votersGroup = response.data.voter_group;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllVehicles() {
      VehicleDataService.getAll()
        .then((response) => {
          this.availableVehicles = response.data.vehicles;
          console.log(this.availableVehicles);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllTeams() {
      TeamDataService.getAll()
        .then((response) => {
          this.availableTeams = response.data.teams;
          console.log(this.availableTeams);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateAddress(address) {
      this.newEvent.address = address;
    },
    updatePointFormat(pointFormat) {
      this.newEvent.location_name = pointFormat;
    },
    getAllResources() {
      // Assuming you have a data service for resources
      ResourcesDataService.getAll()
        .then((response) => {
          this.availableResources = response.data.resources;
          console.log(this.availableResources);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllArea() {
      AreaDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.locations = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addTask() {
      this.isLoading = true;
      // Clear previous errors
      this.errors = {};

      // Validate required fields
      if (!this.newEvent.name) {
        this.errors.name = "Event name is required.";
      }
      if (!this.newEvent.startDateTime) {
        this.errors.startDateTime = "Start date and time are required.";
      }
      if (!this.newEvent.endDateTime) {
        this.errors.endDateTime = "End date and time are required.";
      }
      if (!this.newEvent.candidate_id) {
        this.errors.candidate_id = "Candidate selection is required.";
      }
      if (!this.newEvent.voter_group_id) {
        this.errors.voter_group_id = "Voter group selection is required.";
      }
      if (!this.newEvent.location) {
        this.errors.location = "Location selection is required.";
      }

      // Log errors if any
      console.log("Validation errors:", this.errors);

      if (Object.keys(this.errors).length > 0) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please fix the errors in the form.",
          confirmButtonText: "OK",
        });
        return; // Stop execution if validation fails
      }

      // If validation is successful, proceed to create the event
      console.log("Validation passed, proceeding to create the event...");

      const selectedArea = this.locations.find(
        (area) => area.id === this.newEvent.location
      );
      const locationString = this.newEvent.location_name;
      const coordinatesMatch = locationString.match(/POINT\(([^)]+)\)/);
      let coordinates = [];

      if (coordinatesMatch) {
        coordinates = coordinatesMatch[1].split(" ").map(Number);
      }

      const candidateId = localStorage.getItem("candidateId");
      console.log("Candidate ID:", candidateId);

      const payload = {
        id: this.events.length + 1,
        event_name: this.newEvent.name,
        location: {
          type: "Point",
          coordinates: coordinates,
        },
        location_name: this.newEvent.address,
        voter_group_id: this.newEvent.voter_group_id,
        first_contact: this.newEvent.first_contact,
        second_contact: this.newEvent.second_contact,
        third_contact: this.newEvent.third_contact,
        team: this.newEvent.teams.map((team) => team.id),
        area: selectedArea ? selectedArea.area : "",
        area_id: selectedArea ? selectedArea.id : "",
        event_start: this.newEvent.startDateTime,
        event_end: this.newEvent.endDateTime,
        candidate_id: this.newEvent.candidate_id,
        vehicles: this.newEvent.vehicles.map((vehicle) => vehicle.id),
        resources: this.newEvent.resources.map((resource) => resource.id),
      };

      console.log("Payload to be sent:", payload);

      // Call the API to create the event
      EventDataService.create(payload)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Event added successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/events");
          });
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.error("Error adding events:", error);
        });

      this.events.push(payload);

      // Reset the form fields (if needed)
      // this.newEvent.name = "";
      // this.newEvent.address = "";
      // this.newEvent.startDateTime = "";
      // this.newEvent.endDateTime = "";
      // this.newEvent.vehicles = [];
      // this.newEvent.resources = [];
    },
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the custom arrow */
  border: 1px solid #ced4da; /* Match border style */
  border-radius: 0.25rem; /* Optional: to match form styling */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}

.card {
  margin-bottom: 20px;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 5px;
}

.text-success {
  margin-left: auto;
  font-weight: bold;
}

.error {
  color: red;
}
</style>
