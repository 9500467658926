<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <survey-table />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <projects-table />
      </div>
    </div> -->
  </div>
</template>

<script>
import SurveyTable from "./components/SurveyTable";
// import ProjectsTable from "./components/ProjectsTable";

export default {
  name: "Users",
  components: {
    SurveyTable,
    // ProjectsTable,
  },
};

</script>
