<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>{{ $t('material_type') }}</h6>
      <button type="button" class="btn btn-primary" @click="showAddModal = true"
        style="background-color: #141727;color:white;border:2px solid #141727">{{ $t('add_resource') }}</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('resource_type_name') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('action') }}</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resource, index) in paginatedResourceType" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ resource.name }}</p>
              </td>
              <td class="align-middle">
                <a href="javascript:;" class="text-secondary font-weight-bold text-xs"
                  @click="openEditModal(resource, index)"><i class="fas fa-edit"></i> {{ $t('edit') }}</a>
                <a href="javascript:;" class="text-danger font-weight-bold text-xs ms-3"
                 @click="confirmDelete(index, resource.id)"><i class="fas fa-trash-alt"></i> {{ $t('delete') }}</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <div v-if="showAddModal" class="modal fade show d-block" tabindex="-1" role="dialog"
      style="background: rgba(0,0,0,0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('add_resource_modal_title') }}</h5>
            <button type="button" class="close" @click="showAddModal = false" aria-label="Close"
              style="background-color: #141727;color:white;border:2px solid #141727;border-radius: 50%;"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addResource">
              <div class="form-group">
                <label for="resourceName">{{ $t('resource_name') }}</label>
                <input v-model="newResource.name" type="text" class="form-control" id="resourceName"
                  required />
              </div>
              <button type="submit" class="btn btn-primary mt-3"
                style="background-color: #141727;color:white;border:2px solid #141727">{{ $t('add_resource_button') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div v-if="showEditModal" class="modal fade show d-block" tabindex="-1" role="dialog"
      style="background: rgba(0,0,0,0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('edit_resource_modal_title') }}</h5>
            <button type="button" class="close" @click="showEditModal = false" aria-label="Close"
              style="background-color: #141727;color:white;border:2px solid #141727;border-radius: 50%;"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateResource">
              <div class="form-group">
                <label for="editResourceName">{{ $t('resource_name') }}</label>
                <input v-model="currentResource.name" type="text" class="form-control" id="editResourceName"
                  required />
              </div>
              <button type="submit" class="btn btn-primary mt-3"
                style="background-color: #141727;color:white;border:2px solid #141727">{{ $t('save_changes_button') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css';
import ResourcesTypeDataService from "../../services/ResourcesTypeDataService.js";
import Swal from 'sweetalert2';

export default {
  name: "matrial-type",
  data() {
    return {
      resourceType: [],
      newResource: {
        id: "",
        name: ""
      },
      currentResource: {
        id: "",
        name: ""
      },
      showAddModal: false,
      showEditModal: false,
      currentIndex: null,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  created() {
    this.getAllResources();
  },
  computed: {
    paginatedResourceType() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.resourceType.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.resourceType.length / this.itemsPerPage);
    }
  },
  methods: {
    getAllResources() {
      ResourcesTypeDataService.getAll()
        .then(response => {
          this.resourceType = response.data.resources;
          console.log(this.resourceType);
        })
        .catch(e => {
          console.log(e);
        });
    },
    addResource() {
      const payload = {
        name: this.newResource.name,
      };

      ResourcesTypeDataService.create(payload)
        .then(response => {
          const createdResource = response.data.resourcestype;
          this.resourceType.push(createdResource);
          this.resetNewResource();
          this.showAddModal = false;
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateResource() {
      const resourceData = { ...this.currentResource };
      const payload = {
        id: resourceData.id,
        name: resourceData.name
      };

      ResourcesTypeDataService.update(payload)
        .then(response => {
          console.log(response);
          if (response) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Resource type updated successfully!',
              confirmButtonText: 'OK'
            }).then(() => {
              this.resourceType.splice(this.currentIndex, 1, resourceData);
              this.showEditModal = false;
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    openEditModal(resource, index) {
      this.currentResource = { ...resource };
      this.currentIndex = index;
      this.showEditModal = true;
    },
    resetNewResource() {
      this.newResource = {
        id: "",
        name: ""
      };
    },
    confirmDelete(index, id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteResourceType(index, id);
        Swal.fire(
          'Deleted!',
          'The Resource Type has been deleted.',
          'success'
        ).then(() => {
          window.location.reload();
        });
      }
    });
  },
    deleteResourceType(index,id) {
      ResourcesTypeDataService.delete(id)
        .then(response => {
          console.log(response);
          this.offices.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  }
};
</script>

<style scoped>
.table {
  width: 100%;
  margin-top: 20px;
}

.modal.fade.show {
  display: block;
}

.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;color:white;border:2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
