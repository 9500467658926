<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between">
      <ul class="nav nav-pills flex-column flex-md-row">
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" @click="navigateToSurvey">{{ $t('survey') }}</a>
        </li>
        <li class="nav-item ml-md-3">
          <a class="nav-link active" data-toggle="tab" @click="navigateToGetCategory">{{ $t('categories') }}</a>
        </li>
      </ul>
      <button type="button" class="btn btn-primary" @click="showAddModal = true"
        style="background-color: #141727;color:white;border:2px solid #141727">{{ $t('add_category') }}</button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('category_name') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('candidate_name') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('description') }}</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('action') }}</th>
            </tr>
          </thead>
          <tbody>
            <no-data-message v-if="paginatedCategory.length === 0" :colspan="8" :message="$t('no_categories_found')"
              :subtext="$t('please_add_new_category')"></no-data-message>

            <tr v-for="(category, index) in paginatedCategory" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ category.category_name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ category.category_candidate?.f_name || '' }} {{ category.category_candidate?.l_name || '' }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ category.description }}</p>
              </td>

              <td class="align-middle">
                <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip"
                  data-original-title="{{ $t('edit_category') }}" @click="openEditModal(index)">
                  <i class="fas fa-edit"></i>
                </a>
                <a href="javascript:;" class="text-danger font-weight-bold text-xs ms-3"
                  @click="confirmDelete(index, category.id)">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
        </div>
      </div>
    </div>

    <div v-if="showAddModal" class="modal fade show d-block" tabindex="-1" role="dialog"
      style="background: rgba(0,0,0,0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('add_category') }}</h5>
            <button type="button" class="close" @click="showAddModal = false" aria-label="Close"
              style="background-color: #141727;color:white;border:2px solid #141727;border-radius: 50%;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addCategory">
              <div class="form-group">
                <label for="newCategory">{{ $t('category_name') }}</label>
                <input v-model="newCategory.category_name" type="text" class="form-control" id="newCategory" required />
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <label for="option1">{{ $t('candidate') }}</label>
                    <select v-model="newCategory.candidate_id" id="candidate_id" class="form-control"
                      aria-label="candidate">
                      <option value="" selected>{{ $t('select_candidate') }}</option>
                      <option v-for="candidate in candidates" :key="candidate.id" :value="candidate.id">
                        {{ candidate.f_name }} {{ candidate.l_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="option1">{{ $t('description') }}</label>
                <textarea v-model="newCategory.description" id="description" class="form-control"
                  placeholder="" aria-label="Description" rows="3"></textarea>
              </div>
              <button type="submit" class="btn btn-primary mt-3"
                style="background-color: #141727;color:white;border:2px solid #141727">{{ $t('add_category') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showEditModal" class="modal fade show d-block" tabindex="-1" role="dialog"
      style="background: rgba(0,0,0,0.5);">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('edit_category') }}</h5>
            <button type="button" class="close" @click="showEditModal = false" aria-label="Close"
              style="background-color: #141727;color:white;border:2px solid #141727;border-radius: 50%;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateCategory">
              <div class="form-group">
                <label for="editCategory">{{ $t('category_name') }}</label>
                <input v-model="editCategory.category_name" type="text" class="form-control" id="editCategory"
                  required />
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <label for="option1">{{ $t('candidate') }}</label>
                    <select v-model="editCategory.candidate_id" id="candidate_id" class="form-control"
                      aria-label="candidate">
                      <option value="" selected>{{ $t('select_candidate') }}</option>
                      <option v-for="candidate in candidates" :key="candidate.id" :value="candidate.id">
                        {{ candidate.f_name }} {{ candidate.l_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="option1">{{ $t('description') }}</label>
                <textarea v-model="editCategory.description" id="description" class="form-control"
                  placeholder="" aria-label="Description" rows="3"></textarea>
              </div>
              <button type="submit" class="btn btn-primary mt-3"
                style="background-color: #141727;color:white;border:2px solid #141727">{{ $t('update_category') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css';
import Swal from 'sweetalert2';
import CategoryMasterDataService from "../../services/CategoryMasterDataService.js";
import CandidateDataService from "../../services/CandidateDataService.js";

export default {
  name: "Categories",
  components: {
    // NoDataMessage
  },
  data() {
    return {
      categories: [],
      newCategory: {
        category_name: "",
        candidate_id: "",
        description: ""
      },
      showModal: false,
      showAddModal: false,
      showEditModal: false,
      showQuestionsModalFlag: false,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  created() {
    this.getAllCategories();
    this.getAllCandidate();
  },
  computed: {
    paginatedCategory() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.categories.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.categories.length / this.itemsPerPage);
    }
  },
  methods: {
    getAllCategories() {
      CategoryMasterDataService.getAll()
        .then(response => {
          this.categories = response.data.categoryMasterData;
          console.log(this.categories);
        })
        .catch(e => {
          console.log(e);
        });
    },
    navigateToSurvey() {
      this.$router.push('/survey');
    },
    navigateToEditSurvey(id) {
      this.$router.push(`/edit-Survey/${id}`);
    },
    navigateToSurveyQuiz(id) {
      this.$router.push(`/survey-questions/${id}`);
    },
    getAllCandidate() {
      CandidateDataService.getAll()
        .then(response => {
          console.log(response.data);
          this.candidates = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    openEditModal(index) {

      let modifiedCategory = { ...this.categories[index] };

      this.editCategory = modifiedCategory;
      this.editIndex = index;
      this.showEditModal = true;
    },

    updateCategory() {

      this.categories.splice(this.editIndex, 1, { ...this.editCategory });

      const payload = {
        id: this.editCategory.id,
        category_name: this.editCategory.category_name,
        candidate_id: this.editCategory.candidate_id,
        description: this.editCategory.description,
      };
      console.log(payload);
      // CategoryMasterDataService.update(payload)
      //   .then(response => {
      //     console.log(response.data);
      //     Swal.fire({
      //       icon: 'success',
      //       title: 'Success',
      //       text: 'Category Updated successfully!',
      //       confirmButtonText: 'OK'
      //     }).then(() => {
      //       this.editCategory = { category_name: "", candidate_id: "", description: "" };
      //       this.showEditModal = false;
      //     }).catch(e => {
      //       console.log(e);
      //     });

      //   })

        CategoryMasterDataService.update(payload)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Category Updated successfully!',
            confirmButtonText: 'OK'
          }).then(() => {
            this.editCategory = { category_name: "", candidate_id: "", description: "" };
            this.showEditModal = false;
            // this.$router.push('/events'); 
          });
        })
        .catch(error => {
          console.error("Error adding events:", error);
        });
    },
    addCategory() {
      this.categories.push({ ...this.newCategory });
      const payload = {
        category_id: this.newCategory.category_id,
        category_name: this.newCategory.category_name,
        candidate_id: this.newCategory.candidate_id,
        description: this.newCategory.description,
      };

      // CategoryMasterDataService.create(payload)
      //   .then(response => {
      //     console.log(response.data);
      //     this.newCategory = { category_id: "", candidate_id: "", description: "" };
      //     this.showAddModal = false;
      //   })
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Success',
      //   text: 'Category added successfully!',
      //   confirmButtonText: 'OK'
      // }).then(() => {
      //   this.$router.push('/category');
      // }).then(() => {
      //   // location.reload();
      // }).catch(e => {
      //     console.log(e);
      //   });

        CategoryMasterDataService.create(payload)
        .then(() => {
          this.newCategory = { category_id: "",candidate_id: "",  category_name: "", description: "" };
          this.showAddModal = false;

          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Category added successfully!',
            confirmButtonText: 'OK'
          }).then(() => {
            this.$router.push('/category');
          });
        })
        .catch(error => {
          console.error("Error adding events:", error);
        });


    },


    confirmDelete(index, id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCategory(index, id);
          Swal.fire(
            'Deleted!',
            'The Category has been deleted.',
            'success'
          ).then(() => {
            window.location.reload();
          });
        }
      });
    },
    deleteCategory(index, id) {
      CategoryMasterDataService.delete(id)
        .then(response => {
          console.log(response);
          this.categories.splice(index, 1);
        })
        .catch(e => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  }
};
</script>

<style scoped>
.table {
  width: 100%;
  margin-top: 20px;
}

.modal.fade.show {
  display: block;
}

.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.nav-pills {
  display: flex;
  flex-direction: column;
}

.nav-pills .nav-item {
  margin-bottom: 10px;
}

.nav-pills .nav-item .nav-link {
  text-align: center;
  padding: 10px;
}

.nav-pills .nav-link.active {
  background-color: #141727;
  color: white;
}

.table-responsive {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .nav-pills {
    flex-direction: row;
  }

  .nav-pills .nav-item {
    margin-bottom: 0;
  }

  .nav-item.ml-md-3 {
    margin-left: 15px;
  }

  .col-md-4.mb-3.mb-md-0 {
    margin-bottom: 0 !important;
  }
}
</style>
