<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <ul class="nav nav-pills flex-column flex-md-row">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-toggle="tab"
            href="#votersearch"
            @click="
              resetSearchInput();
              resetFilters();
            "
          >
            {{ $t("voter_search") }}
          </a>
        </li>
        <li class="nav-item ml-md-3">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#votingcentervoice"
            @click="
              resetFilters();
              handleVcenterVotersTab();
            "
          >
            {{ $t("voting_center_wise") }}
          </a>
        </li>
        <li class="nav-item ml-md-3">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#sectionvoice"
            @click="
              resetFilters();
              handleSectionVotersTab();
            "
          >
            {{ $t("area_wise") }}
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <!-- Voter Search Tab Content -->
        <div class="tab-pane fade show active" id="votersearch">
          <div class="card-body px-0 pt-0 pb-2">
            <div class="row p-3">
              <div class="col-12 col-md-4 mb-3 mb-md-0" v-if="userType == 2">
                <label
                  for="installation_type"
                  class="form-control-label"
                  style="font-weight: bolder; font-size: 16px"
                >
                  {{ $t("विधान सभा") }}
                </label>
                <div class="select-wrapper">
                  <select
                    v-model="filters.acno"
                    class="form-control select-with-arrow"
                    @change="getAllVotersList($event)"
                  >
                    <option value="">Select acno</option>
                    <option :value="37">37</option>
                    <option :value="38">38</option>
                    <option :value="39">39</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <label
                  for="installation_type"
                  class="form-control-label"
                  style="font-weight: bolder; font-size: 16px"
                >
                  {{ $t("यादी भाग क्र") }}
                </label>
                <div class="select-wrapper">
                  <select
                    v-model="filters.wardNumber"
                    class="form-control select-with-arrow"
                    @change="onWardNumberChange"
                  >
                    <option value="0">Select यादी भाग क्र</option>
                    <!--<option value="0">All</option>-->
                    <!-- Add All option -->
                    <option
                      v-for="part in partnoList"
                      :key="part.partno"
                      :value="part.partno"
                    >
                      {{ part.partno }} - {{ part.area_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <label
                  for="installation_type"
                  class="form-control-label"
                  style="font-weight: bolder; font-size: 16px"
                >
                  {{ $t("मतदाराचे नाव") }}
                </label>
                <input
                  type="text"
                  v-model="filters.fullName"
                  class="form-control"
                  placeholder="आडनाव पहिलेनाव"
                  @input="onFullNameInput"
                />
              </div>
            </div>

            <div class="table-responsive p-0">
              <div v-if="isLoading" class="loader">Loading...</div>
              <div v-else>
                <div v-if="voters.length === 0">
                  <p
                    class="text-xs mb-0"
                    style="
                      color: red;
                      text-align: center;
                      font-size: 20px !important;
                    "
                  >
                    Voters not found
                  </p>
                </div>
                <table v-else class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        यादी भाग / अ.क.
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        आडनाव पहिलेनाव
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        लिग/वय
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        मतदान केंद
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        मतदाराचा पत्ता
                      </th>

                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(event, index) in paginatedEvents" :key="index">
                      <td>
                        <p class="text-xs font-weight-bold mb-0">
                          {{ event.partno }}/{{ event.slnoinpart }}
                        </p>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">
                          {{ event.name }}
                        </p>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">
                          {{ event.gender }}/{{ event.age }}
                        </p>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">
                          {{ event.vcenter }}
                        </p>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">
                          {{ event.section }}
                        </p>
                      </td>

                      <td>
                        <button
                          style="
                            background-color: #141727;
                            color: white;
                            border: 2px solid #141727;
                            border-radius: 5px;
                          "
                          @click="showModal('add', event.id)"
                          data-bs-toggle="modal"
                          data-bs-target="#addDetailsModal"
                        >
                          {{ $t("add_details") }}
                        </button>
                        <button
                          v-if="
                            hasWhatsAppNumber(event.family_members, event.id)
                          "
                          class="btn btn-sm ms-2"
                          style="
                            background-color: #25d366;
                            color: white;
                            border: none;
                            border-radius: 5px;
                            font-weight: bold;
                            padding: 4px 8px;
                            font-size: 12px;
                            cursor: pointer;
                            margin-top: 13px;
                          "
                          @click="
                            openPopup(
                              event.family_members,
                              event.voterslist,
                              event.id
                            )
                          "
                        >
                          <i
                            class="fab fa-whatsapp me-1"
                            style="font-size: 17px"
                          ></i>
                          WhatsApp
                        </button>

                        <!-- Simple Popup for Candidate Selection -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="pagination mt-4">
                  <button @click="prevPage" :disabled="currentPage === 1">
                    {{ $t("previous") }}
                  </button>
                  <span>{{ currentPage }} / {{ totalPages }}</span>
                  <button
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                  >
                    {{ $t("next") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showPopup" class="popup-overlay">
          <div class="popup-content">
            <!-- Only show the candidate selection for userType = 1 -->
            <h6 v-if="userType === '1'">Select Candidate</h6>
            <select
              v-if="userType === '1'"
              v-model="selectedCandidate"
              style="height: 40px; border-radius: 7px"
            >
              <option disabled value="">Please select candidate</option>
              <option
                v-for="candidate in candidates"
                :key="candidate.id"
                :value="candidate.id"
              >
                {{ candidate.f_name }} {{ candidate.l_name }}
              </option>
            </select>
            <br v-if="userType === '1'" /><br v-if="userType === '1'" />
            <h4 v-if="userType === '2'">Are You Sure?</h4>
            <button
              @click="submitCandidate"
              class="btn btn-primary"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
                padding-left: 12px;
                padding-right: 12px;
                padding-bottom: 5px;
                padding-top: 5px;
              "
            >
              Send
            </button>
            <button
              @click="closePopup"
              class="btn btn-primary"
              style="
                background-color: white;
                color: #141727;
                border: 2px solid #141727;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 5px;
                padding-top: 5px;
                margin-left: 20px;
              "
            >
              close
            </button>
          </div>
        </div>

        <!-- Voting Center Voice Tab Content -->
        <div class="tab-pane fade" id="votingcentervoice">
          <div class="card-body px-0 pt-0 pb-2">
            <div class="row p-3">
              <div class="col-12 col-md-4 mb-3 mb-md-0" v-if="userType == 2">
                <div class="select-wrapper">
                  <select
                    v-model="filters.acno"
                    class="form-control select-with-arrow"
                    @change="getAllVcenterVoters($event)"
                  >
                    <option value="">Select acno</option>
                    <option :value="37">37</option>
                    <option :value="38">38</option>
                    <option :value="39">39</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <div class="select-wrapper">
                  <select
                    v-model="filters.kendraNihayYadiNo"
                    class="form-control select-with-arrow"
                    @change="getAllVcenterVoters($event)"
                  >
                    <option value="">Select केंद्र निहाय यादी क्र.</option>
                    <option value="0">All</option>
                    <option
                      v-for="center in vcenterList"
                      :key="center.vcenter"
                      :value="center.vcenter"
                    >
                      {{ center.vcenter }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <input
                  type="text"
                  v-model="filters.fullName"
                  class="form-control"
                  placeholder="आडनाव पहिलेनाव"
                  @input="onFullNameInputVcenter"
                />
              </div>
            </div>
            <div v-if="isLoading" class="loader">Loading...</div>
            <div class="table-responsive p-0" v-else>
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      यादी भाग / अ.क.
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      आडनाव पहिलेनाव
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      लिग/वय
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      मतदाराचा पत्ता
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(event, index) in paginatedVcenter" :key="index">
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.partno }}/{{ event.slnoinpart }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.name }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.gender }}/{{ event.age }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.section }}
                      </p>
                    </td>
                    <td>
                      <button
                        style="
                          background-color: #141727;
                          color: white;
                          border: 2px solid #141727;
                          border-radius: 5px;
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#addDetailsModal"
                        @click="showModal('add', event.id)"
                      >
                        {{ $t("add_details") }}
                      </button>
                      <button
                        v-if="hasWhatsAppNumber(event.family_members, event.id)"
                        class="btn btn-sm ms-2"
                        style="
                          background-color: #25d366;
                          color: white;
                          border: none;
                          border-radius: 5px;
                          font-weight: bold;
                          padding: 4px 8px;
                          font-size: 12px;
                          cursor: pointer;
                          margin-top: 13px;
                        "
                        @click="
                          openPopup(
                            event.family_members,
                            event.voterslist,
                            event.id
                          )
                        "
                      >
                        <i
                          class="fab fa-whatsapp me-1"
                          style="font-size: 17px"
                        ></i>
                        WhatsApp
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="pagination mt-4">
                <button @click="prevPage" :disabled="currentPage === 1">
                  {{ $t("previous") }}
                </button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                >
                  {{ $t("next") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Area Wise Tab Content -->
        <div class="tab-pane fade" id="sectionvoice">
          <div class="card-body px-0 pt-0 pb-2">
            <div class="row p-3">
              <div class="col-12 col-md-4 mb-3 mb-md-0" v-if="userType == 2">
                <div class="select-wrapper">
                  <select
                    v-model="filters.acno"
                    class="form-control select-with-arrow"
                    @change="getAllVcenterVoters($event)"
                  >
                    <option value="">Select acno</option>
                    <option :value="37">37</option>
                    <option :value="38">38</option>
                    <option :value="39">39</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <div class="select-wrapper">
                  <select
                    v-model="filters.wardNumber"
                    class="form-control select-with-arrow"
                    @change="filterAreaWiseList"
                  >
                    <option value="">Select यादी भाग क्र</option>
                    <option
                      v-for="part in partnoList"
                      :key="part.partno"
                      :value="part.partno"
                    >
                      {{ part.partno }} - {{ part.area_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <div>
                  <select
                    v-model="filters.location_name"
                    class="form-control select-with-arrow"
                    @change="onSectionChange($event)"
                  >
                    <option value="">Select area</option>
                    <option
                      v-for="list in filteredAreaWiseList"
                      :key="list.location_name"
                      :value="list.location_name"
                    >
                      {{
                        `${list.ac_no} / ${list.partno} / ${list.location_name}`
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <input
                  type="text"
                  v-model="filters.fullName"
                  class="form-control"
                  placeholder="आडनाव पहिलेनाव"
                  @input="onFullNameInput"
                />
              </div>
            </div>
            <div v-if="isLoading" class="loader">Loading...</div>
            <div class="table-responsive p-0" v-else>
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      यादी भाग / अ.क.
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      आडनाव पहिलेनाव
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      लिग/वय
                    </th>

                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      मतदान केंद
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      मतदाराचा पत्ता
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="voters.length === 0">
                    <td colspan="3" class="text-center">
                      <p
                        class="text-xs font-weight-bold mb-0"
                        style="
                          color: red;
                          text-align: center;
                          font-size: 20px !important;
                        "
                      >
                        Voters not found
                      </p>
                    </td>
                  </tr>
                  <tr v-for="(event, index) in paginatedEvents" :key="index">
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.partno }}/{{ event.slnoinpart }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.name }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.gender }}/{{ event.age }}
                      </p>
                    </td>

                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.vcenter }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ event.section }}
                      </p>
                    </td>
                    <td>
                      <button
                        style="
                          background-color: #141727;
                          color: white;
                          border: 2px solid #141727;
                          border-radius: 5px;
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#addDetailsModal"
                        @click="showModal('add', event.id)"
                      >
                        {{ $t("add_details") }}
                      </button>
                      <button
                        v-if="hasWhatsAppNumber(event.family_members, event.id)"
                        class="btn btn-sm ms-2"
                        style="
                          background-color: #25d366;
                          color: white;
                          border: none;
                          border-radius: 5px;
                          font-weight: bold;
                          padding: 4px 8px;
                          font-size: 12px;
                          cursor: pointer;
                          margin-top: 13px;
                        "
                        @click="
                          openPopup(
                            event.family_members,
                            event.voterslist,
                            event.id
                          )
                        "
                      >
                        <i
                          class="fab fa-whatsapp me-1"
                          style="font-size: 17px"
                        ></i>
                        WhatsApp
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination mt-4">
                <button @click="prevPage" :disabled="currentPage === 1">
                  {{ $t("previous") }}
                </button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                >
                  {{ $t("next") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="isAddModalVisible"
          class="modal fade show"
          id="addDetailsModal"
          style="display: block; width: 100%"
          tabindex="-1"
          aria-labelledby="addDetailsModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog custom-modal-dialog">
            <div
              class="modal-content"
              style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"
            >
              <div class="modal-header">
                <h5 class="modal-title" id="addDetailsModalLabel">
                  {{ selectedEvent.name }}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <!-- <div class="col-md-4 mb-3" v-if="userType == '1'">
                      <label for="area" class="form-label">{{
                        $t("area")
                      }}</label>
                      <select
                        id="category"
                        v-model="selectedarea"
                        class="form-select"
                      >
                        <option selected>Select Area</option>
                        <option
                          v-for="area in areaList"
                          :key="area.id"
                          :value="area.id"
                        >
                          {{ area.name }}
                        </option>
                      </select>
                    </div> -->
                    <div class="col-md-5 mb-3">
                      <label for="familyMember" class="form-label">{{
                        $t("family_member")
                      }}</label>
                      <multiselect
                        v-model="selectedFamilyMembers"
                        :options="familyMembersOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Pick some family members"
                        label="label"
                        track-by="value"
                        @search-change="onSearchChange"
                      ></multiselect>
                    </div>
                    <div class="col-md-3 mb-3">
                      <label for="mobileNumber" class="form-label">{{
                        $t("phone_number")
                      }}</label>
                      <input
                        type="text"
                        v-model="selectedMobileNumber"
                        id="mobileNumber"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                        @input="prependCountryCode"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <!-- Family Member Select Dropdown -->

                    <!-- Voter Group Select Dropdown -->
                    <div class="col-md-4 mb-3" v-if="userType == '1'">
                      <label for="voterGroup" class="form-label">{{
                        $t("Voter Group")
                      }}</label>
                      <multiselect
                        v-model="selectedVoterGroups"
                        :options="votersGroups"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Pick some"
                        label="voter_group_name"
                        track-by="voter_group_name"
                      ></multiselect>
                    </div>
                    <div class="col-md-2 mb-3" v-if="userType == '1'">
                      <label for="align_with_party " class="form-label">{{
                        $t("Align With Party")
                      }}</label>
                      <select
                        id="category"
                        v-model="selectedaAlignwithParty"
                        class="form-select"
                      >
                        <option selected>
                          Select {{ $t("Align With Party") }}
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="prefer_not_to_say">Prefer Not to Say</option>
                      </select>
                    </div>
                    <div class="col-md-2 mb-3" v-if="userType == '1'">
                      <label for="category" class="form-label">{{
                        $t("Align With Candidate")
                      }}</label>
                      <select
                        id="category"
                        v-model="selectedAlignWithCandidate"
                        class="form-select"
                      >
                        <option selected>
                          Select {{ $t("Align With Candidate") }}
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="prefer_not_to_say">Prefer Not to Say</option>
                      </select>
                    </div>

                    <div class="col-md-4 mb-3" v-if="userType == '1'">
                      <label for="family_types" class="form-label">{{
                        $t("family_type")
                      }}</label>
                      <select
                        id="family_types"
                        v-model="selectedFamilyType"
                        class="form-select"
                      >
                        <option selected>Select family types</option>
                        <option value="nuclear">Nuclear</option>
                        <option value="joint">Joint</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Category (Caste) Dropdown -->
                    <div class="col-md-4 mb-3" v-if="userType == '1'">
                      <label for="category" class="form-label">{{
                        $t("category_cast")
                      }}</label>
                      <select
                        id="category"
                        v-model="selectedCategory"
                        class="form-select"
                      >
                        <option selected>Select Category</option>
                        <option
                          v-for="catcaste in categoryCaste"
                          :key="catcaste.id"
                          :value="catcaste.name"
                        >
                          {{ catcaste.name }}
                        </option>
                      </select>
                    </div>
                    <!-- Religion Dropdown -->
                    <div class="col-md-4 mb-3" v-if="userType == '1'">
                      <label for="religion" class="form-label">{{
                        $t("Religion")
                      }}</label>
                      <select
                        id="religion"
                        v-model="selectedReligion"
                        class="form-select"
                      >
                        <option selected>Select Religion</option>
                        <option
                          v-for="religion in religionList"
                          :key="religion.id"
                          :value="religion.name"
                        >
                          {{ religion.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 mb-3" v-if="userType == '1'">
                      <label for="category" class="form-label">{{
                        $t("employment_types")
                      }}</label>
                      <select
                        id="category"
                        v-model="selectedEmpType"
                        class="form-select"
                      >
                        <option selected>Select employment types</option>
                        <option value="self-employed">Self-employed</option>
                        <option value="unemployed">Unemployed</option>
                        <option value="salaried">Salaried</option>
                      </select>
                    </div>
                    <!-- Area Dropdown -->
                  </div>
                  <!-- Mobile Number Textfield -->

                  <div class="row">
                    <!-- Category (Caste) Dropdown -->

                    <!-- Religion Dropdown -->

                    <!-- Area Dropdown -->
                    <div class="col-md-3 mb-3" v-if="userType == '1'">
                      <label for="income_groups" class="form-label">{{
                        $t("income_group")
                      }}</label>
                      <select
                        id="income_groups"
                        v-model="selectedIncomeGroup"
                        class="form-select"
                      >
                        <option selected>Select income groups</option>
                        <option value="low">Low</option>
                        <option value="middle">Middle</option>
                        <option value="high">High</option>
                      </select>
                    </div>
                    <div class="col-md-3 mb-3" v-if="userType == '1'">
                      <label for="marital_statuses" class="form-label">{{
                        $t("marital_status")
                      }}</label>
                      <select
                        id="marital_statuses"
                        v-model="selectedMaritalStatus"
                        class="form-select"
                      >
                        <option selected>Select marital statuses</option>
                        <option value="Single">Single</option>
                        <option value="married">Married</option>
                        <option value="divorced">Divorced</option>
                      </select>
                    </div>
                    <div class="col-md-3 mb-3" v-if="userType == '1'">
                      <label for="category" class="form-label">{{
                        $t("house_type")
                      }}</label>
                      <select
                        id="category"
                        v-model="selectedHouseType"
                        class="form-select"
                      >
                        <option selected>Select house types</option>
                        <option value="apartment">Apartment</option>
                        <option value="villa">Villa</option>
                      </select>
                    </div>
                    <!-- Religion Dropdown -->
                    <div class="col-md-3 mb-3" v-if="userType == '1'">
                      <label for="building_types" class="form-label">{{
                        $t("building_type")
                      }}</label>
                      <select
                        id="building_types"
                        v-model="selectedBuildingType"
                        class="form-select"
                      >
                        <option selected>Select building types</option>
                        <option value="commercial">Commercial</option>
                        <option value="residential">Residential</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal('add')"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="voterInfoAdd()"
                  :style="{
                    backgroundColor: '#141727',
                    color: 'white',
                    border: '2px solid #141727',
                  }"
                >
                  Save Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import "@fortawesome/fontawesome-free/css/all.css";
import VotersSearchDataService from "../../services/VotersSearchDataService.js";
import ReligionCategoryDataService from "../../services/ReligionCategoryDataService.js";
import ReligionDataService from "../../services/ReligionDataService.js";
import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import AreaDataService from "../../services/AreaDataService.js";
import EventDataService from "../../services/EventDataService.js";
import Swal from "sweetalert2";
import { handleApiErrors } from "@/utils/errorHandler";

export default {
  components: {
    Multiselect,
  },
  name: "VoterSearch",
  data() {
    return {
      showPopup: false,
      userType: localStorage.getItem("installation_type"),
      isLoading: false,
      voters: [],
      areaList: [],
      votersList: [],
      votersGroups: [],
      categoryCaste: [],
      religionList: [],
      filters: {
        fullName: "",
        wardArea: "",
        location_name: "",
        section: "",
        wardNumber: "",
        kendraNihayBhag: "",
        kendraNihayYadiNo: "",
        sectionWiseList: "",
        acno: localStorage.getItem("defaultacno"),
        wardNumberChangeTriggered: false,
      },
      filteredAreaWiseList: [],
      searchQuery: "",
      partnoList: [],
      vcenterList: [],
      sectionList: [],
      areaWiseList: [],
      forceUpdateKey: 0,
      selectedCategory: "",
      selectedEmpType: "",
      selectedarea: "",
      selectedReligion: "",
      selectedAlignWithCandidate: "",
      selectedaAlignwithParty: "",
      selectedBuildingType: "",
      selectedHouseType: "",
      selectedMaritalStatus: "",
      selectedIncomeGroup: "",
      selectedFamilyType: "",
      selectedMobileNumber: "",
      selectedFamilyMembers: [],
      familyMembersOptions: [],
      selectedVoterGroups: [],
      voters_list: { fullName: [] },
      voters_group: { fullName: [] },
      isAddModalVisible: false,
      isEditModalVisible: false,
      selectedEvent: {},
      selectedLanguage: this.$i18n.locale,
      currentPage: 1,
      itemsPerPage: 10,
      activeTab: "votersearch",
      searchTerm: "",
    };
  },

  mounted() {
    // this.getAllPartno();
    // this.getAreaWiseList();
    this.filteredAreaWiseList = this.areaWiseList;
  },
  created() {
    // this.getAllVotersList();
    // this.getAllVcenterVoters();
    this.getAllPartno();
    this.getAllAreaList();
    this.getAllCategoryCaste();
    this.getAllReligion();
    this.getAllVotersGroupList();
    this.getAllvcenterList();
    this.getSectionList();
    this.getAreaWiseList();
    this.getCandidates();
    // this.searchAcnoWithoutVotersFamily();
  },

  methods: {
    // customLabel(option) {
    //   return `${option.name} (${option.enfname || ""})`;
    // },
    getCandidates() {
      UsersDataService.getAdminCandidate()
        .then((response) => {
          this.candidates = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    customLabel(option) {
      return option.label; // Display name directly from the options
    },
    customFilter(option, search) {
      // Ensure the search term filters by enfname (case-insensitive)
      const name = option.label.toLowerCase();
      const searchTerm = search.toLowerCase();
      return name.includes(searchTerm);
    },

    // selectEvent(event) {
    //   this.selectedEvent = event;
    //   this.isAddModalVisible = true;
    // },
    async showModal(mode, eventId = null) {
      if (mode === "add") {
        if (eventId) {
          // Find the selected event (voter)
          this.selectedEvent = this.voters.find(
            (event) => event.id === eventId
          );
          console.log(this.selectedEvent);

          // Filter the voters list to exclude the selected event
          this.votersList = this.voters.filter(
            (event) => event.id !== this.selectedEvent.id
          );
          console.log(this.votersList);

          // Check if the selected event has family members and if they match
          if (
            this.selectedEvent &&
            this.selectedEvent.family_members &&
            this.selectedEvent.family_members.length > 0
          ) {
            // Filter to find the family member where `voter_details.id` matches `selectedEvent.id`
            const matchingFamilyMember = this.selectedEvent.family_members.find(
              (member) => member.voter_details.id === this.selectedEvent.id
            );

            // If a matching family member is found, populate form fields with their data
            if (matchingFamilyMember) {
              this.selectedCategory = matchingFamilyMember.category;
              this.selectedReligion = matchingFamilyMember.religion;
              this.selectedarea = matchingFamilyMember.location;
              this.selectedaAlignwithParty =
                matchingFamilyMember.align_with_party;
              this.selectedAlignWithCandidate =
                matchingFamilyMember.align_with_candidate;
              this.selectedMobileNumber = matchingFamilyMember.phone_no;
              this.selectedEmpType = matchingFamilyMember.employment_types;
              this.selectedFamilyType = matchingFamilyMember.family_types;
              this.selectedIncomeGroup = matchingFamilyMember.income_groups;
              this.selectedMaritalStatus =
                matchingFamilyMember.marital_statuses;
              this.selectedHouseType = matchingFamilyMember.house_types;
              this.selectedBuildingType = matchingFamilyMember.building_types;
              this.selectedVoterGroups = this.votersGroups.filter((group) => {
                // Ensure matchingFamilyMember exists and voter_group_id is defined
                console.log("GROUP ID");
                console.log(group.id);
                console.log(matchingFamilyMember);
                console.log(matchingFamilyMember.voter_group_id);
                if (
                  matchingFamilyMember &&
                  matchingFamilyMember.voter_group_id
                ) {
                  // Parse voter_group_id string into an array
                  console.log("if");
                  let voterGroupIdArray;
                  try {
                    voterGroupIdArray = JSON.parse(
                      matchingFamilyMember.voter_group_id
                    );
                    console.log("votergroupidarray");
                    console.log(voterGroupIdArray);
                  } catch (error) {
                    console.error("Failed to parse voter_group_id:", error);
                    return false; // Skip this entry if parsing fails
                  }

                  // Ensure parsed voterGroupIdArray is an array and includes the group.id
                  return (
                    Array.isArray(voterGroupIdArray) &&
                    voterGroupIdArray.includes(group.id)
                  );
                }

                return false; // Return false if matchingFamilyMember or voter_group_id is undefined
              });

              // Prepare family members for the multiselect, excluding the matching family member
              this.selectedFamilyMembers = this.selectedEvent.family_members
                .filter(
                  (member) => member.voter_details.id !== this.selectedEvent.id
                ) // Exclude current voter
                .map((member) => {
                  const voterDetails = member.voter_details;
                  return {
                    value: member.id,
                    label: `${voterDetails.enfname} (${
                      voterDetails.age ? voterDetails.age : "unknown"
                    } years)`,
                  };
                });

              console.log(
                "Selected Family Members:",
                this.selectedFamilyMembers
              );
            } else {
              console.log("No matching family member found.");
            }
          } else {
            console.log("No family members or selectedEvent is undefined.");
          }

          // Fetch family member data for the selected event from the API
          try {
            const response = await VotersSearchDataService.getVotersFamilyInfo(
              this.selectedEvent.partno,
              this.selectedEvent.enfname,
              this.selectedEvent.enlname,
              this.selectedEvent.id
            );

            const { voterslist } = response.data;
            console.log("Voters List from API:", voterslist);

            if (Array.isArray(voterslist)) {
              // Populate family members options, excluding the current voter
              this.familyMembersOptions = voterslist
                .filter((member) => member.id !== this.selectedEvent.id) // Exclude the current voter
                .map((member) => ({
                  value: member.id,
                  label: `${member.name} (${member.age} years)`,
                }));
              console.log("Family Members Options:", this.familyMembersOptions);
            } else {
              console.warn("No family members found for this ID.");
              this.familyMembersOptions = [];
            }
          } catch (error) {
            console.error("Error fetching family members:", error);
            this.familyMembersOptions = [];
          }
        }
        this.isAddModalVisible = true; // Show modal
      }
    },
    async onSearchChange(search) {
      console.log("Search Input:", search); // Logs search term

      // If search input is empty, clear the options and return
      if (!search) {
        this.familyMembersOptions = [];
        return;
      }

      try {
        // Fetch data from the API using the search term
        const response = await VotersSearchDataService.searchAcnoWithoutVotersFamily(
          this.selectedEvent.acno,
          this.selectedEvent.prabhag,
          this.selectedEvent.partno,
          search
        );

        const { voterslist } = response.data;
        console.log("API Response voterslist:", voterslist); // Log API response

        // Check if the API returned a valid array of voters
        if (Array.isArray(voterslist) && voterslist.length > 0) {
          // Map the data to the format required by the multiselect
          this.familyMembersOptions = voterslist.map((member) => ({
            value: member.id, // Set the unique ID as value
            label: `${member.name} ${member.enlname} (${member.age} years)`, // Combine first name, last name, and age
          }));
          console.log(
            "Updated Family Members Options:",
            this.familyMembersOptions
          ); // Log the mapped options
        } else {
          this.familyMembersOptions = []; // Clear options if no data found
        }
      } catch (error) {
        console.error("Error fetching family members:", error);
        this.familyMembersOptions = []; // Clear options in case of error
      }
    },
    // Show modal and fetch initial event and voters data

    hideModal(mode) {
      if (mode === "add") {
        this.selectedMobileNumber = "";
        this.selectedCategory = "";
        this.selectedEmpType = "";
        this.selectedReligion = "";
        this.selectedAlignWithCandidate = "";
        this.selectedaAlignwithParty = "";
        this.selectedBuildingType = "";
        this.selectedHouseType = "";
        this.selectedMaritalStatus = "";
        this.selectedIncomeGroup = "";
        this.selectedFamilyType = "";
        this.selectedVoterGroups = "";
        this.selectedFamilyMembers = "";
        this.selectedarea = "";
        this.isAddModalVisible = false;
      } else if (mode === "edit") {
        this.isEditModalVisible = false;
      }
      this.selectedIndex = null;
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    prependCountryCode() {
      // Remove any non-digit characters (except + for the country code)
      this.selectedMobileNumber = this.selectedMobileNumber.replace(/\D/g, "");

      // Prepend the country code if not present
      if (!this.selectedMobileNumber.startsWith("91")) {
        this.selectedMobileNumber = "91" + this.selectedMobileNumber;
      }

      // Ensure the length is restricted to 13 characters, including "+91"
      this.selectedMobileNumber = "+" + this.selectedMobileNumber.slice(0, 12);
    },
    getAllAreaList() {
      AreaDataService.getAll()
        .then((response) => {
          console.log("area " + response.data.area);
          this.areaList = response.data.area;
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.error("Error voter info add:", error);
        });
    },
    getAllPartno() {
      const defaultacno = localStorage.getItem("defaultacno"); // Get the acno from localStorage

      // Check if defaultacno is available
      if (!defaultacno) {
        console.error("No acno found in localStorage");
        return; // Exit if no acno is found
      }

      VotersSearchDataService.getAllPartno(defaultacno) // Pass the acno to the service method
        .then((response) => {
          console.log("Response:", response.data);
          this.partnoList = response.data.partnolist;

          // Sort partnoList in ascending order by partno
          this.partnoList.sort((a, b) => a.partno - b.partno); // Set the partnoList

          // Set the default value to the first partno if partnoList is not empty
          if (this.partnoList.length > 0) {
            this.filters.wardNumber = this.partnoList[0].partno;

            // Now call getAllVotersList to load the voters based on the first partno
            this.getAllVotersList();
          }
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.error("Error voter info add:", error);
        });
    },
    getAllvcenterList() {
      const defaultacno = localStorage.getItem("defaultacno"); // Retrieve acno from localStorage

      // Check if defaultacno is available
      if (!defaultacno) {
        console.error("No acno found in localStorage");
        return; // Exit if no acno is found
      }

      // Call the service method with the acno
      VotersSearchDataService.getAllvcenterList(defaultacno) // Pass the acno to the service method
        .then((response) => {
          console.log("Response:", response.data);
          this.vcenterList = response.data.vcenterlist; // Set vcenterList
          if (this.vcenterList.length > 0) {
            this.filters.kendraNihayYadiNo = this.vcenterList[0].vcenter; // Set default kendra value
          }
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages; // Handle any errors
          }
          console.error("Error fetching vcenter list:", error);
        });
    },

    getSectionList() {
      const defaultacno = localStorage.getItem("defaultacno"); // Retrieve acno from localStorage

      // Check if defaultacno is available
      if (!defaultacno) {
        console.error("No acno found in localStorage");
        return; // Exit if no acno is found
      }

      // Call the service method with the acno
      VotersSearchDataService.sectionList(defaultacno) // Pass the acno to the service method
        .then((response) => {
          console.log("Response SECTION LIST:", response.data);
          this.sectionList = response.data.sectionlist; // Set sectionList
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages; // Handle any errors
          }
          console.error("Error fetching section list:", error);
        });
    },

    getAreaWiseList() {
      const defaultacno = localStorage.getItem("defaultacno"); // Retrieve acno from localStorage
      VotersSearchDataService.areaWiseList(defaultacno)
        .then((response) => {
          console.log("Response SECTION:", response.data);
          this.areaWiseList = response.data.areawiselist;
          this.filteredAreaWiseList = this.areaWiseList; // Initialize filtered list with full list
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.error("Error fetching section list:", error);
        });
    },

    filterAreaWiseList() {
      // Get the selected partno
      const selectedPartno = this.filters.wardNumber;
      console.log("AAAAAAAAAAA", selectedPartno);
      // Filter the area wise list based on selected partno
      if (selectedPartno) {
        this.filteredAreaWiseList = this.areaWiseList.filter((list) => {
          return list.partno === selectedPartno; // Match only the selected partno
        });
      } else {
        // If 'All' is selected or nothing is selected, show the full list
        this.filteredAreaWiseList = this.areaWiseList;
      }

      // Optionally, you can also reset the location_name when partno changes
      this.filters.location_name = ""; // Reset location name selection
    },

    voterInfoAdd() {
      const voterListId = this.selectedEvent.id;

      // Extract family_id from the first family member in the selectedEvent family_members array
      const firstFamilyMember =
        this.selectedEvent.family_members.length > 0
          ? this.selectedEvent.family_members[0]
          : null;
      const familyId = firstFamilyMember ? firstFamilyMember.family_id : null; // Get family_id from the first family member

      // Main member object
      const selectedEventMember = {
        voter_list_id: voterListId,
        family_id: familyId, // Use the family_id from the first family member or null
        voter_group_id: Array.isArray(this.selectedVoterGroups)
          ? this.selectedVoterGroups.map((group) => group.id)
          : [], // Ensure it's an array, or return an empty array
        address: this.selectedEvent.section || "",
        location: this.selectedarea,
        phone_no: this.selectedMobileNumber, // Main member's phone number
      };

      console.warn("DATAAAAAAAAAAAAAAAAAAAAA", this.selectedEvent);

      // Ensure selectedFamilyMembers reflects the correct selections
      const selectedFamilyMemberIds = this.selectedFamilyMembers.map(
        (member) => member.value // Assuming value is the ID
      );

      // Family members mapping, including family_id or null
      const familyMembers = this.familyMembersOptions
        .filter((option) => selectedFamilyMemberIds.includes(option.value)) // Filter for selected options
        .map((member) => ({
          voter_list_id: member.value, // Use the member's unique ID
          family_id: familyId || null, // Use the family_id from the first family member or null
          voter_group_id: null, // No voter group ID for family members
          address: member.section || "", // Adjust if section is part of the member data
          location: this.selectedarea,
          phone_no: member.phone_no || null, // Include phone_no from the member data
        }));

      // Complete data structure to be sent in the API request
      const voterInfoData = {
        user_id: localStorage.getItem("admin_id"),
        phone_no: this.selectedMobileNumber,
        category: this.selectedCategory,
        employment_types: this.selectedEmpType,
        religion: this.selectedReligion,
        align_with_candidate: this.selectedAlignWithCandidate,
        align_with_party: this.selectedaAlignwithParty,
        building_types: this.selectedBuildingType,
        house_types: this.selectedHouseType,
        marital_statuses: this.selectedMaritalStatus,
        income_groups: this.selectedIncomeGroup,
        family_types: this.selectedFamilyType,
        familyMembers: [selectedEventMember, ...familyMembers], // Attach event member and family members
      };

      console.log("Voter Info Data:", voterInfoData); // Log the data being sent

      // Send the data to the API
      VoterGroupsDataService.voter_info_add(voterInfoData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Voter info added successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.resetForm(); // Reset form after submission
          });

          console.log("API Response:", response);
          this.showAddModal = false;
          window.location.reload();
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.error("Error adding voter info:", error);
        });
    },

    resetForm() {
      this.selectedMobileNumber = "";
      this.selectedCategory = "";
      this.selectedEmpType = "";
      this.selectedReligion = "";
      this.selectedAlignWithCandidate = "";
      this.selectedaAlignwithParty = "";
      this.selectedBuildingType = "";
      this.selectedHouseType = "";
      this.selectedMaritalStatus = "";
      this.selectedIncomeGroup = "";
      this.selectedFamilyType = "";
      this.selectedVoterGroups = "";
      this.selectedFamilyMembers = "";
      this.selectedarea = "";
      this.isAddModalVisible = false;
    },
    getAllVotersList() {
  this.isLoading = true;

  // Always default to the first partno on page load or if no selection is made
  let wardNumber =
    this.filters.wardNumber !== 0
      ? this.filters.wardNumber ||
        (this.partnoList.length > 0 ? this.partnoList[0].partno : "")
      : "";

  // When "All" is explicitly selected, set wardNumber to an empty string
  if (this.filters.wardNumber == 0) {
    wardNumber = ""; // Clear the wardNumber only if "All" is selected
  }

  // Make the API call with the correct payload
  VotersSearchDataService.searchAcnoVoters(
    localStorage.getItem("defaultacno") ?? "", // ACNO or empty string
    this.filters.fullName ?? "", // fullName or empty string
    this.filters.wardArea ?? "", // Use wardArea or empty string
    wardNumber, // Send selected partno or empty string if "All" is selected
    this.filters.section ?? this.filters.location_name // Section or location_name
  )
    .then((response) => {
      this.voters = response.data.voterslist || []; // Handle empty list case

      // Sorting the voters list in ascending order by "slnoinpart"
      this.voters.sort((a, b) => a.slnoinpart - b.slnoinpart);

      if (this.voters.length === 0) {
        console.log("Voters not found");
      }

      this.currentPage = 1;

      // Process the voter data if available
      this.uniqueAcno = [
        ...new Set(this.voters.map((event) => event.acno)),
      ];
      this.uniqueWardAreas = [
        ...new Set(this.voters.map((event) => event.prabhag)),
      ];
      this.uniqueWardNumbers = [
        ...new Set(this.voters.map((event) => event.partno)),
      ];

      console.log("Unique ACNO:", this.uniqueAcno);
      console.log("Unique Ward Areas:", this.uniqueWardAreas);
      console.log("Unique Ward Numbers:", this.uniqueWardNumbers);
      this.isLoading = false;
    })
    .catch((error) => {
      this.isLoading = false;
      this.voters = []; // Reset voters on error
      console.error("Error fetching voter data:", error);
    });
}
,

    getAllVotersListForSectionWise() {
      this.isLoading = true;
      console.log("Filters being sent to API:", this.filters);

      VotersSearchDataService.searchVotersListForSectionWise(
        localStorage.getItem("defaultacno") || "",
        this.filters.fullName || "",
        this.filters.wardArea || "",
        this.filters.center_id || "",
        this.filters.location_name || "",
        this.filters.partno || ""
      )
        .then((response) => {
          this.voters = response.data.voterslist || [];
          this.isLoading = false;
          console.log("API Response:", response.data);
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error fetching voter data:", error);
        });
    },
    resetSearchInput() {
      this.filters.fullName = "";
      this.getAllVotersList();
    },
    handleVcenterVotersTab() {
      // this.resetSearchInput();
      this.getAllVcenterVoters(); // Now this function should be called properly
    },
    handleSectionVotersTab() {
      this.resetSearchInput();
      // this.getAllVotersList(); // Now this function should be called properly
    },
    onFullNameInput() {
      if (this.filters.fullName.length >= 3) {
        // No need to reset wardNumber, so remove the line that clears it
        this.filters.wardNumberChangeTriggered = false;
        this.getAllVotersList();
      }
    },
    onFullNameInputVcenter() {
      if (this.filters.fullName.length >= 3) {
        // No need to reset kendraNihayYadiNo, keep it as it is
        this.getAllVcenterVoters();
      }
    },
    onWardNumberChange() {
    if(this.filters.wardNumber == 0){
      this.votersList = [];
      this.voters = [];
      return;
    }
      this.filters.wardNumberChangeTriggered = true;

      // If "All" is selected, clear the ward number to fetch all voters
      if (this.filters.wardNumber === "all") {
        this.filters.wardNumber = ""; // Reset the ward number
      }

      this.getAllVotersList();
    },
    onSectionChange(event) {
      // Get the selected partno from the event or the filters
      const partno = this.filters.wardNumber || event.target.value;

      const selectedLocation = this.filters.location_name;
      console.log("Selected Location:", selectedLocation);

      const selectedList = this.areaWiseList.find(
        (list) =>
          list.location_name === selectedLocation && list.partno == partno
      );
      console.log("Selected List:", selectedList);

      if (selectedList) {
        this.filters.acno = selectedList.ac_no;
        this.filters.partno = partno; // Assign the partno correctly
        this.filters.center_id = selectedList.center_id;
      }

      this.filters.wardNumberChangeTriggered = true;
      this.getAllVotersListForSectionWise();
    },

    getAllVcenterVoters() {
  this.isLoading = true;

  // Determine kendraNihayYadiNo to send, defaulting to the first vcenter in vcenterList if none is selected
  let kendraNihayYadiNo = ""; // Default to blank

  if (this.filters.kendraNihayYadiNo == "0") {
    // If "All" is selected, send blank
    kendraNihayYadiNo = "";
  } else {
    // Use the selected kendraNihayYadiNo or default to the first vcenter if vcenterList is not empty
    kendraNihayYadiNo = this.filters.kendraNihayYadiNo || 
      (this.vcenterList.length > 0 ? this.vcenterList[0].vcenter : "");
  }

  console.log("this.filters.acno");
  console.log(this.filters.acno);

  // Make the API call with the correct payload
  VotersSearchDataService.searchVcenterVoters(
    localStorage.getItem("defaultacno") || "", // Use ACNO or empty string
    this.filters.fullName || "", // Pass fullName or empty string
    kendraNihayYadiNo // Send the appropriate kendraNihayYadiNo
  )
    .then((response) => {
      this.voters = response.data.voterslist || []; // Handle empty list case

      // Sorting the voters list in ascending order by "slnoinpart"
      this.voters.sort((a, b) => a.slnoinpart - b.slnoinpart);

      this.isLoading = false;

      if (this.voters.length === 0) {
        console.log("Voters not found");
      }

      console.log("Fetched voters:", this.voters); // Debugging check
    })
    .catch((error) => {
      this.isLoading = false;
      this.voters = []; // Reset voters on error
      console.error("Error fetching voter data:", error);
    });
}
,

    // Open the candidate selection modal
    openPopup(familyMembers, votersList, id) {
      this.showPopup = true;
      this.selectedFamilyMembers = familyMembers;
      this.selectedVotersList = votersList;
      this.selectedVoterId = id;
      this.getCandidates(); // Fetch candidates when opening popup
    },

    // Close the popup
    closePopup() {
      this.showPopup = false;
      this.selectedCandidate = ""; // Reset candidate selection
    },

    // Submit the candidate selection and send the WhatsApp message
    submitCandidate() {
      if (this.userType === "1" && !this.selectedCandidate) {
        alert("Please select a candidate.");
        return;
      }

      const candidate =
        this.userType === "1"
          ? this.candidates.find((cand) => cand.id === this.selectedCandidate)
          : {}; // Skip candidate selection for userType = 2

      // Fetch the voting center location before sending the WhatsApp message
      EventDataService.votingCenterLocation(
        parseInt(this.filters.acno),
        parseInt(this.filters.wardNumber)
        // this.filters.wardNumber
      )
        .then((response) => {
          const locationData = response.data[0]; // Assuming the first result is the one you need
          const coordinates = locationData.voting_center_location.coordinates;
          const latitude = coordinates[1]; // Latitude
          const longitude = coordinates[0]; // Longitude
          var googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
          console.log("Voting center coordinates fetched:", {
            latitude,
            longitude,
          });

          // Now call sendWhatsAppMessage with the selected details
          this.sendWhatsAppMessage(
            this.selectedFamilyMembers,
            this.selectedVotersList,
            this.selectedVoterId,
            candidate?.f_name || "", // Use empty strings if no candidate is selected
            candidate?.l_name || "",
            googleMapsUrl
          );

          // Call the service function to update WhatsApp message send count
          return EventDataService.whatsAppMessageSendCount();
        })
        .then(() => {
          console.log("WhatsApp message count updated successfully.");
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        })
        .finally(() => {
          this.closePopup();
        });
    },
    // Check if a family member has a WhatsApp number
    hasWhatsAppNumber(familyMembers, voterListId) {
      return familyMembers.some(
        (member) =>
          member.phone_no &&
          member.phone_no != null &&
          member.voter_list_id == voterListId
      );
    },

    // Send WhatsApp message with candidate's name
    sendWhatsAppMessage(
      familyMembers = [],
      votersList = [],
      id,
      candidateFirstName,
      candidateLastName,
      googleMapsUrl
    ) {
      if (!Array.isArray(familyMembers) || familyMembers.length === 0) return;
      console.log(votersList);

      const organizationName = localStorage.getItem("organization_full_name");
      const userType = localStorage.getItem("installation_type");

      // Only include candidate's name if userType === 1
      const candidateName =
        userType === "1"
          ? `उमेदवार : ${candidateFirstName} ${candidateLastName}`
          : "";

      let voterDetails = null;
      let familyDetailsList = [];

      familyMembers.forEach((member) => {
        const details = member.voter_details;
        if (details.id === id) {
          voterDetails = details;
        } else {
          familyDetailsList.push(
            `नाव  : ${details.name}\n` +
              `वडिल/पती : ${details.rlname}\n` +
              `यादीभाग  : ${details.partno}\n` +
              `अनुक्रमांक : ${details.slnoinpart}\n` +
              `मतदान कार्ड : ${details.idcardno}\n` +
              `मतदान केंद्र : ${details.vcenter}\n` +
              `------------------------`
          );
        }
      });

      if (!voterDetails && familyMembers.length > 0) {
        voterDetails = familyMembers[0].voter_details;
      }

      const familyDetails = familyDetailsList.length
        ? familyDetailsList.join("\n")
        : "No family members available.";

      const message = `${googleMapsUrl}
-----Voter Slip-----

संस्था : ${organizationName}
${candidateName}

Your Voter Details
----------Voter Details----------

नाव  : ${voterDetails.name}
वडिल/पती : ${voterDetails.rlname}
यादीभाग  : ${voterDetails.partno}
अनुक्रमांक : ${voterDetails.slnoinpart}
मतदान कार्ड : ${voterDetails.idcardno}
मतदान केंद्र : ${voterDetails.vcenter}

Your Family Slip 
----------Family Details----------
${familyDetails}`;

      const memberWithPhone = familyMembers.find(
        (member) => member.phone_no && member.phone_no !== "NULL"
      );

      if (memberWithPhone) {
        const phoneNumber = memberWithPhone.phone_no.replace(/[^0-9]/g, "");
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
          message
        )}`;
        window.open(whatsappUrl, "_blank");
      } else {
        console.error("No family member with a valid phone number.");
      }
    },
    getAllVotersGroupList() {
      VoterGroupsDataService.getAll()
        .then((response) => {
          console.log(response.data.voter_group);
          this.votersGroups = response.data.voter_group;
          console.log(this.votersGroups);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllCategoryCaste() {
      ReligionCategoryDataService.getAll()
        .then((response) => {
          console.log(response.data.voterslist);
          this.categoryCaste = response.data;
          console.log(this.voters);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllReligion() {
      ReligionDataService.getAll()
        .then((response) => {
          console.log(response.data.voterslist);
          this.religionList = response.data;
          console.log(this.voters);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllVotingCenterVoiceList() {
      this.resetFilters();
      // VotersSearchDataService.getAllVoters()
      //   .then(response => {
      //     this.voters = response.data.voterslist;
      //     // this.kendraNihayYadiNo = [...new Set(this.voters.map(event => event.vcenter))];
      //     // this.kendraNihayBhag = [...new Set(this.voters.map(event => event.section))];
      //   })
      //   .catch(error => {
      //     console.error('Error fetching Voting Center Voice data:', error);
      //   });
    },
    getAllSectionVoiceList() {
      this.resetFilters();
      //this.voters = [];
      this.filters.acno = 35;
      console.log(this.filters);
      // VotersSearchDataService.searchAcnoVoters()
      //   .then(response => {
      //     this.voters = response.data.voterslist;
      //     this.uniqueWardAreas = [...new Set(this.voters.map(event => event.prabhag))];
      //     this.uniqueWardNumbers = [...new Set(this.voters.map(event => event.partno))];
      //       console.log('Unique Ward Areas:', this.uniqueWardAreas);
      //       console.log('Unique Ward Numbers:', this.uniqueWardNumbers);
      //   })
      //   .catch(error => {
      //     console.error('Error fetching Section Voice data:', error);
      //   });
    },
    // resetFilters() {
    //   this.filters = {
    //     fullName: "",
    //     wardArea: "",
    //     section: "",
    //     wardNumber: "",
    //     kendraNihayBhag: "",
    //     kendraNihayYadiNo: "",
    //     acno: "",
    //   };
    // },
    resetFilters() {
      this.filters = {
        acno: "",
        fullName: "",
        wardNumber: "",
        wardArea: "",
        section: "",
        location_name: "",
        kendraNihayBhag: "",
        kendraNihayYadiNo: "",
      };
      if (this.partnoList.length > 0 ? this.partnoList[0].partno : "") {
        this.filters.wardNumber = this.partnoList[0].partno;
      }
      this.selectedWardNumber = "";
      this.voters = [];
      this.currentPage = 1;
    },
    navigateToAddEvent() {
      this.$router.push("/add-event");
    },
    navigateToEditEvent(id) {
      this.$router.push(`/edit-event/${id}`);
    },
    nextPage() {
      if (
        this.currentPage < Math.ceil(this.voters.length / this.itemsPerPage)
      ) {
        this.currentPage++;
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  computed: {
    paginatedEvents() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.voters.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.voters.length / this.itemsPerPage);
    },
    filteredEvents() {
      console.log("Filters:", this.filters);
      const fullNameFilterTokens = this.filters.fullName
        .toLowerCase()
        .split(" ")
        .filter(Boolean);

      const filtered = this.voters.filter((event) => {
        const fullName = `${event.enfname.toLowerCase()} ${event.enlname.toLowerCase()}`;
        const matchesFullName = fullNameFilterTokens.every(
          (token) =>
            event.enfname.toLowerCase().includes(token) ||
            event.enlname.toLowerCase().includes(token) ||
            fullName.includes(token)
        );
        const matchesWardArea = this.filters.wardArea
          ? event.prabhag === this.filters.wardArea
          : true;
        const matchesWardNumber = this.filters.wardNumber
          ? event.partno === this.filters.wardNumber
          : true;
        const kendraNihayBhag = this.filters.kendraNihayBhag
          ? event.section === this.filters.kendraNihayBhag
          : true;
        const kendraNihayYadiNo = this.filters.kendraNihayYadiNo
          ? event.vcenter === this.filters.kendraNihayYadiNo
          : true;
        const acnoMatch = this.filters.acno
          ? event.acno === this.filters.acno
          : true;

        return (
          matchesFullName &&
          matchesWardArea &&
          matchesWardNumber &&
          kendraNihayYadiNo &&
          kendraNihayBhag &&
          acnoMatch
        );
      });

      console.log("Filtered Events:", filtered);
      return filtered;
    },

    paginatedVcenter() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.voters.slice(start, start + this.itemsPerPage);
    },

    uniqueWardAreas() {
      return [...new Set(this.voters.map((event) => event.prabhag))];
    },
    uniqueAcno() {
      return [...new Set(this.voters.map((event) => event.acno))];
    },

    uniqueWardNumbers() {
      return [...new Set(this.voters.map((event) => event.partno))];
    },
    uniqueKendraNihayYadiNo() {
      return [...new Set(this.voters.map((event) => event.vcenter))];
    },
    uniqueKendraNihayYadiBhag() {
      return [...new Set(this.voters.map((event) => event.section))];
    },
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}
.custom-modal-dialog {
  max-width: 76%; /* Adjust this percentage or use pixels for a specific width */
  margin: 1.75rem auto; /* Adjust margin if needed */
  z-index: 1050; /* Ensure this is higher than other elements */
  margin-left: 300px;
}
@media (min-width: 300px) and (max-width: 500px) {
  .custom-modal-dialog {
    max-width: 76%; /* Adjust this percentage or use pixels for a specific width */
    margin: 20px auto; /* Adjust margin if needed */
    z-index: 1050;
  }
}
.modal-backdrop.show {
  z-index: 1040; /* Ensure the backdrop is below the modal */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  /* Ensure background color matches */
  padding-right: 2rem;
  /* Add padding for the custom arrow */
  border: 1px solid #ced4da;
  /* Match border style */
  border-radius: 0.25rem;
  /* Optional: to match form styling */
}

.select-wrapper::after {
  content: "\25BC";
  /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057;
  /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevent clicking on the arrow */
}

.langdrop {
  width: 130px;
  margin-left: 87%;
}

@media (min-width: 300px) and (max-width: 550px) {
  .langdrop {
    width: 130px;
    margin-left: 57%;
  }
}

.fa-edit,
.fa-trash-alt {
  font-size: 1rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.nav-pills .nav-link {
  cursor: pointer;
}

.nav-pills .nav-link.active {
  background-color: #141727;
  color: white;
  border: 1px solid #141727;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  margin-bottom: 1rem;
}

.btn-primary {
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
}

.nav-pills {
  display: flex;
  flex-direction: column;
}

.nav-pills .nav-item {
  margin-bottom: 10px;
}

.nav-pills .nav-item .nav-link {
  text-align: center;
  padding: 10px;
}

.nav-pills .nav-link.active {
  background-color: #141727;
  color: white;
}

.table-responsive {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .nav-pills {
    flex-direction: row;
  }

  .nav-pills .nav-item {
    margin-bottom: 0;
  }

  .nav-item.ml-md-3 {
    margin-left: 15px;
  }

  .col-md-4.mb-3.mb-md-0 {
    margin-bottom: 0 !important;
  }
}
/* Add some basic styling for the loader */
.loader {
  position: relative; /* Position relative to its wrapper */
  margin: auto;
  padding: 50px;
  font-size: 1.5em;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
/* Fullscreen overlay for popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content box */
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  position: relative;
}

/* Close button (cross) */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: red;
}
</style>
