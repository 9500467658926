import { createRouter, createWebHistory } from "vue-router";
import process from "process";
import Dashboard from "@/views/Dashboard.vue";
import InstallationStep from "@/views/InstallationStep.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import UserRegister from "@/views/UserRegister.vue";
import Questions from "@/views/Questions.vue";
import Office from "@/views/Office.vue";
import Master from "@/views/Master.vue";
import Material from "@/views/Material.vue";
import MaterialType from "@/views/MaterialType.vue";
import Vehicle from "@/views/Vehicle.vue";
import Events from "@/views/Events.vue";
import Voters from "@/views/Voters.vue";
import Survey from "@/views/Survey.vue";
import AddSurvey from "@/views/AddSurvey.vue";
import SurveyQuestions from "@/views/SurveyQuestions.vue";
import Roles from "@/views/Roles.vue";
import Religions from "@/views/Religion.vue";
import ReligionCategory from "@/views/ReligionCategory.vue";
import Candidates from "@/views/Candidates.vue";
import Users from "@/views/Users.vue";
import Task from "@/views/Task.vue";
import Alphabetically from "@/views/Alphabetically.vue";
import ReportByAge from "@/views/ReportByAge.vue";
import ReportByAlign from "@/views/ReportByAlign.vue";
import AddTask from "@/views/AddTask.vue";
import AddEvent from "@/views/AddEvent.vue";
import EditEvent from "@/views/EditEvent.vue";
import AddVehicle from "@/views/AddVehicle.vue";
import EditCandidate from "@/views/EditCandidate.vue";
import EditUser from "@/views/EditUser.vue";
import EditTask from "@/views/EditTask.vue";
import RequestVehicle from "@/views/RequestVehicle.vue";
import Team from "@/views/Team.vue";
import Area from "@/views/Area.vue";
import VotingCenterLocation from "@/views/VotingCenterLocation.vue";
import EditVotingCenterLocation from "@/views/EditVotingCenterLocation.vue";
import Stock from "@/views/Stock.vue";
import Voterslist from "@/views/Voterslist.vue";
import AreaEdit from "@/views/AreaEdit.vue";
import AddVotersGroup from "@/views/AddVotersGroup.vue";
import EditVotersGroup from "@/views/EditVotersGroup.vue";
import AddOffice from "@/views/AddOffice.vue";
import EditOffice from "@/views/EditOffice.vue";
import EditSurvey from "@/views/EditSurvey.vue";
import stockRequest from "@/views/stockRequest.vue";
import VoterSearch from "@/views/VoterSearch.vue";
import Categories from "@/views/Categories.vue";
import SocialMediaPostAdd from "@/views/SocialMediaPostAdd.vue";
import SocialMediaPost from "@/views/SocialMediaPost.vue";
import TopVotingCenter from "@/views/TopVotingCenter.vue";
import LeastVotingCenter from "@/views/LeastVotingCenter.vue";
import EventsReport from "@/views/EventsReport.vue";
import SocialMediaPostReport from "@/views/SocialMediaPostReport.vue";
import TaskReport from "@/views/TaskReport.vue";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/installation/:step",
    name: "Installation-Step",
    component: InstallationStep,
    props: route => ({ step: Number(route.params.step) }),
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/user-register",
    name: "User Register",
    component: UserRegister,
    meta: { requiresAuth: true }
  },
  {
    path: "/voter-search",
    name: "Voter Search",
    component: VoterSearch,
    meta: { requiresAuth: true }
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta: { requiresAuth: true }
  },
  {
    path: "/candidates",
    name: "Candidates",
    component: Candidates,
    meta: { requiresAuth: true }
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { requiresAuth: true }
  },
  {
    path: "/questions",
    name: "Questions",
    component: Questions,
    meta: { requiresAuth: true }
  },
  {
    path: "/survey",
    name: "Survey",
    component: Survey,
    meta: { requiresAuth: true }
  },
  {
    path: "/report-top-center",
    name: "Top Voting Center",
    component: TopVotingCenter,
    meta: { requiresAuth: true }
  },
  {
    path: "/report-least-center",
    name: "Least Voting Center",
    component: LeastVotingCenter,
    meta: { requiresAuth: true }
  },
  {
    path: "/report-events",
    name: "Events Report",
    component: EventsReport,
    meta: { requiresAuth: true }
  },
  {
    path: "/report-task",
    name: "Task Report",
    component: TaskReport,
    meta: { requiresAuth: true }
  },
  {
    path: "/report-social-media-post",
    name: "Social media post",
    component: SocialMediaPostReport,
    meta: { requiresAuth: true }
  },
  {
    path: "/category",
    name: "Categories",
    component: Categories,
    meta: { requiresAuth: true }
  },
  {
    path: "/office",
    name: "Office",
    component: Office,
    meta: { requiresAuth: true }
  },
  {
    path: "/resources",
    name: "Material",
    component: Material,
    meta: { requiresAuth: true }
  },
  {
    path: "/resources-type",
    name: "MaterialType",
    component: MaterialType,
    meta: { requiresAuth: true }
  },
  {
    path: "/vehicle",
    name: "Vehicle",
    component: Vehicle,
    meta: { requiresAuth: true }
  },
  {
    path: "/request_vehicle",
    name: "Request-Vehicle",
    component: RequestVehicle,
    meta: { requiresAuth: true }
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
    meta: { requiresAuth: true }
  },
  {
    path: "/voters-group",
    name: "Voters",
    component: Voters,
    meta: { requiresAuth: true }
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: { requiresAuth: true }
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: "/social-media-post",
    name: "Social Media Post",
    component: SocialMediaPost,
    meta: { requiresAuth: true }
  },
  {
    path: "/social-media-post-add",
    name: "Social Media Post Add",
    component: SocialMediaPostAdd,
    meta: { requiresAuth: true }
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
    meta: { requiresAuth: true }
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn
  },
  {
    path: "/add-Survey",
    name: "Add Survey",
    component: AddSurvey,
    meta: { requiresAuth: true }
  },
  {
    path: "/edit-Survey/:id",
    name: "Edit Survey",
    component: EditSurvey,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-office",
    name: "Add office",
    component: AddOffice,
    meta: { requiresAuth: true }
  },
  {
    path: "/edit-office/:id",
    name: "Edit office",
    component: EditOffice,
    meta: { requiresAuth: true }
  },
  {
    path: "/survey-questions/:id",
    name: "Survey Questions",
    component: SurveyQuestions,
    meta: { requiresAuth: true }
  },
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
    meta: { requiresAuth: true }
  },
  {
    path: "/alphabetically",
    name: "Alphabetically",
    component: Alphabetically,
    meta: { requiresAuth: true }
  },
  {
    path: "/report-by-age",
    name: "Report By Age",
    component: ReportByAge,
    meta: { requiresAuth: true }
  },
  {
    path: "/report-by-align",
    name: "Report By Alignment",
    component: ReportByAlign,
    meta: { requiresAuth: true }
  },
  {
    path: "/religion",
    name: "Religions",
    component: Religions,
    meta: { requiresAuth: true }
  },
  {
    path: "/master",
    name: "Master",
    component: Master,
    meta: { requiresAuth: true }
  },
  {
    path: "/religions-category",
    name: "ReligionCategory",
    component: ReligionCategory,
    meta: { requiresAuth: true }
  },
  {
    path: "/task",
    name: "Task",
    component: Task,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-task",
    name: "Add Task",
    component: AddTask,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-event",
    name: "Add Event",
    component: AddEvent,
    meta: { requiresAuth: true }
  },
  {
    path: "/edit-event/:id",
    name: "Edit Event",
    component: EditEvent,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-vehicle",
    name: "Add Vehicle",
    component: AddVehicle,
    meta: { requiresAuth: true }
  },
  {
    path: "/stock-request",
    name: "Stock Request",
    component: stockRequest,
    meta: { requiresAuth: true }
  },
  {
    path: "/edit-candidate/:id",
    name: "Edit Candidate",
    component: EditCandidate,
    meta: { requiresAuth: true }
  },
  {
    path: "/edit-user/:id",
    name: "Edit User",
    component: EditUser,
    meta: { requiresAuth: true }
  },
  // {
  //   path: "/super-admin/admin/edit/:id",
  //   name: "Edit User",
  //   component: EditUser,
  //   meta: { requiresAuth: true }
  // },
  {
    path: "/edit-task/:id",
    name: "Edit Task",
    component: EditTask,
    meta: { requiresAuth: true }
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: { requiresAuth: true }
  },
  {
    path: "/area",
    name: "Area",
    component: Area,
    meta: { requiresAuth: true }
  },
  {
    path: "/voting-center-location",
    name: "Voting Center Location",
    component: VotingCenterLocation,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-voting-center/:center_id',
    name: 'Edit Voting Center Location',
    component: EditVotingCenterLocation,
    props: true, // Allow params to be passed as props
  }
,  
  {
    path: "/stock",
    name: "Stock",
    component: Stock,
    meta: { requiresAuth: true }
  },
  {
    path: "/voter-slist",
    name: "Voters List",
    component: Voterslist,
    meta: { requiresAuth: true }
  },
  {
    path: "/edit-area/:id",
    name: "Area Edit",
    component: AreaEdit,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-voters-group",
    name: "Add Voters Group",
    component: AddVotersGroup,
    meta: { requiresAuth: true }
  },
  {
    path: "/edit-voters-group/:id",
    name: "Edit Voters Group",
    component: EditVotersGroup,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// Modify the beforeEach hook to check authentication
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next('/sign-in');
    } else {
      next();
    }
  } else if (to.path === '/sign-in' && token) {
    next('/dashboard');
  } else {
    next();
  }
});

export default router;

