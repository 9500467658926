<template>
  <div class="container mb-4">
    <!-- Header Section -->
    <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="mx-auto text-center col-lg-8">
            <h1 class="mt-5 mb-2 text-white">{{ $t("welcome") }}</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Form Section -->
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10">
        <div class="mx-auto col-xl-8 col-lg-9 col-md-10">
          <div class="card z-index-0">
            <div class="pt-4 card-header">
              <h5>{{ $t("register_with") }}</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent="addCandidates">
                <div class="row mb-3">
                  <div
                    class="col-md-6"
                    v-if="installationType === '1' && step == 4"
                  >
                    <div class="form-group">
                      <label for="candidate_id" class="form-control-label">
                        {{ $t("Candidate") }}
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.candidate_id"
                          id="candidate_id"
                          class="form-control select-with-arrow"
                          aria-label="Candidate"
                        >
                          <option value="" disabled selected>
                            {{ $t("select_candidate") }}
                          </option>
                          <option
                            v-for="candidate in candidates"
                            :key="candidate.id"
                            :value="candidate.id"
                          >
                            {{ candidate.f_name }} {{ candidate.l_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="reporting_to" class="form-control-label">
                        {{ $t("reporting_to") }}
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.reporting_to"
                          id="reporting_to"
                          class="form-control select-with-arrow"
                          aria-label="Reporting To"
                        >
                          <option value="" disabled selected>
                            {{ $t("select_reporting_to") }}
                          </option>
                          <option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                          >
                            {{ user.f_name }} {{ user.l_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- First Name Field -->
                  <div class="col-md-6">
                    <label for="f_name" class="form-control-label">
                      {{ $t("first_name") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.f_name"
                      id="f_name"
                      type="text"
                      :placeholder="$t('first_name_placeholder')"
                      aria-label="First Name"
                      icon="mdi-account"
                    />
                    <span v-if="errors.f_name" class="error">{{
                      errors.f_name
                    }}</span>
                  </div>

                  <!-- Last Name Field -->
                  <div class="col-md-6">
                    <label for="l_name" class="form-control-label">
                      {{ $t("last_name") }}
                    </label>
                    <soft-input
                      v-model="newCandidate.l_name"
                      id="l_name"
                      type="text"
                      :placeholder="$t('last_name_placeholder')"
                      aria-label="Last Name"
                      icon="mdi-account-outline"
                    />
                  </div>

                  <!-- Phone Number Field -->
                  <div class="col-md-6">
                    <label for="phone_no" class="form-control-label">
                      {{ $t("phone_number") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.phone_no"
                      id="phone_no"
                      type="text"
                      :placeholder="$t('phone_number_placeholder')"
                      aria-label="Phone Number"
                      icon="mdi-phone"
                      @input="prependCountryCode"
                    />
                    <span v-if="errors.phone_no" class="error">{{
                      errors.phone_no
                    }}</span>
                  </div>

                  <!-- Role Field -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="role_id" class="form-control-label">
                        {{ $t("role") }} <span style="color: red">*</span>
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.role_id"
                          id="role_id"
                          class="form-control select-with-arrow"
                          aria-label="Role"
                          required
                          @change="onRoleChange"
                        >
                          <option value="" disabled selected>
                            {{ $t("select_role") }}
                          </option>
                          <option
                            v-for="role in filteredRoles"
                            :key="role.id"
                            :value="role.id"
                          >
                            {{ role.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Email Field -->
                  <div class="col-md-4" v-if="showEmailAndPasswordFields">
                    <label for="email" class="form-control-label">
                      {{ $t("email") }}
                    </label>
                    <soft-input
                      v-model="newCandidate.email"
                      id="email"
                      type="email"
                      :placeholder="$t('email_placeholder')"
                      aria-label="Email"
                      icon="mdi-email"
                    />
                  </div>

                  <!-- Password Field -->
                  <div class="col-md-4" v-if="showEmailAndPasswordFields">
                    <label for="password" class="form-control-label">
                      {{ $t("password") }} <span style="color: red">*</span>
                    </label>
                    <soft-input
                      v-model="newCandidate.password"
                      id="password"
                      type="password"
                      :placeholder="$t('password_placeholder')"
                      aria-label="Password"
                      icon="mdi-lock"
                    />
                  </div>
                  <div class="col-md-4" v-if="showPartFields">
                    <div class="form-group">
                      <label for="part_no" class="form-control-label">
                        {{ $t("Part no") }}
                      </label>
                      <multiselect
                        v-model="newCandidate.partnoList"
                        :options="availablePartno"
                        :multiple="true"
                        :track-by="'value'"
                        label="resource_name"
                        placeholder="Select partno"
                      >
                      </multiselect>
                    </div>
                  </div>

                  <!-- Gender Field -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="gender" class="label-text">
                        {{ $t("gender") }} <span class="required">*</span>
                      </label>
                      <div class="radio-group">
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="male"
                            name="gender"
                            value="1"
                          />
                          <label for="male" class="radio-label">
                            {{ $t("male") }}
                          </label>
                        </div>
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="female"
                            name="gender"
                            value="2"
                          />
                          <label for="female" class="radio-label">
                            {{ $t("female") }}
                          </label>
                        </div>
                        <div class="radio-option">
                          <input
                            type="radio"
                            v-model="newCandidate.gender"
                            id="other"
                            name="gender"
                            value="3"
                          />
                          <label for="other" class="radio-label">
                            {{ $t("other") }}
                          </label>
                        </div>
                      </div>
                      <span v-if="errors.gender" class="error">{{
                        errors.gender
                      }}</span>
                    </div>
                  </div>

                  <!-- Office Field -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="office" class="form-control-label">
                        {{ $t("office") }} <span style="color: red">*</span>
                      </label>
                      <div class="select-wrapper">
                        <select
                          v-model="newCandidate.office"
                          id="office"
                          class="form-control select-with-arrow"
                          aria-label="Office"
                        >
                          <option value="" disabled selected>
                            {{ $t("select_office") }}
                          </option>
                          <option
                            v-for="office in offices"
                            :key="office.id"
                            :value="office.id"
                          >
                            {{ office.office_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Special Note Field -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="special_note" class="form-control-label">
                        {{ $t("special_note") }}
                      </label>
                      <textarea
                        v-model="newCandidate.special_note"
                        id="special_note"
                        class="form-control"
                        :placeholder="$t('special_note_placeholder')"
                        aria-label="Special Note"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>

                  <!-- Aadhaar Upload Field -->
                  <div class="col-md-4">
                    <div class="form-group text-center">
                      <label for="aadhaar" class="form-control-label">
                        {{ $t("aadhaar_upload") }}
                      </label>
                      <div>
                        <img
                          :src="aadhaarPhotoUrl"
                          alt="Aadhaar Card"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerAadhaarFileInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="aadhaar"
                        ref="aadhaarFileInput"
                        class="d-none"
                        aria-label="Aadhaar Upload"
                        @change="onAadhaarChange"
                      />
                    </div>
                  </div>

                  <!-- Profile Picture Upload Field -->
                  <div class="col-md-4">
                    <div class="form-group text-center">
                      <label for="profile_photo" class="form-control-label">
                        {{ $t("profile_picture") }}
                      </label>
                      <div>
                        <img
                          :src="profilePhotoUrl"
                          alt="Profile Picture"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerProfileFileInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="profile_photo"
                        ref="profileFileInput"
                        class="d-none"
                        aria-label="Profile Picture"
                        @change="onProfilePhotoChange"
                      />
                    </div>
                  </div>
                  <div class="col-md-4" v-if="showSlipImageField">
                    <div class="form-group text-center">
                      <label for="slip_image" class="form-control-label">
                        {{ $t("Slip Image") }}
                      </label>
                      <div>
                        <img
                          :src="SlipImagePhotoUrl"
                          alt="Slip Image"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerSlipImageFileInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="slip_image"
                        ref="SlipImageFileInput"
                        class="d-none"
                        aria-label="Slip Image"
                        @change="onSlipImageChange"
                      />
                    </div>
                  </div>

                  <!-- Terms and Conditions -->
                  <div class="col-md-6 mb-3">
                    <soft-checkbox
                      id="flexCheckDefault"
                      name="flexCheckDefault"
                      class="font-weight-light"
                      checked
                    >
                      {{ $t("agree_terms") }}
                      <a
                        href="javascript:;"
                        class="text-dark font-weight-bolder"
                      >
                        {{ $t("terms_and_conditions") }}
                      </a>
                    </soft-checkbox>
                  </div>
                  <div class="col-md-6 mb-3" v-if="showfortUserFields">
                    <soft-checkbox
                      id="flexCheckDefault"
                      name="flexCheckDefault"
                      class="font-weight-light"
                      :checked="newCandidate.phone_visibility === 1"
                      @change="handlePhoneVisibilityChange"
                    >
                      <a
                        href="javascript:;"
                        class="text-dark font-weight-bolder"
                      >
                        {{ $t("Phone Number Visible for Everyone!") }}
                      </a>
                    </soft-checkbox>
                  </div>
                </div>

                <!-- Submit Button -->
                <div class="text-center">
                  <soft-button
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                    :disabled="isLoading"
                    :loading="isLoading"
                  >
                    {{ $t("sign_up") }}
                  </soft-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SoftInput from "@/components/SoftInput.vue";
import profilePhotoUrl from "@/assets/img/profile.png";
import SlipImagePhotoUrl from "@/assets/img/slipimage.png";
import aadhaarPhotoUrl from "@/assets/img/adhaar.png";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import Swal from "sweetalert2";
import UsersDataService from "../services/UsersDataService.js";
import OfficesDataService from "../services/OfficesDataService.js";
import RolesDataService from "../services/RolesDataService.js";
import CandidateDataService from "../services/CandidateDataService.js";
import VotersSearchDataService from "../services/VotersSearchDataService.js";
// import { handleApiErrors } from "@/utils/errorHandler";
import Multiselect from "vue-multiselect";

export default {
  name: "SignupBasic",
  components: {
    SoftInput,
    SoftCheckbox,
    SoftButton,
    Multiselect,
  },
  data() {
    return {
      step: localStorage.getItem("steps"),
      phone_visibility: localStorage.getItem("phone_visibility"),
      users: [],
      candidates: [],
      offices: [],
      availablePartno: [],
      roles: [],
      installationType: null,
      isLoading: false,
      newCandidate: {
        installation_type: "",
        org_id: localStorage.getItem("orgId"),
        reporting_to: "",
        candidate_id: "",
        f_name: "",
        l_name: "",
        phone_no: "",
        email: "",
        password: "",
        gender: "",
        office: "",
        role_id: "",
        special_note: "",
        profile_picture: "",
        adharcard_picture: "",
        slip_image: "",
        partnoList: [], // Make sure this is initialized as an empty array
        phone_visibility:
          parseInt(localStorage.getItem("phone_visibility"), 10) || 0,
      },
      selectedLanguage: this.$i18n.locale,
      errors: {},
      profilePhotoUrl,
      aadhaarPhotoUrl,
      SlipImagePhotoUrl,
    };
  },

  computed: {
    showEmailAndPasswordFields() {
      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );
      return selectedRole && selectedRole.type !== undefined
        ? selectedRole.type !== 2
        : true;
    },
    showPartFields() {
      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );
      return selectedRole && selectedRole.type !== undefined
        ? selectedRole.type === 2
        : true;
    },
    showfortUserFields() {
      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );
      return selectedRole && selectedRole.type !== undefined
        ? selectedRole.type === 2
        : false;
    },
    filteredRoles() {
      const steps = parseInt(localStorage.getItem("steps"), 10);
      if (steps === 3) {
        return this.roles.filter((role) => role.name === "Candidate");
      }
      return this.roles;
    },
    showSlipImageField() {
      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );
      return selectedRole ? selectedRole.name === "Candidate" : false;
    },
  },
  created() {
    this.getUsers();
    this.getCandidates();
    this.getOffices();
    this.getRoles();
    this.getAllPartno();
  },
  methods: {
    getAllPartno() {
      const defaultacno = localStorage.getItem("defaultacno");

      if (!defaultacno) {
        console.error("No acno found in localStorage");
        return;
      }

      VotersSearchDataService.getAllPartno(defaultacno)
        .then((response) => {
          if (response.data && response.data.partnolist) {
            // Sort the partnolist in ascending order based on partno
            const sortedPartnoList = response.data.partnolist.sort(
              (a, b) => a.partno - b.partno
            );

            // Map the sorted list to the required format, including both partno and area_name for display
            this.availablePartno = sortedPartnoList.map((item) => ({
              resource_name: `${item.partno} - ${item.area_name}`, // Concatenate partno and area_name
              value: item.partno, // Keep partno as the value for payload
            }));
          } else {
            this.availablePartno = []; // Fallback to empty array if no data
            console.warn("No partnolist in response");
          }
        })
        .catch((error) => {
          this.availablePartno = []; // Ensure it's an array even in case of error
          console.error("Error voter info add:", error);
        });
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    getUsers() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getCandidates() {
      UsersDataService.getAdminCandidate()
        .then((response) => {
          this.candidates = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getOffices() {
      OfficesDataService.getAll()
        .then((response) => {
          this.offices = response.data.offices;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getRoles() {
      RolesDataService.getAll()
        .then((response) => {
          this.roles = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    triggerAadhaarFileInput() {
      this.$refs.aadhaarFileInput.click();
    },
    triggerProfileFileInput() {
      this.$refs.profileFileInput.click();
    },
    triggerSlipImageFileInput() {
      this.$refs.SlipImageFileInput.click();
    },
    onProfilePhotoChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          this.profilePhotoUrl = base64Data;
          this.newCandidate.profile_picture = base64Data;
        };
        reader.readAsDataURL(file);
      }
    },
    onAadhaarChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          this.aadhaarPhotoUrl = base64Data;
          this.newCandidate.adharcard_picture = base64Data;
        };
        reader.readAsDataURL(file);
      }
    },
    onSlipImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          this.SlipImagePhotoUrl = base64Data;
          this.newCandidate.slip_image = base64Data;
        };
        reader.readAsDataURL(file);
      }
    },
    onRoleChange() {
      if (!this.showEmailAndPasswordFields) {
        this.newCandidate.email = "";
        this.newCandidate.password = "";
      }
    },
    prependCountryCode() {
      this.newCandidate.phone_no = this.newCandidate.phone_no.replace(
        /[^\d]/g,
        ""
      );

      if (
        this.newCandidate.phone_no &&
        !this.newCandidate.phone_no.startsWith("91")
      ) {
        this.newCandidate.phone_no = "91" + this.newCandidate.phone_no;
      }
      this.newCandidate.phone_no = this.newCandidate.phone_no.slice(0, 12);

      this.newCandidate.phone_no = "+" + this.newCandidate.phone_no;

      if (this.newCandidate.phone_no.length !== 13) {
        this.errors.phone_no = this.$t("phone_number_must_be_10_digits");
      } else {
        this.errors.phone_no = "";
      }
    },
    handlePhoneVisibilityChange(event) {
      this.newCandidate.phone_visibility = event.target.checked ? 1 : 0;
      localStorage.setItem(
        "phone_visibility",
        this.newCandidate.phone_visibility
      );
    },

    addCandidates() {
      this.isLoading = true; // Start loading state

      // Debugging: Log selected part numbers
      console.log("Selected partnoList:", this.newCandidate.partnoList); // Check selected partnos

      const selectedRole = this.roles.find(
        (role) => role.id === this.newCandidate.role_id
      );

      // Construct the payload
      const payload = {
        installation_type: localStorage.getItem("installation_type"),
        org_id: this.newCandidate.org_id,
        candidate_id:
          localStorage.getItem("installation_type") == 1
            ? this.newCandidate.candidate_id
            : localStorage.getItem("candidateId"),
        reporting_to: this.newCandidate.reporting_to,
        f_name: this.newCandidate.f_name,
        l_name: this.newCandidate.l_name,
        phone_no: this.newCandidate.phone_no,
        email: this.newCandidate.email,
        password: this.newCandidate.password,
        gender: this.newCandidate.gender,
        office: this.newCandidate.office,
        role_id: this.newCandidate.role_id,
        special_note: this.newCandidate.special_note,
        profile_picture: this.newCandidate.profile_picture,
        adharcard_picture: this.newCandidate.adharcard_picture,
        slip_image: this.newCandidate.slip_image,
        partno_list:
          this.newCandidate.partnoList.length > 0 // Only include if there are selected partnos
            ? JSON.stringify(
                this.newCandidate.partnoList.map((part) => part.value)
              )
            : "[]", // Send empty array if no part numbers selected
      };

      // Debugging: Log the constructed payload
      console.log("Payload before sending:", payload);

      // Optionally add phone visibility to payload based on selected role
      if (selectedRole && selectedRole.type === 2) {
        payload.phone_visibility = this.newCandidate.phone_visibility;
      }

      // Send the payload to the API
      CandidateDataService.create(payload)
        .then((response) => {
          console.log(response); // Log the response
          const steps = parseInt(localStorage.getItem("steps"), 10);

          if (steps < 4) {
            const updatedStep = steps + 1;

            const updatePayload = {
              admin_id: localStorage.getItem("admin_id"),
              step: updatedStep,
              candidate_id: response.data.candidate.id,
            };

            return UsersDataService.updateStep(updatePayload).then(
              () => updatedStep
            );
          } else {
            // Show success message for Office and redirect to /candidates
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Registered successfully!",
              confirmButtonText: "OK",
            }).then(() => {
              this.$router.push(`/candidates`);
            });

            return Promise.resolve(steps); // No step update
          }
        })
        .then((updatedStep) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Registered successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            localStorage.setItem("steps", updatedStep);
            window.location.href = `/dashboard`;
          });
        })
        .catch((e) => {
          console.log(e); // Log any errors

          // Handle error response from the API
          if (e.response && e.response.data && e.response.data.errors) {
            const errorMessages = e.response.data.errors
              .map((error) => `${error.message}`)
              .join("\n");
            Swal.fire({
              icon: "error",
              title: "Error",
              text:
                errorMessages ||
                "There was an error registering the candidate.",
              confirmButtonText: "OK",
            });
          } else {
            // Fallback error message if the response structure is not as expected
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "There was an error registering the candidate.",
              confirmButtonText: "OK",
            });
          }
        })
        .finally(() => {
          this.isLoading = false; // Stop loading state
        });
    },
  },
  mounted() {
    console.log("Initial partnoList:", this.newCandidate.partnoList); // Should log []
    this.installationType = localStorage.getItem("installation_type");
    if (sessionStorage.getItem("reloadDashboard") === "true") {
      sessionStorage.removeItem("reloadDashboard");
      window.location.reload();
    }
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  padding-right: 2rem;
  border: 1px solid #ced4da;
}

.select-wrapper::after {
  content: "\25BC";
  font-size: 0.8rem;
  color: #495057;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.error {
  color: red;
  font-size: 13px;
  font-family: "sans-serif";
  font-weight: bold;
}

.label-text {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.required {
  color: red;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.radio-option {
  display: flex;
  align-items: center;
}

.radio-label {
  font-size: 14px;
  margin-left: 0.5rem;
  cursor: pointer;
  margin-top: 7px;
}

input[type="radio"] {
  margin-right: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.img-fluid {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.text-center {
  text-align: center;
}
.readonly-input {
  background-color: #f5f5f5;
  color: #666666;
  pointer-events: none;
  opacity: 1;
}
@media (max-width: 576px) {
  .select-wrapper,
  .form-group,
  .radio-group {
    width: 100%;
  }
}
</style>
