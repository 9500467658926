import http from '../http-common';

class VehicleDataService {
  getAll() {
    return http.get("/vehicles");
  }

  get(id) {
    return http.get(`/vehicles/${id}`);
  }

  create(data) {
    return http.post("/vehicles/add", data);
  }

  update(data) {
    return http.put(`/vehicles/update`, data);
  }

  delete(id) {
    return http.put(`/vehicles/delete/${id}`);
  }
  assignUserToVehicle(data) {
    return http.post(`/vehicles/assign`, data);
  }
  assignTeamToVehicle(data) {
    return http.post(`/vehicles/assign`, data);
  }
}

export default new VehicleDataService();
