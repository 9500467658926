<template>
  <div ref="mapContainer" class="google-map-container"></div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    coordinates: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const map = new google.maps.Map(this.$refs.mapContainer, {
        center: { lat: this.coordinates[1], lng: this.coordinates[0] },
        zoom: 15
      });

      new google.maps.Marker({
        position: { lat: this.coordinates[1], lng: this.coordinates[0] },
        map: map,
        title: "Vehicle Location"
      });
    }
  }
};
</script>

<style scoped>
.google-map-container {
  height: 400px; /* Adjust as needed */
  width: 100%;
}
</style>
