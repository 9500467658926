<template>
  <!-- team -->
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>team</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1869.000000, -293.000000)" fill="#000000" fill-rule="nonzero">
        <g transform="translate(1716.000000, 291.000000)">
          <g id="team" transform="translate(153.000000, 2.000000)">
            <path
              class="color-background"
              d="M12,12 C14.209139,12 16,10.209139 16,8 C16,5.790861 14.209139,4 12,4 C9.790861,4 8,5.790861 8,8 C8,10.209139 9.790861,12 12,12 Z M12,14 C9.33,14 4,15.34 4,18 L4,20 L20,20 L20,18 C20,15.34 14.67,14 12,14 Z M4,22 L4,22.9 C4,23.5 4.5,24 5.1,24 L18.9,24 C19.5,24 20,23.5 20,22.9 L20,22 L4,22 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script></script>
