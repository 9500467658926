<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h5>{{ $t("edit_voter_group") }}</h5>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="updateVoterGroup" class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="groupName">{{ $t("voter_group_name") }}</label>
              <input
                v-model="votersGroup.voter_group_name"
                type="text"
                class="form-control"
                id="groupName"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="groupDesc">{{ $t("voter_group_description") }}</label>
              <input
                v-model="votersGroup.voter_group_description"
                type="text"
                class="form-control"
                id="groupDesc"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="surveyArea">{{ $t("area") }}</label>
              <select
                v-model="votersGroup.area_id"
                class="form-control"
                id="surveyArea"
                required
              >
                <option value="" disabled>{{ $t("select_area") }}</option>
                <option v-for="area in areas" :key="area.id" :value="area.id">
                  {{ area.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="specialInstructions">{{
                $t("special_instructions")
              }}</label>
              <input
                v-model="votersGroup.special_instructions"
                type="text"
                class="form-control"
                id="specialInstructions"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="votersGroupLocation">{{
                $t("location_point")
              }}</label>
              <input
                id="autocomplete"
                v-model="locationSearch"
                placeholder="{{ $t('search_location') }}"
                class="form-control"
              />
              <GoogleMapEdit
                :center="mapCenter"
                :markerPosition="markerPosition"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-12 text-center">
            <button
              type="submit"
              class="btn btn-primary mt-3"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
              "
              :disabled="isLoading"
              :loading="isLoading"
            >
              {{ $t("update_voter_group") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import GoogleMapEdit from "./GoogleMapEdit.vue";
import AreaDataService from "../../services/AreaDataService.js";
import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import Swal from "sweetalert2";

export default {
  name: "EditVotersGroup",
  components: {
    GoogleMapEdit,
  },
  data() {
    return {
      isLoading: false,
      votersGroup: {
        id: null,
        voter_group_name: "",
        voter_group_description: "",
        area: "",
        location: { coordinates: [0, 0] },
        location_name: "",
        special_instructions: "",
        address: "",
      },
      areas: [],
      locationSearch: "",
      mapCenter: { lat: 0, lng: 0 },
      markerPosition: { lat: 0, lng: 0 },
      geocoder: null,
      autocomplete: null,
    };
  },
  created() {
    this.getAllAreas();
    this.getVoterGroup();
  },
  methods: {
    getAllAreas() {
      AreaDataService.getAll()
        .then((response) => {
          this.areas = response.data.area;
        })
        .catch((e) => {
          console.error("Error fetching areas:", e);
        });
    },
    fetchLocationData() {
      if (this.votersGroup.location && this.votersGroup.location.coordinates) {
        const [lng, lat] = this.votersGroup.location.coordinates;
        const locationData = {
          lat: lat,
          lng: lng,
          address: this.votersGroup.location_name || "",
        };
        this.locationSearch = locationData.address;
        this.mapCenter = { lat: locationData.lat, lng: locationData.lng };
        this.markerPosition = { lat: locationData.lat, lng: locationData.lng };
      } else {
        console.warn("Location data is not available or invalid.");
      }
    },
    updateMarker() {
      if (!this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }

      this.geocoder.geocode(
        { address: this.locationSearch },
        (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            this.mapCenter = { lat: location.lat(), lng: location.lng() };
            this.markerPosition = { lat: location.lat(), lng: location.lng() };
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    },
    initAutocomplete() {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { types: ["geocode"] }
      );

      this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        this.mapCenter = { lat: location.lat(), lng: location.lng() };
        this.markerPosition = { lat: location.lat(), lng: location.lng() };
        this.locationSearch = place.formatted_address;
      }
    },
    getVoterGroup() {
      const id = this.$route.params.id;
      VoterGroupsDataService.get(id)
        .then((response) => {
          const votersGroupData = response.data.voter_group;

          this.votersGroup = {
            id: votersGroupData.id,
            voter_group_name: votersGroupData.voter_group_name,
            location: votersGroupData.location,
            location_name: votersGroupData.location_name,
            voter_group_description: votersGroupData.voter_group_description,
            area: votersGroupData.area,
            area_id: votersGroupData.area_id,
            special_instructions: votersGroupData.special_instructions,
          };
          this.fetchLocationData();
        })
        .catch((e) => {
          console.error("Error fetching voter group:", e);
        });
    },
    // updatePointFormat(pointFormat) {
    //   this.votersGroup.location.coordinates = pointFormat;
    // },
    updateVoterGroup() {
      this.isLoading = true;
      if (!this.votersGroup.id) {
        console.error("Voter Group ID is missing");
        return;
      }

      const updatedPayload = {
        id: this.votersGroup.id,
        voter_group_name: this.votersGroup.voter_group_name,
        voter_group_description: this.votersGroup.voter_group_description,
        area: this.votersGroup.area,
        area_id: this.votersGroup.area_id,
        special_instructions: this.votersGroup.special_instructions,
        location: {
          type: "Point",
          coordinates: [this.markerPosition.lng, this.markerPosition.lat],
        },
        location_name: this.locationSearch,
      };

      VoterGroupsDataService.update(updatedPayload)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Voters Group updated successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/voters-group");
          });
        })
        .catch((error) => {
          console.error("Error updating Voters Group:", error);
        });
    },
  },
  mounted() {
    this.initAutocomplete();
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}
</style>
