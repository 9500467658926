<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Category</title>
    <g fill="none" stroke="none" stroke-width="1" fill-rule="evenodd">
      <g fill="#000000">
        <path d="M3,6H21V8H3V6ZM3,12H21V14H3V12ZM3,18H21V20H3V18ZM3,4C2.44772,4 2,4.44772 2,5V19C2,19.5523 2.44772,20 3,20H21C21.5523,20 22,19.5523 22,19V5C22,4.44772 21.5523,4 21,4H3ZM3,2H21C22.1046,2 23,2.89543 23,4V20C23,21.1046 22.1046,22 21,22H3C1.89543,22 1,21.1046 1,20V4C1,2.89543 1.89543,2 3,2Z" />
      </g>
    </g>
  </svg>
</template>
<script></script>
