<template>
  <div class="card mb-4">
    <div class="card-header pb-0 d-flex justify-content-between align-items-center">
      <h6>Voters List</h6>
    </div>
    <div class="container">
      <div class="card-body px-0 pt-0 pb-2">
      <div class="row filters_show">
            <div class="col-md-2"><input type="text" class="form-control" placeholder="Filter by Slnoinpart" v-model="filters.slnoinpart" @input="applyFilters" /></div>
            <div class="col-md-2"><input type="text" class="form-control" placeholder="Filter by Section" v-model="filters.section" @input="applyFilters" /></div>
            <div class="col-md-2"><input type="text" class="form-control" placeholder="Filter by Name" v-model="filters.name" @input="applyFilters" /></div>
            <div class="col-md-2"><input type="text" class="form-control" placeholder="Filter by ID Card No" v-model="filters.idcardno" @input="applyFilters" /></div>
            <div class="col-md-2"><input type="text" class="form-control" placeholder="Filter by Mobile" v-model="filters.mobile" @input="applyFilters" /></div>
            <div class="col-md-2"><input type="text" class="form-control" placeholder="Filter by Vcenter" v-model="filters.vcenter" @input="applyFilters" /></div>

      </div>
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">ID</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Acno</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Prabhagno</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Part No</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Slnoinpart</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Section</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Rlname</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">ID Card No</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Age</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Gender</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Mobile</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Caste</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Religion</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Vcenter</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resource, index) in filteredResources" :key="index">
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.id }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.acno }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.prabhagno }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.partno }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.slnoinpart }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.section }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.name }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.rlname }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.idcardno }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.age }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.gender }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.mobile }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.caste }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.religion }}</p></td>
              <td><p class="text-xs font-weight-bold mb-0">{{ resource.vcenter }}</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {
        partno: '',
        slnoinpart: '',
        section: '',
        name: '',
        idcardno: '',
        mobile: '',
        vcenter: '',
      },
      resources: [
      { id: 2, slnoinpart: 4527, name: 'अ मजीद अ अजीज', idcardno: 'UUG3537248', enfname: 'a ajija', rlname: 'अ अजीज', age: 55, gender: 'पु', prabhag: 1, partno: 132, section: '२ - लक्ष्मीनगर कारंजा वॉर्ड क्र .७', prabhagno: 1, acno: 35, srno: 1775, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 1234567890, caste: 'Open', religion: 'मुस्लिम' },
        { id: 3, slnoinpart: 3205, name: 'अ सलीम अ मूक्तदिर शेख', idcardno: 'UUG6681647', enname: 'salima', enfname: 'a muktadira', rlname: 'अ मूक्तदिर शेख', age: 30, gender: 'पु', prabhag: 1, partno: 131, section: '५ - लक्ष्मीनगर कारंजा वॉर्ड क्र.७ कारंजा', prabhagno: 1, acno: 35, srno: 1016, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 9987654321, caste: 'Open', religion: 'मुस्लिम' },
        { id: 4, slnoinpart: 3483, name: 'अ समीर अ नसीम', idcardno: 'UUG6614556', enname: 'samira', enfname: 'a nasima', rlname: 'अ नसीम', age: 21, gender: 'पु', prabhag: 1, partno: 132, section: '२ - लक्ष्मीनगर कारंजा वॉर्ड क्र .७', prabhagno: 1, acno: 35, srno: 496, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 1234567890, caste: 'Open', religion: 'मुस्लिम' },
        { id: 5, slnoinpart: 3937, name: 'अ शरीक', idcardno: 'UUG5947940', enname: 'sharika', enfname: '', rlname: 'अ सलाम', age: 29, gender: 'पु', prabhag: 1, partno: 132, section: '२ - लक्ष्मीनगर कारंजा वॉर्ड क्र .७', prabhagno: 1, acno: 35, srno: 1002, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 9987654321, caste: 'Open', religion: 'मुस्लिम' },
        { id: 6, slnoinpart: 3892, name: 'अ.अजीज अ.कादर', idcardno: 'UUG3740685', enlname: 'a.ajija', enname: 'a|kadara', enfname: '', rlname: 'अ.कादर', age: 65, gender: 'पु', prabhag: 1, partno: 132, section: '२ - लक्ष्मीनगर कारंजा वॉर्ड क्र .७', prabhagno: 1, acno: 35, srno: 947, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 1234567890, caste: 'Open', religion: 'मुस्लिम' },
        { id: 7, slnoinpart: 3029, name: 'अ. अजीज अ. खलील', idcardno: 'UUG4203980', enlname: 'a|', enname: 'ajija', enfname: 'a| khalila', rlname: 'अ. खलील', age: 37, gender: 'पु', prabhag: 1, partno: 131, section: '१- नळा समोर गवळीपुरा कारंजा गवळीपुरा वॉर्ड क्रं.५', prabhagno: 1, acno: 35, srno: 312, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 9987654321, caste: 'Open', religion: 'मुस्लिम' },
        { id: 8, slnoinpart: 3032, name: 'अ. हक्क अ. रब', idcardno: 'UUG6077309', enlname: 'a|', enname: 'hakka', enfname: 'a| raba', rlname: 'अ. रब', age: 34, gender: 'पु', prabhag: 1, partno: 131, section: '१- नळा समोर गवळीपुरा कारंजा गवळीपुरा वॉर्ड क्रं.५', prabhagno: 1, acno: 35, srno: 315, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 1234567890, caste: 'Open', religion: 'मुस्लिम' },
        { id: 9, slnoinpart: 3030, name: 'अ. जफर अ. खलील', idcardno: 'UUG4203972', enlname: 'a|', enname: 'japhara', enfname: 'a| khalila', rlname: 'अ. खलील', age: 34, gender: 'पु', prabhag: 1, partno: 131, section: '१- नळा समोर गवळीपुरा कारंजा गवळीपुरा वॉर्ड क्रं.५', prabhagno: 1, acno: 35, srno: 313, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 9987654321, caste: 'Open', religion: 'मुस्लिम' },
        { id: 10, slnoinpart: 3031, name: 'अ. मोइन अ. रहेमान', idcardno: 'UUG4203642', enlname: 'a|', enname: 'moina', enfname: 'a| rahemana', rlname: 'अ. रहेमान', age: 34, gender: 'पु', prabhag: 1, partno: 131, section: '१- नळा समोर गवळीपुरा कारंजा गवळीपुरा वॉर्ड क्रं.५', prabhagno: 1, acno: 35, srno: 314, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 1234567890, caste: 'Open', religion: 'मुस्लिम' },
        { id: 11, slnoinpart: 3872, name: 'अ. मुक्‍तादीर अ. मुक्‍तादीर अ.कदी', idcardno: 'MCD0897033', enlname: 'a|', enname: 'muk{}tadira', enfname: 'a| muk{}tadira', rlname: 'अ. मुक्तादीर अ.कदीर', age: 85, gender: 'पु', prabhag: 1, partno: 132, section: '२ - लक्ष्मीनगर कारंजा वॉर्ड क्र .७', prabhagno: 1, acno: 35, srno: 925, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 9987654321, caste: 'Open', religion: 'मुस्लिम' },
        { id: 12, slnoinpart: 4405, name: 'अ. नझीर अ. गफुर', idcardno: 'MCD0862235', enlname: 'a|', enname: 'najhira', enfname: 'a| gaphura', rlname: 'अ. गफुर', age: 45, gender: 'पु', prabhag: 1, partno: 132, section: '२ - लक्ष्मीनगर कारंजा वॉर्ड क्र .७', prabhagno: 1, acno: 35, srno: 1647, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 1234567890, caste: 'Open', religion: 'मुस्लिम' },
        { id: 13, slnoinpart: 4409, name: 'अ. फाहीम अ. जब्बार', idcardno: 'MCD0940262', enlname: 'a|', enname: 'phahima', enfname: 'a| jabbara', rlname: 'अ. जब्बार', age: 40, gender: 'पु', prabhag: 1, partno: 132, section: '२ - लक्ष्मीनगर कारंजा वॉर्ड क्र .७', prabhagno: 1, acno: 35, srno: 1651, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 9987654321, caste: 'Open', religion: 'मुस्लिम' },
        { id: 14, slnoinpart: 4543, name: 'अ. शमी अ. बशीर', idcardno: 'UUG6455323', enlname: 'a|', enname: 'shami', enfname: 'a| bashira', rlname: 'अ. शमी अ. बशीर', age: 52, gender: 'पु', prabhag: 1, partno: 141, section: '१- मौलाना आजाद चौक डाफनी पुरा नगर परिषद मराठी प्राथमिक शाला क्र.३कारंजा', prabhagno: 1, acno: 35, srno: 691, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 1234567890, caste: 'Open', religion: 'मुस्लिम' },
        { id: 15, slnoinpart: 3515, name: 'अ. हबीब हाजी. अबूबकर', idcardno: 'MCD0895185', enlname: 'a|', enname: 'habiba', enfname: 'haji| abubakara', rlname: 'अ. हबीब हाजी. अबूबकर', age: 69, gender: 'पु', prabhag: 1, partno: 132, section: '२ - लक्ष्मीनगर कारंजा वॉर्ड क्र .७', prabhagno: 1, acno: 35, srno: 531, vcenter: 'प्रगती विदयालय खोली क्र १ रहाटगांव', mobile: 1234567890, caste: 'Open', religion: 'मुस्लिम' },
      ]
    };
  },
  computed: {
    filteredResources() {
      return this.resources.filter(resource => {
        return (
          (!this.filters.partno || resource.partno.toString().includes(this.filters.partno)) &&
          (!this.filters.slnoinpart || resource.slnoinpart.toString().includes(this.filters.slnoinpart)) &&
          (!this.filters.section || resource.section.toString().toLowerCase().includes(this.filters.section.toLowerCase())) &&
          (!this.filters.name || resource.name.toLowerCase().includes(this.filters.name.toLowerCase())) &&
          (!this.filters.idcardno || resource.idcardno.toLowerCase().includes(this.filters.idcardno.toLowerCase())) &&
          (!this.filters.mobile || resource.mobile.toString().includes(this.filters.mobile)) &&
          (!this.filters.vcenter || resource.vcenter.toLowerCase().includes(this.filters.vcenter.toLowerCase()))
        );
      });
    }
  },
  methods: {
    applyFilters() {
      this.filteredResources;
    }
  }
};
</script>

<style scoped>
.table-responsive {
  max-height: 400px;
  overflow-y: auto;
}
.d-flex input.form-control {
  margin-bottom: 10px;
  margin-right: 10px;
}
.filters_show{
  /* display: inline; */
}
@media (min-width: 300px) and (max-width:600px){
 .filters_show{
  display: none;
 }
}
</style>

