<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <ul class="nav nav-pills flex-column flex-md-row">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#stockTransaction">{{ $t('stock_transaction') }}</a>
        </li>
        <li class="nav-item ml-md-3" style="margin-left: 5px;">
          <a class="nav-link" data-toggle="tab" href="#StockRequest">{{ $t('stock_request_approval') }}</a>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="stockTransaction">
          <form @submit.prevent="updateTeam">
            <div class="form-row mb-3">
              <div class="col-12">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="fromDonor" value="fromDonor" v-model="inwardType">
                  <label class="form-check-label" for="fromDonor">{{ $t('from_donor') }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="officeTransfer" value="officeTransfer" v-model="inwardType">
                  <label class="form-check-label" for="officeTransfer">{{ $t('office_transfer') }}</label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>{{ $t('select_resources_and_quantity') }}</label>
              <div v-for="(resource, index) in currentTeam.resources" :key="index"
                class="form-row align-items-center mb-2 position-relative resource-field">
                <div class="row">
                  <div class="col-md-9 mb-2 mb-md-0">
                    <multiselect v-model="resource.resource_name" :options="resourcesList" :placeholder="$t('pick_a_resource')"
                      label="resource_name" track-by="resource_name"></multiselect>
                  </div>
                  <div class="col-md-3 mb-2 mb-md-0">
                    <input v-model="resource.quantity" type="number" class="form-control" :placeholder="$t('quantity')">
                  </div>
                </div>
                <div class="position-absolute delete-icon" v-if="currentTeam.resources.length > 1 && index !== 0">
                  <a href="javascript:;" class="text-danger font-weight-bold text-xs" @click="removeResource(index)">
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
              <center><button type="button" class="btn btn-primary mt-2" @click="addResource"
                  style="background-color: #141727;color:white;border:2px solid #141727">+</button></center>
            </div>

            <div v-if="inwardType === 'officeTransfer'" class="form-row">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="fromOffice">{{ $t('from_office') }}</label>
                  <multiselect v-model="currentTeam.fromOffice" :options="officeList" :placeholder="$t('from_office')"
                    label="office_name" track-by="office_name"></multiselect>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="handoverBy">{{ $t('handover_by') }}</label>
                  <multiselect v-model="currentTeam.handoverBy" :options="userList" :placeholder="$t('handover_by')"
                    label="f_name" track-by="f_name"></multiselect>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="toOffice">{{ $t('to_office') }}</label>
                  <multiselect v-model="currentTeam.toOffice" :options="officeList" :placeholder="$t('to_office')"
                    label="office_name" track-by="office_name"></multiselect>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="handoverTo">{{ $t('handover_to') }}</label>
                  <multiselect v-model="currentTeam.handoverTo" :options="userList" :placeholder="$t('handover_to')"
                    label="f_name" track-by="f_name"></multiselect>
                </div>
              </div>
            </div>

            <center><button type="submit" class="btn btn-primary mt-2"
                style="background-color: #141727;color:white;border:2px solid #141727">{{ $t('send') }}</button></center>
          </form>
        </div>

        <div class="tab-pane fade" id="StockRequest">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('users') }}</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('resource_name') }}</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('quantity') }}</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('from_office') }}</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('to_office') }}</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{{ $t('action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(stockrequest, index) in paginatedstockrequests" :key="index">
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ stockrequest.transactions_user ?
                      stockrequest.transactions_user.f_name + ' ' + stockrequest.transactions_user.l_name : "" }}</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ stockrequest.transactions_resources ?
                      stockrequest.transactions_resources.resource_name : "" }}</p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ stockrequest.quantity }}</p>
                  </td>
                  <td>
                    <select v-model="selectedOffices[stockrequest.transaction_id]" class="form-control" id="office" required>
                      <option value="" selected>{{ $t('select_office') }}</option>
                      <option v-for="office1 in officeList" :key="office1.id" :value="office1.id">{{ office1.office_name }}</option>
                    </select>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{ stockrequest.transactions_offices ?
                      stockrequest.transactions_offices.office_name : ""}}</p>
                  </td>
                  <td>
                    <select v-model="stockrequest.action" @change="handleAction(stockrequest)" class="form-control">
                      <option value="" selected>{{ $t('select_action') }}</option>
                      <option value="approve">{{ $t('approve') }}</option>
                      <option value="reject">{{ $t('reject') }}</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>
              <span>{{ currentPage }} / {{ totalPages }}</span>
              <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('next') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import ResourcesDataService from "../../services/ResourcesDataService.js";
import OfficesDataService from "../../services/OfficesDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import StockInwardDataService from "../../services/StockInwardDataService.js";
import Swal from 'sweetalert2';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      inwardType: 'fromDonor',
      currentTeam: {
        resources: [{ resource_name: null, quantity: 1 }],
        fromOffice: null,
        handoverBy: null,
        toOffice: null,
        transactions_offices: null,
        handoverTo: null,
        transaction_id: null
      },
      cancelReason: "",
      officeList: [],
      userList: [],
      resourcesList: [],
      stockrequest: [], 
      selectedOffices: {},
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    paginatedstockrequests() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.stockrequest.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.stockrequest.length / this.itemsPerPage);
    }
  },
  created() {
    this.getAllResource();
    this.getAllOffices();
    this.getAllUser();
    this.getAllInwardStocks();
  },
  methods: {
    getAllUser() {
      UsersDataService.getAll()
        .then(response => {
          this.userList = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAllInwardStocks() {
      StockInwardDataService.getAll()
        .then(response => {
          console.log(StockInwardDataService.getAll());
          this.stockrequest = response.data.inward_stock.map(stock => ({
            id: stock.id,
            user: stock.created_by,// Update this according to your data structure
            resource_name: stock.resource_id, // Update this according to your data structure
            quantity: stock.quantity,
            transaction_id: stock.transaction_id,
            transactions_user: stock.transactions_user,
            transactions_resources: stock.transactions_resources,
            transactions_offices: stock.transactions_offices,
            action: '' // Default action value
          }));
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAllOffices() {
      OfficesDataService.getAll()
        .then(response => {
          this.officeList = response.data.offices;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getAllResource() {
      ResourcesDataService.getAll()
        .then(response => {
          this.resourcesList = response.data.resources;
        })
        .catch(e => {
          console.log(e);
        });
    },
    addResource() {
      this.currentTeam.resources.push({ resource_name: null, quantity: 1 });
    },
    removeResource(index) {
      if (this.currentTeam.resources.length > 1) {
        this.currentTeam.resources.splice(index, 1);
      }
    },
    updateTeam() {
      const payload = {
        inwardType: this.inwardType,
        transaction_type: this.inwardType === 'fromDonor' ? 1 : 2,
        resources: this.currentTeam.resources.map(resource => ({
          id: resource.resource_name.id,
          resource_name: resource.resource_name.resource_name,
          quantity: resource.quantity,
        })),
        from_office: this.currentTeam.fromOffice ? this.currentTeam.fromOffice.id : null,
        handover_by: this.currentTeam.handoverBy ? this.currentTeam.handoverBy.id : null,
        to_office: this.currentTeam.toOffice ? this.currentTeam.toOffice.id : null,
        handover_to: this.currentTeam.handoverTo ? this.currentTeam.handoverTo.id : null,
      };

      StockInwardDataService.create(payload)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Stock inward added successfully!',
            confirmButtonText: 'OK'
          }).then(() => {
            this.$router.push('/stock');
          });
        })
        .catch(error => {
          console.error("Error adding events:", error);
        });
    },
    handleAction(stockrequest) {
      if (stockrequest.action === 'approve') {
        this.approveStockRequest(stockrequest);
      } else if (stockrequest.action === 'reject') {
        this.rejectStockRequest(stockrequest);
      }
    },
    approveStockRequest(stockrequest) {
      const selectedOffice = this.selectedOffices[stockrequest.transaction_id];
      const tenantId = localStorage.getItem('tenantId');
      if(selectedOffice){
        var params = {
          id: stockrequest.transaction_id,
          from_office: selectedOffice
        };
        console.log(params);
        const token = localStorage.getItem('token');
        axios.get(`https://rushivarya-stg-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/inward-stocks/accept`, {
          params,
          headers: {
            'Authorization': token
          }
        })
          .then(response => {
            console.log("Stock Request approved", response.messages);
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Stock Request approved successfully!',
              confirmButtonText: 'OK'
            }).then(() => {
              this.getAllInwardStocks();
              this.resetAction(stockrequest); 
            });
          })
          .catch(error => {
            console.log("Error Object:", error); // Log the entire error object
            if (error.response && error.response.data && error.response.data.messages && error.response.status == '422') {
              console.log("Error Message:", error.response.data.messages);
              const messages = Array.isArray(error.response.data.messages)
                ? error.response.data.messages.join(', ')  // Convert array to comma-separated string
                : error.response.data.messages;

              let type = '';
              let msg = '';
              if (messages == 'stock_not_found') {
                type = 'stock_not_found';
                msg = 'Stock not found!';
              } else {
                type = messages;
                msg = 'Insufficient stock!';
              }
              params.type = type;

              console.log(params);
              Swal.fire({
                icon: 'error',
                title: msg,
                text: 'Do you want to continue ?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              }).then((result) => {
                if (result.isConfirmed) {
                  const tenantId = localStorage.getItem('tenantId');
                  axios.get(`https://rushivarya-stg-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/inward-stocks/stock-not-found`, {
                    params,
                    headers: {
                      'Authorization': token
                    }
                  })
                    .then(response => {
                      console.log("Stock Request approved", response.messages);
                      Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Stock Request approved successfully!',
                        confirmButtonText: 'OK'
                      }).then(() => {
                        this.getAllInwardStocks();
                      });
                    })
                    .catch(error => {
                      console.log("Error Object:", error);
                    });
                } else {
                  console.log('User chose not to continue.');
                  stockrequest.action = ''; // Reset the select field value
                }
              });

            } else {
              console.log('Error does not contain a response.');
            }
            console.error("There was an error approving the vehicle!", error);
          });
        }else{
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Please select from office first!',
              confirmButtonText: 'OK'
            }).then(() => {
              this.resetAction(stockrequest); 
            });
        }
    },
    rejectStockRequest(stockrequest) {
      const token = localStorage.getItem('token');
      Swal.fire({
        title: 'Reject Stock Request',
        input: 'textarea',
        inputLabel: 'Rejection Reason',
        inputPlaceholder: 'Type your rejection reason here...',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
        }
      }).then(result => {
        const params = {
          id: stockrequest.transaction_id,
          rejection_reason: result.value,
        };
        console.log(params);
        if (result.isConfirmed) {
          const tenantId = localStorage.getItem('tenantId');
          axios.get(`https://rushivarya-stg-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/inward-stocks/deny`, {
            params,
            headers: {
              'Authorization': token
            }
          })
            .then(() => {
              Swal.fire('Rejected!', 'The stock request has been rejected.', 'success');
            })
            .catch(error => {
              console.error("Error rejecting stock request:", error);
              Swal.fire('Error!', 'Failed to reject the stock request.', 'error');
            });
        } else {
          this.resetAction(stockrequest); // Reset action value
        }
      });
    },
    resetAction(stockrequest) {
      stockrequest.action = ''; // Reset the action property
    }
    ,
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  }
};
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-pills {
  display: flex;
  flex-direction: column;
}

.nav-pills .nav-item {
  margin-bottom: 10px;
}

.nav-pills .nav-item .nav-link {
  text-align: center;
  padding: 10px;
}

.nav-pills .nav-link.active {
  background-color: #141727;
  color: white;
}

.table-responsive {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .nav-pills {
    flex-direction: row;
  }

  .nav-pills .nav-item {
    margin-bottom: 0;
  }

  .nav-item.ml-md-3 {
    margin-left: 15px;
  }

  .col-md-4.mb-3.mb-md-0 {
    margin-bottom: 0 !important;
  }
}
.form-group {
  margin-bottom: 1rem;
}

.form-row {
  margin-bottom: 1rem;
}

.btn-primary {
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
}

.multiselect {
  flex: 1;
}

.resource-field {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.delete-icon {
  top: 0px;
  right: 10px;
}

.table-responsive {
  margin-top: 1rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50px;
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
