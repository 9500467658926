import http from '../http-common';

class ReligionDataService {
  getAll() {
    return http.get("/religion");
  }

  get(id) {
    return http.get(`/religion/edit/${id}`);
  }

  create(data) {
    return http.post("/religion/add", data);
  }

  update(id, data) {
    return http.put(`/religion/update/${id}`, data);
  }

  
  delete(id) {
    return http.put(`/religion/delete/${id}`);
  }
}

export default new ReligionDataService();
