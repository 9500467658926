<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <!-- <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        /> -->
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form
                    @submit.prevent="handleSubmit"
                    role="form"
                    class="text-start"
                  >
                    <label>Email</label>
                    <soft-input
                      type="email"
                      placeholder="Email"
                      id="email"
                      v-model="user.email"
                    />
                    <div v-if="emailError" class="error-message">
                      {{ emailError }}
                    </div>
                    <label>Password</label>
                    <soft-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      v-model="user.password"
                    />
                    <div v-if="passwordError" class="error-message">
                      {{ passwordError }}
                    </div>
                    <!-- <soft-switch id="rememberMe" name="rememberMe" checked>
                      Remember me
                    </soft-switch> -->
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        type="submit"
                        :disabled="loading"
                      >
                        <span v-if="!loading">Sign in</span>
                        <span v-else>Loading...</span>
                      </soft-button>
                    </div>
                  </form>

                  <div v-if="error" class="alert alert-danger mt-2">
                    {{ error }}
                  </div>

                  <div v-if="showTable && devices.length > 0">
                    <table>
                      <thead>
                        <tr>
                          <th>Device Name</th>
                          <th>Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="device in devices" :key="device.id">
                          <td>{{ device.device_info.browser }}</td>
                          <td>{{ device.device_type }}</td>
                          <td>
                            <button
                              @click="logout(device)"
                              class="logout-button"
                            >
                              Logout
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <button
                    v-if="showTable && devices.length > 0"
                    @click="cancel"
                    class="cancel-button"
                  >
                    Cancel
                  </button>
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Sign Up' }"
                      class="text-success text-gradient font-weight-bold"
                      >Sign up</router-link
                    >
                  </p>
                </div> -->
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <!-- <app-footer /> -->
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import axios from "axios";
// import UsersDataService from "../../src/services/UsersDataService";

export default {
  name: "SignIn",
  data() {
    return {
      user: {
        email: "", // Define email property in data
        password: "",
      },
      devices: [],
      error: "", // Define password property in data
      emailError: "",
      passwordError: "",
      loading: false, // Loading state
      showTable: true,
    };
  },
  components: {
    // Navbar,
    // AppFooter,
    SoftInput,
    // SoftSwitch,
    SoftButton,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async handleSubmit() {
      // Here you can handle your form validation and authentication logic
      // For now, let's assume the authentication is successful and navigate to /dashboard
      //this.$router.push('/dashboard');

      this.emailError = ""; // Clear any existing email error message
      this.passwordError = ""; // Clear any existing password error message
      this.error = ""; // Clear any existing error message

      // Check if email or password is empty
      if (!this.user.email) {
        this.emailError = "Email is required.";
      }
      if (!this.user.password) {
        this.passwordError = "Password is required.";
      }

      // If there are validation errors, do not proceed
      if (this.emailError || this.passwordError) {
        return;
      }

      this.loading = true; // Start loading

      try {
        const requestData = {
          email: this.user.email,
          password: this.user.password,
          device_info: "web",
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.post(
          "https://rushivarya-stg-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/auth/login",
          requestData,
          config
        );
        const token = response.data.accessToken;
        console.log(response.data);
        if (response.data.devices) {
          this.error = response.data.msg;
          this.devices = response.data.devices;
          this.showTable = true;
          this.$router.push("/sign-in");
        } else {
          localStorage.setItem("token", token);
          const phoneVisibility = response.data.configData.find(
            (item) => item.name === "phone_visibility"
          ).value;
          localStorage.setItem("phone_visibility", phoneVisibility);
          const organName = response.data.configData.find(
            (item) => item.name === "organization_full_name"
          ).value;
          localStorage.setItem("organization_full_name", organName);
          localStorage.setItem("admin_id", response.data.user.id);
          localStorage.setItem("type", response.data.user.type);
          localStorage.setItem(
            "installation_type",
            response.data.user.installation_type
          );
          if (
            response.data.user.candidate_id &&
            response.data.user.candidate_id != null
          ) {
            localStorage.setItem(
              "candidateId",
              response.data.user.candidate_id
            );
          } else {
            console.warn("Candidate ID is null");
          }
          localStorage.setItem("orgId", response.data.user.org_id);
          localStorage.setItem("tenantId", response.data.user.tenant.admin_id);
          localStorage.setItem('defaultacno', response.data.configData.find(item => item.name === "default_acno").value);
          // localStorage.setItem('acno', '35');
          if (response.data.user.steps === 4) {
            this.$router.push("/dashboard");
          } else {
            this.$router.push(`/installation/${response.data.user.steps}`);
          }
          localStorage.setItem("steps", response.data.user.steps);
          this.showTable = false;
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data && error.response.data.msg) {
          // Handle validation errors
          this.error = error.response.data.msg;
        } else {
          // Handle other errors
          this.error = "An error occurred. Please try again later.";
        }
        this.$router.push("/sign-in");
      } finally {
        this.loading = false; // Stop loading
      }
    },
    async logout(device) {
      this.loading = true;
      console.log(device);
      try {
        // await UsersDataService.delete(device);
        const requestData = {
          id: device.id,
          user_id: device.user_id,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.post(
          "https://rushivarya-stg-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/sign-out",
          requestData,
          config
        );
        console.log(response);

        // Clear local storage after successful logout
        localStorage.removeItem("token");
        localStorage.removeItem("candidateId");
        localStorage.removeItem("orgId");
        localStorage.removeItem("acno");

        await this.handleSubmit();
      } catch (error) {
        console.log(error);
        this.error =
          "An error occurred while logging out. Please try again later.";
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      console.log("Cancel button clicked");

      this.emailError = "";
      this.passwordError = "";
      this.error = "";
      this.user.email = "";
      this.user.password = "";

      this.showTable = false;
    },
  },
};
</script>

<style scoped>
.error-message {
  color: #e74c3c;
  /* Light red color */
  font-size: 0.875rem;
  /* Small font size */
  margin-top: 0.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.logout-button {
  background-color: #ff4d4d; /* Red background */
  color: white; /* White text */
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.logout-button:hover {
  background-color: #ff1a1a; /* Darker red on hover */
}

.cancel-button {
  background-color: #f44336; /* Red background */
  color: white; /* White text color */
  border: none; /* Remove borders */
  padding: 10px 20px; /* Add padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Align elements */
  font-size: 16px; /* Font size */
  margin: 4px 2px; /* Margins */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 4px; /* Rounded corners */
}
</style>
