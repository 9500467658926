<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h6>{{ $t("team") }}</h6>
      <button
        type="button"
        class="btn btn-primary"
        @click="showAddModal = true"
        :style="{
          backgroundColor: '#141727',
          color: 'white',
          border: '2px solid #141727',
        }"
      >
        {{ $t("add_team") }}
      </button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("team_name") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("team_incharge_1") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("team_incharge_2") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("office") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("area") }}
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(team, index) in paginatedTeams" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ team.name }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{
                    team.team_primary_incharge_details
                      ? team.team_primary_incharge_details.f_name +
                        " " +
                        team.team_primary_incharge_details.l_name
                      : ""
                  }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{
                    team.team_secondary_incharge_details
                      ? team.team_secondary_incharge_details.f_name +
                        " " +
                        team.team_secondary_incharge_details.l_name
                      : ""
                  }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ team.team_office ? team.team_office.office_name : "" }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ team.team_area ? team.team_area.name : "" }}
                </p>
              </td>
              <td class="align-middle">
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  @click="openEditModal(team, index)"
                  ><i class="fas fa-edit"></i
                ></a>
                <a
                  href="javascript:;"
                  class="text-danger font-weight-bold text-xs ms-3"
                  @click="confirmDelete(index, team.id)"
                  ><i class="fas fa-trash-alt"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Pagination -->
    <nav class="mt-4" aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <button
            class="page-link"
            :class="{ active: currentPage === page }"
            :style="pageLinkStyle(page)"
            @click="goToPage(currentPage - 1)"
            :disabled="currentPage === 1"
            style="border-radius: 10px !important; width: 70px"
          >
            {{ $t("previous") }}
          </button>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page">
          <button
            class="page-link"
            :class="{ active: currentPage === page }"
            :style="pageLinkStyle(page)"
            @click="goToPage(page)"
          >
            {{ page }}
          </button>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            :class="{ active: currentPage === page }"
            :style="pageLinkStyle(page)"
            @click="goToPage(currentPage + 1)"
            :disabled="currentPage === totalPages"
            style="border-radius: 10px !important; width: 70px"
          >
            {{ $t("next") }}
          </button>
        </li>
      </ul>
    </nav>

    <!-- Add Modal -->
    <div
      v-if="showAddModal"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("add_team") }}</h5>
            <button
              type="button"
              class="close"
              @click="showAddModal = false"
              aria-label="Close"
              :style="{
                backgroundColor: '#141727',
                color: 'white',
                border: '2px solid #141727',
                borderRadius: '50%',
              }"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addTeam">
              <div class="form-group">
                <label for="teamName">{{ $t("team_name") }}</label>
                <input
                  v-model="newTeam.teamName"
                  type="text"
                  class="form-control"
                  id="teamName"
                  required
                />
              </div>

              <div class="form-group">
                <label for="teamIncharge1">{{ $t("team_incharge_1") }}</label>
                <div class="select-wrapper">
                  <select
                    v-model="newTeam.teamIncharge1"
                    class="form-control select-with-arrow"
                    id="teamIncharge1"
                    required
                  >
                    <option value="" selected>
                      {{ $t("select_incharge_1") }}
                    </option>
                    <option v-for="user in users" :key="user.id" :value="user">
                      {{ user.f_name }} {{ user.l_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="teamIncharge2">{{ $t("team_incharge_2") }}</label>
                <div class="select-wrapper">
                  <select
                    v-model="newTeam.teamIncharge2"
                    class="form-control select-with-arrow"
                    id="teamIncharge2"
                  >
                    <option value="" selected>
                      {{ $t("select_incharge_2") }}
                    </option>
                    <option
                      v-for="user in filteredUsersForIncharge2"
                      :key="user.id"
                      :value="user"
                    >
                      {{ user.f_name }} {{ user.l_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="office">{{ $t("office") }}</label>
                <div class="select-wrapper">
                  <select
                    v-model="newTeam.offices"
                    class="form-control select-with-arrow"
                    id="office"
                    required
                  >
                    <option value="" selected>
                      {{ $t("select_offices") }}
                    </option>
                    <option
                      v-for="office in offices"
                      :key="office.id"
                      :value="office"
                    >
                      {{ office.office_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="area">{{ $t("area") }}</label>
                <div class="select-wrapper">
                  <select
                    v-model="newTeam.area"
                    class="form-control select-with-arrow"
                    id="area"
                    required
                  >
                    <option value="" selected>{{ $t("select_area") }}</option>
                    <option v-for="area in areas" :key="area.id" :value="area">
                      {{ area.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="teamMembers">{{ $t("team_members") }}</label>
                <multiselect
                  v-model="newTeam.teamMembers"
                  :options="filteredUsersForTeamMembers"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Pick some"
                  :custom-label="customLabel"
                  track-by="id"
                ></multiselect>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :style="{
                  backgroundColor: '#141727',
                  color: 'white',
                  border: '2px solid #141727',
                }"
              >
                {{ $t("add_team_button") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div
    v-if="showEditModal"
    class="modal fade show d-block"
    tabindex="-1"
    role="dialog"
    style="background: rgba(0, 0, 0, 0.5)"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("edit_team") }}</h5>
          <button
            type="button"
            class="close"
            @click="showEditModal = false"
            aria-label="Close"
            :style="{
              backgroundColor: '#141727',
              color: 'white',
              border: '2px solid #141727',
              borderRadius: '50%',
            }"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="updateTeam">
            <div class="form-group">
              <label for="editTeamName">{{ $t("team_name") }}</label>
              <input
                v-model="editTeam.teamName"
                type="text"
                class="form-control"
                id="editTeamName"
                required
              />
            </div>

            <div class="form-group">
              <label for="editTeamIncharge1">{{
                $t("team_incharge_1")
              }}</label>
              <select
                v-model="editTeam.teamIncharge1"
                class="form-control"
                id="editTeamIncharge1"
                required
              >
                <option value="" disabled>{{ $t("select_incharge_1") }}</option>
                <option v-for="user in users" :key="user.id" :value="user.id">
                  {{ user.f_name }} {{ user.l_name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="editTeamIncharge2">{{
                $t("team_incharge_2")
              }}</label>
              <select
                v-model="editTeam.teamIncharge2"
                class="form-control"
                id="editTeamIncharge2"
              >
                <option value="" disabled>{{ $t("select_incharge_2") }}</option>
                <option
                  v-for="user in filteredUsersForIncharge2"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.f_name }} {{ user.l_name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="editOffice">{{ $t("office") }}</label>
              <select
                v-model="editTeam.offices"
                class="form-control"
                id="editOffice"
                required
              >
                <option value="" disabled>{{ $t("select_offices") }}</option>
                <option
                  v-for="office in offices"
                  :key="office.id"
                  :value="office.id"
                >
                  {{ office.office_name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="editArea">{{ $t("area") }}</label>
              <select
                v-model="editTeam.area"
                class="form-control"
                id="editArea"
                required
              >
                <option value="" disabled>{{ $t("select_area") }}</option>
                <option v-for="area in areas" :key="area.id" :value="area.id">
                  {{ area.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="editTeamMembers">{{ $t("team_members") }}</label>
              <multiselect
                v-model="editTeam.teamMembers"
                :options="users"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                :custom-label="customLabel"
                track-by="id"
              ></multiselect>
            </div>

            <button
              type="submit"
              class="btn btn-primary"
              :style="{
                backgroundColor: '#141727',
                color: 'white',
                border: '2px solid #141727',
              }"
            >
              {{ $t("update_team") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import TeamDataService from "../../services/TeamDataService.js";
import AreaDataService from "../../services/AreaDataService.js";
import OfficesDataService from "../../services/OfficesDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import Swal from "sweetalert2";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      teams: [],
      areas: [],
      offices: [],
      teamIncharge1: [],
      teamIncharge2: [],
      availableMembers: [],
      currentTeam: {
        id: "",
        name: "",
        office_id: "",
        team_incharge_1: "",
        team_incharge_2: "",
        area: "",
        teamMembers: [],
      },
      newTeam: {
        teamName: "",
        teamIncharge1: "",
        teamIncharge2: "",
        officeId: "",
        area: "",
        area_id: "",
        teamMembers: [],
      },
      editTeam: {
        teamName: "",
        teamIncharge1: "",
        teamIncharge2: "",
        officeId: "",
        area: "",
        area_id: "",
        teamMembers: [],
      },
      showAddModal: false,
      showEditModal: false,
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    paginatedTeams() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.teams.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.teams.length / this.perPage);
    },
    filteredUsersForIncharge2() {
      // Filter out the user selected in team_incharge_1
      return this.users.filter(
        (user) => user.id !== this.newTeam.teamIncharge1?.id
      );
    },
    filteredUsersForTeamMembers() {
      // Filter out the user selected in team_incharge_1 and team_incharge_2
      return this.users.filter(
        (user) =>
          user.id !== this.newTeam.teamIncharge1?.id &&
          user.id !== this.newTeam.teamIncharge2?.id
      );
    },
  },
  created() {
    this.getAllTeams();
    this.getAllAreas();
    this.getAllOffices();
    this.getAllUser();
  },
  methods: {
    customLabel(option) {
      return `${option.f_name} ${option.l_name}`; // Concatenates first and last name
    },
    getAllAreas() {
      AreaDataService.getAll()
        .then((response) => {
          this.areas = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllOffices() {
      OfficesDataService.getAll()
        .then((response) => {
          this.offices = response.data.offices;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllTeams() {
      TeamDataService.getAll()
        .then((response) => {
          this.teams = response.data.teams;
          console.log(this.teams);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllUser() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addTeam() {
      const payload = {
        name: this.newTeam.teamName,
        office_id: this.newTeam.offices.id,
        team_incharge_1: this.newTeam.teamIncharge1.id,
        team_incharge_2: this.newTeam.teamIncharge2.id,
        area: this.newTeam.area.area,
        area_id: this.newTeam.area.id,
        team_members: this.newTeam.teamMembers.map((member) => member.id),
      };

      TeamDataService.create(payload)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Team added successfully!",
            confirmButtonText: "OK",
          })
            .then(() => {
              this.showAddModal = false; // Close the modal after adding team
              this.teams = response.data.teams; // Update teams data
              this.newTeam = {
                // Reset newTeam data for next addition
                teamName: "",
                teamIncharge1: "",
                teamIncharge2: "",
                officeId: "",
                area: "",
                area_id: "",
                teamMembers: [],
              };
            })
            .then(() => {
              window.location.reload();
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    openEditModal(team) {
      TeamDataService.get(team.id)
        .then((response) => {
          const fetchedTeam = response.data.team;

          // Populate the editTeam object with fetched data
          this.editTeam = {
            id: fetchedTeam.id,
            teamName: fetchedTeam.name,
            teamIncharge1: fetchedTeam.team_incharge_1,
            teamIncharge2: fetchedTeam.team_incharge_2,
            offices: fetchedTeam.office_id,
            area: fetchedTeam.area_id,
            // Map team details to include both user_id and f_name for display
            teamMembers: fetchedTeam.team_details.map((member) => ({
              id: member.user_id,
              f_name: member.user.f_name,
              l_name: member.user.l_name,
            })),
          };

          this.showEditModal = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateTeam() {
      const updateData = {
        id: this.editTeam.id,
        name: this.editTeam.teamName,
        office_id: this.editTeam.offices,
        team_incharge_1: this.editTeam.teamIncharge1,
        team_incharge_2: this.editTeam.teamIncharge2,
        area_id: this.editTeam.area,
        team_members: this.editTeam.teamMembers.map((member) => {
          // Use either member's id or user_id
          return member.id || member.user_id;
        }),
      };

      // Log updateData to debug
      console.log("Update Data:", updateData);

      TeamDataService.update(updateData)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Team updated successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    confirmDelete(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTeam(index, id);
          Swal.fire("Deleted!", "The Team has been deleted.", "success").then(
            () => {
              // window.location.reload();
            }
          );
        }
      });
    },
    deleteTeam(index, id) {
      TeamDataService.delete(id)
        .then((response) => {
          console.log(response);
          this.teams.splice(index, 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    pageLinkStyle(page) {
      return {
        backgroundColor: this.currentPage === page ? "#141727" : "white",
        color: this.currentPage === page ? "white" : "#141727",
        borderRadius: "10px",
        width: "40px",
      };
    },
  },
};
</script>

<style>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.modal.show .modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
</style>
