<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h6>{{ $t("questions") }}</h6>
      <button
        type="button"
        class="btn btn-primary"
        @click="showAddModal = true"
        style="
          background-color: #141727;
          color: white;
          border: 2px solid #141727;
        "
      >
        {{ $t("add_question") }}
      </button>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                प्रश्न
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("category") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                पर्याय अ
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                पर्याय ब
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                पर्याय क
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                स्थान
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("action") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(question, index) in paginatedQuestions" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ question.question }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ question.survey_category_name?.category_name || "" }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ question.option1 }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ question.option2 }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ question.option3 }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ question.area?.name || "No Area" }}
                </p>
              </td>
              <td class="align-middle">
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Edit Question"
                  @click="openEditModal(index)"
                >
                  <i class="fas fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  class="text-danger font-weight-bold text-xs ms-3"
                  @click="confirmDelete(index, question.id)"
                >
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a
            class="page-link"
            href="javascript:;"
            @click="changePage(currentPage - 1)"
            style="border-radius: 10px !important; width: 70px"
            >{{ $t("previous") }}</a
          >
        </li>
        <li
          class="page-item"
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
        >
          <a
            class="page-link"
            href="javascript:;"
            @click="changePage(page)"
            :style="currentPage === page ? activePageStyle : {}"
            >{{ page }}</a
          >
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a
            class="page-link"
            href="javascript:;"
            @click="changePage(currentPage + 1)"
            style="border-radius: 10px !important; width: 70px"
            >{{ $t("next") }}</a
          >
        </li>
      </ul>
    </div>

    <!-- Add Question Modal -->
    <!-- Add Question Modal -->
    <div
      v-if="showAddModal"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("add_question") }}</h5>
            <button
              type="button"
              class="close"
              @click="showAddModal = false"
              aria-label="Close"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
                border-radius: 50%;
              "
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addQuestion">
              <div class="form-group">
                <label for="candidate_id" class="form-control-label">
                  {{ $t("Candidate") }}
                </label>
                <div class="select-wrapper">
                  <select
                    v-model="newQuestion.candidate_id"
                    id="candidate_id"
                    class="form-control select-with-arrow"
                    aria-label="Candidate"
                  >
                    <option value="" disabled selected>
                      {{ $t("select_candidate") }}
                    </option>
                    <option
                      v-for="candidate in candidates"
                      :key="candidate.id"
                      :value="candidate.id"
                    >
                      {{ candidate.f_name }} {{ candidate.l_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="newQuestion">{{ $t("category") }}</label>
                <select
                  v-model="newQuestion.category_id"
                  id="category_id"
                  class="form-control"
                >
                  <option value="" selected>{{ $t("select_category") }}</option>
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.category_name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="newQuestion">{{ $t("question") }}</label>
                <input
                  v-model="newQuestion.question"
                  type="text"
                  class="form-control"
                  id="newQuestion"
                  required
                />
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-9">
                    <label for="option1">{{ $t("option_a") }}</label>
                    <input
                      v-model="newQuestion.option1"
                      type="text"
                      class="form-control"
                      id="option1"
                      required
                    />
                  </div>
                  <div class="col-sm-3">
                    <label for="answer1">{{ $t("weightage") }}</label>
                    <select
                      v-model="newQuestion.answer1"
                      class="form-select"
                      id="answer1"
                      required
                    >
                      <option
                        v-for="v in allWeightage"
                        :key="v.id"
                        :value="v.weightage"
                      >
                        {{ v.weightage }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-9">
                    <label for="option2">{{ $t("option_b") }}</label>
                    <input
                      v-model="newQuestion.option2"
                      type="text"
                      class="form-control"
                      id="option2"
                      required
                    />
                  </div>
                  <div class="col-sm-3">
                    <label for="answer2">{{ $t("weightage") }}</label>
                    <select
                      v-model="newQuestion.answer2"
                      class="form-select"
                      id="answer2"
                      required
                    >
                      <option
                        v-for="v in allWeightage"
                        :key="v.id"
                        :value="v.weightage"
                      >
                        {{ v.weightage }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-9">
                    <label for="option3">{{ $t("option_c") }}</label>
                    <input
                      v-model="newQuestion.option3"
                      type="text"
                      class="form-control"
                      id="option3"
                      required
                    />
                  </div>
                  <div class="col-sm-3">
                    <label for="answer3">{{ $t("weightage") }}</label>
                    <select
                      v-model="newQuestion.answer3"
                      class="form-select"
                      id="answer3"
                      required
                    >
                      <option
                        v-for="v in allWeightage"
                        :key="v.id"
                        :value="v.weightage"
                      >
                        {{ v.weightage }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="location">{{ $t("area") }}</label>
                <select
                  v-model="newQuestion.location"
                  id="location"
                  class="form-control"
                  required
                >
                  <option value="" disabled selected>
                    {{ $t("select_area") }}
                  </option>
                  <option
                    v-for="area in locations"
                    :key="area.id"
                    :value="area.id"
                  >
                    {{ area.name }}
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="startDate">{{ $t("start_date") }}</label>
                    <input
                      v-model="newQuestion.start_date"
                      type="datetime-local"
                      class="form-control"
                      id="startDate"
                      required
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="endDate">{{ $t("end_date") }}</label>
                    <input
                      v-model="newQuestion.end_date"
                      type="datetime-local"
                      class="form-control"
                      id="endDate"
                      required
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary mt-3"
                style="
                  background-color: #141727;
                  color: white;
                  border: 2px solid #141727;
                "
              >
                {{ $t("add_question") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Question Modal -->
    <div
      v-if="showEditModal"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("edit_question") }}</h5>
            <button
              type="button"
              class="close"
              @click="showEditModal = false"
              aria-label="Close"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
                border-radius: 50%;
              "
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateQuestion">
              <div class="form-group">
                <label for="candidate_id" class="form-control-label">
                  {{ $t("Candidate") }}
                </label>
                <div class="select-wrapper">
                  <select
                    v-model="newQuestion.candidate_id"
                    id="candidate_id"
                    class="form-control select-with-arrow"
                    aria-label="Candidate"
                  >
                    <option value="" disabled selected>
                      {{ $t("select_candidate") }}
                    </option>
                    <option
                      v-for="candidate in candidates"
                      :key="candidate.id"
                      :value="candidate.id"
                    >
                      {{ candidate.f_name }} {{ candidate.l_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="editQuestion">{{ $t("category") }}</label>
                <select
                  v-model="editQuestion.category_id"
                  id="category_id"
                  class="form-control"
                >
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.category_name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="editQuestion">{{ $t("question") }}</label>
                <input
                  v-model="editQuestion.question"
                  type="text"
                  class="form-control"
                  id="editQuestion"
                  required
                />
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-9">
                    <label for="editoption1">{{ $t("option_a") }}</label>
                    <input
                      v-model="editQuestion.option1"
                      type="text"
                      class="form-control"
                      id="editoption1"
                      required
                    />
                  </div>
                  <div class="col-sm-3">
                    <label for="answer1">{{ $t("weightage") }}</label>
                    <select
                      v-model="editQuestion.answer1"
                      class="form-select"
                      id="answer1"
                    >
                      <option
                        v-for="v in allWeightage"
                        :key="v.id"
                        :value="v.weightage"
                      >
                        {{ v.weightage }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-9">
                    <label for="editoption2">{{ $t("option_b") }}</label>
                    <input
                      v-model="editQuestion.option2"
                      type="text"
                      class="form-control"
                      id="editoption2"
                      required
                    />
                  </div>
                  <div class="col-sm-3">
                    <label for="answer2">{{ $t("weightage") }}</label>
                    <select
                      v-model="editQuestion.answer2"
                      class="form-select"
                      id="answer2"
                    >
                      <option
                        v-for="v in allWeightage"
                        :key="v.id"
                        :value="v.weightage"
                      >
                        {{ v.weightage }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-9">
                    <label for="editoption3">{{ $t("option_c") }}</label>
                    <input
                      v-model="editQuestion.option3"
                      type="text"
                      class="form-control"
                      id="editoption3"
                      required
                    />
                  </div>
                  <div class="col-sm-3">
                    <label for="answer3">{{ $t("weightage") }}</label>
                    <select
                      v-model="editQuestion.answer3"
                      class="form-select"
                      id="answer3"
                    >
                      <option
                        v-for="v in allWeightage"
                        :key="v.id"
                        :value="v.weightage"
                      >
                        {{ v.weightage }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="location">{{ $t("area") }}</label>
                <select
                  v-model="editQuestion.location"
                  id="location"
                  class="form-control"
                >
                  <option
                    v-for="area in locations"
                    :key="area.id"
                    :value="area.id"
                  >
                    {{ area.name }}
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="startDate">{{ $t("start_date") }}</label>
                    <input
                      v-model="editQuestion.start_date"
                      type="datetime-local"
                      class="form-control"
                      id="startDate"
                      required
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="endDate">{{ $t("end_date") }}</label>
                    <input
                      v-model="editQuestion.end_date"
                      type="datetime-local"
                      class="form-control"
                      id="endDate"
                      required
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary mt-3"
                style="
                  background-color: #141727;
                  color: white;
                  border: 2px solid #141727;
                "
              >
                {{ $t("update_question") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import QuestionsDataService from "../../services/QuestionsDataService.js";
import AreaDataService from "../../services/AreaDataService.js";
import CategoryMasterDataService from "../../services/CategoryMasterDataService.js";
import { formatDateTime } from "../../utils.js";
import UsersDataService from "../../services/UsersDataService.js";

export default {
  name: "QuestionsTable",
  data() {
    return {
      questions: [],
      locations: [],
      candidates: [],
      allWeightage: [
        { id: 1, weightage: "4" },
        { id: 2, weightage: "3" },
        { id: 3, weightage: "2" },
        { id: 4, weightage: "1" },
        { id: 5, weightage: "0" },
        { id: 6, weightage: "-1" },
        { id: 7, weightage: "-2" },
        { id: 8, weightage: "-3" },
        { id: 9, weightage: "-4" },
      ],
      selectedOption: null,
      showAddModal: false,
      showEditModal: false,
      newQuestion: {
        category_id: "",
        question: "",
        option1: "",
        option2: "",
        option3: "",
        location: "",
        answer1: "",
        answer2: "",
        answer3: "",
        start_date: "",
        end_date: "",
        candidate_id: "",
      },
      editQuestion: {
        category_id: "",
        question: "",
        option1: "",
        option2: "",
        option3: "",
        location: "",
        answer1: "",
        answer2: "",
        answer3: "",
        start_date: "",
        end_date: "",
        candidate_id: "",
      },
      editIndex: null,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getAllQuestions();
    this.getAllArea();
    this.getAllCandidate();
    this.getCandidates();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.questions.length / this.itemsPerPage);
    },
    paginatedQuestions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.questions.slice(start, end);
    },
    activePageStyle() {
      return {
        backgroundColor: "#141727",
        color: "white",
      };
    },
  },
  methods: {
    getCandidates() {
      UsersDataService.getAdminCandidate()
        .then((response) => {
          this.candidates = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getAllQuestions() {
  QuestionsDataService.getAll()
    .then((response) => {
      console.log("GET QUIZ", response.data.surveyQuestions);
      if (response.data && response.data.surveyQuestions && Array.isArray(response.data.surveyQuestions)) {
        this.questions = response.data.surveyQuestions;
        // If paginatedQuestions should contain all questions, assign it here
        this.paginatedQuestions = this.questions; // or logic to paginate if necessary
      } else {
        console.error("Invalid data format or surveyQuestions is null.");
        this.questions = [];
        this.paginatedQuestions = [];
      }
    })
    .catch((e) => {
      console.log("Error fetching survey questions", e);
    });
}
,
    getAllCandidate() {
      CategoryMasterDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.categories = response.data.categoryMasterData;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllArea() {
      AreaDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.locations = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    addQuestion() {
      this.questions.push({ ...this.newQuestion });

      const candidateId = localStorage.getItem("candidateId");
      console.log(candidateId);

      const payload = {
        category_id: this.newQuestion.category_id,
        question: this.newQuestion.question,
        candidate_id: this.newQuestion.candidate_id,
        option1: this.newQuestion.option1,
        option2: this.newQuestion.option2,
        option3: this.newQuestion.option3,
        location: this.newQuestion.location,
        answer1: this.newQuestion.answer1,
        answer2: this.newQuestion.answer2,
        answer3: this.newQuestion.answer3,
        start_date: this.newQuestion.start_date,
        end_date: this.newQuestion.end_date,
      };

      QuestionsDataService.create(payload).then((response) => {
        console.log(response.data);
        this.newQuestion = {
          candidate_id: "",
          category_id: "",
          question: "",
          option1: "",
          option2: "",
          option3: "",
          location: "",
          answer1: "",
          answer2: "",
          answer3: "",
          start_date: "",
          end_date: "",
        };
        this.showAddModal = false;
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Question added successfully!",
        confirmButtonText: "OK",
      })
        .then(() => {
          this.$router.push("/questions");
        })
        .then(() => {
          location.reload();
        })

        .catch((e) => {
          console.log(e);
        });
    },
    confirmDelete(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteQuestion(index, id);
          Swal.fire(
            "Deleted!",
            "The Question has been deleted.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    },
    deleteQuestion(index, id) {
      QuestionsDataService.delete(id)
        .then((response) => {
          console.log(response);
          this.questions.splice(index, 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openEditModal(index) {
      let modifiedQuestion = { ...this.questions[index] };

      modifiedQuestion.start_date = formatDateTime(modifiedQuestion.start_date);
      modifiedQuestion.end_date = formatDateTime(modifiedQuestion.end_date);

      this.editQuestion = modifiedQuestion;
      this.editIndex = index;
      this.showEditModal = true;
    },
    updateQuestion() {
      this.questions.splice(this.editIndex, 1, { ...this.editQuestion });

      const candidateId = localStorage.getItem("candidateId");
      console.log(candidateId);

      const payload = {
        id: this.editQuestion.id,
        category_id: this.editQuestion.category_id,
        question: this.editQuestion.question,
        candidate_id: this.editQuestion.candidate_id,
        option1: this.editQuestion.option1,
        option2: this.editQuestion.option2,
        option3: this.editQuestion.option3,
        location: this.editQuestion.location,
        answer1: this.editQuestion.answer1,
        answer2: this.editQuestion.answer2,
        answer3: this.editQuestion.answer3,
        start_date: this.editQuestion.start_date,
        end_date: this.editQuestion.end_date,
      };

      QuestionsDataService.update(payload).then((response) => {
        console.log(response.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Question Updated successfully!",
          confirmButtonText: "OK",
        })
          .then(() => {
            this.getAllQuestions();

            this.editQuestion = {
              candidate_id: "",
              category_id: "",
              question: "",
              option1: "",
              option2: "",
              option3: "",
              location: "",
              start_date: "",
              end_date: "",
            };
            this.showEditModal = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
  },
};
</script>

<style scoped>
/* Add your styles here if needed */
.pagination .page-item.active .page-link {
  background-color: #141727 !important;
  border-color: #141727 !important;
  color: white !important;
}

.page-link {
  color: #141727 !important;
}
</style>
