<template>
  <div class="container" style="background-color: white;padding: 10px;border-radius: 10px;">
    <div class="row mb-3">
      <div class="col-sm-4">
        <label>यादी भाग क्र</label>
        <select
          class="form-control"
          v-model="selectedPartno"
          @change="loadVotersByPartno"
        >
          <option value="">Select यादी भाग क्र</option>
          <option
            v-for="part in partnoList"
            :key="part.partno"
            :value="part.partno"
          >
            {{ part.partno }} - {{ part.area_name }}
          </option>
        </select>
      </div>
      <div class="col-sm-4">
        <label>Align with Party</label>
        <select
          class="form-control"
          v-model="selectedAlignParty"
          @change="applyFilters"
        >
          <option value="">Align with Party (All)</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
          <option value="prefer_not_to_say">Prefer Not to Say</option>
        </select>
      </div>
      <div class="col-sm-4">
        <label>Align with Candidate</label>
        <select
          class="form-control"
          v-model="selectedAlignCandidate"
          @change="applyFilters"
        >
          <option value="">Align with Candidate (All)</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
          <option value="prefer_not_to_say">Prefer Not to Say</option>
        </select>
      </div>
    </div>

    <!-- Loader -->
    <div v-if="loading" class="text-center mt-3">
      <span>Loading...</span>
    </div>

    <!-- Responsive Data Table -->
    <div v-else class="table-responsive">
      <table class="table mt-3">
        <thead>
          <tr style="font-size: 13px;">
            <th>Align with Party</th>
            <th>Align with Candidate</th>
            <th>Phone No</th>
            <th>AC No/Part No/SL No</th>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Voting Center</th>
            
          </tr>
        </thead>
        <tbody>
          <tr v-for="(voter, index) in paginatedData" :key="index" style="font-size: 13px;">
            <td>
              {{
                voter.voterInfo &&
                voter.voterInfo.align_with_party == "prefer_not_to_say"
                  ? "Prefer Not to Say"
                  : voter.voterInfo?.align_with_party || ""
              }}
            </td>
            <td>
              {{
                voter.voterInfo &&
                voter.voterInfo.align_with_candidate == "prefer_not_to_say"
                  ? "Prefer Not to Say"
                  : voter.voterInfo?.align_with_candidate || ""
              }}
            </td>
            <td>{{ voter.voterInfo ? voter.voterInfo.phone_no : "" }}</td>
            <td>{{ voter.acno }}/{{ voter.partno }}/{{ voter.slnoinpart }}</td>
            <td>{{ voter.name }}</td>
            <td>{{ voter.age }}</td>
            <td>{{ voter.gender }}</td>
            <td>{{ voter.section }}</td>
            <td>{{ voter.vcenter }}</td>
            
          </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div class="pagination d-flex justify-content-center">
        <button
          @click="prevPage"
          :disabled="currentPage === 1"
          class="btn btn-primary"
          style="background-color: #141727"
        >
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="btn btn-primary"
          style="background-color: #141727"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VotersSearchDataService from "../../services/VotersSearchDataService.js";

export default {
  name: "ReportByAge",
  data() {
    return {
      voterslist: [],
      filteredVotersList: [],
      partnoList: [],
      selectedPartno: "",
      selectedAlignParty: "",
      selectedAlignCandidate: "",
      loading: true,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredVotersList.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredVotersList.slice(start, start + this.itemsPerPage);
    },
  },
  created() {
    this.getAllPartno();
  },
  methods: {
    getAllPartno() {
      const defaultacno = localStorage.getItem("defaultacno");
      if (!defaultacno) {
        console.error("No acno found in localStorage");
        return;
      }
      VotersSearchDataService.getAllPartno(defaultacno)
        .then((response) => {
          this.partnoList = response.data.partnolist || [];
          this.partnoList.sort((a, b) => a.partno - b.partno);
          this.selectedPartno = this.partnoList[0]?.partno || "";
          this.loadVotersByPartno();
        })
        .catch((error) => console.error("Error fetching partno list:", error));
    },
    loadVotersByPartno() {
      this.loading = true;
      const acno = localStorage.getItem("defaultacno") || "";

      VotersSearchDataService.getAllAlphabetically(acno, this.selectedPartno)
        .then((response) => {
          this.voterslist = response.data.voterslist.flatMap(
            (group) => group.voters
          );
          this.voterslist.sort((a, b) => {
            if (a.partno !== b.partno) return a.partno - b.partno;
            return a.slnoinpart - b.slnoinpart;
          });
          this.applyFilters();
        })
        .catch((error) => console.error("Error during API call:", error))
        .finally(() => {
          this.loading = false;
          this.currentPage = 1;
        });
    },
    applyFilters() {
      this.filteredVotersList = this.voterslist.filter((voter) => {
        const alignsWithParty = voter.voterInfo
          ? voter.voterInfo.align_with_party
          : "";
        const alignsWithCandidate = voter.voterInfo
          ? voter.voterInfo.align_with_candidate
          : "";

        const partyCondition =
          this.selectedAlignParty === "" ||
          this.selectedAlignParty === alignsWithParty;

        const candidateCondition =
          this.selectedAlignCandidate === "" ||
          this.selectedAlignCandidate === alignsWithCandidate;

        return partyCondition && candidateCondition;
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
  },
};
</script>

<style>
.table-responsive {
  overflow-x: auto;
}
.pagination {
  margin-top: 1rem;
}
</style>
