<template>
  <div class="card mb-4">
    <div class="container">
      <div class="card-header pb-0">
        <h6>{{ $t("area") }}</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <form @submit.prevent="addLocation">
          <div class="form-group">
            <label for="locationName">{{ $t("location_name") }}</label>
            <input
              v-model="newLocation.name"
              type="text"
              class="form-control"
              id="locationName"
              required
            />
          </div>
          <div class="form-group mt-3">
            <label for="polygonLocationSearch">{{
              $t("Location Polygon")
            }}</label>
            <input
              v-model="searchPolygon"
              ref="polygonSearchInput"
              type="text"
              class="form-control"
              id="polygonLocationSearch"
              placeholder="Search for polygon location"
            />
            <div id="map" style="height: 400px; margin-top: 10px"></div>
          </div>
          <div class="form-group mt-3">
            <!-- Voting Center Location Search -->
            <label for="votingCenterLocationSearch">{{
              $t("Voting Center Location")
            }}</label>
            <input
              v-model="searchVotingCenter"
              ref="votingCenterSearchInput"
              type="text"
              class="form-control"
              id="votingCenterLocationSearch"
              placeholder="Search for voting center location"
            />
            <div
              id="votingCenterMap"
              style="height: 400px; margin-top: 10px"
            ></div>
          </div>
          <button
            type="submit"
            class="btn btn-primary mt-3"
            style="
              background-color: #141727;
              color: white;
              border: 2px solid #141727;
            "
          >
            {{ $t("add_location") }}
          </button>
        </form>

        <table class="table mt-4">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("location_name") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("status") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("action") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(location, index) in paginatedLocations" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ location.name }}</p>
              </td>
              <td>
                <label class="switch">
                  <input
                    type="checkbox"
                    :checked="location.status"
                    @change="toggleStatus(location)"
                  />
                  <span class="slider round"></span>
                </label>
              </td>
              <td>
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  @click="navigateToEditArea(location.id)"
                >
                  <i class="fas fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  class="text-danger font-weight-bold text-xs ms-3"
                  @click="confirmDelete(index, location.id)"
                >
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">
            {{ $t("previous") }}
          </button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">
            {{ $t("next") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AreaDataService from "../../services/AreaDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      newLocation: {
        name: "",
        area: [],
      },
      locations: [],
      currentPage: 1,
      itemsPerPage: 10,
      searchPolygon: "", // For polygon area search
      searchVotingCenter: "", // For voting center search
      map: null,
      votingCenterMap: null, // Separate map for voting center
      marker: null,
      votingCenterMarker: null, // Marker for voting center
      polygonAutocomplete: null,
      votingCenterAutocomplete: null, // Autocomplete for voting center
      selectedLanguage: this.$i18n.locale,
      markerLocation: null, // To store marker location for voting center
    };
  },
  mounted() {
    this.initializeMap();
    this.initializeVotingCenterMap(); // Initialize voting center map
    this.initializePolygonAutocomplete(); // Separate autocomplete for polygon
    this.initializeVotingCenterAutocomplete(); // Separate autocomplete for voting center
  },
  created() {
    this.getAllArea();
  },
  computed: {
    paginatedLocations() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.locations.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.locations.length / this.itemsPerPage);
    },
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    navigateToEditArea(id) {
      this.$router.push({ name: "Area Edit", params: { id } });
    },
    getAllArea() {
      AreaDataService.getAll()
        .then((response) => {
          this.locations = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addLocation() {
      function transformArea(area) {
        let coordinates = area.map((point) => [point.lng, point.lat]);
        coordinates.push([area[0].lng, area[0].lat]); // Close the polygon

        return {
          type: "Polygon",
          coordinates: [coordinates],
        };
      }

      if (!this.newLocation.area.length || this.newLocation.area.length < 3) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select area with polygon.",
          confirmButtonText: "OK",
        });
        return;
      }

      const transformedArea = transformArea(this.newLocation.area);

      const payload = {
        name: this.newLocation.name,
        area: transformedArea,
        voting_center_location: {
          type: "Point",
          coordinates: [this.markerLocation.lng, this.markerLocation.lat], // Send as [lng, lat]
        },
      };

      AreaDataService.create(payload)
        .then((response) => {
          console.log(response);
          if (localStorage.getItem("steps") < 4) {
            const steps = parseInt(localStorage.getItem("steps"), 10);
            const updatedStep = steps + 1;

            const payload = {
              admin_id: localStorage.getItem("admin_id"),
              step: updatedStep,
            };
            UsersDataService.updateStep(payload)
              .then((response) => {
                console.log(response);
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Area added successfully!",
                  confirmButtonText: "OK",
                }).then(() => {
                  localStorage.setItem("steps", updatedStep),
                    this.$router.push(`/installation/${updatedStep}`);
                });

                this.getAllArea();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Area added successfully!",
              confirmButtonText: "OK",
            }).then(() => {
              this.$router.push(`/area`);
            });
          }
          this.getAllArea();
        })
        .catch((e) => {
          console.log(e);
        });

      this.newLocation = {
        name: "",
        area: [],
      };
    },

    initializeMap() {
      const mapOptions = {
        zoom: 12,
        center: { lat: 18.5204, lng: 73.8567 }, // Centered on Pune
      };
      this.map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );

      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          editable: true,
        },
      });

      drawingManager.setMap(this.map);

      let currentPolygon = null; // To keep track of the currently drawn polygon

      google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        (event) => {
          if (currentPolygon) {
            currentPolygon.setMap(null);
          }

          if (event.type === google.maps.drawing.OverlayType.POLYGON) {
            currentPolygon = event.overlay; // Save the current polygon
            this.newLocation.area = event.overlay
              .getPath()
              .getArray()
              .map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
          }
        }
      );
    },

    initializeVotingCenterMap() {
      const votingCenterMapOptions = {
        zoom: 12,
        center: { lat: 18.5204, lng: 73.8567 }, // Centered on Pune
      };
      this.votingCenterMap = new google.maps.Map(
        document.getElementById("votingCenterMap"),
        votingCenterMapOptions
      );
    },

    initializePolygonAutocomplete() {
      const input = this.$refs.polygonSearchInput;
      this.polygonAutocomplete = new google.maps.places.Autocomplete(input);
      this.polygonAutocomplete.addListener("place_changed", () => {
        const place = this.polygonAutocomplete.getPlace();
        if (place.geometry) {
          this.map.setCenter(place.geometry.location); // Center map on selected place
        }
      });
    },

    initializeVotingCenterAutocomplete() {
      const input = this.$refs.votingCenterSearchInput;
      this.votingCenterAutocomplete = new google.maps.places.Autocomplete(
        input
      );
      this.votingCenterAutocomplete.addListener("place_changed", () => {
        const place = this.votingCenterAutocomplete.getPlace();
        if (place.geometry) {
          this.markerLocation = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          this.votingCenterMap.setCenter(this.markerLocation); // Center map on selected place

          if (this.votingCenterMarker) {
            this.votingCenterMarker.setMap(null); // Remove previous marker
          }

          // Create a new marker for the voting center
          this.votingCenterMarker = new google.maps.Marker({
            map: this.votingCenterMap,
            position: this.markerLocation,
            draggable: true,
          });

          // Optional: Handle drag end event
          google.maps.event.addListener(
            this.votingCenterMarker,
            "dragend",
            () => {
              const position = this.votingCenterMarker.getPosition();
              this.markerLocation = {
                lat: position.lat(),
                lng: position.lng(),
              };
            }
          );
        }
      });
    },

    toggleStatus(location) {
      location.status = !location.status;
      console.log(
        `Location ${location.name} status: ${
          location.status ? "Active" : "Inactive"
        }`
      );
    },
    confirmDelete(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteArea(index, id);
          Swal.fire("Deleted!", "The Area has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        }
      });
    },
    deleteArea(index, id) {
      AreaDataService.delete(id)
        .then((response) => {
          console.log(response);
          this.locations.splice(index, 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style scoped>
.langdrop {
  width: 130px;
  margin-left: 87%;
}
@media (min-width: 300px) and (max-width: 550px) {
  .langdrop {
    width: 130px;
    margin-left: 57%;
  }
}
/* Switch component styles */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #82d616;
}

input:focus + .slider {
  box-shadow: 0 0 1px #82d616;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f8f9fa;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}
</style>
