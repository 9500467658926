<template>
  <div class="card mb-4">
    <div class="container">
      <div class="card-header pb-0">
        <h6>Edit</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <form @submit.prevent="updateLocation">
          <div class="form-group">
            <label for="locationName">Location Name</label>
            <input v-model="newLocation.name" type="text" class="form-control" id="locationName" required />
          </div>
          <div class="form-group mt-3">
            <label for="polygonLocationSearch">Location Polygon</label>
            <input v-model="searchPolygon" ref="polygonSearchInput" type="text" class="form-control" id="polygonLocationSearch" placeholder="Search for polygon location" />
            <div id="map" style="height: 400px; margin-top: 10px;"></div>
          </div>
          <div class="form-group mt-3">
            <label for="votingCenterLocationSearch">Voting Center Location</label>
            <input v-model="searchVotingCenter" ref="votingCenterSearchInput" type="text" class="form-control" id="votingCenterLocationSearch" placeholder="Search for voting center location" />
            <div id="votingCenterMap" style="height: 400px; margin-top: 10px;"></div>
          </div>
          <button type="submit" class="btn btn-primary mt-3" style="background-color: #141727; color: white; border: 2px solid #141727;">Update Location</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AreaDataService from "../../services/AreaDataService.js";
// import Swal from "sweetalert2";

export default {
  data() {
    return {
      areaId: null,
      newLocation: {
        name: '',
        area: [],
        voting_center_location: { lat: null, lng: null },
      },
      map: null,
      votingCenterMap: null,
      votingCenterMarker: null,
      searchPolygon: "",
      searchVotingCenter: "",
    };
  },
  mounted() {
    this.initializeMap();
    this.initializeVotingCenterMap();
    this.initializePolygonAutocomplete();
    this.initializeVotingCenterAutocomplete();
  },
  created() {
    this.areaId = this.$route.params.id;
    this.getAllArea(this.areaId);
  },
  methods: {
    getAllArea(areaId) {
  AreaDataService.get(areaId)
    .then(response => {
      const areaData = response.data.area;

      // Set area ID
      this.newLocation.id = areaData.id; // Make sure to set the ID

      // Set area name
      this.newLocation.name = areaData.name;

      // Parse coordinates for the polygon
      this.newLocation.area = areaData.area.coordinates[0].map(coord => ({
        lat: coord[1], // lat
        lng: coord[0]  // lng
      }));

      // Parse voting center location
      const votingCenterCoordinates = areaData.voting_center_location.coordinates;
      this.newLocation.voting_center_location = { lat: votingCenterCoordinates[1], lng: votingCenterCoordinates[0] };

      // Initialize the maps with the fetched data
      this.initializeMap();
      this.initializeVotingCenterMap();
    })
    .catch(e => {
      console.log(e);
    });
}
,
    updateLocation() {
  // Ensure the first and last points are the same to form a closed polygon
  let transformedArea = this.newLocation.area.map(point => [point.lng, point.lat]);

  // Check if the polygon is closed; if not, close it
  if (transformedArea.length > 0 && (transformedArea[0][0] !== transformedArea[transformedArea.length - 1][0] ||
      transformedArea[0][1] !== transformedArea[transformedArea.length - 1][1])) {
    transformedArea.push(transformedArea[0]);
  }

  // Debugging log for voting_center_location
  console.log("Final voting_center_location to be sent in payload:", this.newLocation.voting_center_location);

  const payload = {
    id: this.newLocation.id,
    name: this.newLocation.name,
    area: {
      type: "Polygon",
      coordinates: [transformedArea]
    },
    voting_center_location: {
      type: "Point",
      coordinates: [
        this.newLocation.voting_center_location.lng, 
        this.newLocation.voting_center_location.lat 
      ]
    }
  };

  console.log("Payload to be sent:", JSON.stringify(payload, null, 2)); // Log payload

  AreaDataService.update(payload)
    .then(response => {
      console.log("Response from update API:", response.data); // Log response data
      this.$router.push({ name: 'Area' });
    })
    .catch(e => {
      console.error("Error occurred while updating:", e.response ? e.response.data : e.message); // Log errors
    });
}

,
    initializeMap() {
      const mapOptions = {
        zoom: 12,
        center: this.newLocation.area.length > 0
          ? this.newLocation.area[0] // Center map based on the area
          : this.newLocation.voting_center_location // Default to voting center location if area is empty
      };

      this.map = new google.maps.Map(document.getElementById("map"), mapOptions);

      // Add polygon drawing functionality
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          editable: true,
        },
      });

      drawingManager.setMap(this.map);

      let currentPolygon = null; // To keep track of the currently drawn polygon

      google.maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
        if (currentPolygon) {
          currentPolygon.setMap(null);
        }

        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          currentPolygon = event.overlay; // Save the current polygon
          this.newLocation.area = event.overlay
            .getPath()
            .getArray()
            .map(coord => ({ lat: coord.lat(), lng: coord.lng() }));
        }
      });

      // Set existing polygon if available
      if (this.newLocation.area.length) {
        const polygonPath = this.newLocation.area.map(coord => new google.maps.LatLng(coord.lat, coord.lng));
        currentPolygon = new google.maps.Polygon({
          paths: polygonPath,
          editable: true,
          draggable: false
        });
        currentPolygon.setMap(this.map);
      }
    },

    initializeVotingCenterMap() {
      const votingCenterMapOptions = {
        zoom: 12,
        center: this.newLocation.voting_center_location, // Center on voting center location
      };

      this.votingCenterMap = new google.maps.Map(
        document.getElementById("votingCenterMap"),
        votingCenterMapOptions
      );

      if (this.newLocation.voting_center_location.lat && this.newLocation.voting_center_location.lng) {
        this.votingCenterMarker = new google.maps.Marker({
          map: this.votingCenterMap,
          position: this.newLocation.voting_center_location,
          draggable: true,
        });

        // Handle drag end event for voting center marker
        google.maps.event.addListener(this.votingCenterMarker, "dragend", () => {
          const position = this.votingCenterMarker.getPosition();
          this.newLocation.voting_center_location = {
            lat: position.lat(),
            lng: position.lng(),
          };
        });
      }
    },

    initializePolygonAutocomplete() {
      const input = this.$refs.polygonSearchInput;
      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          this.map.setCenter(place.geometry.location); // Center map on selected place
        }
      });
    },

    initializeVotingCenterAutocomplete() {
      const input = this.$refs.votingCenterSearchInput;
      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          this.newLocation.voting_center_location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          this.votingCenterMap.setCenter(this.newLocation.voting_center_location); // Center map on selected place

          if (this.votingCenterMarker) {
            this.votingCenterMarker.setMap(null); // Remove existing marker
          }

          this.votingCenterMarker = new google.maps.Marker({
            position: this.newLocation.voting_center_location,
            map: this.votingCenterMap,
            draggable: true,
          });

          // Handle drag end event for voting center marker
          google.maps.event.addListener(this.votingCenterMarker, "dragend", () => {
            const position = this.votingCenterMarker.getPosition();
            this.newLocation.voting_center_location = {
              lat: position.lat(),
              lng: position.lng(),
            };
          });
        }
      });
    },
  },
};
</script>




<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #82d616;
}

input:focus + .slider {
  box-shadow: 0 0 1px #82d616;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.table {
  width: 100%;
  margin-top: 20px;
}
.table .btn {
  margin-right: 5px;
}
</style>
