<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h5>Edit Office</h5>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="updateOffice" class="container">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="editOfficeName">Office Name</label>
              <input
                v-model="currentOffice.officeName"
                type="text"
                class="form-control"
                id="editOfficeName"
                required
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyArea">Area</label>
              <div class="select-wrapper">
                <select
                  v-model="currentOffice.area_id"
                  class="form-control select-with-arrow"
                  id="editOrganizationId"
                  required
                >
                  <option value="" selected>Select area</option>
                  <option v-for="area in areas" :key="area.id" :value="area.id">
                    {{ area.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-4">
            <div class="form-group">
              <label for="teamMembers">Teams</label>
              <multiselect v-model="currentOffice.teams" :options="availableTeams" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name"></multiselect>
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="editPrimaryIncharge">Primary Incharge</label>
              <div class="select-wrapper">
                <select
                  v-model="currentOffice.primaryIncharge"
                  class="form-control select-with-arrow"
                  id="editPrimaryIncharge"
                  required
                >
                  <option value="" selected>Select Primary Incharge</option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label for="editSecondaryIncharge">Secondary Incharge</label>
              <div class="select-wrapper">
                <select
                  v-model="currentOffice.secondaryIncharge"
                  class="form-control select-with-arrow"
                  id="editSecondaryIncharge"
                >
                  <option value="" selected>Select Secondary Incharge</option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="OfficeLocation">Location</label>
              <!-- <google-map v-model="currentOffice.location" @point-format="updatePointFormat" @address-changed="updateAddress" /> -->

              <input
                id="autocomplete"
                v-model="locationSearch"
                placeholder="Search for a location"
                class="form-control"
              />
              <GoogleMapEdit
                :center="mapCenter"
                :markerPosition="markerPosition"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <center>
          <button
            type="submit"
            class="btn btn-primary mt-3"
            style="
              background-color: #141727;
              color: white;
              border: 2px solid #141727;
            "
            :disabled="isLoading"
            :loading="isLoading"
          >
            Update Office
          </button>
        </center>
      </form>
    </div>
  </div>
</template>

<script>
import OfficesDataService from "../../services/OfficesDataService.js";
import AreaDataService from "../../services/AreaDataService.js";
// import TeamDataService from "../../services/TeamDataService.js";
import GoogleMapEdit from "./GoogleMapEdit.vue";
// import Multiselect from 'vue-multiselect';
// import 'vue-multiselect/dist/vue-multiselect.css';
import UsersDataService from "../../services/UsersDataService.js";
import Swal from "sweetalert2";

export default {
  name: "AddOffice",
  components: {
    GoogleMapEdit,
  },
  data() {
    return {
      // availableTeams: [],
      isLoading: false,
      currentOffice: {
        officeName: "",
        primaryIncharge: "",
        address: "",
        area: "",
        area_id: "",
        location_name: "",
        location: "",
        secondaryIncharge: "",
        teams: [],
      },
      areas: [],
      users: [],
      locationSearch: "",
      mapCenter: { lat: 0, lng: 0 },
      markerPosition: { lat: 0, lng: 0 },
      geocoder: null,
      autocomplete: null,
    };
  },
  created() {
    this.officeId = this.$route.params.id;
    this.getOffice(this.officeId);
    this.getAllAreas();
    // this.getAllTeams();
    this.getAllUsers();
  },
  methods: {
    getAllAreas() {
      AreaDataService.getAll()
        .then((response) => {
          this.areas = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // getAllTeams() {
    //   TeamDataService.getAll()
    //     .then(response => {
    //       this.availableTeams = response.data.teams;
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    // },
    getAllUsers() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
          console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // updateAddress(address) {
    //   this.currentOffice.address = address;
    // },
    // updatePointFormat(pointFormat) {
    //   this.currentOffice.location_name = pointFormat;
    // },
    fetchLocationData() {
      if (
        this.currentOffice.location &&
        this.currentOffice.location.coordinates
      ) {
        const [lng, lat] = this.currentOffice.location.coordinates;
        const locationData = {
          lat: lat,
          lng: lng,
          address: this.currentOffice.location_name || "",
        };
        this.locationSearch = locationData.address;
        this.mapCenter = { lat: locationData.lat, lng: locationData.lng };
        this.markerPosition = { lat: locationData.lat, lng: locationData.lng };
      } else {
        console.warn("Location data is not available or invalid.");
      }
    },
    updateMarker() {
      if (!this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }

      this.geocoder.geocode(
        { address: this.locationSearch },
        (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            this.mapCenter = { lat: location.lat(), lng: location.lng() };
            this.markerPosition = { lat: location.lat(), lng: location.lng() };
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    },
    initAutocomplete() {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { types: ["geocode"] }
      );

      this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        this.mapCenter = { lat: location.lat(), lng: location.lng() };
        this.markerPosition = { lat: location.lat(), lng: location.lng() };
        this.locationSearch = place.formatted_address;
      }
    },
    getOffice(officeId) {
      OfficesDataService.get(officeId)
        .then((response) => {
          const officeData = response.data.office;
          this.currentOffice = {
            id: officeData.id,
            officeName: officeData.office_name || "",
            area_id: officeData.area_id || [],
            area: officeData.area || "",
            // teams: officeData.teams  || [],
            primaryIncharge: officeData.primary_incharge || "",
            secondaryIncharge: officeData.secondary_incharge || "",
            location_name: officeData.location_name || "",
            location: officeData.location || "",
          };
          this.fetchLocationData();
          // Set initial coordinates and address for the map
          // if (officeData.location && officeData.location.coordinates) {
          //   this.initialCoordinates = {
          //     lat: officeData.location.coordinates[1],
          //     lng: officeData.location.coordinates[0]
          //   };
          //   this.initialAddress = officeData.location_name;
          // }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateOffice() {
      this.isLoading = true;
      const selectedArea = this.areas.find(
        (area) => area.id === this.currentOffice.area_id
      );

      const payload = {
        office_id: this.currentOffice.id,
        office_name: this.currentOffice.officeName,
        primary_incharge: this.currentOffice.primaryIncharge,
        secondary_incharge: this.currentOffice.secondaryIncharge,
        location: {
          type: "Point",
          coordinates: [this.markerPosition.lng, this.markerPosition.lat],
        },
        location_name: this.locationSearch,
        area: selectedArea ? selectedArea.area : "",
        area_id: selectedArea ? selectedArea.id : "",
        teams: Array.isArray(this.currentOffice.teams)
          ? this.currentOffice.teams.map((team) => team.id)
          : [], // Check if teams is defined
      };
      console.log(payload);

      OfficesDataService.update(payload).then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Office updated successfully!",
          confirmButtonText: "OK",
        }).then(() => {
          this.$router.push("/office");
          this.events.push(payload);
        });

        console.log(response);
        this.showAddModal = false;
      });
    },
  },
  mounted() {
    this.initAutocomplete();
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.card {
  margin-bottom: 20px;
}

.btn {
  background-color: #141727;
  color: white;
  border: 2px solid #141727;
}
</style>
