import http from '../http-common';

class SurveryMasterDataService {
  getAll() {
    return http.get("/surveys");
  }

  get(id) {
    return http.get(`/surveys/edit/${id}`);
  }

  create(data) {
    return http.post("/surveys/add", data);
  }

  update(data) {
    return http.put(`/surveys/update`, data);
  }

  delete(id) {
    return http.put(`/surveys/delete/${id}`);
  }
}

export default new SurveryMasterDataService();
