<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        ></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center fordesktop" style="margin-right:20px">
            <div class="form-group langdrop">
              <label for="language-selector" class="sr-only">{{ $t('select_language') }}</label>
              <select
                id="language-selector"
                class="form-control langselector"
                @change="switchLanguage($event.target.value)"
                :value="$i18n.locale"
              >
                <option value="">{{ $t('select_language') }}</option>
                <option value="en">{{ $t('English') }}</option>
                <option value="hi">{{ $t('हिन्दी') }}</option>
                <option value="mr">{{ $t('मराठी') }}</option>
              </select>
            </div>
          </li>

          <li class="nav-item d-flex align-items-center fordesktop">
            <router-link
              :to="{ name: 'Sign In' }"
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
              @click="closeSidebarOnMobile"
            >
              <i
                class="fa fa-user"
                :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-1'"
              ></i>
              <span v-if="loading">Loading...</span> <!-- Loader text or spinner -->
              <span v-else @click="logout">Sign Out</span> <!-- Logout button -->
            </router-link>
          </li>

          <!-- <li class="nav-item d-xl-none ps-3 d-flex align-items-center formobile">
            <span v-if="loading">Loading...</span>
            <span v-else @click="logout">Sign Out</span>
          </li> -->

          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
//import UsersDataService from "../../services/UsersDataService";
import axios from "axios";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      selectedLanguage: this.$i18n.locale,
      loading: false, // Loading state
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    async logout() {
      this.loading = true; // Show loader

      try {
        //const response = await UsersDataService.logout();
        //console.log(response);
        const token = localStorage.getItem("token");
        const tenantId = localStorage.getItem('tenantId');
      axios
        .get(
          `https://rushivarya-stg-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/log-out`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((response) => {
          console.log('logout admin successfull:', response); 
          
          // Remove token from localStorage, sessionStorage, and cookies
          localStorage.removeItem('token');
          sessionStorage.removeItem('token');
          this.deleteCookie('token');

          // Redirect to login or home page
          this.$router.push({ name: '/' });
        })
        .catch((error) => {
          console.error("Error in logout admin:", error);
        });

      } catch (error) {
        console.error("Error updating events:", error);
      } finally {
        this.loading = false; // Hide loader
      }
    },
    deleteCookie(name) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    closeSidebarOnMobile() {
      if (window.innerWidth < 992) {
        this.navbarMinimize();
      }
    },
    toggleDropdownMenu() {
      this.showMenu = !this.showMenu;
      if (window.innerWidth < 992) {
        this.closeSidebarOnMobile();
      }
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>


<style scoped>
.langselector{
  width:150px !important;
}
@media (min-width: 300px) and (max-width:550px){
  .langselector{
    width:140px !important;
    margin-left: -90px;
    margin-top: 10px;
  }
}
.langdrop{
   /* width:130px;margin-left:87%; */
}
@media (min-width:300px) and (max-width:550px){
  .langdrop{
   width:130px;margin-left:57%;
}
}
.fordesktop{
  display: block;
}
.formobile{
  display: none;
}
@media (min-width:300px) and (max-width:500px){
  .fordesktop{
  display: none;
  }
  .formobile{
    display: block;
  }
}
</style>
