<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    ></div>
    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addSurvey" class="container">
        <!-- Survey Details -->
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyName">{{ $t("survey_name") }}</label>
              <input
                v-model="newSurvey.name"
                type="text"
                class="form-control"
                id="surveyName"
                required
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyArea">{{ $t("area") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newSurvey.area"
                  class="form-control select-with-arrow"
                  id="surveyArea"
                  required
                >
                  <option value="" selected>{{ $t("select_area") }}</option>
                  <option
                    v-for="group in area"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyGroup">{{ $t("group_name") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newSurvey.surveyGroup"
                  class="form-control select-with-arrow"
                  id="surveyGroup"
                >
                  <option value="" selected>
                    {{ $t("select_group_name") }}
                  </option>
                  <option
                    v-for="group in groups"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.voter_group_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Event Details -->
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="surveyEvent">{{ $t("event_name") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newSurvey.surveyEvent"
                  class="form-control select-with-arrow"
                  id="surveyEvent"
                  required
                >
                  <option value="" selected>
                    {{ $t("select_event_name") }}
                  </option>
                  <option
                    v-for="event in events"
                    :key="event.id"
                    :value="event.id"
                  >
                    {{ event.event_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="form-group">
              <label for="StartDate">{{ $t("start_date") }}</label>
              <input
                v-model="newSurvey.StartDate"
                type="datetime-local"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="EndDate">{{ $t("end_date") }}</label>
              <input
                v-model="newSurvey.EndDate"
                type="datetime-local"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>

        <!-- Location Details -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="surveyLocation">{{ $t("location") }}</label>
              <google-map
                v-model="newSurvey.location"
                @point-format="updatePointFormat"
                @address-changed="updateAddress"
                required
              />
            </div>
          </div>
        </div>

        <!-- Survey Description -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="surveyDescription">{{
                $t("survey_description")
              }}</label>
              <textarea
                v-model="newSurvey.survery_descriptions"
                class="form-control"
                id="surveyDescription"
                cols="30"
                rows="4"
                required
              ></textarea>
            </div>
          </div>
        </div>

        <!-- Select Questions -->
        <div class="form-group">
          <label>{{ $t("select_questions") }}</label
          ><br />
          <div class="row">
            <div class="form-group">
              <div class="col-sm-6">
                <select
                  v-model="newSurvey.category_id"
                  id="category_id"
                  class="form-control"
                  aria-label="category"
                >
                  <option value="" selected>{{ $t("select_category") }}</option>
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.category_name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="col-md-4"
              v-for="(question, index) in limitedFilteredQuestions"
              :key="index"
            >
              <div class="form-check">
                <input
                  type="checkbox"
                  v-model="newSurvey.questions"
                  :value="question.id"
                  class="form-check-input"
                  :id="'question' + index"
                />
                <label class="form-check-label" :for="'question' + index">{{
                  question.text
                }}</label>
              </div>
            </div>
          </div>
          <button
            v-if="limitedFilteredQuestions.length < filteredQuestions.length"
            @click="loadMore"
            type="button"
            class="btn btn-secondary mt-3"
          >
            {{ $t("load_more") }}
          </button>
        </div>

        <!-- Submit Button -->
        <center>
          <button
            type="submit"
            class="btn btn-primary mt-3"
            style="
              background-color: #141727;
              color: white;
              border: 2px solid #141727;
            "
            :disabled="isLoading"
            :loading="isLoading"
          >
            {{ $t("add_survey") }}
          </button>
        </center>
      </form>
    </div>
  </div>
</template>

<script>
import GoogleMap from "./GoogleMap.vue";
import AreaDataService from "../../services/AreaDataService.js";
import QuestionsDataService from "../../services/QuestionsDataService.js";
import EventDataService from "../../services/EventDataService.js";
import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import SurveryMasterDataService from "../../services/SurveryMasterDataService.js";
import CategoryMasterDataService from "../../services/CategoryMasterDataService.js";
import Swal from "sweetalert2";

export default {
  name: "add-survey",
  data() {
    return {
      surveys: [],
      isLoading: false,
      newSurvey: {
        name: "",
        location: "",
        address: "",
        location_name: "",
        area: "",
        survery_descriptions: "",
        StartDate: "",
        EndDate: "",
        surveyEvent: "",
        surveyGroup: "",
        questions: [],
        category_id: "",
      },
      area: [],
      events: [],
      groups: [],
      showModal: false,
      showQuestionsModalFlag: false,
      selectedSurveyQuestions: [],
      availableQuestions: [],
      categories: [],
      questionsToShow: 10,
    };
  },
  components: {
    GoogleMap,
  },
  created() {
    this.getAllAreas();
    this.getAllEvents();
    this.getAllGroups();
    this.generateQuestions();
    this.getAllCategory();
  },
  computed: {
    filteredQuestions() {
      if (this.newSurvey.category_id) {
        const filtered = this.availableQuestions.filter(
          (question) => question.category_id === this.newSurvey.category_id
        );
        console.log("Filtered Questions:", filtered); // Debug log
        return filtered;
      } else {
        console.log("All Questions:", this.availableQuestions); // Debug log
        return this.availableQuestions;
      }
    },
    limitedFilteredQuestions() {
      const limited = this.filteredQuestions.slice(0, this.questionsToShow);
      console.log("Limited Filtered Questions:", limited); // Debug log
      return limited;
    },
  },
  methods: {
    getAllAreas() {
      AreaDataService.getAll()
        .then((response) => {
          this.area = response.data.area || [];
          console.log("Fetched Areas:", this.area); // Debug log
        })
        .catch((e) => {
          console.log(e);
          this.area = [];
        });
    },
    getAllCategory() {
      CategoryMasterDataService.getAll()
        .then((response) => {
          this.categories = response.data.categoryMasterData || [];
          console.log("Fetched Categories:", this.categories); // Debug log
        })
        .catch((e) => {
          console.log(e);
          this.categories = [];
        });
    },
    getAllEvents() {
      EventDataService.getAll()
        .then((response) => {
          this.events = response.data.events || [];
          console.log("Fetched Events:", this.events); // Debug log
        })
        .catch((e) => {
          console.log(e);
          this.events = [];
        });
    },
    getAllGroups() {
      VoterGroupsDataService.getAll()
        .then((response) => {
          this.groups = response.data.voter_group || [];
          console.log("Fetched Groups:", this.groups); // Debug log
        })
        .catch((e) => {
          console.log(e);
          this.groups = [];
        });
    },
    generateQuestions() {
      QuestionsDataService.getAll()
        .then((response) => {
          this.availableQuestions =
            response.data.surveyQuestions.map((question) => ({
              id: question.id,
              text: question.question,
              category_id: question.category_id,
            })) || [];
          console.log("Fetched Questions:", this.availableQuestions); // Debug log
        })
        .catch((e) => {
          console.log(e);
          this.availableQuestions = [];
        });
    },
    updateAddress(address) {
      this.newSurvey.address = address;
    },
    updatePointFormat(pointFormat) {
      this.newSurvey.location_name = pointFormat;
    },
    addSurvey() {
      this.isLoading = true;
      // Check if a location has been selected
      const selectedArea = this.area.find(
        (area) => area.id === this.newSurvey.area
      );
      const locationString = this.newSurvey.location_name;
      const coordinatesMatch = locationString.match(/POINT\(([^)]+)\)/);
      let coordinates = [];

      // Extract coordinates if available
      if (coordinatesMatch) {
        coordinates = coordinatesMatch[1].split(" ").map(Number);
      }

      // Validate the coordinates
      if (
        !coordinates.length ||
        coordinates.length !== 2 ||
        isNaN(coordinates[0]) ||
        isNaN(coordinates[1])
      ) {
        Swal.fire({
          icon: "warning",
          title: "Invalid Location",
          text: "Please Select location",
          confirmButtonText: "OK",
        });
        return; // Stop execution if the location is invalid
      }

      console.log("New Survey Data before submission:", this.newSurvey); // Debug: Log the newSurvey object before creating newSurvey object

      const newSurvey = {
        id: this.surveys.length + 1,
        survey_name: this.newSurvey.name,
        location: {
          type: "Point",
          coordinates: coordinates, // Ensure this is an array with [longitude, latitude]
        },
        address: this.newSurvey.address,
        location_name: this.newSurvey.address, // Ensure location_name is set properly
        area: selectedArea ? selectedArea.area : "",
        area_id: selectedArea ? selectedArea.id : "",
        event_id: this.newSurvey.surveyEvent,
        group_id: this.newSurvey.surveyGroup,
        valid_from: this.newSurvey.StartDate,
        valid_to: this.newSurvey.EndDate,
        survey_description: this.newSurvey.survery_descriptions,
        questions: this.newSurvey.questions,
      };

      console.log("New Survey object to be sent:", newSurvey); // Debug: Log the newSurvey object after creating it

      // Assuming SurveryMasterDataService.create() handles the creation
      SurveryMasterDataService.create(newSurvey)
        .then((response) => {
          console.log("Response from server:", response);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Survey added successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/survey");
          });
        })
        .catch((error) => {
          console.error("Error adding survey:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to add survey. Please try again later.",
            confirmButtonText: "OK",
          });
        });
    },

    loadMore() {
      this.questionsToShow += 10;
    },
  },
};
</script>
<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Space for the custom arrow */
  border: 1px solid #ced4da; /* Match the border style */
  border-radius: 0.25rem; /* Optional: to match the form styling */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode character for down arrow */
  font-size: 0.8rem; /* Adjust size if needed */
  color: #495057; /* Adjust arrow color if needed */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't interfere with interactions */
}
</style>
