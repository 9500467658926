import http from '../http-common';

class RolesDataService {
  getAll() {
    return http.get("/roles");
  }

  get(id) {
    return http.get(`/roles/${id}`);
  }

  create(data) {
    return http.post("/roles/add", data);
  }

  update(id, data) {
    return http.put(`/roles/update/${id}`, data);
  }

  
  delete(id) {
    return http.put(`/roles/delete/${id}`);
  }
}

export default new RolesDataService();
