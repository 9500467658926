<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <MaterialType />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <projects-table />
      </div>
    </div> -->
  </div>
</template>

<script>
import MaterialType from "./components/MaterialType";
// import ProjectsTable from "./components/ProjectsTable";

export default {
  name: "tables",
  components: {
    MaterialType,
    // ProjectsTable,
  },
};
</script>
