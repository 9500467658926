<template>
  <div>
    <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/socail_media.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="mx-auto col-xl-8 col-lg-9 col-md-10">
          <div class="card z-index-0">
            <div class="pt-4 text-center card-header">
              <h5>{{ $t("upload_post") }}</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent="addCandidates">
                <div class="row mb-3">
                  <label for="profile_photo" class="form-control-label">
                    {{ $t("upload_media") }}
                  </label>
                  <div class="col-md-6">
                    <div class="form-group text-center">
                      <div>
                        <img
                          :src="uploadImageUrl"
                          alt="Upload Image"
                          class="img-fluid mb-2"
                          style="
                            width: 100px;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          @click="triggerAadhaarFileInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="aadhaar"
                        ref="aadhaarFileInput"
                        class="d-none"
                        aria-label="Aadhaar Upload"
                        @change="onAadhaarChange"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="form-group text-center">
                      <div>
                        <img
                          :src="uploadVideoUrl"
                          alt="Upload Video"
                          class="img-fluid mb-2"
                          style="width: 100px; object-fit: cover; cursor: pointer;"
                          @click="triggerProfileFileInput"
                        />
                      </div>
                      <input
                        type="file"
                        id="profile_photo"
                        ref="profileFileInput"
                        class="d-none"
                        aria-label="Profile Picture"
                        @change="onProfilePhotoChange"
                      />
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-md-8">
                      <label for="title" class="form-control-label">
                        {{ $t("title") }}
                      </label>
                      <soft-input
                        v-model="newCandidate.title"
                        id="title"
                        type="text"
                        placeholder="Add Title"
                        aria-label="Add Title"
                        icon="mdi-account"
                      />
                      <span v-if="errors.title" class="error">{{
                        errors.title
                      }}</span>
                    </div>

                    <div class="col-md-4">
                      <label for="platform" class="form-control-label">
                        {{ $t("platform_type") }}
                      </label>
                      <select
                        v-model="newCandidate.platform"
                        id="platform"
                        class="form-control"
                        aria-label="{{ $t('platform_type') }}"
                        required
                      >
                        <option value="" disabled selected>
                          {{ $t("select_platform") }}
                        </option>
                        <option value="whatsapp">{{ $t("whatsapp") }}</option>
                        <option value="facebook">{{ $t("facebook") }}</option>
                        <option value="twitter">{{ $t("twitter") }}</option>
                        <option value="instagram">{{ $t("instagram") }}</option>
                        <option value="linkedin">{{ $t("linkedin") }}</option>
                      </select>
                      <span v-if="errors.platform" class="error">{{
                        errors.platform
                      }}</span>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="PostDescription" class="form-control-label">
                        {{ $t("post_description") }}
                      </label>
                      <textarea
                        v-model="newCandidate.text_content"
                        id="PostDescription"
                        class="form-control"
                        placeholder="post Description"
                        aria-label="post Description"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="area">{{ $t("area") }}</label>
                      <multiselect
                        v-model="newCandidate.area"
                        :options="availableArea"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Pick some"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="teamMembers">{{ $t("teams") }}</label>
                      <multiselect
                        v-model="newCandidate.teams"
                        :options="availableTeams"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Pick some"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Group">{{ $t("offices") }}</label>
                      <multiselect
                        v-model="newCandidate.offices"
                        :options="availableOffices"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Pick some"
                        label="office_name"
                        track-by="office_name"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Group">{{ $t("voters_group") }}</label>
                      <multiselect
                        v-model="newCandidate.voter_group"
                        :options="availableGroup"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Pick some"
                        label="voter_group_name"
                        track-by="voter_group_name"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="start_date_time">{{
                        $t("start_date")
                      }}</label>
                      <input
                        type="date"
                        v-model="newCandidate.start_date_time"
                        class="form-control"
                        id="start_date_time"
                        required
                      />
                      <span class="error"></span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="end_date_time">{{ $t("end_date") }}</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="newCandidate.end_date_time"
                        id="end_date_time"
                        required
                      />
                      <span class="error"></span>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <soft-button
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                  >
                    {{ $t("add_post") }}
                  </soft-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import uploadVideoUrl from "@/assets/img/upload_video.png";
import uploadImageUrl from "@/assets/img/upload_image.png";
import SoftButton from "@/components/SoftButton.vue";
// import SoftRadio from "@/components/SoftRadio.vue";
import Swal from "sweetalert2";
import UsersDataService from "../../services/UsersDataService.js";
import OfficesDataService from "../../services/OfficesDataService.js";
import SocialMediaPostDataService from "../../services/SocialMediaPostDataService.js";
import AreaDataService from "../../services/AreaDataService.js";
import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import TeamDataService from "../../services/TeamDataService.js";
import { handleApiErrors } from "@/utils/errorHandler";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "SignupBasic",

  data() {
    return {
      users: [],
      offices: [],
      area: [],
      voter_group: [], // Initialize groups as an empty array
      newCandidate: {
        title: "",
        platform: "",
        start_date_time: "",
        end_date_time: "",
        area: [],
        teams: [],
        voter_group: [],
        offices: [],
        text_content: "",
        adharcard_picture: "",
      },
      errors: {},
      uploadVideoUrl,
      uploadImageUrl,
      availableTeams: [], // Initialize availableTeams as an empty array
      availableArea: [], // Initialize availableTeams as an empty array
      availableGroup: [], // Initialize availableTeams as an empty array
      availableOffices: [], // Initialize availableTeams as an empty array
    };
  },
  components: {
    SoftInput,
    SoftButton,
    Multiselect,
  },
  created() {
    this.getAllUsers();
    this.getOffice();
    this.getAllAreas();
    this.getAllTeams();
    this.getAllGroups();
  },
  methods: {
    getAllUsers() {
      UsersDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getOffice() {
      OfficesDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.availableOffices = response.data.offices;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllAreas() {
      AreaDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.availableArea = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllTeams() {
      TeamDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.availableTeams = response.data.teams;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllGroups() {
      VoterGroupsDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.availableGroup = response.data.voter_group;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    triggerAadhaarFileInput() {
      this.$refs.aadhaarFileInput.click();
    },
    async onAadhaarChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.newCandidate.adharcard_picture = await this.convertToBase64(file);
        this.uploadImageUrl = URL.createObjectURL(file);
      }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async addCandidates() {
      try {
        const newTask = {
          title: this.newCandidate.title,
          platform: this.newCandidate.platform,
          start_date: this.newCandidate.start_date_time,
          end_date: this.newCandidate.end_date_time,
          text_content: this.newCandidate.text_content,
          area_id: this.newCandidate.area.map((area) => area.id),
          team_id: this.newCandidate.teams.map((teams) => teams.id),
          voter_group_id: this.newCandidate.voter_group.map(
            (groups) => groups.id
          ),
          office_id: this.newCandidate.offices.map((offices) => offices.id),
          media_url: this.newCandidate.adharcard_picture,
        };
        console.log(newTask);
        SocialMediaPostDataService.create(newTask)
          .then((response) => {
            console.log(response);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Social media post created successfully!",
              confirmButtonText: "OK",
            }).then(() => {
              this.$router.push("/social-media-post");
            });
          })
          .catch((error) => {
            const errorMessages = handleApiErrors(error);
            if (errorMessages) {
              this.errors = errorMessages;
            }
            console.log(error);
          });
        // Reset the form after successful submission
        // this.newCandidate = {
        //   org_id: "",
        //   reporting_to: "",
        //   title: "",
        //   area: [],
        //   phone_no: "",
        //   email: "",
        //   password: "",
        //   gender: "", // Ensure this matches the value in the SoftRadio component
        //   office: "",
        //   surveyGroup: "",
        //   teams: [],
        //   groups: [],
        //   offices: [],
        //   text_content: "",
        //   profile_picture: "",
        // };

        // this.uploadImageUrl = require('@/assets/img/upload_image.png');
        // this.uploadVideoUrl = require('@/assets/img/upload_video.png');
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          // Display error messages using SweetAlert
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: handleApiErrors(error.response.data),
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      }
    },
  },
};
</script>

<style>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.form-control-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
</style>
