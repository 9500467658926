<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h5>Edit Event</h5>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <form @submit.prevent="addTask" class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="taskName">{{ $t("event_name") }}</label>
              <input
                v-model="newEvent.event_name"
                type="text"
                class="form-control"
                id="taskName"
                required
              />
              <span v-if="errors.event_name" class="error">{{
                errors.event_name
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="taskName">{{ $t("area") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.area_id"
                  id="location"
                  class="form-control select-with-arrow"
                  aria-label="location"
                  required
                >
                  <option value="" selected>{{ $t("select_area") }}</option>
                  <option
                    v-for="area in locations"
                    :key="area.id"
                    :value="area.id"
                  >
                    {{ area.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="startDateTime">{{ $t("start_date_time") }}</label>
              <input
                v-model="newEvent.startDateTime"
                type="datetime-local"
                class="form-control"
                id="startDateTime"
                required
              />
              <span v-if="errors.event_start" class="error">{{
                errors.event_start
              }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="endDateTime">{{ $t("end_date_time") }}</label>
              <input
                v-model="newEvent.endDateTime"
                type="datetime-local"
                class="form-control"
                id="endDateTime"
                required
              />
              <span v-if="errors.event_end" class="error">{{
                errors.event_end
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="vehicles">{{ $t("vehicles") }}</label>
              <multiselect
                v-model="newEvent.vehicles"
                :options="availableVehicles"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="vehicle_name"
                track-by="vehicle_name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="resources">{{ $t("resources") }}</label>
              <multiselect
                v-model="newEvent.resources"
                :options="availableResources"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="resource_name"
                track-by="resource_name"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="candidate_id" class="form-control-label">
                {{ $t("Candidate") }}
              </label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.candidate_id"
                  id="candidate_id"
                  class="form-control select-with-arrow"
                  aria-label="Candidate"
                >
                  <option value="" disabled selected>
                    {{ $t("select_candidate") }}
                  </option>
                  <option
                    v-for="candidate in candidates"
                    :key="candidate.id"
                    :value="candidate.id"
                  >
                    {{ candidate.f_name }} {{ candidate.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="team">{{ $t("team") }}</label>
              <multiselect
                v-model="newEvent.team"
                :options="availableTeams"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="name"
                track-by="id"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="votersGroup">{{ $t("voters_group") }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.voter_group_id"
                  id="votersGroup"
                  class="form-control select-with-arrow"
                  aria-label="votersGroup"
                >
                  <option value="" selected>
                    {{ $t("select_voters_group") }}
                  </option>
                  <option
                    v-for="voterG in votersGroup"
                    :key="voterG.id"
                    :value="voterG.id"
                  >
                    {{ voterG.voter_group_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="first_contact">{{
                $t("select_first_contact")
              }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.first_contact"
                  id="first_contact"
                  class="form-control select-with-arrow"
                  aria-label="first_contact"
                  required
                >
                  <option value="" selected>
                    {{ $t("select_first_contact") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="second_contact">{{
                $t("select_second_contact")
              }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.second_contact"
                  id="second_contact"
                  class="form-control select-with-arrow"
                  aria-label="second_contact"
                >
                  <option value="" selected>
                    {{ $t("select_second_contact") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="third_contact">{{
                $t("select_third_contact")
              }}</label>
              <div class="select-wrapper">
                <select
                  v-model="newEvent.third_contact"
                  id="third_contact"
                  class="form-control select-with-arrow"
                  aria-label="third_contact"
                >
                  <option value="" selected>
                    {{ $t("select_third_contact") }}
                  </option>
                  <option v-for="user in users" :key="user.id" :value="user.id">
                    {{ user.f_name }} {{ user.l_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="votersGroupLocation">{{
                $t("location_point")
              }}</label>
              <!-- <input v-model="locationSearch" @input="updateMarker" id="autocomplete" class="form-control"  placeholder="Search for a location" />
              <GoogleMapEditTry :center="mapCenter" :markerPosition="markerPosition" class="form-control"  /> -->

              <input
                id="autocomplete"
                v-model="locationSearch"
                placeholder="Search for a location"
                class="form-control"
              />
              <GoogleMapEdit
                :center="mapCenter"
                :markerPosition="markerPosition"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button
              type="submit"
              class="btn btn-primary mt-3"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
              " :disabled="isLoading"
        :loading="isLoading"
            >
              {{ $t("update_event") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import GoogleMapEdit from "./GoogleMapEdit.vue";
import Multiselect from "vue-multiselect";
import VehicleDataService from "../../services/VehicleDataService.js";
import ResourcesDataService from "../../services/ResourcesDataService.js";
import EventDataService from "../../services/EventDataService.js";
import { formatDateTime } from "../../utils.js";
import AreaDataService from "../../services/AreaDataService.js";
import UsersDataService from "../../services/UsersDataService.js";
import VoterGroupsDataService from "../../services/VoterGroupsDataService.js";
import TeamDataService from "../../services/TeamDataService.js";
import Swal from "sweetalert2";
import { handleApiErrors } from "@/utils/errorHandler";

export default {
  name: "AddEvent",
  components: {
    Multiselect,
    GoogleMapEdit,
  },
  data() {
    return {
      locationSearch: "",
      mapCenter: { lat: 0, lng: 0 },
      markerPosition: { lat: 0, lng: 0 },
      geocoder: null,
      autocomplete: null,
      events: [],
      locations: [],
      candidates: [],
      isLoading: false,
      newEvent: {
        id: "",
        voter_group_id: "",
        address: "",
        event_name: "",
        candidate_id: "",
        location: "",
        location_name: "",
        startDateTime: "",
        endDateTime: "",
        first_contact: "",
        second_contact: "",
        third_contact: "",
        area: "",
        area_id: "",
        team: [],
        vehicles: [],
        resources: [],
      },
      availableVehicles: [],
      availableResources: [],
      users: [],
      votersGroup: [],
      availableTeams: [],
      errors: {},
    };
  },
  created() {
    this.eventId = this.$route.params.id;
    this.getEvent(this.eventId);
    this.getAllVehicles();
    this.getAllResources();
    this.getAllArea();
    this.getAllUser();
    this.getAllVotersGroup();
    this.getAllTeams();
    this.getCandidates();
  },
  methods: {
    getCandidates() {
      UsersDataService.getAdminCandidate()
        .then((response) => {
          this.candidates = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getAllUser() {
      UsersDataService.getAll()
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllVotersGroup() {
      VoterGroupsDataService.getAll()
        .then((response) => {
          this.votersGroup = response.data.voter_group;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllTeams() {
      TeamDataService.getAll()
        .then((response) => {
          console.log("TEAM DATA : ", response.data.teams);
          this.availableTeams = response.data.teams;
          // Map the selected team IDs to the actual team objects
          this.newEvent.team = this.availableTeams.filter((team) =>
            this.event.team.includes(team.id)
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getAllVehicles() {
      VehicleDataService.getAll()
        .then((response) => {
          this.availableVehicles = response.data.vehicles;
          console.log(this.availableVehicles);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllResources() {
      // Assuming you have a data service for resources
      ResourcesDataService.getAll()
        .then((response) => {
          this.availableResources = response.data.resources;
          console.log(this.availableResources);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllArea() {
      AreaDataService.getAll()
        .then((response) => {
          console.log(response.data);
          this.locations = response.data.area;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchLocationData() {
      if (this.newEvent.location && this.newEvent.location.coordinates) {
        const [lng, lat] = this.newEvent.location.coordinates; // lng comes first in data
        const locationData = {
          lat: lat, // Lat comes first
          lng: lng, // Lng comes second
          address: this.newEvent.location_name || "",
        };
        this.locationSearch = locationData.address;
        this.mapCenter = { lat: locationData.lat, lng: locationData.lng };
        this.markerPosition = { lat: locationData.lat, lng: locationData.lng };
      } else {
        console.warn("Location data is not available or invalid.");
      }
    },
    updateMarker() {
      if (!this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }

      this.geocoder.geocode(
        { address: this.locationSearch },
        (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            this.mapCenter = { lat: location.lat(), lng: location.lng() };
            this.markerPosition = { lat: location.lat(), lng: location.lng() };
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    },
    getEvent(id) {
      EventDataService.get(id)
        .then((response) => {
          const event = response.data.event;

          // Reverse the coordinates for Google Maps (lat comes first)
          const [lng, lat] = event.location.coordinates;

          this.newEvent = {
            ...this.newEvent,
            id: event.id,
            event_name: event.event_name,
            startDateTime: formatDateTime(event.event_start),
            endDateTime: formatDateTime(event.event_end),
            candidate_id: event.candidate_id,
            first_contact: event.first_contact,
            second_contact: event.second_contact,
            third_contact: event.third_contact,
            area_id: event.area_id,
            voter_group_id: event.voter_group_id,
            vehicles: event.vehicles,
            resources: event.resources,
            team: event.team,
            locationSearch: event.location_name,
          };

          // Set map center and marker position correctly for Google Maps
          this.mapCenter = { lat: lat, lng: lng }; // Correct order: lat first
          this.markerPosition = { lat: lat, lng: lng }; // Correct order: lat first
        })
        .catch((e) => {
          console.error(e);
        });
    },
    initAutocomplete() {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { types: ["geocode"] }
      );

      this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        this.mapCenter = { lat: location.lat(), lng: location.lng() };
        this.markerPosition = { lat: location.lat(), lng: location.lng() };
        this.locationSearch = place.formatted_address;
      }
    },
    addTask() {
      this.isLoading = true;
      const selectedArea = this.locations.find(
        (area) => area.id === this.newEvent.area_id
      );

      console.log(this.markerPosition);

      const newEvent = {
        id: this.newEvent.id,
        event_name: this.newEvent.event_name,
        first_contact: this.newEvent.first_contact,
        second_contact: this.newEvent.second_contact,
        third_contact: this.newEvent.third_contact,
        voter_group_id: this.newEvent.voter_group_id,
        candidate_id: this.newEvent.candidate_id,
        //location: this.newEvent.location,
        location: {
          type: "Point",
          coordinates: [this.markerPosition.lng, this.markerPosition.lat],
        },
        location_name: this.locationSearch,
        area: selectedArea ? selectedArea.area : "",
        area_id: selectedArea ? selectedArea.id : "",
        event_start: this.newEvent.startDateTime,
        event_end: this.newEvent.endDateTime,
        vehicles: this.newEvent.vehicles.map((vehicle) => vehicle.id),
        resources: this.newEvent.resources.map((resource) => resource.id),
        team: this.newEvent.team.map((team) => team.id),
      };
      console.log(newEvent);
      EventDataService.update(newEvent)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Event update successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/events");
          });
        })
        .catch((error) => {
          const errorMessages = handleApiErrors(error);
          if (errorMessages) {
            this.errors = errorMessages;
          }
          console.error("Error updating events:", error);
        });
    },
  },
  mounted() {
    this.initAutocomplete();
  },
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
}

.select-with-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff; /* Ensure background color matches */
  padding-right: 2rem; /* Add padding for the arrow */
  border: 1px solid #ced4da; /* Match border style */
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  font-size: 0.8rem;
  color: #495057; /* Match color to your design */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicking on the arrow */
}
.card {
  margin-bottom: 20px;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 5px;
}

.text-success {
  margin-left: auto;
  font-weight: bold;
}

.error {
  color: red;
}
</style>
