import http from '../http-common';

class UsersDataService {
  getAll() {
    return http.get("/users-list");
  }

  getAdminCandidate() {
    return http.get("/candidate-dropdown-list");
  }

  logout() {
    return http.get(`/log-out`);
  }
  
  delete(data) {
    return http.post(`/sign-out`,data);
  }

  updateStep(data) {
    return http.post(`/update-step`,data);
  }
}

export default new UsersDataService();
