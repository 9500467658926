import http from '../http-common';

class VoterGroupsDataService {
  getAll() {
    return http.get("/voters-group");
  }

  get(id) {
    return http.get(`/voters-group/edit/${id}`);
  }

  update(data) {
    return http.put(`/voters-group/update`, data);
  }
  create(data) {
    return http.post("/voters-group/add", data);
  }
  voter_info_add(data) {
    return http.post("/voter-info-add", data);
  }
  delete(id) {
    return http.put(`/voters-group/delete/${id}`);
  }
}

export default new VoterGroupsDataService();
