<template>
  <div class="card mb-4">
    <div class="container">
      <div class="card-header pb-0">
        <h6>Edit</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <form @submit.prevent="updateLocation">
          <div class="form-group">
            <label for="locationName">Location Name</label>
            <input
              v-model="newLocation.name"
              type="text"
              class="form-control"
              id="locationName"
              required
            />
          </div>

          <!-- Voting Center Location Map -->
          <div class="form-group mt-3">
            <label for="votingCenterLocationSearch"
              >Voting Center Location</label
            >
            <input
              v-model="searchVotingCenter"
              ref="votingCenterSearchInput"
              type="text"
              class="form-control"
              id="votingCenterLocationSearch"
              placeholder="Search for voting center location"
            />
            <div
              id="votingCenterMap"
              style="height: 400px; margin-top: 10px"
            ></div>
          </div>

          <button
            type="submit"
            class="btn btn-primary mt-3"
            style="
              background-color: #141727;
              color: white;
              border: 2px solid #141727;
            " :disabled="isLoading"
        :loading="isLoading"
          >
            Update Location
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import AreaDataService from "../../services/AreaDataService.js";
import VotersSearchDataService from "../../services/VotersSearchDataService.js";

export default {
  data() {
    return {
      areaId: null,
      isLoading: false,
      newLocation: {
        name: "",
        voting_center_location: { lat: null, lng: null },
      },
      votingCenterMap: null,
      votingCenterMarker: null,
      searchVotingCenter: "",
    };
  },
  created() {
    this.areaId = this.$route.params.center_id; // Make sure params are being fetched correctly
    if (!this.areaId) {
      console.error("Center ID is missing in route params!");
      return;
    }
    this.getVotingLocation(this.areaId);
  },
  mounted() {
    this.initializeVotingCenterMap();
    this.initializeVotingCenterAutocomplete();
  },
  methods: {
    getVotingLocation(areaId) {
      if (!areaId) {
        console.error("Missing areaId for fetching voting location.");
        return;
      }

      VotersSearchDataService.getVotingLocation(areaId)
        .then((response) => {
          const areaData = response.data.matdan_kendra;

          // Update form fields with the fetched data
          this.newLocation.name = areaData.location_name;

          // Extract coordinates correctly from the API response
          const coordinates = areaData.voting_center_location
            ? areaData.voting_center_location.coordinates
            : null;

          if (coordinates) {
            // If coordinates exist, set the location
            this.newLocation.voting_center_location = {
              lat: coordinates[1], // Latitude
              lng: coordinates[0], // Longitude
            };
          } else {
            // If no coordinates, initialize location as null
            this.newLocation.voting_center_location = { lat: null, lng: null };
          }

          // Initialize the voting center map with this data
          this.initializeVotingCenterMap();
        })
        .catch((e) => {
          console.error("Error fetching voting location:", e);
        });
    },

    updateLocation() {
      this.isLoading = true;
      // Get acno from localStorage
      const acno = localStorage.getItem("defaultacno");

      // Ensure acno exists
      if (!acno) {
        console.error("ACNO is missing from localStorage!");
        return;
      }

      // Get center_id from the areaId (from the API response)
      const center_id = this.areaId;

      // Ensure center_id and voting_center_location exist
      if (!center_id) {
        console.error("Center ID is missing!");
        return;
      }

      if (
        !this.newLocation.voting_center_location.lat ||
        !this.newLocation.voting_center_location.lng
      ) {
        console.error("Voting center location is missing!");
        return;
      }

      // Create payload with the necessary data
      const payload = {
        id: center_id, // Use areaId as center_id
        acno: acno, // Send acno from localStorage
        voting_center_location: {
          type: "Point",
          coordinates: [
            this.newLocation.voting_center_location.lng, // Use lng from newLocation
            this.newLocation.voting_center_location.lat, // Use lat from newLocation
          ],
        },
      };

      // Log payload for debugging purposes
      console.log("Payload to be sent:", payload);

      // Send the update request using the service function
      VotersSearchDataService.VotingLocationUpdate(payload)
        .then((response) => {
          console.log("Update successful:", response);
          this.$router.push({ name: "Voting Center Location" });
        })
        .catch((e) => {
          console.error(
            "Error occurred during update:",
            e.response ? e.response.data : e.message
          );
        });
    },

    initializeVotingCenterMap() {
      const votingCenterMapOptions = {
        zoom: 12,
        center:
          this.newLocation.voting_center_location.lat &&
          this.newLocation.voting_center_location.lng
            ? this.newLocation.voting_center_location
            : { lat: 20.9319821, lng: 77.7523039 }, // Default center if location is null
      };

      this.votingCenterMap = new google.maps.Map(
        document.getElementById("votingCenterMap"),
        votingCenterMapOptions
      );

      // If location is not null, create a marker
      if (
        this.newLocation.voting_center_location.lat &&
        this.newLocation.voting_center_location.lng
      ) {
        this.votingCenterMarker = new google.maps.Marker({
          map: this.votingCenterMap,
          position: this.newLocation.voting_center_location,
          draggable: true,
        });

        // Handle drag end event for voting center marker
        google.maps.event.addListener(
          this.votingCenterMarker,
          "dragend",
          () => {
            const position = this.votingCenterMarker.getPosition();
            this.newLocation.voting_center_location = {
              lat: position.lat(),
              lng: position.lng(),
            };
          }
        );
      }
    },

    initializeVotingCenterAutocomplete() {
      const input = this.$refs.votingCenterSearchInput;
      const autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          // Update the new location based on the selected place
          this.newLocation.voting_center_location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          // Center the map on the new location
          this.votingCenterMap.setCenter(
            this.newLocation.voting_center_location
          );

          // Clear previous marker if exists
          if (this.votingCenterMarker) {
            this.votingCenterMarker.setMap(null);
          }

          // Add a new marker at the new location
          this.votingCenterMarker = new google.maps.Marker({
            position: this.newLocation.voting_center_location,
            map: this.votingCenterMap,
            draggable: true,
          });

          // Update newLocation on marker drag
          google.maps.event.addListener(
            this.votingCenterMarker,
            "dragend",
            () => {
              const position = this.votingCenterMarker.getPosition();
              this.newLocation.voting_center_location = {
                lat: position.lat(),
                lng: position.lng(),
              };
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #82d616;
}

input:focus + .slider {
  box-shadow: 0 0 1px #82d616;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.table {
  width: 100%;
  margin-top: 20px;
}
.table .btn {
  margin-right: 5px;
}
</style>
