import moment from 'moment-timezone';

export const formatDateTime = (dateTime) => {
    // const date = new Date(dateTime);
    // const pad = (num) => (num < 10 ? '0' + num : num);
    // const year = date.getFullYear();
    // const month = pad(date.getMonth() + 1);
    // const day = pad(date.getDate());
    // const hours = pad(date.getHours());
    // const minutes = pad(date.getMinutes());
    // return `${year}-${month}-${day}T${hours}:${minutes}`;

    const date = moment.tz(dateTime, 'Asia/Kolkata');
  return date.format('YYYY-MM-DDTHH:mm');
  };
  
export function parsePoint(pointString) {
  const match = pointString.match(/POINT\(([^ ]+) ([^ ]+)\)/);
  if (match) {
    const [lon, lat] = match;
    return { lat: parseFloat(lat), lng: parseFloat(lon) };
  }
  return { lat: 0, lng: 0 }; // Default to 0 if parsing fails
}
  